import React, { useCallback, useContext, useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import ReactTable from 'react-table';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import Input from '../../../../../common/data-entry/Input';
import Checkbox from '../../../../../common/data-entry/InputSelectors/Checkbox';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import { toFormattedBill } from '../../../../../services/financial';
import { CREDIT_MEMO, DEBIT_MEMO, WITHHOLDING } from '../../NewInvoice/InvoiceTable/TableConstants';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { calculateAmounts } from '../Reconcile';
import { ReconcileContext } from '../ReconcileContext';

import './ReconcileTable.scss';

export function ReconcileTable() {
  const {
    selectedItems,
    setSelectedItems,
    onTableDataChange,
    onReconcile,
    depositNumber,
    datePosted,
    changedEvents,
    eventsForTable
  } = useContext(ReconcileContext);

  const navigate = useNavigate();

  const headerCheckbox = useCallback(() => {
    const checkedAll = eventsForTable.length === selectedItems.length;
    const someChecked = selectedItems.length > 0 && selectedItems.length < eventsForTable.length;
    return (
      <Checkbox
        disabled={isEmpty(eventsForTable)}
        onChange={() =>
          setSelectedItems(() => {
            return checkedAll ? [] : eventsForTable.map(element => element.id);
          })
        }
        checked={checkedAll}
        indeterminate={!checkedAll && someChecked}
        data-testid="select-all-reconciles-checkbox"
      />
    );
  }, [eventsForTable, selectedItems, setSelectedItems]);

  const checkBoxItems = useCallback(
    origin => {
      const checked = !!selectedItems.includes(origin.id);
      return (
        <Checkbox
          onChange={() =>
            setSelectedItems(prevState =>
              checked ? prevState.filter(element => element !== origin.id) : [...prevState, origin.id]
            )
          }
          value={origin.id}
          checked={checked}
          data-testid={`checkbox-${origin.eventId}`}
        />
      );
    },
    [selectedItems, setSelectedItems]
  );

  const columns = useMemo(() => {
    return [
      {
        id: 'selected',
        sortable: false,
        width: 55,
        Header: headerCheckbox,
        accessor: origin => {
          return checkBoxItems(origin);
        }
      },
      {
        Header: 'Event ID',
        id: 'adjustmentVersionSequenceId',
        accessor: e => {
          return e.invoiceType === WITHHOLDING
            ? `${e.eventNumber}-WH`
            : e.invoiceType === CREDIT_MEMO
            ? `${e.eventNumber}.${e.eventAdjustmentSequence}-CM`
            : e.invoiceType === DEBIT_MEMO
            ? `${e.eventNumber}.${e.eventAdjustmentSequence}-DM`
            : e.eventNumber;
        },
        sortable: true,
        Cell: row => {
          if (row.original.invoiceType === WITHHOLDING) {
            return `${row.original.eventNumber}-WH`;
          }
          if (row.original.invoiceType === CREDIT_MEMO) {
            return `${row.original.eventNumber}.${row.original.eventAdjustmentSequence}-CM`;
          }
          if (row.original.invoiceType === DEBIT_MEMO) {
            return `${row.original.eventNumber}.${row.original.eventAdjustmentSequence}-DM`;
          }
          return row.original.eventNumber;
        }
      },
      {
        Header: 'Invoice Number',
        id: 'invoiceNumber',
        accessor: e => {
          return e.invoiceType === WITHHOLDING
            ? `${e.displayNumber}-WH`
            : e.invoiceType === CREDIT_MEMO
            ? `${e.displayNumber}-CM`
            : e.invoiceType === DEBIT_MEMO
            ? `${e.displayNumber}-DM`
            : e.displayNumber;
        },
        sortable: true,
        Cell: row => {
          return (
            <span className="tooltip-cell">
              {row.original.invoiceType === WITHHOLDING
                ? row.original.displayNumber + '-WH'
                : row.original.invoiceType === CREDIT_MEMO
                ? row.original.displayNumber + '-CM'
                : row.original.invoiceType === DEBIT_MEMO
                ? row.original.displayNumber + '-DM'
                : row.original.displayNumber}
            </span>
          );
        }
      },
      {
        Header: 'Invoice Amount',
        className: 'align-amount-end',
        headerClassName: 'align-amount-end',
        accessor: 'invoiceAmount',
        sortable: true,
        Cell: ({ value }) => {
          return <Amount coinsAmount={value} defaultZeroValue={''} showDollarSign />;
        }
      },
      {
        Header: 'Amount Paid',
        accessor: 'reconcileAmountPaid',
        Cell: row => {
          return (
            <NumericFormat
              className="reconcile-amount-paid"
              data-testid="reconcile-amount-input"
              onChange={({ target: { value } }) => {
                const newValue = isEmpty(value) || +value === 0 ? null : value.replace(/,/g, '');
                onTableDataChange(row.original.id, newValue, 'reconcileAmountPaid');
              }}
              value={row.value || ''}
              placeholder="0"
              validate={false}
              type="text"
              thousandSeparator=","
            />
          );
        }
      },
      {
        Header: 'Variance',
        className: 'align-amount-end',
        headerClassName: 'align-amount-end',
        Cell: row => {
          return <Amount coinsAmount={row?.original?.variance} showDollarSign />;
        }
      },
      {
        Header: 'Comments',
        accessor: 'reconcileComment',
        minWidth: 500,
        Cell: row => {
          return (
            <Input
              className="reconcile-input"
              data-testid="comment-input"
              onChange={({ target: { value } }) => {
                const newValue = isEmpty(value) ? '' : value;
                onTableDataChange(row.original.id, newValue, 'reconcileComment');
              }}
              value={row.value}
              validate={false}
              maxLength={512}
            />
          );
        }
      }
    ];
  }, [onTableDataChange, headerCheckbox, checkBoxItems]);

  return (
    <div className="reconcile-table-wrapper">
      <ReactTable
        data={eventsForTable}
        columns={columns}
        showPagination={false}
        minRows={1}
        sortable={false}
        className="reconcile-payments-table"
        pageSize={Number.MAX_VALUE}
      />
      <ButtonGroup>
        <Button size="h28" priority="medium" onClick={() => navigate('/invoice')}>
          Cancel
        </Button>
        <Button
          size="h28"
          onClick={() => {
            openConfirmWindow(onReconcile, changedEvents);
          }}
          disabled={
            !datePosted ||
            !datePosted.isSameOrBefore(moment()) ||
            !depositNumber ||
            depositNumber?.trim() === '' ||
            isEmpty(changedEvents) ||
            !isEmpty(changedEvents.find(event => event.reconcileAmountPaid === 0 && isEmpty(event.reconcileComment)))
          }
        >
          Reconcile
        </Button>
      </ButtonGroup>
    </div>
  );
}

const openConfirmWindow = (onReconcile, changedEvents) => {
  ModalBoxes.confirm({
    className: 'reconcile-payment-modal',
    title: 'Reconcile Payments',
    content: (
      <>
        <span className="reconcile-answer">Are you sure you want to reconcile these payments?</span>
        <div className="reconcile-information">
          <div data-testid="included-events-ids">
            <span>Included Event IDs </span> <span className="count">{changedEvents.length}</span>
          </div>
          <div data-testid="total-amount">
            <span>Total Amount</span>
            <Amount
              className="count"
              highlightIntegerValue
              coinsAmount={calculateAmounts(changedEvents, 'reconcileAmountPaid', false)}
              customCoinsFormat={toFormattedBill}
            />
          </div>
        </div>
      </>
    ),
    confirmButton: 'Yes',
    cancelButton: 'No'
  }).then(
    () => {
      onReconcile();
    },
    () => {}
  );
};
