import { isFunction, isString, isUndefined } from 'lodash/lang';
import { get } from 'lodash/object';

import NotificationManager from '../common/notifications/NotificationManager';
import { COMMENT_NOT_SAVED, SOMETHING_WENT_WRONG } from '../constants/notificationMessages';

import logger from './logger';
import { removeQuotesIfExist } from './string';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

export function onRequestError(err, params) {
  logger.error(err);

  const location = params && isString(params.messageLocation) ? params.messageLocation : 'response.data.message';
  const message =
    params && isString(params.customMessage) ? params.customMessage : get(err, location, DEFAULT_ERROR_MESSAGE);

  NotificationManager.error(message);
}

export function onRequestDefaultError(err) {
  logger.error(err);
  NotificationManager.error(DEFAULT_ERROR_MESSAGE);
}

export function onRequestErrorByStatus(error, messageLocation) {
  const message = get(error, messageLocation, DEFAULT_ERROR_MESSAGE);
  switch (error.response.status) {
    case 400: {
      NotificationManager.error(message);
      break;
    }
    case 409: {
      NotificationManager.warning(message);
      break;
    }
    default: {
      NotificationManager.error(DEFAULT_ERROR_MESSAGE);
      break;
    }
  }
}

export function handleFinLedgerEventIdFieldValidationError(error) {
  if (error.response?.data?.name?.includes('MethodArgumentNotValidException')) {
    NotificationManager.error(error.response.data.errors[0]);
  } else {
    NotificationManager.error(SOMETHING_WENT_WRONG);
  }
}

export function decodeFilenameInContentDisposition(header) {
  //according to https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
  //file name should be enclosed with ""
  const fileNameEncoded = header.match(/filename="([^"]+)"/)[1];
  const fileNameDecoded = new TextDecoder('utf-8').decode(Buffer.from(fileNameEncoded, 'base64'));
  return header.replace(/filename="[^"]+"/g, `filename="${fileNameDecoded}"`);
}

export function onBudgetFileSave({ data, headers }) {
  headers['content-disposition'] = decodeFilenameInContentDisposition(headers['content-disposition']);
  onFileSave({ data, headers });
}

export function onFileSave({ data, headers }) {
  const mime = headers['content-type'];
  const filename = removeQuotesIfExist(
    headers['content-disposition'].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
  );
  const blob = new Blob([data], { type: mime || 'application/octet-stream' });

  if (isFunction(window.navigator.msSaveBlob)) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const burl = window.URL.createObjectURL(blob);
    const tlink = document.createElement('a');
    tlink.href = burl;
    tlink.style.display = 'none';
    tlink.setAttribute('download', filename);

    if (isUndefined(tlink.download)) {
      tlink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tlink);
    tlink.click();
    document.body.removeChild(tlink);
    window.URL.revokeObjectURL(burl);
  }
}

export function handleReviewCommentExceptions(err) {
  if (err.response.status === 409) {
    NotificationManager.error(err.response.data.message);
  } else {
    NotificationManager.error(COMMENT_NOT_SAVED);
  }
  console.error(err);
}
