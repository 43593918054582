import React, { useCallback, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, ClickAwayListener, InputAdornment, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import { FinBudgetEventApi } from '../../../../../../../api';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import {
  PATIENT_REIMBURSEMENT_EXISTS,
  PROTOCOL_SETUP_IS_INCOMPLETE
} from '../../../../../../../constants/notificationMessages';
import { onRequestError } from '../../../../../../../services/handlers';
import AddOrEditEncounterBudgetEvent from '../../modal/encounter/AddOrEditEncounterBudgetEvent';
import AddOrEditEncounterItemGroupBudgetEvent from '../../modal/encounter-item-group/AddOrEditEncounterItemGroupBudgetEvent';
import AddOrEditEncounterStatusChangeEventModal from '../../modal/encounter-status-change/AddOrEditEncounterStatusChangeEventModal';
import { AddOrEditExpenseVariableEventModal } from '../../modal/expense-variable/AddOrEditExpenseVariableEventModal';
import AddOrEditFixedItemBudgetEvent from '../../modal/fixed-item/AddOrEditFixedItemBudgetEvent';
import AddOrEditItemGroupGeneralBudgetEvent from '../../modal/item-group/AddOrEditItemGroupGeneralBudgetEvent';
import AddOrEditPatientReimbursementBudgetEvent from '../../modal/patient-reimbursement/AddOrEditPatientReimbursementBudgetEvent';
import AddOrEditPatientStipendBudgetEvent from '../../modal/patient-stipend/AddOrEditPatientStipendBudgetEvent';
import AddOrEditStatusChangeEventModal from '../../modal/status-change/AddOrEditStatusChangeEventModal';

import './AddBudgetEvent.scss';

export const AddBudgetEvent = ({
  studyId,
  managementFeePercent,
  currentStudyBudget,
  encounterTriggers,
  itemGroupTriggers,
  vendors,
  budgetId,
  loadBudgetEvents,
  protocolIncomplete,
  events
}) => {
  const [showSelector, setShowSelector] = useState(false);

  const showAddBudgetEvent = useCallback(
    (Component, triggerType, budgetEventType, saveEndpoint) => {
      ModalBoxes.open({
        title: Component.title,
        component: (
          <Component
            triggerType={triggerType}
            budgetEventType={budgetEventType}
            className={triggerType === 'ENCOUNTER_ITEM_GROUP' ? 'enc-ig' : ''}
            //data is not set in add mode
            studyId={studyId}
            managementFeePercent={managementFeePercent}
            currentStudyBudget={currentStudyBudget}
            encounterTriggers={encounterTriggers}
            itemGroupTriggers={itemGroupTriggers}
            vendors={vendors}
            onSave={data => {
              return saveEndpoint(budgetId, data)
                .then(() => {
                  loadBudgetEvents();
                })
                .catch(onRequestError);
            }}
          />
        )
      });
    },
    [
      budgetId,
      currentStudyBudget,
      encounterTriggers,
      itemGroupTriggers,
      loadBudgetEvents,
      managementFeePercent,
      studyId,
      vendors
    ]
  );

  const showAddExpenseVariable = useCallback(() => {
    ModalBoxes.open({
      title: 'Add Expense - Variable budget event',
      component: (
        <AddOrEditExpenseVariableEventModal
          currentStudyBudget={currentStudyBudget}
          saveEvent={data => {
            return FinBudgetEventApi.createExpenseVariableEvent(budgetId, data).then(loadBudgetEvents, onRequestError);
          }}
        />
      ),
      size: 'w650',
      className: 'expense-variable-modal'
    });
  }, [budgetId, currentStudyBudget, loadBudgetEvents]);

  const showAddStatusChange = useCallback(
    (triggerType, budgetEventType) => {
      ModalBoxes.open({
        component: (
          <AddOrEditStatusChangeEventModal
            onSave={data => {
              return FinBudgetEventApi.createStatusChangeEvent(budgetId, data).then(loadBudgetEvents, onRequestError);
            }}
            triggerType={triggerType}
            budgetEventType={budgetEventType}
            currentStudyBudget={currentStudyBudget}
          />
        ),
        title: 'Status Change budget event'
      });
    },
    [budgetId, currentStudyBudget, loadBudgetEvents]
  );

  const showAddRatioStatusChange = useCallback(
    (triggerType, budgetEventType) => {
      ModalBoxes.open({
        title: 'Status Change with ratio budget event',
        component: (
          <AddOrEditStatusChangeEventModal
            onSave={data => {
              return FinBudgetEventApi.createRatioStatusChangesEvent(budgetId, data).then(
                loadBudgetEvents,
                onRequestError
              );
            }}
            triggerType={triggerType}
            budgetEventType={budgetEventType}
            currentStudyBudget={currentStudyBudget}
          />
        )
      });
    },
    [budgetId, currentStudyBudget, loadBudgetEvents]
  );

  const showAddEncounterStatusChange = useCallback(
    (triggerType, budgetEventType) => {
      ModalBoxes.open({
        title: 'Add or Edit Encounter Status Change budget event',
        component: (
          <AddOrEditEncounterStatusChangeEventModal
            onSave={data => {
              return FinBudgetEventApi.createEncounterStatusChanges(budgetId, data).then(
                loadBudgetEvents,
                onRequestError
              );
            }}
            triggerType={triggerType}
            budgetEventType={budgetEventType}
            studyId={studyId}
            currentStudyBudget={currentStudyBudget}
            encounterTriggers={encounterTriggers}
            managementFeePercent={managementFeePercent}
          />
        )
      });
    },
    [budgetId, currentStudyBudget, encounterTriggers, loadBudgetEvents, managementFeePercent, studyId]
  );

  const budgetEventOptions = useMemo(() => {
    return [
      {
        name: 'Encounter',
        disabled: protocolIncomplete,
        onSelect: () =>
          showAddBudgetEvent(
            AddOrEditEncounterBudgetEvent,
            'ENCOUNTER',
            'ENCOUNTER',
            FinBudgetEventApi.createEncounterEvent
          )
      },
      {
        name: 'Encounter - Item Group',
        disabled: protocolIncomplete,
        onSelect: () =>
          showAddBudgetEvent(
            AddOrEditEncounterItemGroupBudgetEvent,
            'ENCOUNTER_ITEM_GROUP', //triggerType -- used only for specific class name
            'ENCOUNTER_ITEM_GROUP', //budgetEventType
            FinBudgetEventApi.createEncounterItemgroupEvent
          )
      },
      {
        name: 'Encounter - Status Change',
        disabled: protocolIncomplete,
        onSelect: () => showAddEncounterStatusChange('ENCOUNTER_STATUS_CHANGE', 'ENCOUNTER_STATUS_CHANGE')
      },
      {
        name: 'Expense - Fixed',
        onSelect: () =>
          showAddBudgetEvent(
            AddOrEditFixedItemBudgetEvent,
            'FIXED_ITEM',
            'FIXED_ITEM',
            FinBudgetEventApi.createFixedItemEvent
          )
      },
      {
        name: 'Expense - Variable',
        onSelect: showAddExpenseVariable
      },
      {
        name: 'Item Group',
        disabled: protocolIncomplete,
        onSelect: () =>
          showAddBudgetEvent(
            AddOrEditItemGroupGeneralBudgetEvent,
            'ITEM_GROUP',
            'ITEM_GROUP_GENERAL',
            FinBudgetEventApi.createItemGroupEvent
          )
      },
      {
        name: 'Patient Reimbursement',
        disabled: events.some(event => event.type === 'PATIENT_REIMBURSEMENT') || protocolIncomplete,
        onSelect: () =>
          showAddBudgetEvent(
            AddOrEditPatientReimbursementBudgetEvent,
            'PATIENT_REIMBURSEMENT',
            'PATIENT_REIMBURSEMENT',
            FinBudgetEventApi.createPatientReimbursementEvent
          )
      },
      {
        name: 'Patient Stipend',
        disabled: protocolIncomplete,
        onSelect: () =>
          showAddBudgetEvent(
            AddOrEditPatientStipendBudgetEvent,
            'ITEM_GROUP',
            'ITEM_GROUP_PATIENT_STIPEND',
            FinBudgetEventApi.createPatientStipendEvent
          )
      },
      {
        name: 'Status Change',
        disabled: protocolIncomplete,
        onSelect: () => showAddStatusChange('STATUS_CHANGE', 'STATUS_CHANGE')
      },
      {
        name: 'Status Ratio',
        disabled: protocolIncomplete,
        onSelect: () => showAddRatioStatusChange('RATIO_STATUS_CHANGE', 'RATIO_STATUS_CHANGE')
      }
    ];
  }, [
    events,
    protocolIncomplete,
    showAddBudgetEvent,
    showAddEncounterStatusChange,
    showAddExpenseVariable,
    showAddRatioStatusChange,
    showAddStatusChange
  ]);

  const renderOption = (props, option) => {
    if (option?.disabled) {
      const tooltipTitle =
        option.name === 'Patient Reimbursement' && !protocolIncomplete
          ? PATIENT_REIMBURSEMENT_EXISTS
          : PROTOCOL_SETUP_IS_INCOMPLETE;
      return renderTooltip(props, option, tooltipTitle);
    } else {
      return <li {...props}>{option.name}</li>;
    }
  };

  const renderTooltip = (props, option, title) => {
    return (
      <Tooltip title={title} placement="top" sx={{ cursor: 'not-allowed' }} disabled>
        <Box
          component="li"
          {...props}
          style={{
            cursor: 'not-allowed',
            opacity: option?.disabled ? 0.5 : 1
          }}
          onClick={event => event.preventDefault()}
        >
          {option.name}
        </Box>
      </Tooltip>
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setShowSelector(false)}>
      <div className="add-budget-event-container">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setShowSelector(prevState => !prevState)}
          startIcon={<AddIcon />}
          sx={{ textTransform: 'none', lineHeight: 'unset' }}
        >
          Budget Event
        </Button>
        {showSelector && (
          <div className="budget-event-selector">
            <Autocomplete
              options={budgetEventOptions}
              sx={{ width: 250, backgroundColor: 'white' }}
              getOptionLabel={option => option.name}
              forcePopupIcon={false}
              renderOption={renderOption}
              filterOptions={(options, params) =>
                options.filter(option => option.name.toLowerCase().includes(params.inputValue.toLowerCase()))
              }
              onChange={(event, value) => {
                if (!value?.disabled) {
                  setShowSelector(false);
                  value.onSelect();
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Search"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              )}
              open
            />
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
