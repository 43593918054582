import React from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';

import Select from '../../../../../../common/data-entry/Select';
import { SSUFilter } from '../../../../../SSUFilter/SSUFilter';
import { SSUFilterMaterialRepresentation } from '../../../../../SSUFilter/SSUFilterMaterialRepresentation';
import { INITIAL_PATIENT_STATUSES } from '../../constants';

import { SourceSelect } from './SourceSelect';

export default function StudySiteStatusSourceSelect(props) {
  const {
    onSSUFilterChange,
    getValidationMessage,
    setSource,
    source,
    setStatus,
    status,
    className,
    studiesToReject
  } = props;
  return (
    <div className={cx('eds-study-site-status-source-select', className)}>
      <div className="ssss-study-site">
        <SSUFilter handleSSUFilterChange={onSSUFilterChange} studiesToReject={studiesToReject}>
          <SSUFilterMaterialRepresentation
            isStudyRequired={!getValidationMessage('study')}
            isSiteRequired={!getValidationMessage('site')}
            studyValidationMessage={getValidationMessage('study')}
            siteValidationMessage={getValidationMessage('site')}
          />
        </SSUFilter>
      </div>
      <div className="ssss-status-source">
        <SourceSelect source={source} setSource={setSource} getValidationMessage={getValidationMessage} />
        <Select
          label="Status"
          clearSearchOnSelection={false}
          searchable
          clearable={false}
          closeOnSelectedOptionClick={false}
          deselectOnSelectedOptionClick={false}
          onChange={setStatus}
          dataSource={INITIAL_PATIENT_STATUSES}
          value={status || null}
          valid={!getValidationMessage('status')}
          validationMessage={getValidationMessage('status')}
          required
        />
      </div>
    </div>
  );
}
StudySiteStatusSourceSelect.propTypes = {
  onSSUFilterChange: PropTypes.func,
  getValidationMessage: PropTypes.func,
  setSource: PropTypes.func,
  source: PropTypes.object,
  setStatus: PropTypes.func,
  status: PropTypes.object,
  className: PropTypes.string,
  studyToReject: PropTypes.array
};
