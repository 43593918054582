import { isArray, isEmpty, isEqual } from 'lodash/lang';

import { PROCESS } from './taskConstants';

export function prepareAutoPopulatedData(data) {
  const {
    studySiteId = null,
    study = null,
    site = null,
    sitePatientId = null,
    patientUniqueIdentifier = null,
    patientId = null,
    patientSubjectId = null,
    patientFirstName = null,
    patientLastName = null,
    patientBirthDate = null,
    protocolEncounter = null,
    protocolItemGroup = null
  } = data;

  return {
    id: null,
    sequenceNumber: 0,
    type: PROCESS,
    topic: null,
    topicTitle: null,
    status: 'OPEN',
    title: '',
    description: '',
    studySiteId,
    study,
    site,
    sitePatientId,
    patientUniqueIdentifier,
    patientId,
    patientSubjectId,
    patientFirstName,
    patientLastName,
    patientBirthDate,
    protocolEncounter,
    protocolItemGroup,
    assignees: [null],
    dueDate: null,
    lastUpdateDate: null,
    updatedByName: null,
    updatedBy: null,
    creationDate: null,
    createdByName: null,
    createdBy: null,
    taskLinks: [],
    taskComments: [],
    taskCommentField: null
  };
}

export function prepareCreateRequestData(taskForm) {
  const {
    id,
    type,
    status,
    title,
    description,
    topic,
    topicTitle,
    studySiteId,
    study,
    site,
    patientUniqueIdentifier,
    sitePatientId,
    protocolEncounter,
    protocolItemGroup,
    assignees,
    dueDate,
    taskLinks
  } = taskForm;
  return {
    id,
    type,
    status,
    title,
    description,
    topic,
    topicTitle,
    studySiteId,
    studyId: study?.id,
    siteId: site?.id,
    patientUniqueIdentifier,
    sitePatientId,
    protocolEncounter: protocolEncounter || null,
    protocolItemGroup: protocolItemGroup || null,
    assigneeDto: {
      personnelIds: !isEmpty(assignees) ? assignees.filter(e => !!e).map(e => e.personnelIdentifier) : [],
      edited: true
    },
    dueDate: dueDate,
    taskLinks
  };
}

export function prepareTaskData(data) {
  const { task, taskLinks, taskComments } = data;
  const {
    id,
    sequenceNumber,
    type,
    topic,
    topicTitle,
    status,
    title,
    description,
    studySiteId,
    study,
    site,
    sitePatientId,
    patientUniqueIdentifier,
    patientId,
    patientSubjectId,
    patientFirstName,
    patientLastName,
    patientBirthDate,
    protocolEncounter,
    protocolItemGroup,
    assignees,
    dueDate,
    lastUpdateDate,
    updatedByName,
    updatedBy,
    creationDate,
    createdByName,
    createdBy
  } = task;

  return {
    id,
    sequenceNumber,
    type,
    topic,
    topicTitle,
    status,
    title,
    description,
    studySiteId,
    study,
    site,
    sitePatientId,
    patientUniqueIdentifier,
    patientId,
    patientSubjectId,
    patientFirstName,
    patientLastName,
    patientBirthDate,
    protocolEncounter,
    protocolItemGroup,
    assignees,
    dueDate,
    lastUpdateDate,
    updatedByName,
    updatedBy,
    creationDate,
    createdByName,
    createdBy,
    taskLinks,
    taskComments,
    taskCommentField: null
  };
}

export function prepareUpdateRequestData(taskForm, originTaskForm) {
  const {
    id,
    type,
    status,
    title,
    description,
    topic,
    topicTitle,
    studySiteId,
    study,
    site,
    patientUniqueIdentifier,
    sitePatientId,
    protocolEncounter,
    protocolItemGroup,
    assignees,
    dueDate,
    taskLinks
  } = taskForm;
  return {
    id,
    type,
    status,
    title,
    description,
    topic,
    topicTitle,
    studySiteId,
    studyId: study?.id,
    siteId: site?.id,
    patientUniqueIdentifier,
    sitePatientId,
    protocolEncounter: protocolEncounter || null,
    protocolItemGroup: protocolItemGroup || null,
    assigneeDto: {
      personnelIds: !isEmpty(assignees) ? assignees.map(e => e.personnelIdentifier) : [],
      edited: assigneesEdited(taskForm.assignees, originTaskForm.assignees)
    },
    dueDate: dueDate,
    taskLinks
  };
}

function assigneesEdited(assignees, originAssignees) {
  if (isArray(originAssignees) && isArray(assignees)) {
    return !isEqual(originAssignees, assignees);
  }
  return false;
}
