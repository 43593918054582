import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';

import { SMWorklistApi } from '../../../../api';
import useAppInfo from '../../../../common/hooks/useAppInfo';
import { CLOSED } from '../../../../constants/ssuStatuses';
import { MANAGE_PROGRESS_NOTES, VIEW_PROGRESS_NOTES } from '../../../../constants/userOperations';
import { ROLE_STUDY_MANAGER, ROLE_SYSTEM_ADMINISTRATOR } from '../../../../constants/userRoles';
import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';
import { userHasAccessTo, userHasRole } from '../../../../services/auth';
import { CellFormattedDate } from '../../../CellFormattedDate/CellFormattedDate';
import { generateUrlByKey } from '../../../root/router';
import { DATA_REQUIRED, ENCOUNTER_WIDGET, OPEN, SM_REVIEW } from '../shared/Worklist/constants';
import HighlightIcon from '../shared/Worklist/HighlightIcon';
import HighlightToolTip from '../shared/Worklist/HighlightToolTip';
import { NotesColumn } from '../shared/Worklist/NotesColumn';
import ReactTableHoc, {
  CustomSortHeader,
  resolveBasicCell,
  resolveUnsignedProcedureValue
} from '../shared/Worklist/ReactTableHoc/ReactTableHoc';
import { ReviewColumn } from '../shared/Worklist/ReviewColumn';
import { getUrlToPatientInfoPage } from '../shared/Worklist/WorklistService';
import { EncounterFilterColumn } from '../shared/Worklist/WorklistTableHeaderFilter/WorklistEncounterFilter/EncounterFilterColumn';
import { SignedByFilterColumn } from '../shared/Worklist/WorklistTableHeaderFilter/WorklistSignedByFilter/SignedByFilterColumn';

import { defaultSmFilters, SmStatusesProvider } from './constants';
import { SmWorklistContext } from './SmWorklistFilterContext';

const getColumns = (
  selectedStatus,
  statusType,
  signedByFilterEnabled,
  groupsAndSignatures,
  isSignedByColumnFetching
) => {
  if (!selectedStatus) {
    return [];
  }
  if (statusType === ENCOUNTER_WIDGET) {
    return [
      {
        id: 'highlight',
        Header: (
          <HighlightToolTip>
            <span>Highlighted</span>
          </HighlightToolTip>
        ),
        accessor: 'highlight',
        sortable: false,
        className: 'rt-hoc-centered',
        Cell: ({ value }) => {
          return <HighlightIcon highlightType={value} />;
        },
        resizable: false,
        minWidth: 60,
        maxWidth: 100
      },
      {
        id: 'patientName',
        Header: <CustomSortHeader title={'Patient'} />,
        minWidth: 60,
        accessor({ patientFirstName, patientLastName }) {
          return `${patientFirstName} ${patientLastName}`;
        },
        Cell: ({ value, original }) => (
          <Link
            onClick={function(e) {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}
            to={getUrlToPatientInfoPage(original, 'Study Manager Worklist')}
          >
            {value}
          </Link>
        )
      },
      {
        id: 'subjectId',
        Header: <CustomSortHeader title={'Subject ID'} />,
        Cell: ({ value }) => resolveBasicCell(value.subjectId || 'N/A', value.blinded),
        accessor: d => d,
        minWidth: 60,
        sortMethod(a, b) {
          const aSubjectId = a.subjectId?.toUpperCase();
          const bSubjectId = b.subjectId?.toUpperCase();
          if (a.subjectId === b.subjectId) {
            return 0;
          }
          if (a.subjectId === null || aSubjectId > bSubjectId) {
            return 1;
          }
          if (b.subjectId === null || aSubjectId < bSubjectId) {
            return -1;
          }
        }
      },
      {
        id: 'date',
        Header: <CustomSortHeader title={'Date'} />,
        accessor: 'edcVisitDate',
        width: 140,
        Cell: ({ value }) => (
          <span className="tooltip-cell">
            <CellFormattedDate date={value} defaultValue={'--'} format={'DD/MMM/YYYY'} toUpper={true} />
          </span>
        )
      },
      EncounterFilterColumn(
        SmWorklistContext,
        SMWorklistApi.getEncounterNamesAndStudyNamesBySsuIds,
        defaultSmFilters,
        SmStatusesProvider,
        'Study Manager Worklist'
      ),
      {
        Header: <CustomSortHeader title={'Study'} />,
        accessor: 'studyName',
        minWidth: 60,
        Cell: row => resolveBasicCell(row.original.studyName, row.original.blinded)
      },
      {
        Header: <CustomSortHeader title={'Site'} />,
        accessor: 'siteName',
        minWidth: 60,
        Cell: row => resolveBasicCell(row.original.siteName, row.original.blinded)
      },
      {
        Header: () => <span className={'two-row-header'}>Incomplete Procedures</span>,
        accessor: 'incompleteFormsCount',
        sortable: false,
        show: selectedStatus === OPEN,
        Cell: ({ value }) => <span className="tooltip-cell">{value}</span>,
        className: 'rt-hoc-centered',
        width: 150
      },
      {
        Header: () => <span className={'two-row-header'}>Unsigned Procedures</span>,
        accessor: 'formsCount',
        sortable: false,
        show: selectedStatus === SM_REVIEW,
        Cell: ({ original }) =>
          isSignedByColumnFetching ? (
            <div className={'signed-by-column-loader-gradient'} />
          ) : (
            <span className="tooltip-cell">{resolveUnsignedProcedureValue(original, groupsAndSignatures)}</span>
          ),
        className: 'rt-hoc-centered p-3',
        width: 135
      },
      SignedByFilterColumn(
        SmWorklistContext,
        SMWorklistApi.getSMWorkListSignedByFilterSignersAndSmReviewers,
        SmStatusesProvider,
        signedByFilterEnabled,
        statusType,
        selectedStatus
      ),
      {
        Header: 'Review',
        accessor: 'review',
        sortable: false,
        className: 'rt-hoc-centered rt-hoc-pointer',
        width: 70,
        Cell: row => {
          const url = getUrlToReviewPage(row.original);
          return <ReviewColumn link={url} isBlinded={row.original.blinded} reviewButtonDisabled={!url} />;
        }
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        sortable: false,
        className: 'rt-hoc-centered rt-hoc-pointer',
        width: 60,
        Cell: row => {
          const hasAccessToManage =
            userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
            (row.original.studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_PROGRESS_NOTES));
          return (
            <NotesColumn
              hasAccessToView={userHasAccessTo(VIEW_PROGRESS_NOTES)}
              hasAccessToEdit={hasAccessToManage}
              sitePatientIdentifier={row.original.ssuPatientId}
              studySiteStatus={row.original.studySiteStatus}
              notesInfo={row.original}
            />
          );
        }
      }
    ];
  } else {
    return [
      {
        id: 'date',
        Header: <CustomSortHeader title={'Date'} />,
        accessor: 'date',
        width: 180,
        Cell: ({ value }) => (
          <span className="tooltip-cell">
            <CellFormattedDate date={value} defaultValue={'--'} format={'DD/MMM/YYYY, h:mm a'} toUpper={true} />
          </span>
        )
      },
      {
        id: 'adverseEventName',
        Header: <CustomSortHeader title={'AE'} />,
        minWidth: 60,
        accessor: 'adverseEventName',
        Cell: ({ value, original }) => (
          <Link
            onClick={function(e) {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}
            to={getUrlToAdverseEventEncounter(original)}
          >
            {value ? value : 'Adverse Event'}
          </Link>
        )
      },
      {
        Header: <div className="title">{'Data Required By'}</div>,
        accessor: 'dataRequiredBySm',
        minWidth: 60,
        sortable: false,
        show: selectedStatus === DATA_REQUIRED,
        Cell: row =>
          resolveBasicCell(row.original.dataRequiredBySm ? 'Study Manager' : 'Investigator', row.original.blinded)
      },
      {
        id: 'patientName',
        Header: <CustomSortHeader title={'Patient'} />,
        minWidth: 60,
        accessor({ patientFirstName, patientLastName }) {
          return `${patientFirstName} ${patientLastName}`;
        },
        Cell: ({ value, original }) => (
          <Link
            onClick={function(e) {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}
            to={getUrlToPatientInfoPage(original, 'Study Manager Worklist')}
          >
            {value}
          </Link>
        )
      },
      {
        id: 'subjectId',
        Header: <CustomSortHeader title={'Subject ID'} />,
        Cell: ({ value }) => resolveBasicCell(value.subjectId || 'N/A', value.blinded),
        accessor: d => d,
        minWidth: 60,
        sortMethod(a, b) {
          const aSubjectId = a.subjectId?.toUpperCase();
          const bSubjectId = b.subjectId?.toUpperCase();
          if (a.subjectId === b.subjectId) {
            return 0;
          }
          if (a.subjectId === null || aSubjectId > bSubjectId) {
            return 1;
          }
          if (b.subjectId === null || aSubjectId < bSubjectId) {
            return -1;
          }
        }
      },
      {
        Header: <CustomSortHeader title={'Study'} />,
        accessor: 'studyName',
        minWidth: 60,
        Cell: row => resolveBasicCell(row.original.studyName, row.original.blinded)
      },
      {
        Header: <CustomSortHeader title={'Site'} />,
        accessor: 'siteName',
        minWidth: 60,
        Cell: row => resolveBasicCell(row.original.siteName, row.original.blinded)
      },
      SignedByFilterColumn(
        SmWorklistContext,
        SMWorklistApi.getSMWorkListAdverseEventSignedByFilterSignersAndSmReviewers,
        SmStatusesProvider,
        signedByFilterEnabled,
        statusType,
        selectedStatus
      ),
      {
        Header: 'Notes',
        accessor: 'notes',
        sortable: false,
        className: 'rt-hoc-centered rt-hoc-pointer',
        width: 60,
        Cell: row => {
          const hasAccessToManage =
            userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
            (row.original.studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_PROGRESS_NOTES));
          return (
            <NotesColumn
              hasAccessToView={userHasAccessTo(VIEW_PROGRESS_NOTES)}
              hasAccessToEdit={hasAccessToManage}
              sitePatientIdentifier={row.original.ssuPatientId}
              studySiteStatus={row.original.studySiteStatus}
              notesInfo={row.original}
            />
          );
        }
      }
    ];
  }
};

export const SmWorklistTableNew = () => {
  const Context = useContext(SmWorklistContext);
  const appInfo = useAppInfo();
  const signedByFilterEnabled = appInfo?.features?.signedByFilterEnabled;
  const {
    page,
    pageSize,
    tableData,
    totalPages,
    EncounterWidgetContext,
    AdverseEventsWidgetContext,
    setPageSize,
    setPage,
    statusType,
    sorted,
    setSorted,
    groupsAndSignatures,
    isSignedByColumnFetching
  } = Context;
  const currentlySelected =
    statusType === ENCOUNTER_WIDGET
      ? EncounterWidgetContext.currentlySelected
      : AdverseEventsWidgetContext.currentlySelected;
  return (
    <ReactTableHoc>
      <ReactTable
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        onSortedChange={sorted => {
          pendoTrackDefaultSortingChange(sorted);
          setSorted(sorted);
        }}
        columns={getColumns(
          currentlySelected,
          statusType,
          signedByFilterEnabled,
          groupsAndSignatures,
          isSignedByColumnFetching
        )}
        pages={totalPages}
        data={currentlySelected ? tableData : []}
        showPagination={!!currentlySelected}
        manual
        showPageSizeOptions
        minRows={1}
        pageSize={pageSize}
        page={page}
        noDataText={currentlySelected && 'No Records Found'}
        className="mx-4"
        defaultSorted={sorted}
      />
    </ReactTableHoc>
  );
};

function getUrlToEncounterDetailsPage(pathDetails) {
  const { patientIdentifier, ssuPatientId, patientEncounterId } = pathDetails;
  if (patientEncounterId) {
    return generateUrlByKey('Study Manager Worklist.Patient Profile.Patient Studies.Encounter Details', {
      patientId: patientIdentifier,
      ssuPatientId,
      patientEncounterId
    });
  }
}

function getUrlToReviewPage(original) {
  const {
    ssuPatientId,
    piReviewItemGroupId,
    smReviewItemGroupId,
    patientEncounterId,
    patientIdentifier,
    groupAssignStudy,
    encounterStatus,
    hasInvestigatorReview,
    hasSmReview,
    groupAssignId
  } = original;

  if (
    encounterStatus === 'PI_REVIEW'
      ? groupAssignStudy
        ? !hasInvestigatorReview
        : !piReviewItemGroupId
      : groupAssignStudy
      ? !hasSmReview
      : !smReviewItemGroupId
  ) {
    return;
  }

  if (groupAssignStudy) {
    if (!groupAssignId) {
      return;
    }
    if (!userHasRole(ROLE_STUDY_MANAGER) || encounterStatus === 'PI_REVIEW') {
      return;
    }
  }

  const params = {
    ssuPatientId,
    patientId: patientIdentifier,
    patientEncounterId
  };

  if (!groupAssignStudy) {
    params.reviewPatientItemGroupId = encounterStatus === 'PI_REVIEW' ? piReviewItemGroupId : smReviewItemGroupId;
  } else {
    params.groupAssignId = groupAssignId;
    params.signatureId = 'current';
  }

  return generateUrlByKey(
    `Study Manager Worklist.Patient Profile.Patient Studies.Encounter Details.${getReviewPageName()}`,
    params
  );

  function getReviewPageName() {
    if (encounterStatus === 'PI_REVIEW') {
      return groupAssignStudy ? `Investigator Encounter Review` : `Investigator Review`;
    }
    return groupAssignStudy ? `Study Manager Encounter Review` : `SM Review`;
  }
}

function getUrlToAdverseEventEncounter(original) {
  const { ssuPatientId, patientIdentifier, rowId, itemGroupTemplateId, itemGroupTemplateVersionId } = original;
  if (itemGroupTemplateId) {
    return generateUrlByKey('Study Manager Worklist.Adverse Event Log Row', {
      ssuPatientId,
      patientId: patientIdentifier,
      patientItemGroupId: itemGroupTemplateId,
      itemGroupTemplateVersionId,
      rowId
    });
  }
}
