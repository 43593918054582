import axios from 'axios';

const ROOT = 'api/v1/finance/ledger-events';

const FinLedgerEventApi = {
  calculatePendingElligoEvents() {
    return axios.post(`${ROOT}/process-pending-elligo-events`);
  },
  createInvoice(data) {
    return axios.post(`${ROOT}/client/invoice`, data);
  },
  getCreatedInvoice(invoiceNumber, invoiceType) {
    return axios.get(`${ROOT}/client/invoice/${invoiceNumber}/type/${invoiceType}`);
  },
  generateInvoiceNumber() {
    return axios.post(`${ROOT}/client/invoice-number`);
  },
  inactivateExpenseEvent(eventId) {
    return axios.post(`${ROOT}/${eventId}/inactivate`);
  },
  getSearchEventID(filters) {
    return axios.post(`${ROOT}/search/event-id`, filters, {
      hideLoader: true
    });
  },
  getSearchDepositNumber(filters) {
    return axios.post(`${ROOT}/search/deposit-number`, filters, {
      hideLoader: true
    });
  },
  getSearchInvoiceNumber(filters) {
    return axios.post(`${ROOT}/search/invoice-number`, filters, {
      hideLoader: true
    });
  },
  getSearchSubjectId(filters) {
    return axios.post(`${ROOT}/search/subject-id`, filters, {
      hideLoader: true
    });
  },
  getSearchPatientId(filters) {
    return axios.post(`${ROOT}/search/patient-id`, filters, {
      hideLoader: true
    });
  },
  getSearchEncounterName(filters) {
    return axios.post(`${ROOT}/search/encounter-name`, filters, {
      hideLoader: true
    });
  },
  getSearchItemGroupName(filters) {
    return axios.post(`${ROOT}/search/item-group-name`, filters, {
      hideLoader: true
    });
  },
  getSearchEventName(filters) {
    return axios.post(`${ROOT}/search/event-name`, filters, {
      hideLoader: true
    });
  },
  getExtendedInformation(id) {
    return axios.get(`${ROOT}/details/${id}`, {
      hideLoader: true
    });
  },
  getTotalElementsCount(filters) {
    return axios.post(`${ROOT}/search/total-elements`, filters, {
      hideLoader: true
    });
  },
  getLedgerEventsByFilter(filterProperty) {
    return axios.post(`${ROOT}/search`, filterProperty);
  }
};

export default FinLedgerEventApi;
