import React from 'react';

import ModalBoxes from '../common/feedback/ModalBoxes/ModalBoxes';

export default function UnauthorizedAccessModal() {
  return (
    <React.Fragment>
      <h5>You are not authorized to access this page.</h5>
      <p>
        If you require access to this page, please contact your project manager to review and, if appropriate, request
        that access to be granted.
      </p>
    </React.Fragment>
  );
}

UnauthorizedAccessModal.open = () => {
  return ModalBoxes.confirm({
    content: <UnauthorizedAccessModal />,
    title: 'Unauthorized Access',
    confirmButton: 'OK',
    cancelButton: false
  });
};
