import React, { useContext, useMemo } from 'react';
import cx from 'classnames';

import Icon from '../../../../../../../common/general/Icon';
import { scPurple80 } from '../../../../../../../constants/systemColors';
import { useCurrentRoute } from '../../../../../../root/router';
import { DATA_COLLECTED, NO_DATA_TO_COLLECT } from '../../../EncounterDescNew/LogCheck/LogCheckNew';
import { ReviewContext } from '../../ReviewContext';
import { getIcon, getIconSuit, isSmOrPiReview } from '../../reviewService';

import './ReviewSidebarItem.scss';

export default function ReviewSidebarItem({
  name,
  type,
  loading,
  isNotPerformed,
  isSigned,
  isDisabled,
  onClick,
  highlightedAnswersCount,
  blinded,
  logCheckSnapshotReviewState,
  item
}) {
  const { reviewType } = useContext(ReviewContext);

  const currentRoute = useCurrentRoute();
  const showNotPerformedIcon = isSmOrPiReview(reviewType) && isSigned ? false : isNotPerformed;
  const isRequiredForAttention = !isSigned && !isDisabled && !isNotPerformed;

  const isInvestigatorOrStudyManagerReview = useMemo(() => {
    return currentRoute.key.includes('Investigator Review') || currentRoute.key.includes('SM Review');
  }, [currentRoute]);

  function ignoreDisabledOnClick(item) {
    if (blinded || (!isDisabled && !loading)) {
      return onClick(item);
    } else {
      return () => {};
    }
  }

  return (
    <>
      {(!isInvestigatorOrStudyManagerReview || type !== 'Logs' || item.domainCode !== 'AE') && (
        <div
          onClick={ignoreDisabledOnClick}
          className={cx('eds-review-sidebar-item', {
            'eds-review-sidebar-item-disabled': isDisabled || loading || blinded
          })}
        >
          <div className="eds-review-sidebar-item-icon">
            {loading ? (
              <span className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </span>
            ) : (
              <Icon suit={getIconSuit(type)}>{getIcon(type)}</Icon>
            )}
          </div>

          <div className="eds-review-sidebar-name-and-icon">
            <>
              <div className="eds-review-sidebar-item-name" title={name}>
                {name}
                {type === 'LogChecks' && item.domainCode === 'AE' && (
                  <div style={{ color: scPurple80, fontWeight: 'bold' }}>
                    {logCheckSnapshotReviewState?.logCheckSnapshotState?.encounterLogCheckSnapshotKey?.rowType ===
                    DATA_COLLECTED
                      ? 'AE Reported'
                      : logCheckSnapshotReviewState?.logCheckSnapshotState?.encounterLogCheckSnapshotKey?.rowType ===
                          NO_DATA_TO_COLLECT && 'No AE Reported'}
                  </div>
                )}
              </div>
            </>
            {getIconStatusOfItemGroup()}
          </div>
        </div>
      )}
    </>
  );

  function getIconStatusOfItemGroup() {
    if (blinded) {
      return <Icon className="eds-review-sidebar-item-denied-icon">block</Icon>;
    } else {
      if (!loading) {
        if (showNotPerformedIcon) {
          return <div className="eds-review-sidebar-item-not-performed-icon">NP</div>;
        } else if (isSmOrPiReview(reviewType) && isSigned) {
          return <div className="eds-review-sidebar-item-signed-icon">done</div>;
        } else {
          if (isSmOrPiReview(reviewType)) {
            if (highlightedAnswersCount > 0 && item?.domainCode !== 'AE') {
              return (
                <div className="eds-review-sidebar-item-text-icon">
                  <span>{highlightedAnswersCount}</span>
                </div>
              );
            } else if (isRequiredForAttention) {
              return <div className="eds-review-sidebar-item-not-signed-icon">done</div>;
            }
          }
        }
      }
    }
  }
}

ReviewSidebarItem.defaultProps = {
  isNotPerformed: false,
  isSigned: false,
  isDisabled: true
};
