import React from 'react';
import cx from 'classnames';
import { isEmpty, isNull } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import './ItemGroupsRows.scss';

export default function ItemGroupsRows(props) {
  const {
    itemGroupsList,
    encounters,
    itemGroupsEncounterGrantedMapForActiveBlindingGroup,
    setItemGroupsEncounterGrantedMapForActiveBlindingGroup,
    blindingGroupId
  } = props;
  return (
    <React.Fragment>
      {itemGroupsList?.map(itemGroup => {
        const { itemGroupKey: itemGroupTemplateId, elementName } = itemGroup;
        return (
          <tr key={itemGroupTemplateId} id={'blinding-table-' + itemGroupTemplateId}>
            <td
              title={elementName}
              className="eett-sticky-column eett-header eett-grey-cell eett-right-align"
              style={{ paddingLeft: '10px' }}
            >
              {elementName}
            </td>
            {!isEmpty(itemGroupsEncounterGrantedMapForActiveBlindingGroup) &&
              encounters?.map(encounter => {
                const encounterIdentifier = encounter.encounterIdentifier;
                const granted = defineGranted(itemGroupTemplateId, encounterIdentifier);
                const isCellEditable =
                  !!setItemGroupsEncounterGrantedMapForActiveBlindingGroup &&
                  (typeof granted === 'boolean' || isNull(granted));
                return (
                  <td
                    width="90"
                    key={encounterIdentifier + itemGroupTemplateId}
                    id={`bg,enc,ig-${blindingGroupId},${encounterIdentifier},${itemGroupTemplateId}`}
                    onClick={() =>
                      isCellEditable &&
                      changeItemGroupEncounterGranted(granted, itemGroupTemplateId, encounterIdentifier)
                    }
                    className={cx({
                      'pbs-undefined': isNull(granted),
                      'pbs-allowed': granted,
                      'pbs-denied': granted === false,
                      'bst-existing-cell': isCellEditable
                    })}
                  />
                );
              })}
          </tr>
        );
      })}
    </React.Fragment>
  );

  function defineGranted(itemGroupTemplateId, encounterIdentifier) {
    const itemGroupsOfEncounter = itemGroupsEncounterGrantedMapForActiveBlindingGroup[itemGroupTemplateId];
    if (itemGroupsOfEncounter.isLogItemGroup) {
      return itemGroupsOfEncounter.granted;
    } else {
      return itemGroupsOfEncounter?.encounters[encounterIdentifier]?.granted;
    }
  }

  function changeItemGroupEncounterGranted(granted, itemGroupTemplateId, encounterIdentifier) {
    if (granted) {
      changeGrantedMap(false);
    } else {
      changeGrantedMap(true);
    }

    function changeGrantedMap(value) {
      setItemGroupsEncounterGrantedMapForActiveBlindingGroup(itemGroupsEncounterGrantedMap => {
        const itemGroup = itemGroupsEncounterGrantedMap[itemGroupTemplateId];
        if (itemGroup.isLogItemGroup) {
          return {
            ...itemGroupsEncounterGrantedMap,
            [itemGroupTemplateId]: {
              ...itemGroup,
              granted: value
            }
          };
        } else {
          return {
            ...itemGroupsEncounterGrantedMap,
            [itemGroupTemplateId]: {
              ...itemGroup,
              encounters: {
                ...itemGroup.encounters,
                [encounterIdentifier]: { granted: value }
              }
            }
          };
        }
      });
    }
  }
}

ItemGroupsRows.propTypes = {
  itemGroupsList: PropTypes.array,
  encounters: PropTypes.array,
  itemGroupsEncounterGrantedMapForActiveBlindingGroup: PropTypes.object,
  setItemGroupsEncounterGrantedMapForActiveBlindingGroup: PropTypes.func
};
