import React, { useContext } from 'react';

import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { SitePaymentsContext } from '../SitePaymentsContext';

import { SitePaymentsFilters } from './SitePaymentsFilters/SitePaymentsFilters';

export const SitePaymentsControl = () => {
  const { tableData } = useContext(SitePaymentsContext);

  const eligibleTotal = tableData?.reduce(
    (accumulator, currentValue) => (!currentValue.billNumber ? accumulator + currentValue.amount : accumulator),
    0
  );

  const selectedAmount = 0;

  return (
    <PageInfoHeader
      right={
        <ButtonGroup>
          <Button priority="medium" size="h28" onClick={() => {}}>
            Export
          </Button>
        </ButtonGroup>
      }
      pageInfo={
        <React.Fragment>
          <PageInfoHeader.AdditionalInfo title="Total Amount">
            <Amount coinsAmount={eligibleTotal} showDollarSign />
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Open">
            <Amount coinsAmount={0} showDollarSign />
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Paid">
            <Amount coinsAmount={0} showDollarSign />
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Pending">
            <Amount coinsAmount={0} showDollarSign />
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Selected Amount">
            <Amount coinsAmount={selectedAmount} showDollarSign />
          </PageInfoHeader.AdditionalInfo>
        </React.Fragment>
      }
    >
      <SitePaymentsFilters />
    </PageInfoHeader>
  );
};
