import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { isEmpty } from 'lodash/lang';
import moment from 'moment/moment';

import { FinBudgetFixedItemApi } from '../../../../../../api';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../constants/dateFormat';
import { onRequestError } from '../../../../../../services/handlers';

import './AddFixedExpense.scss';

export const AddFixedExpense = ({ selectedSSU, setNewFixedExpenseData }) => {
  const [selectedDate, setSelectedDate] = useState(moment().utc(true));
  const [finTriggerFixedItems, setFinTriggerFixedItems] = useState([]);
  const [finBudgetSite, setFinBudgetSite] = useState(null);
  const [selectedFinTrigger, setSelectedFinTrigger] = useState(null);
  const [eventAmount, setEventAmount] = useState('');
  const [comment, setComment] = useState('');

  useEffect(() => {
    setNewFixedExpenseData({
      date: selectedDate,
      finTriggerId: selectedFinTrigger?.id ?? null,
      comment: comment.trim()
    });
  }, [comment, selectedDate, selectedFinTrigger?.id, setNewFixedExpenseData]);

  useEffect(() => {
    setSelectedFinTrigger(null);
    setFinTriggerFixedItems([]);
    if (selectedDate?.isValid()) {
      FinBudgetFixedItemApi.findTriggersForActualBudget(
        selectedSSU.studyIdentifier,
        selectedSSU.siteIdentifier,
        selectedSSU.ssuIdentifier,
        selectedDate.format('YYYY-MM-DD')
      ).then(({ data: finTriggers }) => {
        setFinTriggerFixedItems(
          finTriggers.finTriggerFixedItems?.map(trigger => ({
            ...trigger,
            label: trigger.name
          })) ?? []
        );
        setFinBudgetSite(finTriggers.finBudgetSite);
      });
    }
  }, [selectedSSU, selectedDate]);

  useEffect(() => {
    if (selectedSSU?.ssuIdentifier && selectedFinTrigger && finBudgetSite?.finBudget?.id) {
      FinBudgetFixedItemApi.getFixedItemClientAmount(
        selectedSSU.ssuIdentifier,
        selectedFinTrigger.id,
        finBudgetSite.finBudget.id
      ).then(({ data }) => {
        setEventAmount(`$${data.toLocaleString()}`);
      }, onRequestError);
    } else {
      setEventAmount('');
    }
  }, [selectedSSU, selectedFinTrigger, finBudgetSite]);

  const maxCommentLength = 255;

  return (
    <div className="add-fixed-expense-container">
      <DesktopDatePicker
        label="Event Date"
        value={selectedDate}
        onChange={newValue => setSelectedDate(newValue)}
        format={DD_SLASH_MMM_SLASH_YYYY}
        slotProps={{
          textField: { variant: 'standard', required: true }
        }}
        localeText={{ fieldMonthPlaceholder: params => (params.contentType === 'letter' ? 'MMM' : 'MM') }}
      />
      <Autocomplete
        noOptionsText={!isEmpty(finTriggerFixedItems) ? 'No Results Available' : 'No expenses available for this date'}
        options={finTriggerFixedItems}
        onChange={(event, value) => {
          setSelectedFinTrigger(value);
          setComment('');
        }}
        value={selectedFinTrigger}
        renderInput={params => <TextField {...params} label="Expense - Fixed Event Name" variant="standard" required />}
      />
      {selectedFinTrigger && (
        <>
          <TextField label="Amount" variant="standard" value={eventAmount} disabled />
          <TextField
            label="Comment"
            variant="standard"
            value={comment}
            multiline
            onChange={({ target: { value } }) => setComment(value)}
            inputProps={{ maxLength: maxCommentLength }}
            helperText={`${comment?.length ?? 0}/${maxCommentLength}`}
            sx={{ '.MuiFormHelperText-root': { textAlign: 'end' } }}
          />
        </>
      )}
    </div>
  );
};
