import React, { useContext } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

import { withEuiFiltersProvider } from '../../../eui/EuiFiltersContext/EuiFiltersContext';

import FiltersSection from './FiltersSection/FiltersSection';
import { ReimbursementWorklistContext, withReimbursementWorklistContext } from './ReimbursementWorklistContext';

import './ReimbursementWorklist.scss';

function ReimbursementWorklist() {
  const { gridData, loading, columns } = useContext(ReimbursementWorklistContext);
  return (
    <div className="eui-reimbursement-worklist">
      <FiltersSection />
      <DataGridPro
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
            quickFilterProps: {
              debounceMs: 500
            }
          }
        }}
        loading={loading}
        rowHeight={50}
        rows={gridData}
        columns={columns}
        disableRowSelectionOnClick
        localeText={{ noRowsLabel: 'No results to display' }}
      />
    </div>
  );
}

export default withEuiFiltersProvider(withReimbursementWorklistContext(ReimbursementWorklist));
