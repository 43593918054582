import React from 'react';
import { isFunction } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import Loader from './Loader';

export function LoadingHandler({ complete, dark, children }) {
  if (complete) {
    if (isFunction(children)) {
      return children();
    }
    return children;
  }
  return <Loader dark={dark} />;
}

LoadingHandler.propTypes = {
  complete: PropTypes.bool,
  dark: PropTypes.bool
};

LoadingHandler.defaultProps = {
  complete: false,
  dark: false
};
