import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';

import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import { PageInfoHeader } from '../../../../../PageInfoHeader/PageInfoHeader';
import { generateUrlByKey, useCurrentRoute } from '../../../../../root/router';
import BlindingConfigurationTable from '../../BlindingConfigurationTable/BlindingConfigurationTable';

import BlindingGroups from './BlindingGroups/BlindingGroups';
import { ProtocolBlindingSetupContext, ProtocolBlindingSetupProvider } from './ProtocolBlindingSetupContext';
import { CANCEL_CONFIGURATION } from './сonfirmationDialogService';

import './ProtocolBlindingSetup.scss';

function BlindingSetup() {
  const {
    blindingGroups,
    metadata,
    saveConfiguration,
    cancelConfiguration,
    isAllConfigurationSaved,
    itemGroupsList,
    encounters,
    itemGroupsEncounterGrantedMapForActiveBlindingGroup,
    setItemGroupsEncounterGrantedMapForActiveBlindingGroup,
    epochs,
    activeBlindingGroupId,
    protocolInfo
  } = useContext(ProtocolBlindingSetupContext);
  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();

  function moveToProtocolSetup() {
    navigate(generateUrlByKey(currentRoute.parent.key, currentRoute.params));
  }

  return (
    <>
      <PageInfoHeader
        objectRecordLabel={metadata?.studyName}
        pageInfo={
          <PageInfoHeader.CollapsibleList>
            <PageInfoHeader.AdditionalInfo tooltip="Source Data Name">{metadata?.name}</PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Version">{protocolInfo.version}</PageInfoHeader.AdditionalInfo>
          </PageInfoHeader.CollapsibleList>
        }
        right={
          <ButtonGroup>
            <Button onClick={() => cancelConfiguration(moveToProtocolSetup, CANCEL_CONFIGURATION)} priority="low">
              Cancel
            </Button>
            <Button
              disabled={isAllConfigurationSaved}
              onClick={() => {
                !isAllConfigurationSaved && saveConfiguration();
              }}
            >
              Save
            </Button>
          </ButtonGroup>
        }
      />
      <div className="protocol-blinding-setup">
        <div className="pbs-top">
          <div className="pbs-hint">
            Hint:
            <div>
              <span className="pbs-allowed" />
              Access allowed
            </div>
            <div>
              <span className="pbs-denied" />
              Denied
            </div>
            <div>
              <span className="pbs-undefined" />
              Undefined
            </div>
          </div>
        </div>
        <div className="pbs-content">
          {!isEmpty(blindingGroups) ? (
            <React.Fragment>
              <BlindingGroups />
              <BlindingConfigurationTable
                itemGroupsList={itemGroupsList}
                encounters={encounters}
                itemGroupsEncounterGrantedMapForActiveBlindingGroup={
                  itemGroupsEncounterGrantedMapForActiveBlindingGroup
                }
                setItemGroupsEncounterGrantedMapForActiveBlindingGroup={
                  setItemGroupsEncounterGrantedMapForActiveBlindingGroup
                }
                epochs={epochs}
                activeBlindingGroupId={activeBlindingGroupId}
              />
            </React.Fragment>
          ) : (
            <span className="pbs-warning">No blinding groups related to this study</span>
          )}
        </div>
      </div>
    </>
  );
}

export default function ProtocolBlindingSetup() {
  return (
    <ProtocolBlindingSetupProvider>
      <BlindingSetup />
    </ProtocolBlindingSetupProvider>
  );
}
