import React from 'react';
import { isEmpty } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import { BulkBlindingSetup } from '../EncounterEpochTableTemplate/BulkBlindingSetup';
import { EncounterEpochTableTemplate } from '../EncounterEpochTableTemplate/EncounterEpochTableTemplate';

import ItemGroupsRows from './ItemGroupsRows/ItemGroupsRows';

import './BlindingConfigurationTable.scss';

export default function BlindingConfigurationTable(props) {
  const {
    itemGroupsList,
    encounters,
    itemGroupsEncounterGrantedMapForActiveBlindingGroup,
    setItemGroupsEncounterGrantedMapForActiveBlindingGroup,
    epochs,
    blindingGroupId
  } = props;
  return (
    <div className="pbs-blinding-configuration-table">
      <div className="pbs-table-wrap">
        {!isEmpty(itemGroupsList) ? (
          <React.Fragment>
            {!!setItemGroupsEncounterGrantedMapForActiveBlindingGroup && (
              <BulkBlindingSetup
                setItemGroupsEncounterGrantedMapForActiveBlindingGroup={
                  setItemGroupsEncounterGrantedMapForActiveBlindingGroup
                }
              />
            )}
            <EncounterEpochTableTemplate epochs={epochs} encounters={encounters} isUnexpected>
              <ItemGroupsRows
                blindingGroupId={blindingGroupId}
                itemGroupsList={itemGroupsList}
                encounters={encounters}
                itemGroupsEncounterGrantedMapForActiveBlindingGroup={
                  itemGroupsEncounterGrantedMapForActiveBlindingGroup
                }
                setItemGroupsEncounterGrantedMapForActiveBlindingGroup={
                  setItemGroupsEncounterGrantedMapForActiveBlindingGroup
                }
              />
            </EncounterEpochTableTemplate>
          </React.Fragment>
        ) : (
          <span className="pbs-warning">No any item groups configured for this study</span>
        )}
      </div>
    </div>
  );
}

BlindingConfigurationTable.propTypes = {
  itemGroupsList: PropTypes.array,
  encounters: PropTypes.array,
  itemGroupsEncounterGrantedMapForActiveBlindingGroup: PropTypes.object,
  setItemGroupsEncounterGrantedMapForActiveBlindingGroup: PropTypes.func,
  epochs: PropTypes.array,
  blindingGroupId: PropTypes.string
};
