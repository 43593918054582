import axios from 'axios';

const ROOT = 'api/v1/access/studies';

const BlindingConfigApi = {
  getAllBlindingGroupsForStudy(studyId) {
    return axios.get(`${ROOT}/${studyId}/blindingGroups`);
  },
  getBlindingGroupConfiguration(studyId, blindingGroupId, protocolVersionId) {
    return axios.get(
      `${ROOT}/${studyId}/blindingGroups/${blindingGroupId}/protocolConfigurations/${protocolVersionId}`,
      {
        hideLoader: true
      }
    );
  },

  changeBlindingGroupConfiguration(studyId, blindingGroupId, protocolVersionId, blindingSetup) {
    return axios.put(
      `${ROOT}/${studyId}/blindingGroups/${blindingGroupId}/protocolConfigurations/${protocolVersionId}`,
      { ...blindingSetup }
    );
  }
};

export default BlindingConfigApi;
