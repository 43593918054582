import React, { useCallback, useContext, useMemo, useState } from 'react';
import { sortBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import Input from '../../../../../common/data-entry/Input';
import Checkbox from '../../../../../common/data-entry/InputSelectors/Checkbox/Checkbox';
import Radio from '../../../../../common/data-entry/InputSelectors/Radio';
import Select from '../../../../../common/data-entry/Select';
import ApplyAndResetButtons from '../../../../ApplyAndResetButtons/ApplyAndResetButtons';
import { StartEndDatePicker } from '../../shared/FinanceFilters/StartEndDatePicker';
import StudySiteSelector from '../../shared/FinanceFilters/StudySiteSelector';
import { ledgerEventTypes } from '../../shared/finPageFilterTypes';
import { SiteBillContext } from '../SiteBillContext';

import './SiteBillFilters.scss';

const EVENT_ID_MAX_NUMBER_LENGTH = 18;

export function SiteBillFilters() {
  const {
    study,
    site,
    setSite,
    setStudy,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    isPending,
    setPending,
    isBilled,
    setBilled,
    setBillNumber,
    bills,
    applyFilter,
    setPcn,
    type,
    setType,
    patientId,
    setPatientId,
    subjectId,
    setSubjectId,
    eventId,
    setEventId,
    inputEventId,
    setInputEventId,
    setWithholding,
    withholding
  } = useContext(SiteBillContext);

  const [isEventIdFormatInvalid, setIsEventIdFormatInvalid] = useState(false);

  const types = useMemo(() => {
    return sortBy(
      ledgerEventTypes.filter(item => item.name !== 'Patient Reimbursement'),
      'name'
    );
  }, []);

  const onChangeStartDate = moment => {
    const startDateMoment = moment.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    setStartDate(startDateMoment);
  };

  const onChangeEndDate = moment => {
    const endDateMoment = moment.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
    setEndDate(endDateMoment);
  };

  const onStudyChange = useCallback(study => setStudy(study), [setStudy]);

  const onSiteChange = useCallback(site => setSite(site), [setSite]);

  const onPcnChange = useCallback(
    (study, sites) => {
      if (study) {
        setStudy(study);
        setPcn(study.projectCode);
        if (sites.length === 1 && !site) {
          const site = sites[0];
          setSite(site);
        }
      } else {
        setStudy(null);
        setPcn(null);
      }
    },
    [setSite, setStudy, site, setPcn]
  );

  const onPatientId = event => {
    const value = event.target.value;
    if (value.trim() === '') {
      setPatientId(null);
    } else {
      setPatientId(value);
    }
  };

  const onPatientSubjectId = event => {
    const value = event.target.value;
    if (value.trim() === '') {
      setSubjectId(null);
    } else {
      setSubjectId(value);
    }
  };

  const onEventIdChange = event => {
    const [number, adjustmentSequence] = event.target.value.split('.');

    if (event.target.value === '') {
      setEventId(null);
      setInputEventId(null);
    } else {
      setEventId({
        number: number,
        adjustmentSequence: adjustmentSequence
      });
      setInputEventId(event.target.value);
    }
  };

  const isEventIdFormatValid = useMemo(
    function() {
      const finEventIdFormatValidationExpression = new RegExp(/^[1-9]+[0-9]*(\.[1-9]+[0-9]*)?$/);
      const innerEventId = eventId;
      if (
        innerEventId?.number?.length > EVENT_ID_MAX_NUMBER_LENGTH ||
        innerEventId?.adjustmentSequence?.length > EVENT_ID_MAX_NUMBER_LENGTH
      ) {
        setIsEventIdFormatInvalid(true);
        return 'Event ID is too large';
      } else if (
        isEmpty(inputEventId) ||
        (!isEmpty(inputEventId) && finEventIdFormatValidationExpression.test(inputEventId))
      ) {
        setIsEventIdFormatInvalid(false);
      } else {
        setIsEventIdFormatInvalid(true);
        return 'Invalid Event ID format';
      }
    },
    [eventId, inputEventId]
  );

  return (
    <div className="eds-site-bill-filter-layout">
      <div
        className={`general - header - group - container more-than-five-fields-wrapper more-than-ten-fields`}
        id="eds-site-bill-filters-group"
      >
        <StudySiteSelector
          onSiteChange={onSiteChange}
          onStudyChange={onStudyChange}
          studyId={study?.uniqueIdentifier}
          siteId={site?.uniqueIdentifier}
          pcnFlag={true}
          onPcnChange={onPcnChange}
          enableAutoCompleteSite={true}
        />
        <Select onChange={setType} dataSource={types} value={type} label="Type" searchable />
        <Select label="Bill" dataSource={bills} onChange={setBillNumber} clearable />
        <StartEndDatePicker
          onChangeStartDate={onChangeStartDate}
          startDate={startDate}
          onChangeEndDate={onChangeEndDate}
          endDate={endDate}
        />
        <Input label="Patient ID" value={patientId} name="patientId" onChange={event => onPatientId(event)} />

        <Input label="Subject ID" value={subjectId} name="subjectId" onChange={event => onPatientSubjectId(event)} />
        <Input
          label="Event ID"
          value={inputEventId}
          name="finLedgerEventId"
          onChange={event => onEventIdChange(event)}
          validationMessage={isEventIdFormatValid}
        />
        <ApplyAndResetButtons onApply={applyFilter} applyDisabled={isEventIdFormatInvalid} />
      </div>
      <div className="ess-site-bill-status-filter-layout">
        <Checkbox.Group label="Site Status:">
          <Checkbox
            onChange={({ target }) => {
              setPending(target?.checked);
            }}
            label="Pending"
            checked={isPending}
          />
          <Checkbox
            onChange={({ target }) => {
              setBilled(target?.checked);
            }}
            label="Billed"
            checked={isBilled}
          />
        </Checkbox.Group>
        <div className="site-bill-radio">
          <Radio.Group
            label="Withholding:"
            checkedValue={withholding}
            options={[
              { value: true, label: 'Include' },
              { value: false, label: 'Exclude' }
            ]}
            onChange={({ target: { value } }) => {
              setWithholding(value === 'true');
            }}
          />
        </div>
      </div>
    </div>
  );
}
