import { enrollmentRouterConfig } from './Enrollment/routerConfig';
import { ledgerRouterConfig } from './Ledger/routerConfig';
import { newInvoiceRouterConfig } from './NewInvoice/routerConfig';
import { projectedRevenueRouterConfig } from './ProjectedRevenue/routerConfig';
import { reimbursementRouterConfig } from './ReimbursementWorklist/routerConfig';
import { revenueRouterConfig } from './Revenue/routerConfig';
import { siteBillRouterConfig } from './SiteBill/routerConfig';
import { sitePaymentRouterConfig } from './SitePayments/routerConfig';

export const financeRouterConfig = [
  ledgerRouterConfig,
  revenueRouterConfig,
  newInvoiceRouterConfig,
  siteBillRouterConfig,
  sitePaymentRouterConfig,
  enrollmentRouterConfig,
  projectedRevenueRouterConfig,
  reimbursementRouterConfig
];
