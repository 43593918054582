import React, { useEffect, useMemo } from 'react';
import { reduce } from 'lodash/collection';
import { isEqual } from 'lodash/lang';
import { has } from 'lodash/object';

import ReimbursementWorklistApi from '../../../../../../api/patient/ReimbursementWorklistApi';
import MultiSelect from '../../../../../../common/data-entry/MultiSelect/MultiSelect';
import { FRS_LOADED, FRS_READY } from '../../../../../eui/EuiFiltersContext/reducer';
import { initializeFlow } from '../StudySite/processors';

export default function Assigned({ uniqKey, state, dispatch }) {
  const fState = state[uniqKey];
  const fCache = state.cache[uniqKey];
  const status = state.schema[uniqKey].status;
  const ssuStatus = state.schema.ssu.status;
  const { studySites, selectedStudyMap, selectedSiteMap } = state.ssu;

  const ssuIds = useMemo(
    function() {
      return studySites.reduce(function(accumulator, { id, study, site }) {
        if (selectedStudyMap[study.id] && selectedSiteMap[site.id]) {
          accumulator.push(id);
        }
        return accumulator;
      }, []);
    },
    [selectedSiteMap, selectedStudyMap, studySites]
  );

  useEffect(
    function() {
      if (ssuStatus !== FRS_READY) {
        return;
      }
      ReimbursementWorklistApi.getPersonnelWithReimbursement(ssuIds).then(({ data }) => {
        const assignees = [{ id: null, name: 'Not Assigned' }, ...data];
        dispatch({
          type: 'PERFORM',
          processor: initializeProcessor,
          payload: {
            key: uniqKey,
            source: assignees
          }
        });
      });
    },
    [uniqKey, dispatch, ssuStatus, ssuIds]
  );

  useEffect(
    function() {
      if (status !== FRS_LOADED) {
        return;
      }

      dispatch({
        type: 'PERFORM',
        processor: syncProcessor,
        payload: { [uniqKey]: fCache }
      });
    },
    [dispatch, uniqKey, status, fCache]
  );

  if (status !== FRS_READY) return null;

  const { source, value } = fState;

  return (
    <MultiSelect
      label="Assigned"
      searchable
      clearSearchOnSelection
      dataSource={source}
      onChange={selectedAssignee => {
        dispatch({
          type: 'PERFORM',
          processor: setValueProcessor,
          payload: {
            key: uniqKey,
            value: selectedAssignee
          }
        });
      }}
      value={value}
      validate={false}
    />
  );
}

function initializeProcessor(state, action) {
  const { type, payload } = action;
  const { key, source } = payload;
  const previousFilterState = state[key];
  const isReady = state.schema[key].status === FRS_READY;
  const previousSource = previousFilterState.source;
  const previousValue = previousFilterState.value;

  return {
    ...state,
    schema: {
      ...state.schema,
      [key]: {
        ...state.schema[key],
        status: isReady ? FRS_READY : FRS_LOADED
      }
    },
    [key]: {
      ...previousFilterState,
      source,
      value:
        isReady && previousSource.length !== previousValue.length ? filterValuesIfNeeded(source, previousValue) : source
    }
  };
}

function setValueProcessor(state, action) {
  const { type, payload } = action;
  const { key, value } = payload;
  const previousFilterState = state[key];
  if (isEqual(value, previousFilterState.value)) {
    return state;
  }
  return {
    ...state,
    [key]: {
      ...previousFilterState,
      value
    }
  };
}

function filterValuesIfNeeded(source, value) {
  return value.filter(function(v) {
    return source.some(function(s) {
      return s.id === v.id;
    });
  });
}

function syncProcessor(state, action) {
  const { type, payload } = action;

  return reduce(
    state,
    function(accumulator, value, key) {
      if (key === 'schema' || key === 'cache') return accumulator;
      if (has(payload, key)) {
        if (key === 'ssu') {
          accumulator[key] = initializeFlow({
            ...accumulator[key],
            ...payload[key]
          });
        } else {
          accumulator[key] = {
            ...accumulator[key],
            ...payload[key]
          };
        }
        accumulator.schema = {
          ...accumulator.schema,
          [key]: {
            ...accumulator.schema[key],
            status: FRS_READY
          }
        };
      }
      return accumulator;
    },
    { ...state }
  );
}
