import { UNEXPECTED } from '../../../../../../constants/encounterConstants';

function transformGrantedMapToEncounterItemGroupConfig(itemGroups) {
  return itemGroups
    .filter(({ isLogItemGroup }) => !isLogItemGroup)
    .flatMap(({ itemGroupTemplateId, encounters }) => {
      return Object.keys(encounters).map(encounterTemplateId => ({
        encounterTemplateId,
        itemGroupTemplateId,
        granted: encounters[encounterTemplateId].granted
      }));
    })
    .filter(({ encounterTemplateId }) => encounterTemplateId !== UNEXPECTED)
    .filter(({ granted }) => typeof granted === 'boolean');
}

function transformGrantedMapToLogItemGroupConfig(itemGroups) {
  return itemGroups
    .filter(({ isLogItemGroup }) => isLogItemGroup)
    .map(({ itemGroupTemplateId, granted }) => {
      return {
        itemGroupTemplateId,
        granted: granted
      };
    })
    .filter(({ granted }) => typeof granted === 'boolean');
}

function transformGrantedMapToUnexpectedEncounterItemGroupsConfig(itemGroups) {
  return itemGroups
    .filter(itemGroup => !itemGroup.isLogItemGroup)
    .filter(itemGroup => typeof itemGroup?.encounters[UNEXPECTED].granted === 'boolean')
    .map(itemGroup => {
      return {
        itemGroupTemplateId: itemGroup.itemGroupTemplateId,
        granted: itemGroup.encounters[UNEXPECTED].granted
      };
    });
}

export function transformBlindingGrantedMapToBlindingSetup(itemGroupsEncounterGrantedMapForActiveBlindingGroup) {
  const itemGroupsTemplateIds = Object.keys(itemGroupsEncounterGrantedMapForActiveBlindingGroup);
  const itemGroups = itemGroupsTemplateIds.map(itemGroupTemplateId => {
    return {
      itemGroupTemplateId,
      ...itemGroupsEncounterGrantedMapForActiveBlindingGroup[itemGroupTemplateId]
    };
  });
  const encountersItemGroupsConfig = transformGrantedMapToEncounterItemGroupConfig(itemGroups);

  const logItemGroupsConfig = transformGrantedMapToLogItemGroupConfig(itemGroups);
  const unexpectedEncounterItemGroupsConfig = transformGrantedMapToUnexpectedEncounterItemGroupsConfig(itemGroups);

  return {
    itemGroupsConfig: [...logItemGroupsConfig, ...unexpectedEncounterItemGroupsConfig],
    encountersItemGroupsConfig
  };
}
