import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { without } from 'lodash/array';
import { includes } from 'lodash/collection';
import { isArray, isEmpty, isString, isUndefined } from 'lodash/lang';

import { FinVendorApi } from '../../../../../../../api';
import Checkbox from '../../../../../../../common/data-entry/InputSelectors/Checkbox';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { ControlledMultiselectList } from '../../../../../../../common/inputs/MultiSelectList/ControlledMultiselectList';
import { createBudgetRowForm, getBudgetEventType } from '../../../../../../../services/budgetRowService';
import { toBill } from '../../../../../../../services/financial';
import { onRequestError } from '../../../../../../../services/handlers';
import { BUDGET_EVENT_NAME_LENGTH } from '../../budgetConstant';
import BudgetEventControlButtons from '../BudgetEventControlButtons';
import { DIRECT_COST } from '../ExpenseType';
import AmountInput from '../help-component/AmountInput';
import ExpenseRadioInputs from '../help-component/ExpenseRadioInputs';

const AddOrEditPatientStipendBudgetEvent = ({
  data,
  itemGroupTriggers,
  budgetEventType,
  triggerType,
  onSave,
  modalBox,
  currentStudyBudget
}) => {
  const {
    id,
    name,
    overhead,
    withholding,
    clientAmount,
    patientAmount,
    finVendor,
    finTrigger,
    patientAmountType,
    vendorId,
    triggerName,
    triggerId
  } = data || {};

  const initialState = useMemo(
    () => ({
      id: id || null,
      name: name || '',
      overhead: !!overhead,
      withholding: !!withholding,
      clientAmount: !isUndefined(clientAmount) ? toBill(clientAmount, false) : 0,
      patientAmount: !isUndefined(patientAmount) ? toBill(patientAmount, false) : 0,
      selectedTriggers: finTrigger ? [finTrigger] : [],
      selectedVendorId: vendorId ?? null,
      finVendors: [],
      finVendor: finVendor || {},
      triggers: itemGroupTriggers,
      budgetEventType: budgetEventType,
      triggerType: triggerType,
      patientAmountType: patientAmountType ? patientAmountType : DIRECT_COST,
      triggerName: triggerName ?? null,
      triggerId: triggerId ?? null
    }),
    [
      budgetEventType,
      clientAmount,
      finTrigger,
      finVendor,
      id,
      itemGroupTriggers,
      name,
      overhead,
      patientAmount,
      patientAmountType,
      triggerId,
      triggerName,
      triggerType,
      vendorId,
      withholding
    ]
  );

  const [modalState, setModalState] = useState(initialState);

  useEffect(() => {
    FinVendorApi.findAll().then(({ data }) => {
      if (isArray(data)) {
        setModalState(prevState => ({ ...prevState, finVendors: data }));
      }
    }, onRequestError);
  }, []);

  const onChangeSidebarOption = useCallback(option => {
    setModalState(prevState => {
      if (includes(prevState.selectedTriggers, option)) {
        return {
          ...prevState,
          selectedTriggers: without(prevState.selectedTriggers, option)
        };
      }
      return {
        ...prevState,
        selectedTriggers: [...prevState.selectedTriggers, option]
      };
    });
  }, []);

  const toggleAllOptions = useCallback(allSelected => {
    setModalState(prevState => ({ ...prevState, selectedTriggers: allSelected ? [] : prevState.triggers }));
  }, []);

  const onChangeFormControls = useCallback(
    ({ target }) => {
      if (target.type === 'checkbox') {
        setModalState(prevState => ({ ...prevState, [target.name]: target.checked }));
      } else {
        setModalState({
          ...modalState,
          [target.name]: target.value
        });
      }
    },
    [modalState]
  );

  const save = useCallback(() => {
    const {
      id,
      name,
      overhead,
      withholding,
      clientAmount,
      patientAmount,
      selectedVendorId,
      selectedTriggers,
      patientAmountType
    } = modalState;

    const dataForSave = id
      ? createBudgetRowForm(
          name,
          overhead,
          withholding,
          clientAmount,
          null,
          patientAmount,
          null,
          budgetEventType,
          selectedVendorId,
          null,
          id,
          null,
          null,
          patientAmountType
        )
      : selectedTriggers.map(trigger => {
          const budgetRowType = getBudgetEventType(budgetEventType, trigger.type);
          return createBudgetRowForm(
            name,
            overhead,
            withholding,
            clientAmount,
            null,
            patientAmount,
            null,
            budgetRowType,
            selectedVendorId,
            trigger.id,
            id,
            null,
            null,
            patientAmountType
          );
        });

    return onSave(dataForSave);
  }, [budgetEventType, modalState, onSave]);

  const onSaveButton = useCallback(() => {
    save();
    modalBox.close();
  }, [modalBox, save]);

  const onSaveAndContinue = useCallback(() => {
    save().then(() => setModalState(initialState));
  }, [initialState, save]);

  const isValidForm = () => {
    const {
      name,
      clientAmount,
      patientAmount,
      vendorAmount,
      selectedVendorId,
      siteAmount,
      selectedTriggers,
      triggerId
    } = modalState;
    return (
      (selectedTriggers.length > 0 || triggerId) &&
      isString(name) &&
      name.trim() !== '' &&
      clientAmount >= 0 &&
      (!siteAmount || siteAmount >= 0) &&
      (selectedVendorId ? vendorAmount > 0 : true) &&
      patientAmount > 0 &&
      name.length <= BUDGET_EVENT_NAME_LENGTH
    );
  };

  const isEditingForbidden = !currentStudyBudget?.editPossibility;
  return (
    <>
      <ModalBoxes.Body>
        <div className="d-flex align-items-start">
          {!modalState.id && isArray(modalState.triggers) && (
            <div style={{ display: 'flex' }}>
              <div className="add-or-edit-budget-event-sidebar">
                <ControlledMultiselectList
                  options={modalState.triggers}
                  label="name"
                  height="400px"
                  selectedOptions={modalState.selectedTriggers}
                  onChangeSidebarOption={onChangeSidebarOption}
                  toggleAllOptions={toggleAllOptions}
                />
              </div>
            </div>
          )}
          <div className="bem-form container-fluid d-flex">
            <div className="form-row py-3">
              {modalState.id && (
                <div className="form-group col-12">
                  <label htmlFor="bem-event">Protocol Event</label>
                  <input id="bem-event" className="form-control" disabled value={modalState.triggerName} />
                </div>
              )}
              <div className="form-group col-12">
                <label htmlFor="bem-event-name">Event Name</label>
                <input
                  id="bem-event-name"
                  className="form-control reqfeild"
                  name="name"
                  value={modalState.name}
                  disabled={isEditingForbidden}
                  onChange={onChangeFormControls}
                />
                {modalState.name.length > BUDGET_EVENT_NAME_LENGTH && (
                  <p className="event-name-length-notification">
                    {`Event Name cannot exceed ${BUDGET_EVENT_NAME_LENGTH} characters`}
                  </p>
                )}
              </div>
              <Checkbox.Group className="col-12 mb-3" onChange={onChangeFormControls}>
                <Checkbox
                  label="Overhead"
                  id="bem-overhead"
                  name="overhead"
                  checked={modalState.overhead}
                  disabled={isEditingForbidden}
                />
                <Checkbox
                  label="Withholding"
                  id="bem-withholding"
                  name="withholding"
                  checked={modalState.withholding}
                  disabled={isEditingForbidden}
                />
              </Checkbox.Group>
              <div className="form-group col-5">
                <AmountInput
                  label="Client"
                  name="clientAmount"
                  value={modalState.clientAmount}
                  onChange={onChangeFormControls}
                  required
                  disabled={isEditingForbidden}
                  dollar
                />
              </div>
              <div className="form-group col-5">
                <AmountInput
                  name="patientAmount"
                  value={modalState.patientAmount}
                  required
                  onChange={onChangeFormControls}
                  label="Patient"
                  dollar
                  disabled={isEditingForbidden}
                />
                <ExpenseRadioInputs
                  value={modalState.patientAmountType}
                  name="patientAmountType"
                  onChangeFormControls={onChangeFormControls}
                  disabled={isEditingForbidden}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBoxes.Body>
      <BudgetEventControlButtons
        isValidForm={isValidForm}
        onClose={modalBox.close}
        onSave={onSaveButton}
        onSaveAndContinue={isEmpty(data) ? onSaveAndContinue : null}
        currentStudyBudget={currentStudyBudget}
      />
    </>
  );
};

AddOrEditPatientStipendBudgetEvent.title = 'Add Patient Stipend budget event';
AddOrEditPatientStipendBudgetEvent.className = 'add-patient-stipend-modal';
AddOrEditPatientStipendBudgetEvent.size = 'w800';

export default AddOrEditPatientStipendBudgetEvent;
