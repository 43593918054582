import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty, isEqual, isNull } from 'lodash/lang';

import { BlindingConfigApi, ProtocolApi } from '../../../../../../api';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { BLINDING_CONFIG_SAVED } from '../../../../../../constants/notificationMessages';
import { onRequestDefaultError } from '../../../../../../services/handlers';
import { fillConfiguredGrantedMap, getItemGroupEncounterGrantedMap } from '../../../../../../services/protocolBlinding';
import { useCurrentRoute } from '../../../../../root/router';

import { transformBlindingGrantedMapToBlindingSetup } from './blindingConfigurationService';
import getItemGroupEpochEncounter from './itemGroupEpochEncounterService';
import { openConfirmationDialog } from './сonfirmationDialogService';

export const ProtocolBlindingSetupContext = React.createContext(null);

export function ProtocolBlindingSetupProvider({ children }) {
  const {
    params: { studyId, protocolIdentity }
  } = useCurrentRoute();
  const [blindingGroups, setBlindingGroups] = useState([]);
  const [protocolInfo, setProtocolInfo] = useState([]);
  const [activeBlindingGroup, setActiveBlindingGroup] = useState(null);
  const [blindingSetup, setBlindingSetup] = useState(null);
  const [itemGroupsEncounterGrantedMap, setItemGroupsEncounterGrantedMap] = useState({});
  const [
    itemGroupsEncounterGrantedMapForActiveBlindingGroup,
    setItemGroupsEncounterGrantedMapForActiveBlindingGroup
  ] = useState({});
  const [itemGroupsEpochsAndEncounters, setItemGroupsEpochsAndEncounters] = useState({});
  const epochs = itemGroupsEpochsAndEncounters.epoch;
  const metadata = itemGroupsEpochsAndEncounters?.metadata;
  const activeBlindingGroupId = activeBlindingGroup?.key.blindingGroupId;
  const encounters = itemGroupsEpochsAndEncounters.encounters;
  const itemGroupsList = itemGroupsEpochsAndEncounters.itemGroups;

  useEffect(() => {
    ProtocolApi.getPreviewProtocol(studyId, protocolIdentity).then(res => {
      setProtocolInfo(res.data);
    });
  }, [studyId, protocolIdentity]);

  useEffect(
    function() {
      BlindingConfigApi.getAllBlindingGroupsForStudy(studyId).then(function({ data: { blindingGroups } }) {
        setBlindingGroups(blindingGroups);
      });
    },
    [studyId]
  );
  useEffect(
    function() {
      if (!isEmpty(blindingGroups) && isNull(activeBlindingGroup)) {
        setActiveBlindingGroup(blindingGroups[0]);
      }
    },
    [activeBlindingGroup, blindingGroups]
  );

  useEffect(
    function() {
      setItemGroupsEncounterGrantedMap(() => {
        return getItemGroupEncounterGrantedMap(itemGroupsList, encounters);
      });
    },
    [itemGroupsList, encounters]
  );
  useEffect(
    function() {
      getItemGroupEpochEncounter(studyId, protocolIdentity).then(setItemGroupsEpochsAndEncounters);
    },
    [studyId, protocolIdentity]
  );

  const loadConfigurationForCurrentBlindingGroup = useCallback(
    function() {
      BlindingConfigApi.getBlindingGroupConfiguration(studyId, activeBlindingGroupId, protocolIdentity).then(
        ({ data }) => {
          setBlindingSetup(data.setup);
        }
      );
    },
    [studyId, activeBlindingGroupId, protocolIdentity]
  );

  useEffect(
    function() {
      if (activeBlindingGroupId && !isEmpty(itemGroupsEncounterGrantedMap)) {
        loadConfigurationForCurrentBlindingGroup();
      }
    },
    [activeBlindingGroupId, itemGroupsEncounterGrantedMap, loadConfigurationForCurrentBlindingGroup]
  );
  useEffect(
    function() {
      if (blindingSetup) {
        setItemGroupsEncounterGrantedMapForActiveBlindingGroup(
          fillConfiguredGrantedMap(itemGroupsEncounterGrantedMap, blindingSetup, itemGroupsList)
        );
      }
    },
    [blindingSetup, itemGroupsEncounterGrantedMap, itemGroupsList]
  );

  const configurationForSave = useMemo(
    function() {
      const newBlindingConfig = transformBlindingGrantedMapToBlindingSetup(
        itemGroupsEncounterGrantedMapForActiveBlindingGroup
      );
      return {
        itemGroupsConfig: getNewItemGroupsConfigByType('itemGroupsConfig'),
        encountersItemGroupsConfig: getNewItemGroupsConfigByType('encountersItemGroupsConfig')
      };
      function getNewItemGroupsConfigByType(configType) {
        return newBlindingConfig[configType].filter(
          newItemGroup => !blindingSetup[configType].some(itemGroup => isEqual(itemGroup, newItemGroup))
        );
      }
    },
    [blindingSetup, itemGroupsEncounterGrantedMapForActiveBlindingGroup]
  );

  const isAllConfigurationSaved = useMemo(() => {
    return isEmpty(configurationForSave.itemGroupsConfig) && isEmpty(configurationForSave.encountersItemGroupsConfig);
  }, [configurationForSave]);

  return (
    <ProtocolBlindingSetupContext.Provider
      value={{
        metadata,
        epochs,
        encounters,
        setActiveBlindingGroup,
        setItemGroupsEncounterGrantedMap,
        itemGroupsEncounterGrantedMap,
        blindingGroups,
        protocolInfo,
        activeBlindingGroup,
        activeBlindingGroupId,
        itemGroupsList,
        itemGroupsEncounterGrantedMapForActiveBlindingGroup,
        setItemGroupsEncounterGrantedMapForActiveBlindingGroup,
        saveConfiguration,
        cancelConfiguration,
        isAllConfigurationSaved
      }}
    >
      {children}
    </ProtocolBlindingSetupContext.Provider>
  );

  function saveConfiguration() {
    BlindingConfigApi.changeBlindingGroupConfiguration(
      studyId,
      activeBlindingGroupId,
      protocolIdentity,
      configurationForSave
    ).then(() => {
      loadConfigurationForCurrentBlindingGroup();
      NotificationManager.success(BLINDING_CONFIG_SAVED);
    }, onRequestDefaultError);
  }

  function cancelConfiguration(action, actionType) {
    if (isAllConfigurationSaved) {
      action();
    } else {
      openConfirmationDialog(actionType, activeBlindingGroup.name).then(action, () => {});
    }
  }
}
