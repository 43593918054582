import React from 'react';
import jsplumb from 'jsplumb';
import { uniqBy } from 'lodash/array';
import { orderBy } from 'lodash/collection';
import { isEqual } from 'lodash/lang';
import { get } from 'lodash/object';
import Tooltip from 'rc-tooltip';

import { ProtocolApi, ProtocolBranchesApi, ProtocolItemGroupTemplatesApi } from '../../../../../api';
import common from '../../../../../common/common';
import Common from '../../../../../common/common';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import {
  SEQUENCE_BLANK_ERROR,
  SOMETHING_WENT_WRONG,
  UNIQUE_SEQUENCE_ERROR
} from '../../../../../constants/notificationMessages';
import { endWindow, startWindow } from '../../../../../constants/protocolConstant';
import { optionPaintStyle, primaryPaintStyle } from '../../../../../constants/protocolPaintStyles';
import { scPurple80 } from '../../../../../constants/systemColors';
import { onRequestError } from '../../../../../services/handlers';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { withParams } from '../../../../root/router/legacyComponentCompatability';
import { DirectionSection } from '../../../patient-source/Patients/Logs/DirectionSection';
import TableView from '../../shared/DomainTable/DomainTable';
import { LogCheckPreviewNew } from '../../shared/ElementSetupNew/LogCheckSetup/LogCheckPreviewNew';
import { TABLE_SETUP } from '../../shared/ElementSetupNew/TableSetup/TableSetupConstants';
import ItemGroupFormPreview from '../../shared/ItemGroupFormPreview/ItemGroupFormPreview';
import ElementPreviewModal from '../../shared/ItemGroupTemplatePreviewModal/ItemGroupTemplatePreviewModal';
import { EncounterEpochTableTemplate } from '../EncounterEpochTableTemplate/EncounterEpochTableTemplate';
import CommentSectionForPublishedProtocolAndBudget from '../ProtocolSetup/CommentSection/CommentSectionForPublishedProtocolAndBudget';
import { sortEpochsAndEncountersByProtocolSequence } from '../ProtocolSetup/EncounterSetup/services';
import { openNewTablePreview } from '../ProtocolSetup/ItemGroupList/ItemGroupList';
import ProtocolGroupsSetupPreview from '../ProtocolSetup/ProtocolGroupsSetup/ProtocolGroupsSetupPreview';

import BlindingPreview from './BlindingPreview/BlindingPreview';
import SetupComponent from './previewSetupComponent';

import 'jsplumb/css/jsplumbtoolkit-defaults.css';
import './SourceDataPreview.scss';

class SourceDataPreview extends React.Component {
  constructor(props) {
    super(props);
    this.jsPlumbInstance = null;
    this.state = {
      protocolSetupData: [],
      elements: [],
      situationalEncountersSetup: [],
      protocolVersion: '',
      protocolStatus: '',
      isAnyUnexpectedEncounterItemGroup: false,
      protocolName: '',
      publishDate: '',
      studyName: ''
    };
    this.allEndPoints = {};
    this.previewDataList = {};
    const arrowCommon = {
      foldback: 0.7,
      width: 14,
      events: {
        click() {}
      }
    };
    this.connectOverlays = [
      ['Arrow', { location: 0.7 }, arrowCommon],
      [
        'Label',
        {
          location: [0.5, 1.5],
          visible: true,
          id: 'label',
          cssClass: 'aLabel',
          events: {
            tap() {}
          }
        }
      ]
    ];
    this.start_config = {
      endpoint: ['Dot', { radius: 11 }],
      paintStyle: { fill: scPurple80 },
      isSource: true,
      scope: 'green',
      isTarget: false,
      maxConnections: -1,
      onMaxConnections() {},
      draggable: false,
      anchor: 'Right',
      connectorOverlays: this.connectOverlays,
      enabled: false
    };
    this.end_config = {
      endpoint: ['Dot', { radius: 11 }],
      paintStyle: { fill: scPurple80 },
      isSource: false,
      scope: 'green',
      isTarget: true,
      maxConnections: -1,
      onMaxConnections() {},
      draggable: false,
      anchor: 'Left',
      enabled: false,
      connectorOverlays: this.connectOverlays
    };
    this.primaryConnections = {};

    // configure some drop options for use by all endpoints.
    const dropOptionsConfig = {
      tolerance: 'touch',
      hoverClass: 'dropHover',
      activeClass: 'dragActive'
    };
    this.connectionEndpointConfig = {
      endpoint: ['Dot', { radius: 11 }],
      paintStyle: { fill: scPurple80 },
      isSource: true,
      scope: 'green',
      // connectorStyle: { stroke: color2, strokeWidth: 3},
      // connector: ["Bezier", { curviness: 63 } ],
      maxConnections: -1,
      isTarget: true,
      dropOptions: dropOptionsConfig,
      detachable: false,
      reattach: false,
      enabled: false,
      beforeDrop(params) {
        return params.sourceId !== params.targetId;
      }
    };
  }

  updateJSPlumbConfigToElel(el) {
    try {
      setTimeout(() => {
        this.allEndPoints[el.epoch.id] = [
          this.jsPlumbInstance.addEndpoint(
            el.epoch,
            { anchor: 'LeftMiddle', connectorOverlays: this.connectOverlays },
            this.connectionEndpointConfig
          ),
          this.jsPlumbInstance.addEndpoint(
            el.epoch,
            { anchor: 'RightMiddle', connectorOverlays: this.connectOverlays },
            this.connectionEndpointConfig
          )
        ];
      }, 10);
    } catch (e) {
      console.log(e);
    }
  }

  getPaintStyle(sourceId, targetId) {
    if (this.primaryConnections[sourceId] === targetId) {
      return primaryPaintStyle;
    }
    return optionPaintStyle;
  }

  getSource(sourceId) {
    if (isEqual(sourceId, startWindow)) {
      return this.allEndPoints[sourceId];
    }
    return this.allEndPoints[sourceId][1];
  }

  getTarget(targetId) {
    if (isEqual(targetId, endWindow)) {
      return this.allEndPoints.endWindow;
    }
    return this.allEndPoints[targetId][0];
  }

  connectEndpoint(sourceId, targetId) {
    this.jsPlumbInstance.connect({
      source: this.getSource(sourceId),
      target: this.getTarget(targetId),
      paintStyle: this.getPaintStyle(sourceId, targetId)
    });
  }

  connectEpochEndPoints() {
    this.state.protocolSetupData.forEach(ep => {
      if (ep.parent.includes(startWindow)) {
        this.connectEndpoint(startWindow, ep.nodeKey);
      }
      ep.child.forEach(epochChildId => {
        this.connectEndpoint(ep.nodeKey, epochChildId);
      });
    });
  }

  // get setup details of study
  getStudySetupDetails() {
    try {
      // protocolStatus
      ProtocolBranchesApi.getBranches(this.props.params.protocolIdentity).then(res => {
        res.data.forEach(primaryBranch => {
          this.primaryConnections[primaryBranch.source] = primaryBranch.target;
        });
        ProtocolApi.getPreviewProtocol(this.props.params.studyId, this.props.params.protocolIdentity).then(
          res => {
            if (res.status === 200 && res.data && typeof res.data === 'object') {
              let encounterElList = [];
              const sortedEpochData = sortEpochsAndEncountersByProtocolSequence(res.data.epoch);
              this.setState({
                protocolSetupData: sortedEpochData,
                protocolVersion: res.data.version,
                protocolStatus: res.data.status,
                protocolName: res.data.name,
                publishDate: res.data.publishDate,
                studyName: res.data.studyName,
                studyIsGroupAssign: res.data.studyIsGroupAssign,
                comment: res.data.comment,
                isAnyUnexpectedEncounterItemGroup: res.data.unexpectedEncountersSetup.length > 0,
                situationalEncountersSetup: res.data.situationalEncountersSetup || []
              });
              sortedEpochData.forEach(o => {
                if (o.encounters.length > 0) {
                  o.encounters.forEach(s => {
                    encounterElList.push(...s.elements);
                  });
                }
              });

              // for unique element
              encounterElList = uniqBy(encounterElList, 'elementName');
              // sort by itemgroup sequence
              encounterElList = orderBy(encounterElList, 'itemGroupSequence', 'asc');

              this.enrichEncounterElementsListByMissedUnexpectedElements(res, encounterElList);
              encounterElList.forEach(ig =>
                this.enrichAllElementsByUnexpectedFlag(ig, res.data.unexpectedEncountersSetup)
              );
              this.enrichEncounterElementsListByMissedSituationalElements(res, encounterElList);

              this.setState({ elements: encounterElList });
              setTimeout(() => {
                this.connectEpochEndPoints();
              }, 30);
            }
          },
          error => {
            NotificationManager.error(SOMETHING_WENT_WRONG);
          }
        );
      });
    } catch (e) {}
  }

  enrichEncounterElementsListByMissedUnexpectedElements(res, encounterElList) {
    const itemGroupIdsForEnrichment = res.data.unexpectedEncountersSetup
      .map(e => e.itemGroupTemplateId)
      .filter(e => !encounterElList.map(inside => inside.itemGroupKey).includes(e));
    const mappedItemGroupsForEnrichment = res.data.unexpectedEncountersSetup
      .filter(e => itemGroupIdsForEnrichment.includes(e.itemGroupTemplateId))
      .map(e => {
        return {
          domainName: e.domainName,
          elementIcon: e.itemGroupType,
          elementName: e.itemGroupName,
          elementUniqueIdentifier: e.itemGroupTemplateVersionId,
          itemGroupKey: e.itemGroupTemplateId
        };
      });

    Array.prototype.push.apply(encounterElList, mappedItemGroupsForEnrichment);
  }

  enrichEncounterElementsListByMissedSituationalElements(res, encounterElList) {
    const listOfSituationalElements = res.data.situationalEncountersSetup.flatMap(enc => enc.elements);

    const itemGroupIdsForEnrichment = listOfSituationalElements
      .map(e => e.itemGroupTemplateId)
      .filter(e => !encounterElList.map(inside => inside.itemGroupKey).includes(e));
    const mappedItemGroupsForEnrichment = listOfSituationalElements
      .filter(e => itemGroupIdsForEnrichment.includes(e.itemGroupTemplateId))
      .map(e => {
        return {
          domainName: e.domainName,
          elementIcon: e.itemGroupType,
          elementName: e.elementName,
          elementUniqueIdentifier: e.itemGroupTemplateVersionId,
          itemGroupKey: e.itemGroupTemplateId
        };
      });

    Array.prototype.push.apply(encounterElList, mappedItemGroupsForEnrichment);
  }

  enrichAllElementsByUnexpectedFlag(ig, itemGroupsFromServer) {
    const itemGroupExistForUnexpected = itemGroupsFromServer.find(e => e.itemGroupTemplateId === ig.itemGroupKey);
    ig.useForUnexpected = !!itemGroupExistForUnexpected;
    if (!!itemGroupExistForUnexpected) {
      ig.elementIcon = itemGroupExistForUnexpected.elementIcon;
      ig.elementName = itemGroupExistForUnexpected.elementName;
      ig.itemGroupSequence = itemGroupExistForUnexpected.itemGroupSequence;
    }
  }

  componentDidMount() {
    this.initializeJSplumb(this);
    this.getStudySetupDetails();
    window.addEventListener('resize', () => {
      try {
        this.jsPlumbInstance.repaintEverything();
      } catch (e) {
        console.error(e);
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {
      try {
        this.jsPlumbInstance.repaintEverything();
      } catch (e) {
        console.error(e);
      }
    });
  }

  initializeJSplumb() {
    jsplumb.jsPlumb.ready(() => {
      const instance = jsplumb.jsPlumb.getInstance({
        DragOptions: { cursor: 'pointer', zIndex: 2000 },
        PaintStyle: optionPaintStyle,
        EndpointHoverStyle: { fill: 'orange' },
        HoverPaintStyle: { stroke: 'orange' },
        EndpointStyle: { width: 20, height: 16, stroke: '#666' },
        Endpoint: 'Rectangle',
        Anchors: ['TopCenter', 'TopCenter'],
        Container: 'canvas'
      });
      this.jsPlumbInstance = instance;
      // start end setting
      this.allEndPoints.startWindow = instance.addEndpoint(document.getElementById('startWindow'), this.start_config);
      this.allEndPoints.endWindow = instance.addEndpoint(document.getElementById('endWindow'), this.end_config);
      // make .window divs draggable
      instance.draggable(jsplumb.jsPlumb.getSelector('.protocol-setup-container .protocol-epoch'));
      instance.batch(() => {
        instance.bind('connection', (info, originalEvent) => {});
      });
    });
  }

  checkForElementClass(epochElement, { elementName }) {
    const filterEle = Array.isArray(epochElement) && epochElement.find(({ elementName: en }) => en === elementName);
    return filterEle && `align-middle text-center ${!filterEle.optional ? 'selected' : 'selected-optional'}`;
  }

  checkForSituationalElementClass(elements, { elementUniqueIdentifier }) {
    const filterEle =
      Array.isArray(elements) &&
      elements.find(({ itemGroupTemplateVersionId: versionId }) => versionId === elementUniqueIdentifier);
    return filterEle && `align-middle text-center ${!filterEle.optional ? 'selected' : 'selected-optional'}`;
  }

  checkForUnexpectedElementClass(element) {
    let classNames = '';
    if (!!element.useForUnexpected) {
      classNames += 'selected align-middle text-center';
    }
    return classNames;
  }

  rednerElementListItemForEpoch(ele) {
    const itemGroupMappingNotAllowed = ['Review', 'New Table', 'Table'];
    const elementEncounterList = [];
    let toolTipTxt = '';
    let itemGropTooltip;
    itemGropTooltip = ele.elementName;
    if (ele.domainName === 'Custom Domain') {
      toolTipTxt = 'CD-Custom Domain';
    } else if (ele.domainName === 'Elligo Reminder') {
      toolTipTxt = 'ER-Elligo Reminder';
    } else {
      toolTipTxt = ele.domainName + (ele.description ? `-${ele.description}` : '');
    }

    elementEncounterList.push(
      <td
        key={Math.floor(Math.random() * 10000 + 1)}
        className="eett-sticky-column eett-header eett-grey-cell eett-right-align"
      >
        <Tooltip
          placement="top"
          mouseEnterDelay={0}
          mouseLeaveDelay={0.1}
          destroyTooltipOnHide={false}
          trigger={Object.keys({ hover: 1 })}
          onVisibleChange={this.onVisibleChange}
          overlay={
            <div>
              ItemGroup Domain: {toolTipTxt || 'Domain'}
              <div>ItemGroup Name: {itemGropTooltip}</div>
            </div>
          }
          align={{
            offset: [0, -3]
          }}
        >
          <label
            className="encounter_elements"
            onClick={e => {
              if (ele.elementIcon === TABLE_SETUP) {
                openNewTablePreview(
                  { itemGroupTemplateVersionId: ele.elementUniqueIdentifier },
                  this.props.params.studyId
                );
              } else {
                this.getElementFormPreview(ele.elementUniqueIdentifier);
              }
            }}
          >
            {ele.elementName}
          </label>
        </Tooltip>
      </td>
    );
    this.state.protocolSetupData.forEach((epochInf, epid) => {
      epochInf.encounters.forEach((ec, idx) => {
        const uniqueKey = `${epochInf.nodeKey}-${epochInf.name}-${epid}-${idx}`;
        elementEncounterList.push(
          <td className={this.checkForElementClass(ec.elements, ele)} key={uniqueKey}>
            <i className={this.checkForElementClass(ec.elements, ele) ? 'glyphicon glyphicon-ok white-text' : ''} />
          </td>
        );
      });
    });
    elementEncounterList.push(
      <td className={this.checkForUnexpectedElementClass(ele)} key={'uniqueUnexpectedEncIg'}>
        {ele.useForUnexpected && <i className={'selected glyphicon glyphicon-ok white-text'} />}
      </td>
    );
    this.state.situationalEncountersSetup.forEach((enc, idx) => {
      const uniqueKey = `situational-${enc.name}-${idx}`;
      elementEncounterList.push(
        <td className={this.checkForSituationalElementClass(enc.elements, ele)} key={uniqueKey}>
          <i
            className={
              this.checkForSituationalElementClass(enc.elements, ele) ? 'glyphicon glyphicon-ok white-text' : ''
            }
          />
        </td>
      );
    });
    return elementEncounterList;
  }

  // for form preview
  getElementFormPreview = elementIdentifer => {
    const params = {
      study_identifier: this.props && this.props.params && this.props.params.studyId,
      element_identifier: elementIdentifer
    };
    ProtocolItemGroupTemplatesApi.getElementDomain(params).then(res => {
      this.previewDataList = {};
      this.getPreviewViewData(res.data);
    }, onRequestError);
  };

  getPreviewViewData = prevSt => {
    const formData = prevSt && JSON.parse(JSON.stringify(prevSt));
    const columnList = [];
    let optionalDataList = [];
    let customDataList = [];
    if (formData) {
      if (formData.hasOwnProperty('optional') && formData.optional && formData.optional.length > 0) {
        formData.optional.forEach(formLabel => {
          optionalDataList = formLabel.itemDefinitionList.filter(item => {
            return item.isTable && item.isTable === true;
          });
        });
      }
      if (formData.hasOwnProperty('custom') && formData.custom && formData.custom.length > 0) {
        const getMergeCustomList = common.getSortedBySequenceFields(formData.custom);
        customDataList =
          getMergeCustomList.length > 0 &&
          getMergeCustomList.filter(item => {
            return item.isTable && item.isTable === true;
          });
      }
      const columnMergeArray = [].concat(optionalDataList, customDataList);
      common.sortByOrder(columnMergeArray, 'ASC').forEach(item => {
        columnList.push(item.updatedQuestion ? item.updatedQuestion : item.question);
      });

      formData.labelList = [].concat(formData.optional || [], formData.custom || []);

      if (columnList.length > 0) {
        formData.columnList = columnList;
        formData.formview = 'table';
      } else if (formData.elementIcon === 'Logs') {
        formData.columnList = formData.labelList
          .filter(e => !['textBlockLong', 'label'].includes(e.inputType))
          .map(e => e.name);
        formData.formview = 'table';
      }

      this.previewDataList = formData;
    } else {
      this.previewDataList.labelList = [];
    }
    if (this.previewDataList.hasOwnProperty('optional')) {
      delete this.previewDataList.optional;
    }
    if (this.previewDataList.hasOwnProperty('custom')) {
      delete this.previewDataList.custom;
    }
    this.openElementPreviewModal();
  };

  // open the preview modal
  openElementPreviewModal = () => {
    const getSortedBySequenceFields = common.getSortedBySequenceFields(this.previewDataList.labelList);
    const previewShowObj = {
      ...this.previewDataList,
      labelList: getSortedBySequenceFields
    };
    const isSequenceEmpty =
      previewShowObj.labelList &&
      previewShowObj.labelList.filter(item => {
        return item.sequence === '';
      });
    const valueArr =
      previewShowObj.labelList &&
      previewShowObj.labelList.map(function(item) {
        return item.sequence;
      });
    const isDuplicate = valueArr.some(function(item, idx) {
      return valueArr.indexOf(item) !== idx;
    });
    previewShowObj.labelList.forEach(item => {
      if (item.inputType === 'radio') {
        item.inputValue = 0;
      }
    });
    if (isSequenceEmpty && isSequenceEmpty.length > 0) {
      NotificationManager.error(SEQUENCE_BLANK_ERROR);
    } else if (!isDuplicate) {
      const header =
        this.previewDataList && this.previewDataList.domainName && this.previewDataList.elementName
          ? `- ${this.previewDataList.elementName}`
          : '-Element';
      ModalBoxes.open({
        component: <ElementPreviewModal renderFormData={this.renderForm(previewShowObj)} />,
        title: `Item Group Preview ${header}`
      });
    } else {
      NotificationManager.error(UNIQUE_SEQUENCE_ERROR);
    }
  };

  dynamicClassesForFieldName = item => {
    if (item.inputType === 'textBlockLong') {
      return 'pr-2 pl-0 overflow-ellipsis-multiple-line';
    }
    return 'pr-2 col-md-3 pl-0';
  };

  getPredefineColumnValues = formData => {
    if (formData) {
      if (typeof formData.predefinedColumnValues === 'string') {
        return formData.predefinedColumnValues.split(',');
      }
      if (typeof formData.predefinedColumnValues === 'object' && formData.predefinedColumnValues != null) {
        return formData.predefinedColumnValues;
      }
      return [];
    }

    return [];
  };

  // for render form
  renderForm = formData => {
    const customClassObject = {
      textbox: {
        containerClass: 'p-0',
        elementClass: 'form-control select-width'
      },
      dropdown: {
        containerClass: 'p-0',
        elementClass: 'form-control select-width'
      },
      date: {
        containerClass: 'custonDate',
        elementClass: 'p-0'
      },
      textarea: {
        containerClass: 'col pr-0 pl-0 display-inline',
        elementClass: 'form-control'
      },
      time: {
        containerClass: '',
        elementClass: 'select-width p-0'
      },
      checkbox: {
        containerClass: 'p-0',
        elementClass: ''
      },
      label: {
        containerClass: 'p-0',
        elementClass: ''
      },
      file: {
        containerClass: 'input-group ml-0 position-relative',
        elementClass: 'select-width custom-file-input'
      },
      reminder: {
        containerClass: 'col-md-12 ml-0 position-relative pl-0',
        elementClass: ''
      }
    };
    if (Object.keys(formData).length === 0) {
      return <div className="alert alert-info p-1 text-center">Please Add Items</div>;
    }
    const predefinedTableData = {
      predefinedColumnName: formData ? formData.predefinedColumnName : '',
      predefinedColumnValues: this.getPredefineColumnValues(formData)
    };
    return (
      <React.Fragment>
        <LogCheckPreviewNew logCheckOptions={get(formData, 'logCheckTemplate.rows')} domain={formData.domainCode} />
        <DirectionSection directions={formData.directions} isPreview={true} />
        {formData.formview && formData.formview === 'table' ? (
          <TableView
            tableData={[formData]}
            elementIcon={formData.elementIcon}
            displayElementIconTableType={true}
            predefinedTableData={predefinedTableData}
          />
        ) : (
          <div>
            <div className="form-group pt-2 row col-xs-12 col-md-12 position-relative ml-0 mr-0">
              {formData && (formData.domainCode === 'EREVSM' || formData.domainCode === 'EREVPI') && (
                <div className="alert alert-info col-md-12 text-center">No preview for this element</div>
              )}
              <ItemGroupFormPreview
                formData={formData}
                dynamicClassesForFieldName={this.dynamicClassesForFieldName}
                customClassObject={customClassObject}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };
  onCanvasScroll = () => {
    const conavasScrollPos = document.getElementById('canvas')?.scrollLeft;
    const endWinPos = document.getElementById(endWindow);
    endWinPos.style.right = `${-conavasScrollPos}px`;
    return this.jsPlumbInstance ? this.jsPlumbInstance.repaintEverything() : '';
  };
  render() {
    return (
      <div>
        <PageInfoHeader
          objectRecordLabel={this.state.studyName}
          pageInfo={
            <PageInfoHeader.CollapsibleList>
              <PageInfoHeader.AdditionalInfo tooltip="Source Data Name">
                {this.state.protocolName ? this.state.protocolName : 'PN'}
              </PageInfoHeader.AdditionalInfo>
              <PageInfoHeader.AdditionalInfo tooltip="Version">
                {this.state?.protocolVersion}
              </PageInfoHeader.AdditionalInfo>
              <PageInfoHeader.AdditionalInfo tooltip="Publish Date">
                {Common.formatDate(this.state?.publishDate)}
              </PageInfoHeader.AdditionalInfo>
            </PageInfoHeader.CollapsibleList>
          }
        />
        <CommentSectionForPublishedProtocolAndBudget comment={this.state.comment} />
        <section>
          <div className="border p-3 m-0 row justify-content-between protocol-preview-container">
            <SetupComponent
              onCanvasScroll={this.onCanvasScroll.bind(this)}
              epochs={this.state.protocolSetupData}
              updateEndPointHandler={this.updateJSPlumbConfigToElel.bind(this)}
            />
            <div className="preview-table-container">
              <div className="encounter-preview lower-then-full-page">
                <div className="row justify-content-between border-bottom-dotted m-0 mb-3 pb-2 px-0">
                  <div className="col-5 p-0">
                    <h5 className=" c-p">Encounter Preview</h5>
                  </div>
                </div>

                <div className="protocol-preview">
                  <div className="table-wrap">
                    <EncounterEpochTableTemplate
                      epochs={this.state.protocolSetupData}
                      encounters={this.state.protocolSetupData.flatMap(({ encounters }) => encounters)}
                      situationalEncounters={this.state.situationalEncountersSetup}
                    >
                      {this.state.elements && this.state.elements.length > 0 ? (
                        this.state.elements.map((ele, idx) => {
                          return <tr key={idx}>{this.rednerElementListItemForEpoch(ele)}</tr>;
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan={this.state.protocolSetupData.reduce((ac, currentV) => {
                              return ac + currentV.encounters.length;
                            }, 0)}
                            style={{ textAlign: 'center' }}
                          >
                            No record found.
                          </td>
                        </tr>
                      )}
                    </EncounterEpochTableTemplate>
                  </div>
                </div>
              </div>
              <BlindingPreview
                epochs={this.state.protocolSetupData}
                isGroupAssignStudy={this.state.studyIsGroupAssign}
              />
              {this.state.studyIsGroupAssign && (
                <ProtocolGroupsSetupPreview
                  studyId={this.props.params.studyId}
                  protocolIdentity={this.props.params.protocolIdentity}
                />
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withParams(SourceDataPreview);
