import { reduce } from 'lodash/collection';
import { isEmpty, isFunction } from 'lodash/lang';

export const FRS_UNINITIALIZED = 'UNINITIALIZED';
export const FRS_LOADED = 'LOADED';
export const FRS_READY = 'READY';

const schemaStatuses = [FRS_UNINITIALIZED, FRS_LOADED, FRS_READY];

export default function reducer(state, action) {
  const { type, processor, payload } = action;
  if (type === 'PERFORM') {
    if (isFunction(processor)) {
      return recalculateSchemaIfNeeded(state, processor(state, action));
    }
    return state;
  }
}

function recalculateSchemaIfNeeded(previousState, nextState) {
  const { schema } = nextState;

  if (previousState.schema !== schema) {
    const status = recalculateSchemaStatus(schema);
    if (schema.status !== status) {
      schema.status = status;
    }
  }

  if (isEmpty(schema)) {
    nextState.schema = createSchema(nextState);
  }

  return nextState;
}

function createSchema(state) {
  return reduce(
    state,
    function(a, v, k) {
      if (k === 'cache') return a;
      a[k] = { status: FRS_UNINITIALIZED };
      return a;
    },
    {
      status: FRS_UNINITIALIZED
    }
  );
}

function recalculateSchemaStatus(schema) {
  const { status, ...rest } = schema;

  return reduce(
    rest,
    function(a, { status }) {
      const lastStatusIndex = schemaStatuses.indexOf(a);
      const currentStatusIndex = schemaStatuses.indexOf(status);
      if (currentStatusIndex < lastStatusIndex) {
        return status;
      }
      return a;
    },
    FRS_READY
  );
}
