import React, { useMemo } from 'react';
import { filter, groupBy, sortBy } from 'lodash/collection';
import moment from 'moment';

import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../constants/dateFormat';
import { reimbursementItemTypesMap } from '../../../../patient-source/Patients/PatientInfo/EncountersSection/PaymentsSection/reimbursementConstants';
import { eventTypeLabel } from '../../../../setup/Budget/BudgetDetails/budgetConstant';

import { InvoicePageGenerator } from './InvoicePageGenerator';

export const EncounterInvoiceTemplate = ({
  invoices,
  invoiceNumber,
  comment,
  terms,
  payee,
  contact,
  remit,
  balanceDue,
  totalPages,
  setTotalPages,
  billTo,
  invoiceType,
  adjustmentDate
}) => {
  const normalizedGroups = useMemo(() => {
    const invoicesWishGroupNames = sortBy(
      sortBy(
        invoices.map(invoice => {
          let groupName;
          switch (invoice?.eventType) {
            case 'UNEXPECTED_ENCOUNTER':
            case 'UNEXPECTED_ITEM_GROUP':
              groupName = `Unscheduled ${invoice.encounterName?.replace(/^Unexpected:/, '')?.replace(/^.*?:/, '') ||
                '—'} ${invoice.name || '—'}`;
              break;
            case 'SITUATIONAL_ENCOUNTER':
            case 'SITUATIONAL_ITEM_GROUP':
              groupName = `Situational ${invoice.encounterName?.replace(/^Situational:/, '')?.replace(/^.*?:/, '') ||
                '—'} ${invoice.name || '—'}`;
              break;
            case 'ENCOUNTER_ITEM_GROUP':
            case 'ENCOUNTER':
              groupName = `${invoice?.epochName || '—'}-${invoice?.encounterName || '—'}:${invoice?.name || '—'}`;
              break;
            default:
              groupName = invoice?.name || '';
          }
          return { ...invoice, groupName };
        }),
        'subjectNumber'
      ),
      el => el.groupName.toLowerCase()
    );

    const invoicesWishFilteredStipends = filter(
      invoicesWishGroupNames,
      event =>
        (event.budgetEventType === 'Patient Stipend' && event.invoiceAmount !== 0) ||
        event.budgetEventType !== 'Patient Stipend'
    );

    const values = Object.values(
      groupBy(
        invoicesWishFilteredStipends,
        ({ groupName, budgetEventType, invoiceAmount }) => groupName + budgetEventType + invoiceAmount
      )
    )
      .reduce((acc, group) => {
        return [
          ...acc,
          {
            typeHeader: true,
            groupHeader: group[0].groupName,
            groupLength: group[0].budgetEventType === eventTypeLabel.PATIENT_REIMBURSEMENT ? 1 : group.length,
            amountForItem: group[0].invoiceAmount,
            headerBudgetEventType: group[0].budgetEventType
          },
          ...group
        ];
      }, [])
      .flatMap(el => {
        if (el.budgetEventType === eventTypeLabel.PATIENT_REIMBURSEMENT) {
          return [
            {
              templateRowType: 'REIMBURSEMENT_EPOCH_ENCOUNTER',
              groupName: el.groupName,
              content: `${el?.epochName || '—'}-${el?.encounterName || '—'}`,
              ...el
            },
            {
              templateRowType: 'REIMBURSEMENT_SUBJECT',
              groupName: el.groupName,
              content: `Subject ID ${el.subjectNumber || '—'}`,
              ...el
            },
            ...el.reimbursementItems.map(reimbursement => ({
              ...reimbursement,
              templateRowType: 'REIMBURSEMENT_ITEM',
              groupName: el.groupName,
              type: reimbursementItemTypesMap[reimbursement.type],
              ...el
            }))
          ];
        }
        return el;
      });
    return [...values, { typeBalance: true, comment, balanceDue }];
  }, [balanceDue, comment, invoices]);

  return (
    <InvoicePageGenerator
      normalizedGroups={normalizedGroups}
      pageNumber={1}
      totalPages={totalPages}
      setTotalPages={setTotalPages}
      study={invoices?.[0]?.studyName}
      pcn={invoices?.[0]?.pcnName}
      investigator={invoices?.[0]?.investigator}
      site={invoices?.[0]?.siteName}
      siteNumber={invoices?.[0]?.siteNumber}
      invoiceNumber={invoiceNumber}
      invoiceDate={
        adjustmentDate
          ? moment(adjustmentDate).format(DD_SLASH_MMM_SLASH_YYYY)
          : moment().format(DD_SLASH_MMM_SLASH_YYYY)
      }
      terms={terms}
      payee={payee}
      contact={contact}
      remit={remit}
      billTo={billTo}
      invoiceType={invoiceType}
    />
  );
};
