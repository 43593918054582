import { useContext, useEffect, useMemo } from 'react';
import { differenceBy } from 'lodash/array';
import { debounce } from 'lodash/function';
import { isEmpty } from 'lodash/lang';

import Loader from '../../../../../common/elements/Loader/Loader';
import ApplyAndResetButtons from '../../../../ApplyAndResetButtons/ApplyAndResetButtons';
import { useEuiFiltersDispatch, useEuiFiltersState } from '../../../../eui/EuiFiltersContext/EuiFiltersContext';
import { FRS_READY } from '../../../../eui/EuiFiltersContext/reducer';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { useCurrentUser } from '../../../../root/Container/CurrentUserContainer';
import { RBS_PENDING } from '../../../patient-source/Patients/PatientInfo/EncountersSection/PaymentsSection/reimbursementConstants';
import { ReimbursementWorklistContext } from '../ReimbursementWorklistContext';

import Assigned from './Assigned/Assigned';
import Statuses from './Statuses/Statuses';
import StudySite from './StudySite/StudySite';

import './FiltersSection.scss';

export default function FiltersSection() {
  const user = useCurrentUser();
  const userCode = useMemo(() => {
    return `${user.personnelIdentifier}-${user.activeRole}`;
  }, [user]);
  const state = useEuiFiltersState();
  const dispatch = useEuiFiltersDispatch();
  const { gridData, applyButton, loadingAllowed } = useContext(ReimbursementWorklistContext);

  const { schema } = state;

  const status = state.schema?.status;

  const debounceCache = useMemo(function() {
    return debounce(cb => cb(), 500);
  }, []);

  useEffect(
    function() {
      if (status !== FRS_READY) {
        return;
      }
      debounceCache(function() {
        dispatch({
          type: 'PERFORM',
          processor: cacheFiltersProcessor,
          payload: userCode
        });
      });
    },
    [debounceCache, dispatch, gridData, status, userCode]
  );

  useEffect(
    function() {
      if (status || !userCode) return;
      const cacheString = sessionStorage.getItem(`RWF_${userCode}`) || '{}';
      const cache = JSON.parse(cacheString);
      dispatch({
        type: 'PERFORM',
        processor: setInitialStateProcessor,
        payload: {
          statuses: {
            source: [],
            value: [{ id: RBS_PENDING, name: 'Submitted - Pending' }]
          },
          ssu: {
            studySites: [],
            studies: [],
            sites: [],
            selectedStudyMap: {},
            selectedSiteMap: {},
            selectedStudies: [],
            selectedSites: []
          },
          assigned: {
            source: [{ id: null, name: 'Empty' }],
            value: null
          },
          cache
        }
      });
    },
    [dispatch, status, userCode]
  );

  useEffect(
    function() {
      if (isEmpty(gridData)) return;
      const allAssignees = gridData.map(function({ assigneePersonnelId, assigneePersonnelName }) {
        return { id: assigneePersonnelId, name: assigneePersonnelName };
      });

      dispatch({
        type: 'PERFORM',
        processor: setAssignedProcessor,
        payload: allAssignees
      });
    },
    [dispatch, gridData]
  );

  if (isEmpty(schema)) return null;

  return (
    <PageInfoHeader>
      <div className="general-header-wrapper" style={{ minHeight: 71 }}>
        <Statuses uniqKey="statuses" state={state} dispatch={dispatch} />
        <StudySite uniqKey="ssu" state={state} dispatch={dispatch} />
        <Assigned uniqKey="assigned" state={state} dispatch={dispatch} />
        {status === FRS_READY ? (
          <ApplyAndResetButtons
            applyClassName="apply-btn"
            onApply={function() {
              applyButton();
            }}
            applyDisabled={!loadingAllowed}
          />
        ) : (
          <Loader dark={true} />
        )}
      </div>
    </PageInfoHeader>
  );
}

function cacheFiltersProcessor(state, { payload }) {
  const cache = {
    statuses: {
      value: state?.statuses?.value
    },
    ssu: {
      selectedStudyMap: state?.ssu?.selectedStudyMap,
      selectedSiteMap: state?.ssu?.selectedSiteMap
    },
    assigned: {
      value: state?.assigned?.value
    }
  };

  sessionStorage.setItem(`RWF_${payload}`, JSON.stringify(cache));
  return state;
}

function setInitialStateProcessor(state, action) {
  const { type, payload } = action;

  if (isEmpty(payload)) {
    return state;
  }

  return { ...state, ...payload };
}

function setAssignedProcessor(state, action) {
  const { type, payload } = action;

  const notExistInFilters = differenceBy(payload, state.assigned?.source, 'id');

  if (isEmpty(notExistInFilters)) {
    return state;
  }
  return {
    ...state,
    assigned: {
      ...state.assigned,
      source: [...state.assigned.source, ...notExistInFilters],
      value: [...state.assigned.value, ...notExistInFilters]
    }
  };
}
