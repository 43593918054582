import React from 'react';

import Select from '../../common/data-entry/Select/Select';

export function SSUFilterMaterialRepresentation({
  studies,
  sites,
  handleSiteChange,
  handleStudyChange,
  initialStudy,
  initialSite,
  isStudyRequired,
  isSiteRequired,
  selectorClass = '',
  studyValidationMessage,
  siteValidationMessage
}) {
  return (
    <>
      <Select
        label="Study"
        searchable
        clearSearchOnSelection
        onChange={handleStudyChange}
        dataSource={studies}
        optionLabelKey="studyName"
        optionValueKey="uniqueIdentifier"
        value={initialStudy}
        valid={isStudyRequired}
        validationMessage={studyValidationMessage}
        required
      />
      <Select
        label="Site"
        searchable
        clearSearchOnSelection
        onChange={handleSiteChange}
        optionLabelKey="siteName"
        optionValueKey="uniqueIdentifier"
        dataSource={sites}
        value={initialSite}
        valid={isSiteRequired}
        validationMessage={siteValidationMessage}
        required
      />{' '}
    </>
  );
}
