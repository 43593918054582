import axios from 'axios';

const ROOT = 'api/v1/finance';

const FinBudgetFixedItemApi = {
  findTriggersForActualBudget(studyId, siteId, ssuId, date) {
    return axios.get(`${ROOT}/fixed-items/triggers?studyId=${studyId}&siteId=${siteId}&ssuId=${ssuId}&date=${date}`);
  },
  getExpenseVariableTriggers(studyId, siteId, ssuId, date) {
    return axios.get(
      `${ROOT}/expenses/variables/triggers?studyId=${studyId}&siteId=${siteId}&ssuId=${ssuId}&date=${date}`
    );
  },

  getFilteredFixedItems(ssuId) {
    return axios.get(`${ROOT}/study-sites/${ssuId}/fixed-items`);
  },

  getFilteredExpensesVariable(ssuId) {
    return axios.get(`${ROOT}/study-sites/${ssuId}/expenses/variables`);
  },

  findAllFixedItems(filterProperty) {
    return axios.post(`${ROOT}/fixed-items/download`, filterProperty, {
      responseType: 'arraybuffer'
    });
  },

  create(ssuId, finBudgetFixedItem) {
    return axios.post(`${ROOT}/fixed-items?ssuId=${ssuId}`, finBudgetFixedItem);
  },

  createExpenseVariable(ssuId, expenseVariable) {
    return axios.post(`${ROOT}/expenses/variables?ssuId=${ssuId}`, expenseVariable);
  },

  getFixedItemClientAmount(ssuId, finTriggerId, finBudgetId) {
    return axios.get(
      `${ROOT}/fixed-items/triggers/amount?ssuId=${ssuId}&finTriggerId=${finTriggerId}&finBudgetId=${finBudgetId}`
    );
  },

  getExpenseVariableMaximumAmount(ssuId, studyId, siteId, finTriggerId, finBudgetId) {
    return axios.get(
      `${ROOT}/expenses/variables/triggers/amounts?ssuId=${ssuId}&studyId=${studyId}&siteId=${siteId}&finTriggerId=${finTriggerId}&finBudgetId=${finBudgetId}`
    );
  },

  updateCommentForFixedItem(ssuId, commentResponse) {
    return axios.post(`${ROOT}/fixed-items/comment?ssuId=${ssuId}`, commentResponse);
  },

  getExpenseVariableDetails(expenseVariableId, ssuId) {
    return axios.get(`${ROOT}/study-sites/${ssuId}/expenses/variables/${expenseVariableId}`);
  },

  updateExpenseVariable(ssuId, expenseVariableId, expenseVariable) {
    return axios.put(`${ROOT}/expenses/variables/${expenseVariableId}?ssuId=${ssuId}`, expenseVariable);
  },

  getAttachedFile(fileId, ssuId, hideLoader = true) {
    return axios({
      url: `${ROOT}/expenses/variables/files/${fileId}?ssuId=${ssuId}`,
      method: 'GET',
      responseType: 'blob',
      hideLoader
    });
  }
};

export default FinBudgetFixedItemApi;
