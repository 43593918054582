import { useEffect } from 'react';
import { reduce } from 'lodash/collection';
import { has } from 'lodash/object';

import ReimbursementWorklistApi from '../../../../../../api/patient/ReimbursementWorklistApi';
import MultiSelect from '../../../../../../common/data-entry/MultiSelect/MultiSelect';
import { FRS_LOADED, FRS_READY, FRS_UNINITIALIZED } from '../../../../../eui/EuiFiltersContext/reducer';

import { initializeFlow, initializeProcessor, setValueProcessor } from './processors';

export default function StudySite({ uniqKey, state, dispatch }) {
  const fState = state[uniqKey];
  const fCache = state.cache[uniqKey];
  const status = state.schema[uniqKey].status;
  const { studies, sites, selectedStudies, selectedSites } = fState || {};

  useEffect(() => {
    if (status !== FRS_UNINITIALIZED) {
      return;
    }
    ReimbursementWorklistApi.getStudySitesWithReimbursement().then(({ data: studySites }) => {
      dispatch({
        type: 'PERFORM',
        processor: initializeProcessor,
        payload: {
          key: uniqKey,
          studySites
        }
      });
    });
  }, [uniqKey, dispatch, status]);

  useEffect(
    function() {
      if (status !== FRS_LOADED) {
        return;
      }

      dispatch({
        type: 'PERFORM',
        processor: syncProcessor,
        payload: { [uniqKey]: fCache }
      });
    },
    [dispatch, uniqKey, status, fCache]
  );

  if (status !== FRS_READY) return null;

  return (
    <>
      <MultiSelect
        label="Study"
        disabled={false}
        dataSource={studies}
        onChange={function(values) {
          dispatch({
            type: 'PERFORM',
            processor: setValueProcessor,
            payload: {
              key: uniqKey,
              key2: 'Study',
              value: values
            }
          });
        }}
        value={selectedStudies}
        searchable={true}
        clearSearchOnSelection
        customOptionTemplateFunction={e => (
          <div>
            <div className="label">{e?.projectCode}</div>
            <div>{e?.name}</div>
          </div>
        )}
        className="task-ssu-multi-select-study"
        validate={false}
      />
      <MultiSelect
        label="Site"
        disabled={false}
        dataSource={sites}
        onChange={function(values) {
          dispatch({
            type: 'PERFORM',
            processor: setValueProcessor,
            payload: {
              key: uniqKey,
              key2: 'Site',
              value: values
            }
          });
        }}
        value={selectedSites}
        searchable={true}
        clearSearchOnSelection
        validate={false}
      />
    </>
  );
}

function syncProcessor(state, action) {
  const { type, payload } = action;

  return reduce(
    state,
    function(accumulator, value, key) {
      if (key === 'schema' || key === 'cache') return accumulator;
      if (has(payload, key)) {
        if (key === 'ssu') {
          accumulator[key] = initializeFlow({
            ...accumulator[key],
            ...payload[key]
          });
        } else {
          accumulator[key] = {
            ...accumulator[key],
            ...payload[key]
          };
        }
        accumulator.schema = {
          ...accumulator.schema,
          [key]: {
            ...accumulator.schema[key],
            status: FRS_READY
          }
        };
      }
      return accumulator;
    },
    { ...state }
  );
}
