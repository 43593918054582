import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import moment from 'moment';

import InformedConsentApi from '../../../../../api/patient/InformedConsentApi';
import common from '../../../../../common/common';
import Common from '../../../../../common/common';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import Icon from '../../../../../common/general/Icon';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import {
  DOWNLOAD_HAS_NOT_YET_CLEARED_VIRUS_SCAN,
  DOWNLOAD_MISSING,
  VIRUS_DETECTED_IN_DOWNLOAD
} from '../../../../../constants/notificationMessages';
import { scBlue100 } from '../../../../../constants/systemColors';
import { ADD_EDIT_INFORMED_CONSENT_ITEMS } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';

import InformedConsentHistoryModal from './InformedConsentHistoryModal';
import UploadICFModal from './UploadICFModal';

import 'react-table/react-table.css';
import './InformedConsentTable.scss';

const assembleName = ({ items, typeId, languageId }) => {
  const type = items.types.find(item => item.id === typeId)?.type;
  const language = items.languages.find(item => item.id === languageId)?.language;
  return `${type} ${language}`;
};

export const onFileOpen = (filename, formId, ssuId) => {
  Common.showLoader();
  InformedConsentApi.downloadForm(ssuId, formId)
    .then(resp => {
      let file = new File([resp.data], filename, { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      Common.hideLoader();
      window.open(fileURL);
    })
    .catch(err => {
      Common.hideLoader();
      if (!err || !err.response) {
        throw err;
      } else if (err.response.status === 404) {
        NotificationManager.error(DOWNLOAD_MISSING);
      } else if (err.response.status === 409) {
        NotificationManager.warning(DOWNLOAD_HAS_NOT_YET_CLEARED_VIRUS_SCAN);
      } else if (err.response.status === 502) {
        NotificationManager.error(VIRUS_DETECTED_IN_DOWNLOAD);
      } else {
        throw err;
      }
    });
};

const openUploadModal = ({ ssuId, informedConsentItems, updateForms }) => {
  ModalBoxes.open({
    component: (
      <UploadICFModal
        types={informedConsentItems.types}
        languages={informedConsentItems.languages}
        activeTemplates={informedConsentItems.activeTemplates}
        ssuId={ssuId}
        updateForms={updateForms}
      />
    ),
    onClose: isChanged => {
      if (isChanged) {
        NotificationManager.error('Unable to save');
      }
    }
  });
};

const openHistoryModal = ({ onFileOpen, ssuId, typeId, languageId, forms }) => {
  const history = forms.filter(form => form.typeId === typeId && form.languageId === languageId);
  ModalBoxes.open({
    component: <InformedConsentHistoryModal ssuId={ssuId} onFileOpen={onFileOpen} forms={history} />
  });
};

const InformedConsentTable = ({ ssuId, studyId }) => {
  const [informedConsentItems, setInformedConsentItems] = useState({ types: [], languages: [] });
  const [forms, setForms] = useState([]);
  const [activeForms, setActiveForms] = useState([]);

  const updateItems = () => {
    InformedConsentApi.getItemsByStudyId(studyId).then(({ data }) => {
      setInformedConsentItems(data);
    });
  };

  const updateForms = () => {
    InformedConsentApi.getFormsBySsuId(ssuId).then(res => {
      const {
        data: { forms }
      } = res;
      let transformed = [];
      forms.forEach(form => {
        transformed.push({
          active: form.active,
          formId: form.id,
          filename: form.fileName,
          typeId: form.typeId,
          languageId: form.languageId,
          name: assembleName({
            items: informedConsentItems,
            typeId: form.typeId,
            languageId: form.languageId
          }),
          publishDate: form.publishDate,
          uploadDate: form.uploadDate,
          version: form.version
        });
      });
      setForms(transformed.sort((a, b) => (a.name > b.name ? 1 : -1)));
    });
  };

  useEffect(() => {
    if (studyId) {
      updateItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyId]);

  useEffect(() => {
    if (ssuId && informedConsentItems?.languages?.length && informedConsentItems?.types?.length) {
      updateForms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssuId, informedConsentItems]);

  useEffect(() => {
    if (forms) {
      setActiveForms(forms.filter(form => form.active));
    }
  }, [forms]);

  const columns = [
    {
      Header: 'History',
      accessor: 'history',
      sortable: false,
      minWidth: 70,
      width: 70,
      fixed: true,
      Cell: row => {
        const { typeId, languageId } = row.original;
        return (
          <Icon
            onClick={() => openHistoryModal({ onFileOpen, ssuId, typeId, languageId, forms })}
            className={'history-link'}
            style={{ fontSize: '25px', color: scBlue100, cursor: 'pointer' }}
          >
            history
          </Icon>
        );
      }
    },
    {
      Header: 'Name of ICF',
      accessor: 'name',
      sortable: true,
      Cell: row => {
        const { filename, formId } = row.original;
        const { name } = row.row;
        return (
          <span style={{ color: scBlue100, cursor: 'pointer' }} onClick={() => onFileOpen(filename, formId, ssuId)}>
            {name}
          </span>
        );
      }
    },
    {
      Header: 'ICF IRB Approval Date',
      accessor: 'publishDate',
      Cell: row => {
        const { publishDate } = row.row;
        return common.formatDate(publishDate, 'DD/MMM/YYYY');
      },
      sortable: true,
      sortMethod: common.dateComparator
    },
    {
      Header: 'Date Uploaded',
      accessor: 'uploadDate',
      Cell: row => {
        const { uploadDate } = row.row;
        const zonedDate = moment.tz(uploadDate, moment.tz.guess());
        return common.formatDate(zonedDate, 'DD/MMM/YYYY hh:mm a');
      },
      sortable: true,
      sortMethod: common.dateComparator
    },
    {
      Header: 'Version',
      accessor: 'version',
      Cell: row => {
        const { version } = row.row;
        return version ? <span className="cursor-pointer"> {version} </span> : '--';
      }
    }
  ];

  return (
    <div className="row col-12 px-0 pt-1 pb-3 m-0">
      <div className="col-md-12 row m-0 p-0 mb-2 justify-content-between border-bottom-dotted pb-0">
        <h5 className="mt-2 col-md-8 col-sm-auto p-0 c-p ">Informed Consent</h5>
        {userHasAccessTo(ADD_EDIT_INFORMED_CONSENT_ITEMS) && (
          <span className="add-informed-consent-setup-item">
            <Button
              size="h28"
              priority="low"
              onClick={() => openUploadModal({ ssuId, informedConsentItems, updateForms })}
            >
              <Icon suit="glyphicon">plus</Icon>
              Upload ICF
            </Button>
          </span>
        )}
      </div>
      <div className="col-12 border px-0 site-info-table">
        <ReactTable
          data={activeForms}
          columns={columns}
          minRows={1}
          multiSort
          className="table activity-table table-responsive-sm m-0 "
          showPagination
          nextText=">>"
          previousText="<<"
          defaultPageSize={5}
          noDataText="No Informed Consent Items Found"
          sortable
        />
      </div>
    </div>
  );
};

export default InformedConsentTable;
