import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';

import { SiteBillApi } from '../../../../api';

export const SitePaymentsContext = React.createContext(null);

export function SitePaymentsProvider({ children }) {
  const initialStartDate = useMemo(
    () => moment('2019-01-01').set({ hour: 0, minute: 0, second: 0, millisecond: 1 }),
    []
  );
  const initialEndDate = useMemo(() => moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }), []);
  const [tableData, setTableData] = useState([]);
  const [checkedEvents, setCheckedEvents] = useState([]);
  const [site, setSite] = useState(null);
  const [study, setStudy] = useState(null);
  const [pcn, setPcn] = useState(null);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const filtersAssembler = useCallback(() => {
    const statuses = ['PENDING', 'BILLED'];
    return {
      studyName: study?.studyName,
      siteName: site?.siteName,
      studyId: study?.uniqueIdentifier,
      siteId: site?.uniqueIdentifier,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      statuses: statuses,
      finTypeForComparingStatuses: 'SITE',
      projectCode: pcn
    };
  }, [site, study, startDate, endDate, pcn]);

  const applyFilter = useCallback(() => {
    const filters = filtersAssembler();
    SiteBillApi.getSiteBills(filters).then(({ data }) => {
      setTableData(data);
    });
  }, [filtersAssembler]);

  const resetFilters = useCallback(() => {
    setSite(null);
    setStudy(null);
    setPcn(null);
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  }, [initialStartDate, initialEndDate]);

  return (
    <SitePaymentsContext.Provider
      value={{
        setSite,
        setStudy,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        tableData,
        checkedEvents,
        setCheckedEvents,
        applyFilter,
        resetFilters,
        study,
        site,
        pcn,
        setPcn
      }}
    >
      {children}
    </SitePaymentsContext.Provider>
  );
}

export function withSitePaymentsContext(Component) {
  return function WrapperComponent(props) {
    return (
      <SitePaymentsProvider>
        <Component {...props} />
      </SitePaymentsProvider>
    );
  };
}
