import React from 'react';
import { Menu, MenuItem } from '@szhsin/react-menu';
import * as PropTypes from 'prop-types';

import Button from '../../../../../common/general/Button';
import Icon from '../../../../../common/general/Icon';

const menuButton = (
  <Button priority="medium" size="h28">
    Set access
    <Icon>arrow_drop_down</Icon>
  </Button>
);

export function BulkBlindingSetup(props) {
  const { setItemGroupsEncounterGrantedMapForActiveBlindingGroup } = props;
  return (
    <div className="bulk-granted-setup-dropdown">
      <Menu viewScroll="close" align="end" menuButton={menuButton}>
        <MenuItem onClick={() => setBlindingSetupForAll(true)}>Allow all</MenuItem>
        <MenuItem onClick={() => setBlindingSetupForAll(false)}>Deny all</MenuItem>
      </Menu>
    </div>
  );

  function setBlindingSetupForAll(granted) {
    setItemGroupsEncounterGrantedMapForActiveBlindingGroup(grantedMap => {
      Object.keys(grantedMap).forEach(itemGroupId => {
        const itemGroup = grantedMap[itemGroupId];
        if (itemGroup.isLogItemGroup) {
          itemGroup.granted = granted;
        } else {
          const encounters = itemGroup.encounters;
          Object.keys(encounters).forEach(encounterId => {
            encounters[encounterId].granted = granted;
          });
        }
      });
      return { ...grantedMap };
    });
  }
}

BulkBlindingSetup.propTypes = { setItemGroupsEncounterGrantedMapForActiveBlindingGroup: PropTypes.func };
