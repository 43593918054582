import React, { useCallback, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import { FinBudgetFixedItemApi } from '../../../../../api';
import { scPurple80 } from '../../../../../constants/systemColors';
import { ExpenseFixedList } from '../ExpenseFixedList/ExpenseFixedList';
import { ExpenseVariableList } from '../ExpenseVariableList/ExpenseVariableList';

const StudyEventForm = ({ selectedSSU }) => {
  const [showRightPanel, setShowRightPanel] = useState(null);
  const [expensesFixed, setExpensesFixed] = useState([]);
  const [budgetForSSUExistForCurrentDate, setBudgetForSSUExistForCurrentDate] = useState(false);
  const [expenseVariableForSSUExistForCurrentDate, setExpenseVariableForSSUExistForCurrentDate] = useState(false);

  const [expensesVariable, setExpensesVariable] = useState([]);

  const updateAvailableFixedItems = useCallback(() => {
    FinBudgetFixedItemApi.getFilteredFixedItems(selectedSSU.ssuIdentifier).then(({ data: finBudgetFixedItems }) => {
      setExpensesFixed(finBudgetFixedItems);
    });
  }, [selectedSSU?.ssuIdentifier]);

  const updateAvailableExpensesVariable = useCallback(() => {
    FinBudgetFixedItemApi.getFilteredExpensesVariable(selectedSSU.ssuIdentifier).then(({ data }) => {
      setExpensesVariable(data);
    });
  }, [selectedSSU?.ssuIdentifier]);

  useEffect(() => {
    if (selectedSSU?.ssuIdentifier) {
      updateAvailableFixedItems();
      updateAvailableExpensesVariable();
    } else {
      setExpensesFixed([]);
      setExpensesVariable([]);
    }
  }, [selectedSSU, updateAvailableExpensesVariable, updateAvailableFixedItems]);

  useEffect(() => {
    setShowRightPanel(null);
    if (!isEmpty(selectedSSU)) {
      FinBudgetFixedItemApi.findTriggersForActualBudget(
        selectedSSU.studyIdentifier,
        selectedSSU.siteIdentifier,
        selectedSSU.ssuIdentifier,
        moment()
          .utc(true)
          .format('YYYY-MM-DD')
      ).then(({ data: { finBudgetSite } }) => {
        setBudgetForSSUExistForCurrentDate(!isEmpty(finBudgetSite));
      });
      FinBudgetFixedItemApi.getExpenseVariableTriggers(
        selectedSSU.studyIdentifier,
        selectedSSU.siteIdentifier,
        selectedSSU.ssuIdentifier,
        moment()
          .utc(true)
          .format('YYYY-MM-DD')
      ).then(({ data: { finBudgetSite } }) => {
        setExpenseVariableForSSUExistForCurrentDate(!isEmpty(finBudgetSite));
      });
    }
  }, [selectedSSU]);

  if (
    ((!isEmpty(expensesFixed) || !isEmpty(expensesVariable)) && !isEmpty(selectedSSU)) ||
    ((budgetForSSUExistForCurrentDate || expenseVariableForSSUExistForCurrentDate) && !isEmpty(selectedSSU))
  ) {
    return (
      <>
        <Accordion disableGutters defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ color: scPurple80, fontWeight: 'bold' }}>Expenses - Fixed</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ExpenseFixedList
              expensesFixed={expensesFixed}
              updateAvailableFixedItems={updateAvailableFixedItems}
              selectedSSU={selectedSSU}
              showRightPanel={showRightPanel}
              setShowRightPanel={setShowRightPanel}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion disableGutters defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ color: scPurple80, fontWeight: 'bold' }}>Expenses - Variable</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ExpenseVariableList
              expensesVariable={expensesVariable}
              updateAvailableExpensesVariable={updateAvailableExpensesVariable}
              selectedSSU={selectedSSU}
              showRightPanel={showRightPanel}
              setShowRightPanel={setShowRightPanel}
            />
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
  return (
    <div className="border px-3 pt-3 pb-1 m-0 row justify-content-between">
      <h5 className="c-p">
        {selectedSSU ? (
          <span>There is no budget for this study site</span>
        ) : (
          <span>Select site and study with existing budget</span>
        )}
      </h5>
    </div>
  );
};

export default StudyEventForm;
