import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './Divider.scss';

export default function Divider({ className, type }) {
  return <div className={cx(`eui-divider-${type}`, className)} />;
}

Divider.defaultProps = {
  type: 'horizontal'
};

Divider.propTypes = {
  type: PropTypes.oneOf(['horizontal', 'vertical'])
};
