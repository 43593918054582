import { ProtocolApi } from '../../../../../../api';
import { createEncountersCollection, sortItemGroupsBySequence } from '../../../../../../services/protocolBlinding';

export default function getItemGroupEpochEncounter(studyId, protocolIdentity) {
  const itemGroupsPromise = ProtocolApi.getAllItemGroupsByStudy(studyId).then(resEle => {
    const itemGroups = resEle.data.response;
    if (Array.isArray(itemGroups)) {
      return itemGroups;
    } else {
      return [];
    }
  });
  const protocolPromise = ProtocolApi.getPreviewProtocol(studyId, protocolIdentity, 'Draft').then(({ data }) => data);
  return Promise.all([itemGroupsPromise, protocolPromise]).then(([itemGroups, { epoch, name, studyName }]) => {
    const encountersCollection = createEncountersCollection(epoch);
    const sortedListOfItemGroups = sortItemGroupsBySequence(epoch, itemGroups);
    return {
      epoch,
      encounters: encountersCollection,
      itemGroups: sortedListOfItemGroups,
      metadata: { name, studyName }
    };
  });
}
