import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import { isEmpty } from 'lodash/lang';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { MANAGE_BUDGETS } from '../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../services/auth';
import { toBill, toCoins } from '../../../../../../../services/financial';
import { onRequestError } from '../../../../../../../services/handlers';
import { PASS_THRU } from '../ExpenseType';

import './AddOrEditExpenseVariableEventModal.scss';

export const AddOrEditExpenseVariableEventModal = ({ modalBox, currentStudyBudget, saveEvent, eventForUpdate }) => {
  const [eventName, setEventName] = useState('');
  const [radioValue, setRadioValue] = useState(PASS_THRU);
  const [maximumAllowance, setMaximumAllowance] = useState(false);
  const [maximumAmount, setMaximumAmount] = useState('0');

  const isEditingForbidden =
    (currentStudyBudget && !currentStudyBudget.editPossibility) || !userHasAccessTo(MANAGE_BUDGETS);
  const buttonsStyles = { textTransform: 'none', lineHeight: 'unset' };

  const dataForSave = useMemo(() => {
    return {
      id: eventForUpdate?.id,
      name: eventName.trim(),
      maxAllowanceAmount: maximumAllowance ? toCoins(maximumAmount) : null,
      maxAllowanceType: radioValue,
      type: 'EXPENSE_VARIABLE'
    };
  }, [eventForUpdate?.id, eventName, maximumAllowance, maximumAmount, radioValue]);

  useEffect(() => {
    if (eventForUpdate) {
      setEventName(eventForUpdate.name.trim());
      setMaximumAllowance(!!eventForUpdate.maxAllowanceAmount);
      setMaximumAmount(eventForUpdate.maxAllowanceAmount ? toBill(eventForUpdate.maxAllowanceAmount) : '0');
      setRadioValue(eventForUpdate.maxAllowanceType);
    }
  }, [eventForUpdate]);

  const isFormValid = useMemo(() => {
    if (!eventName) {
      return false;
    }
    const amount = toCoins(maximumAmount);
    return !(maximumAllowance && (!amount || amount < 0));
  }, [eventName, maximumAllowance, maximumAmount]);

  const save = useCallback(() => {
    return saveEvent(dataForSave);
  }, [dataForSave, saveEvent]);

  const onSave = useCallback(() => {
    save().then(() => modalBox.close(), onRequestError);
  }, [modalBox, save]);

  const onSaveAndContinue = useCallback(() => {
    save().then(() => {
      setEventName('');
      setMaximumAllowance(false);
      setMaximumAmount('0');
      setRadioValue(PASS_THRU);
    }, onRequestError);
  }, [save]);

  const maxEventNameLength = useMemo(() => 500, []);
  return (
    <>
      <ModalBoxes.Body>
        <TextField
          label="Event Name"
          variant="standard"
          required
          multiline
          disabled={isEditingForbidden}
          value={eventName}
          onChange={({ target: { value } }) => setEventName(value)}
          inputProps={{ maxLength: maxEventNameLength }}
          helperText={`${eventName?.length ?? 0}/${maxEventNameLength}`}
          sx={{ '.MuiFormHelperText-root': { textAlign: 'end' } }}
        />
        <div>
          <FormControl variant="standard">
            <InputLabel>Allowance Type </InputLabel>
            <Select
              autoWidth
              value={maximumAllowance}
              onChange={({ target: { value } }) => {
                if (!value) {
                  setMaximumAmount('0');
                }
                setMaximumAllowance(value);
              }}
              disabled={isEditingForbidden}
              data-testid="allowance-type"
            >
              <MenuItem value={false}>No maximum allowance</MenuItem>
              <MenuItem value={true}>Maximum allowance</MenuItem>
            </Select>
          </FormControl>
        </div>
        {maximumAllowance && (
          <TextField
            sx={{ width: '174px' }}
            label="Maximum Amount"
            required
            variant="standard"
            InputProps={{
              inputComponent: NumericFormatCustom
            }}
            value={maximumAmount}
            onChange={({ target: { value } }) => setMaximumAmount(value)}
            disabled={isEditingForbidden}
          />
        )}
        <FormControl disabled={isEditingForbidden}>
          <RadioGroup
            row
            value={radioValue}
            onChange={({ target: { value } }) => setRadioValue(value)}
            name="radio-buttons-group"
          >
            <FormControlLabel value="PASS_THRU" control={<Radio data-testid="pass-thru-radio" />} label="Pass Thru" />
            <FormControlLabel
              value="DIRECT_COST"
              control={<Radio data-testid="direct-cost-radio" />}
              label="Direct Cost"
            />
          </RadioGroup>
        </FormControl>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <Button sx={buttonsStyles} onClick={modalBox.close}>
          {isEditingForbidden ? 'Close' : 'Cancel'}
        </Button>
        {!isEditingForbidden && (
          <>
            {isEmpty(eventForUpdate) && (
              <Button sx={buttonsStyles} disabled={!isFormValid} onClick={onSaveAndContinue}>
                Save and Add Another
              </Button>
            )}
            <Button sx={buttonsStyles} variant="contained" disabled={!isFormValid} onClick={onSave}>
              Save
            </Button>
          </>
        )}
      </ModalBoxes.Footer>
    </>
  );
};

export const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  );
});
