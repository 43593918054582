import { useRef, useState } from 'react';
import { IconButton, SvgIcon } from '@mui/material';

import useOutsideClickDetector from '../../../../../../../../common/hooks/useOutsideClickDetector';
import { iconButtonStyles } from '../ToolbarPlugin';

import './IconWithDropdown.scss';

export const IconWithDropdown = ({ children, Icon }) => {
  const menuRef = useRef();
  useOutsideClickDetector(menuRef, () => {
    setMenuOpen(false);
  });
  const [menuOpen, setMenuOpen] = useState(false);
  const handleToggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };
  return (
    <div className="icon-with-dropdown-wrapper" ref={menuRef}>
      <IconButton sx={iconButtonStyles} onClick={handleToggleMenu}>
        <Icon />
        <SvgIcon sx={{ width: '8px', height: '6px' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
            <path d="M4 5.5L8 0.5H0L4 5.5Z" fill="black" fillOpacity="0.6" />
          </svg>
        </SvgIcon>
      </IconButton>
      {menuOpen && (
        <div onClick={handleToggleMenu} className="dropdown-option-wrapper">
          {children}
        </div>
      )}
    </div>
  );
};
