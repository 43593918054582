import React, { useContext, useMemo, useState } from 'react';

import Radio from '../../../../../common/data-entry/InputSelectors/Radio';
import ApplyAndResetButtons from '../../../../ApplyAndResetButtons/ApplyAndResetButtons';
import MainFilters from '../../shared/FinanceFilters/MainFilters/MainFilters';
import StatusesCheckboxes from '../../shared/StatusesCheckboxes/StatusesCheckboxes';
import { ACTIVE, BUDGET, CLIENT, ELLIGO, INACTIVE, PENDING, REVENUE } from '../ledgerConstants';
import { LedgerContext } from '../LedgerContext';

import './LedgerFilter.scss';

export default function LedgerFilter() {
  const { filterProperty, applyFilters, setFewFilterProperty } = useContext(LedgerContext);
  const statuses = [PENDING, ACTIVE, INACTIVE];

  const amountState = useMemo(
    function() {
      return filterProperty?.amountState;
    },
    [filterProperty]
  );

  const overhead = useMemo(
    function() {
      return !!filterProperty?.overhead;
    },
    [filterProperty]
  );

  const chosenStatuses = useMemo(
    function() {
      return filterProperty?.statuses;
    },
    [filterProperty]
  );

  const [isEventIdFormatInvalid, setIsEventIdFormatInvalid] = useState(false);

  return (
    <div className="trlf-filters">
      <div className="trlf-all-inputs-and-button">
        <div>
          <MainFilters
            setFewFilterProperty={setFewFilterProperty}
            filterProperty={filterProperty}
            pcnFlag={true}
            enableAutoCompleteSite={true}
            setIsEventIdFormatInvalid={setIsEventIdFormatInvalid}
            calendarLabels={{
              startDate: 'Event Start',
              endDate: 'Event End'
            }}
          >
            <ApplyAndResetButtons
              applyClassName="apply-btn"
              applyDisabled={isEventIdFormatInvalid}
              onApply={applyFilters}
            />
          </MainFilters>
          <div className="trlf-radio-inputs">
            <div className="trlf-radio">
              <Radio.Group
                label="Amount:"
                checkedValue={amountState}
                options={[
                  { label: BUDGET, value: CLIENT },
                  { label: REVENUE, value: ELLIGO }
                ]}
                onChange={({ target: { value } }) => {
                  setFewFilterProperty({ amountState: value });
                }}
              />
            </div>
            <div className="trlf-radio">
              <Radio.Group
                label="Overhead:"
                checkedValue={overhead}
                options={[
                  { value: true, label: 'Show' },
                  { value: false, label: 'Hide' }
                ]}
                onChange={({ target: { value } }) => setFewFilterProperty({ overhead: value === 'true' })}
              />
            </div>
            <div className="trlf-radio">
              <StatusesCheckboxes
                statuses={statuses}
                chosenStatuses={chosenStatuses}
                setFewFilterProperty={setFewFilterProperty}
                label="Status:"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
