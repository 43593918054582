import axios from 'axios';

const ROOT = 'api/v1/finance/budgets';

const FinBudgetEventApi = {
  //READ
  findByBudget(budgetId) {
    return axios.get(`${ROOT}/${budgetId}/events`);
  },

  //ENCOUNTER, UNEXPECTED_ENCOUNTER, SITUATIONAL_ENCOUNTER
  createEncounterEvent(budgetId, rows) {
    return axios.post(`${ROOT}/${budgetId}/events/encounter`, rows);
  },
  updateEncounterEvent(budgetId, groupId, rows) {
    return axios.put(`${ROOT}/${budgetId}/events/encounter/${groupId}`, rows);
  },

  //ENCOUNTER_ITEM_GROUP, UNEXPECTED_ITEM_GROUP, SITUATIONAL_ITEM_GROUP
  createEncounterItemgroupEvent(budgetId, rows) {
    return axios.post(`${ROOT}/${budgetId}/events/encounter-itemgroup`, rows);
  },
  updateEncounterItemgroupEvent(budgetId, groupId, rows) {
    return axios.put(`${ROOT}/${budgetId}/events/encounter-itemgroup/${groupId}`, rows);
  },

  //ITEM_GROUP_GENERAL
  createItemGroupEvent(budgetId, rows) {
    return axios.post(`${ROOT}/${budgetId}/events/item-group`, rows);
  },
  updateItemGroupEvent(budgetId, groupId, rows) {
    return axios.put(`${ROOT}/${budgetId}/events/item-group/${groupId}`, rows);
  },

  //ITEM_GROUP_PATIENT_STIPEND
  createPatientStipendEvent(budgetId, rows) {
    return axios.post(`${ROOT}/${budgetId}/events/patient-stipend`, rows);
  },
  updatePatientStipendEvent(budgetId, budgetEventId, data) {
    return axios.put(`${ROOT}/${budgetId}/events/patient-stipend/${budgetEventId}`, data);
  },

  createPatientReimbursementEvent(budgetId, rows) {
    return axios.post(`${ROOT}/${budgetId}/events/patient-reimbursement`, rows);
  },

  updatePatientReimbursementEvent(budgetId, groupId, data) {
    return axios.put(`${ROOT}/${budgetId}/events/patient-reimbursement/${groupId}`, data);
  },

  //FIXED_ITEM
  createFixedItemEvent(budgetId, rows) {
    return axios.post(`${ROOT}/${budgetId}/events/fixed-item`, rows);
  },
  updateFixedItemEvent(budgetId, groupId, data) {
    return axios.put(`${ROOT}/${budgetId}/events/fixed-item/${groupId}`, data);
  },

  //STATUS_CHANGE
  createStatusChangeEvent(budgetId, data) {
    return axios.post(`${ROOT}/${budgetId}/events/status-changes`, data);
  },
  updateStatusChangeEvent(budgetId, groupId, data) {
    return axios.put(`${ROOT}/${budgetId}/events/status-changes/${groupId}`, data);
  },

  //RATIO_STATUS_CHANGE
  createRatioStatusChangesEvent(budgetId, data) {
    return axios.post(`${ROOT}/${budgetId}/events/ratio-status-changes`, data);
  },
  updateRatioStatusChangeEvent(budgetId, groupId, data) {
    return axios.put(`${ROOT}/${budgetId}/events/ratio-status-changes/${groupId}`, data);
  },

  //ENCOUNTER_STATUS_CHANGE
  createEncounterStatusChanges(budgetId, data) {
    return axios.post(`${ROOT}/${budgetId}/events/encounter-status-changes`, data);
  },
  updateEncounterStatusChanges(budgetId, groupId, data) {
    return axios.put(`${ROOT}/${budgetId}/events/encounter-status-changes/${groupId}`, data);
  },

  //EXPENSE_VARIABLE

  createExpenseVariableEvent(budgetId, data) {
    return axios.post(`${ROOT}/${budgetId}/events/expenses/variables`, data);
  },
  updateExpenseVariableEvent(budgetId, groupId, data) {
    return axios.put(`${ROOT}/${budgetId}/events/expenses/variables/${groupId}`, data);
  },

  //DELETE
  inactivateBudgetEvent(budgetId, budgetEventId) {
    return axios.delete(`${ROOT}/${budgetId}/events/${budgetEventId}`);
  }
};

export default FinBudgetEventApi;
