import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash/lang';

import { useCurrentRoute } from '../../../../root/router';

import { RowReviewStatus } from './reviewConstants';
import ReviewDataResolver from './ReviewDataResolver';
import { getReviewType } from './reviewService';

export const ReviewContext = React.createContext(null);

export function ReviewProvider({ children }) {
  const currentRoute = useCurrentRoute();
  const params = currentRoute.params;

  const { ssuPatientId, patientEncounterId, reviewPatientItemGroupId } = params;

  const reviewType = useMemo(() => getReviewType(currentRoute), [currentRoute]);
  const [itemGroupSnapshotReviewStates, setItemGroupSnapshotReviewStates] = useState({});
  const [logSnapshotReviewStates, setLogSnapshotReviewStates] = useState({});
  const [chosenItem, setChosenItem] = useState();
  const [logItemGroups, setLogItemGroups] = useState([]);
  const [logCheckSnapshotReviewStates, setLogCheckSnapshotReviewStates] = useState({});
  const [logCheckItemGroups, setLogCheckItemGroups] = useState([]);
  const [itemGroups, setItemGroups] = useState([]);
  const [signatureId, setSignatureId] = useState(null);
  const isHistoricalData = useMemo(() => getIsHistoricalData(signatureId), [signatureId]);
  const [reviewMetaData, setReviewMetaData] = useState(null);
  const reviewContentRefContainer = useRef(null);
  const [requiredAttentionItemGroupsConfirmStatuses, setRequiredAttentionItemGroupsConfirmStatuses] = useState({});
  const loadData = useCallback(
    function reloadItemGroupsAndLogs() {
      ReviewDataResolver.getItemGroupsAndLogsByType(reviewType, {
        ssuPatientId,
        patientEncounterId,
        patientItemGroupId: reviewPatientItemGroupId
      }).then(({ data }) => {
        setReviewMetaData(data.reviewMetaData);
        setItemGroups(
          data.patientEncounterStructure.itemGroups
            .map(({ itemGroupRef }) => itemGroupRef)
            .filter(({ domainCode }) => domainCode !== 'EREVPI' && domainCode !== 'EREVSM')
        );
        setLogItemGroups(sortLogItemGroups(data.logsResponse.logItemGroups, (a, b) => (a.name > b.name && 1) || -1));
        setLogCheckItemGroups(
          sortLogItemGroups(
            sortLogItemGroups(
              data?.logChecksResponse?.logCheckItemGroups,
              (a, b) => (a.patientItemGroupName > b.patientItemGroupName && 1) || -1
            )
          )
        );
      });
    },
    [ssuPatientId, patientEncounterId, reviewPatientItemGroupId, reviewType]
  );
  const isRowConfirmed = useCallback(function isRowConfirmed(row) {
    if (row.rowReviewStatus === RowReviewStatus.NEVER_SIGNED) {
      return false;
    }
    return row.rowReviewStatus === RowReviewStatus.SIGNED || !containsHighlightedEdits(row);
  }, []);

  const sortLogItemGroups = function sortItems(items, compare) {
    return items && items?.length > 1 ? items.sort(compare) : items;
  };

  useEffect(loadData, [loadData]);

  const getItemGroupData = useCallback(
    function(patientItemGroupId, hideLoader = true) {
      function itemGroupsSnapshotGetFromResponse({ data }) {
        setItemGroupSnapshotReviewStates(prevState => ({
          ...prevState,
          [data.itemGroupSnapshotState.itemGroupRef.patientItemGroupId]: data
        }));
        const row = data.itemGroupSnapshotState?.rows[0];
        if (data.itemGroupSnapshotState?.dataStatus?.highlightedAnswersCount > 0) {
          const isConfirmed = isRowConfirmed(row);
          setRequiredAttentionItemGroupsConfirmStatuses(prevState => ({
            ...prevState,
            [data.itemGroupSnapshotState.itemGroupRef.patientItemGroupId]: isConfirmed
          }));
        }
      }

      ReviewDataResolver.getItemGroupRowSnapshotReviewStateByTypeAndSignatureId(
        reviewType,
        signatureId,
        {
          ssuPatientId,
          patientEncounterId,
          itemGroupId: patientItemGroupId
        },
        hideLoader
      ).then(itemGroupsSnapshotGetFromResponse);
    },
    [isRowConfirmed, patientEncounterId, reviewType, signatureId, ssuPatientId]
  );

  useEffect(
    function reloadItemGroupSnapshotReviewStates() {
      if (isEmpty(itemGroups)) {
        return;
      }
      itemGroups.forEach(item => {
        if (!item.blinded) {
          getItemGroupData(item.patientItemGroupId);
        }
      });
      return function() {
        setItemGroupSnapshotReviewStates({});
      };
    },
    [
      itemGroups,
      reviewType,
      signatureId,
      patientEncounterId,
      reviewPatientItemGroupId,
      ssuPatientId,
      isRowConfirmed,
      getItemGroupData
    ]
  );

  const getLogData = useCallback(
    function(itemGroupTemplateId, hideLoader = true) {
      function logSnapshotGetFromResponse({ data }) {
        setLogSnapshotReviewStates(prevState => ({
          ...prevState,
          [data.logSnapshotState.logFormRef.itemGroupTemplateId]: data
        }));
        if (data.logSnapshotState?.dataStatus?.highlightedAnswersCount > 0) {
          const isConfirmed = data?.logSnapshotState?.rows.every(row => isRowConfirmed(row));
          if (data.logSnapshotState.logFormRef?.domainCode !== 'AE') {
            setRequiredAttentionItemGroupsConfirmStatuses(prevState => ({
              ...prevState,
              [data.logSnapshotState.logFormRef.itemGroupTemplateId]: isConfirmed
            }));
          }
        }
      }

      ReviewDataResolver.getLogSnapshotReviewStateByTypeAndSignatureId(
        reviewType,
        signatureId,
        {
          ssuPatientId,
          patientEncounterId,
          itemGroupTemplateId: itemGroupTemplateId
        },
        hideLoader
      ).then(logSnapshotGetFromResponse);
    },
    [isRowConfirmed, patientEncounterId, reviewType, signatureId, ssuPatientId]
  );

  useEffect(
    function reloadLogSnapshotReviewStates() {
      if (isEmpty(logItemGroups)) {
        return;
      }
      logItemGroups.forEach(item => {
        if (!item.blinded) {
          getLogData(item.itemGroupTemplateId);
        }
      });
      return function() {
        setLogSnapshotReviewStates({});
      };
    },
    [
      logItemGroups,
      reviewType,
      signatureId,
      patientEncounterId,
      reviewPatientItemGroupId,
      ssuPatientId,
      isRowConfirmed,
      getLogData
    ]
  );

  const getLogCheckData = useCallback(
    function(patientItemGroupId, itemGroupTemplateId, hideLoader = true) {
      function logCheckSnapshotGetFromResponse({ data }) {
        setLogCheckSnapshotReviewStates(prevState => ({
          ...prevState,
          [itemGroupTemplateId]: data
        }));
      }
      ReviewDataResolver.getLogCheckSnapshotReviewStateByTypeAndSignatureId(
        reviewType,
        signatureId,
        {
          ssuPatientId,
          patientEncounterId,
          reviewItemGroupId: reviewPatientItemGroupId,
          patientItemGroupId: patientItemGroupId
        },
        hideLoader
      ).then(logCheckSnapshotGetFromResponse);
    },
    [patientEncounterId, reviewPatientItemGroupId, reviewType, signatureId, ssuPatientId]
  );

  useEffect(
    function reloadLogCheckSnapshotReviewStates() {
      if (isEmpty(logCheckItemGroups)) {
        return;
      }
      logCheckItemGroups.forEach(item => {
        if (!item.blinded) {
          getLogCheckData(item.patientItemGroupId, item.itemGroupTemplateId);
        }
      });
      return function() {
        setLogCheckSnapshotReviewStates({});
      };
    },
    [
      logCheckItemGroups,
      reviewType,
      signatureId,
      patientEncounterId,
      reviewPatientItemGroupId,
      ssuPatientId,
      isRowConfirmed,
      getLogCheckData
    ]
  );

  return (
    <ReviewContext.Provider
      value={{
        itemGroups,
        logItemGroups,
        logCheckItemGroups,
        chosenItem,
        setChosenItem,
        logSnapshotReviewStates,
        logCheckSnapshotReviewStates,
        itemGroupSnapshotReviewStates,
        signatureId,
        setSignatureId,
        patientItemGroupId: reviewPatientItemGroupId,
        ssuPatientId,
        patientEncounterId,
        reviewType,
        isHistoricalData,
        loadData,
        reviewMetaData,
        requiredAttentionItemGroupsConfirmStatuses,
        setRequiredAttentionItemGroupsConfirmStatuses,
        updateItemGroupData: getItemGroupData,
        updateLogData: getLogData,
        updateLogCheckData: getLogCheckData,
        reviewContentRefContainer
      }}
    >
      {children}
    </ReviewContext.Provider>
  );

  function containsHighlightedEdits(row) {
    const answers = row?.rowSnapshotFormData?.form?.answers;
    const editedAfterSignQuestionIds = row?.editedAfterSignQuestionIds;
    const isRowEditedAfterSign = row.rowReviewStatus === RowReviewStatus.EDITED_AFTER_SIGN;
    return (
      isRowEditedAfterSign &&
      answers?.some(answer => answer.highlighted && editedAfterSignQuestionIds.includes(answer.itemGroupQuestionId))
    );
  }
}

function getIsHistoricalData(signatureId) {
  return !!signatureId;
}
