import { uniqBy } from 'lodash/array';
import { orderBy } from 'lodash/collection';
import { isEmpty, isEqual } from 'lodash/lang';
import { assignInWith, has } from 'lodash/object';
import { flow } from 'lodash/util';

import { FRS_LOADED } from '../../../../../eui/EuiFiltersContext/reducer';

export const initializeFlow = flow([
    recalculateSelectedStudies,
    recalculateSites,
    recalculateSelectedSiteIds,
    recalculateSelectedSites
  ]),
  studiesUpdateFlow = flow([
    recalculateSelectedStudies,
    recalculateSites,
    recalculateSelectedSiteIds,
    recalculateSelectedSites
  ]),
  sitesUpdateFlow = flow([recalculateSelectedSites]);

export function initializeProcessor(state, action) {
  const { type, payload } = action;
  const { key, studySites, initialValue } = payload;
  const previousFilterState = state[key];
  const { selectedStudyMap, selectedSiteMap } = previousFilterState || {};
  const studies = getOrderedUniqueStudies(studySites);
  const newState = initializeFlow({
    ...previousFilterState,
    studySites,
    studies,
    selectedStudyMap: isEmpty(selectedStudyMap) ? collectIdsMap(studies) : selectedStudyMap,
    sites: [],
    selectedSiteMap: {},
    selectedStudies: [],
    selectedSites: []
  });
  return {
    ...state,
    schema: {
      ...state.schema,
      [key]: {
        ...state.schema[key],
        status: FRS_LOADED
      }
    },
    [key]: newState
  };
}

export function setValueProcessor(state, action) {
  const { type, payload } = action;
  const { key, key2, value } = payload;
  const previousFilterState = state[key];
  const { selectedStudyMap, selectedSiteMap } = previousFilterState;

  let newState;

  if (key2 === 'Study') {
    const studyIds = collectIdsMap(value);

    if (isEqual(studyIds, selectedStudyMap)) return state;

    newState = studiesUpdateFlow({ ...previousFilterState, selectedStudyMap: mergeMaps(studyIds, selectedStudyMap) });
  }

  if (key2 === 'Site') {
    const siteIds = collectIdsMap(value);
    if (isEqual(siteIds, selectedSiteMap)) return state;
    newState = sitesUpdateFlow({ ...previousFilterState, selectedSiteMap: mergeMaps(siteIds, selectedSiteMap) });
  }

  return {
    ...state,
    [key]: newState
  };
}

function getOrderedUniqueStudies(studySites) {
  const studies = studySites.reduce(function(studies, { study }) {
    studies.push(study);
    return studies;
  }, []);
  return orderBy(uniqBy(studies, 'id'), 'name', 'asc');
}

function getFilteredOrderedUniqueSites(studySites, selectedStudyMap = {}) {
  const sites = studySites.reduce(function(sites, { study, site }) {
    if (selectedStudyMap[study.id]) {
      sites.push(site);
    }
    return sites;
  }, []);

  return orderBy(uniqBy(sites, 'id'), 'name', 'asc');
}

function recalculateSelectedStudies(state) {
  const { studies, selectedStudyMap } = state;
  const selectedStudies = studies.filter(({ id }) => selectedStudyMap[id]);
  return { ...state, selectedStudies };
}

function recalculateSelectedSites(state) {
  const { sites, selectedSiteMap } = state;
  const selectedSites = sites.filter(({ id }) => selectedSiteMap[id]);
  return { ...state, selectedSites };
}

function recalculateSites(state) {
  const { studySites, selectedStudyMap } = state;
  return { ...state, sites: getFilteredOrderedUniqueSites(studySites, selectedStudyMap) };
}

function recalculateSelectedSiteIds(state) {
  const { sites, selectedSiteMap } = state;
  return {
    ...state,
    selectedSiteMap: sites.reduce(function(siteIdsMap, site) {
      siteIdsMap[site.id] = has(selectedSiteMap, site.id) ? selectedSiteMap[site.id] : true;
      return siteIdsMap;
    }, {})
  };
}

function collectIdsMap(item) {
  return item.reduce(function(accumulator, { id }) {
    accumulator[id] = true;
    return accumulator;
  }, {});
}

function mergeMaps(a, b) {
  return assignInWith(a, b, a => a || false);
}
