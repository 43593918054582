export function toCoins(amount) {
  return amount ? Math.round(amount * 100) : 0;
}

export function toBill(coins, toFixed = true) {
  return toFixed ? (coins / 100).toFixed(2) : coins / 100;
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export function toFormattedBill(coins) {
  return formatter.format(coins * 0.01);
}

export function separateThreeDigitsWithCommas(cardBalance) {
  return cardBalance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
