import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';
import { cloneDeep, isEqual } from 'lodash/lang';

import { isInvestigatorFieldAvailable } from '../../../../../../../../services/itemGroupQuestionService';
import { CustomFieldLabelClosableBlock } from '../CustomFieldsGenerator/CustomFieldLabelClosableBlock';
import { getCombinedList } from '../ItemGroupSetupService';
import { OptionalField } from '../OptionalFields/OptionalField';

import { FieldsHeader } from './FieldsHeader';

import './FieldsToSelect.scss';

export function FieldsToSelect({
  inputs,
  hideIsTableOptions,
  updateInputsAndPreviewData,
  getCheckboxForTableView,
  getInvestigatorFieldCheckboxForTableView,
  getFixedItemCheckboxForTableView,
  onChangeLabelTextHandler,
  previewDataList,
  editMode,
  updateState,
  domainId,
  changeSequenceCounter,
  domainArray,
  sequenceCounter,
  isElligoPatientStatusDomain,
  setCombinedLabelList,
  terminologyVersionGroupNumber,
  isLogType,
  domain
}) {
  const [currentInputs, setCurrentInputs] = useState(null);
  const [selectedOnly, setSelectedOnly] = useState(false);
  const [combinedInputs, setCombinedInputs] = useState([]);

  const prepareCombinedInputs = useCallback(() => {
    const combinedList = getCombinedList(inputs, selectedOnly);
    if (!isEqual(combinedList, combinedInputs)) {
      setCombinedInputs(combinedList);
      changeSequenceCounter(combinedList.length + 1);
    }
    setCombinedLabelList(combinedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  useEffect(() => {
    if (!isEqual(currentInputs, inputs)) {
      setCurrentInputs(cloneDeep(inputs));
      prepareCombinedInputs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  useEffect(() => {
    setSelectedOnly(false);
  }, [domainId]);

  useEffect(() => {
    const combinedList = getCombinedList(inputs, selectedOnly);
    setCombinedInputs(combinedList);
    changeSequenceCounter(combinedList.length + 1);
    updateInputsAndPreviewData(inputs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOnly]);

  function handleOnDragEnd(result) {
    const items = Array.from(combinedInputs);
    const [reorderedItem] = items.splice(result.source.index, 1);
    if (result?.destination && result.destination.index >= 0) {
      items.splice(result.destination.index, 0, reorderedItem);

      let addIndex = 0;
      items.forEach((item, index) => {
        if (item.isOptional) {
          item.id = addIndex;
          item.sequence = addIndex + 1;
          addIndex = addIndex + 1;
        }
        if (item.isCustom) {
          item.id = addIndex;
          item.sequence = addIndex + 1;
          item.customItemDefinationList.forEach((customItem, idx) => {
            customItem.sequence = addIndex + 1;
            addIndex = addIndex + 1;
          });
        }
      });
      setCombinedInputs(items);
      updateInputsAndPreviewData(inputs);
    }
  }

  const isAdverseEventLog = () => {
    return isLogType && previewDataList.domainCode === 'AE';
  };

  return (
    <div className="fields-to-select">
      <FieldsHeader
        terminologyVersionGroupNumber={terminologyVersionGroupNumber}
        inputs={inputs}
        updateInputsAndPreviewData={updateInputsAndPreviewData}
        updateState={updateState}
        domainArray={domainArray}
        changeSequenceCounter={changeSequenceCounter}
        sequenceCounter={sequenceCounter}
        onSelectedOnly={setSelectedOnly}
        selectedOnly={selectedOnly}
        isElligoPatientStatusDomain={isElligoPatientStatusDomain}
      />
      <div>
        <div className="form-header">
          <div className="row col-xs-12 col-md-12 position-relative qhead">
            <div
              className={cx({
                'col-md-3': domain !== 'ICF',
                'col-md-7': domain === 'ICF'
              })}
            >
              CDASH Items
            </div>
            {domain !== 'ICF' && <div className="col-md-2 headerMarginLR">Updated CDASH Items</div>}
            <div className="col-md-3 ansHeaderMarginLR">Answer Type</div>
            {domain !== 'ICF' && (
              <>
                <div className="col-md-auto itemSeqMargin">Item Sequence</div>
                {isAdverseEventLog() && <div className="col p-0 pl-4 d-flex justify-content-center ">Fixed Field</div>}
              </>
            )}
            {isInvestigatorFieldAvailable(previewDataList.domainCode) && (
              <div className={cx('col p-0 d-flex justify-content-center', { 'investigator-ml': domain === 'ICF' })}>
                Investigator Field
              </div>
            )}
            {domain !== 'ICF' && !isLogType && !hideIsTableOptions && <div className="col-md-2">{'Log View'}</div>}
          </div>
        </div>
        {getMixedFieldsBlock()}
      </div>
    </div>
  );

  function getMixedFieldsBlock() {
    return (
      <React.Fragment>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="items">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="fields-to-select-list">
                {inputs.length > 0 &&
                  combinedInputs.map((data, labelIndex) => {
                    return (
                      <React.Fragment key={labelIndex}>
                        {data.mandatoryField && (
                          <div className={cx('drag-item')}>
                            <OptionalField
                              terminologyVersionGroupNumber={terminologyVersionGroupNumber}
                              key={data.itemIndex}
                              getCheckboxForTableView={getCheckboxForTableView}
                              getInvestigatorFieldCheckboxForTableView={getInvestigatorFieldCheckboxForTableView}
                              getFixedItemCheckboxForTableView={getFixedItemCheckboxForTableView}
                              onChangeLabelTextHandler={onChangeLabelTextHandler}
                              previewDataList={previewDataList}
                              editMode={editMode}
                              item={data}
                              index={data.itemIndex}
                              formLabelData={data}
                              domain={domain}
                              labelIndex={data.labelIndex}
                              inputs={inputs}
                              updateInputsAndPreviewData={updateInputsAndPreviewData}
                              updateState={updateState}
                              isInvestigatorFieldAvailable={() =>
                                isInvestigatorFieldAvailable(previewDataList.domainCode)
                              }
                              isAdverseEventLog={isAdverseEventLog}
                              isLogViewCheckboxAvailable={!isLogType && !hideIsTableOptions}
                            />
                          </div>
                        )}
                        {data.isOptional && !data.mandatoryField && (
                          <Draggable key={data.name} draggableId={data.name} index={labelIndex}>
                            {(provided, snapshot) => (
                              <div
                                className={cx('drag-item', { dragging: snapshot.isDragging })}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <OptionalField
                                  terminologyVersionGroupNumber={terminologyVersionGroupNumber}
                                  key={data.itemIndex}
                                  getCheckboxForTableView={getCheckboxForTableView}
                                  getInvestigatorFieldCheckboxForTableView={getInvestigatorFieldCheckboxForTableView}
                                  getFixedItemCheckboxForTableView={getFixedItemCheckboxForTableView}
                                  onChangeLabelTextHandler={onChangeLabelTextHandler}
                                  previewDataList={previewDataList}
                                  editMode={editMode}
                                  item={data}
                                  index={data.itemIndex}
                                  formLabelData={data}
                                  domain={domain}
                                  labelIndex={data.labelIndex}
                                  inputs={inputs}
                                  updateInputsAndPreviewData={updateInputsAndPreviewData}
                                  updateState={updateState}
                                  isInvestigatorFieldAvailable={() =>
                                    isInvestigatorFieldAvailable(previewDataList.domainCode)
                                  }
                                  isAdverseEventLog={isAdverseEventLog}
                                  isLogViewCheckboxAvailable={!isLogType && !hideIsTableOptions}
                                />
                              </div>
                            )}
                          </Draggable>
                        )}
                        {data.isCustom && !data.mandatoryField && (
                          <Draggable key={data.name} draggableId={labelIndex.toString()} index={labelIndex}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                className={cx('drag-item', { dragging: snapshot.isDragging })}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <CustomFieldLabelClosableBlock
                                  terminologyVersionGroupNumber={terminologyVersionGroupNumber}
                                  editMode={editMode}
                                  key={data.labelIndex}
                                  getCheckboxForTableView={getCheckboxForTableView}
                                  getInvestigatorFieldCheckboxForTableView={getInvestigatorFieldCheckboxForTableView}
                                  getFixedItemCheckboxForTableView={getFixedItemCheckboxForTableView}
                                  customFieldData={data}
                                  domain={domain}
                                  labelIndex={data.labelIndex}
                                  inputs={inputs}
                                  updateInputsAndPreviewData={updateInputsAndPreviewData}
                                  updateState={updateState}
                                  onChangeLabelTextHandler={onChangeLabelTextHandler}
                                  isInvestigatorFieldAvailable={() =>
                                    isInvestigatorFieldAvailable(previewDataList.domainCode)
                                  }
                                  isAdverseEventLog={isAdverseEventLog}
                                  isLogViewCheckboxAvailable={!isLogType && !hideIsTableOptions}
                                />
                              </div>
                            )}
                          </Draggable>
                        )}
                      </React.Fragment>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </React.Fragment>
    );
  }
}
