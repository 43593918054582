import axios from 'axios';

const ROOT = 'api/v1/tasks';

const TaskApi = {
  getAllStudySites() {
    return axios.get(`${ROOT}/study-sites`, { hideLoader: true });
  },

  getAllPatientsStudySites(patientId) {
    /*TODO: useless*/
    return axios.get(`${ROOT}/patients/${patientId}/study-sites`, {
      hideLoader: true
    });
  },

  getAutoPopulationData(request) {
    return axios.post(`${ROOT}/populate`, request, {
      hideLoader: true
    });
  },
  getSearchPatients(page, pageSize, searchString, patientSsuIds = []) {
    /*TODO: useless*/
    return axios.post(
      `${ROOT}/patients/search`,
      { page, pageSize, searchString, patientSsuIds },
      {
        hideLoader: true
      }
    );
  },
  getTaskDetails(taskId, studySiteId) {
    return axios.get(`${ROOT}/${taskId}/study-sites/${studySiteId}`, {
      hideLoader: true
    });
  },
  getAllTasksByFilter(filter, hideLoader) {
    return axios.post(`${ROOT}/work-list`, filter, {
      hideLoader: hideLoader || false
    });
  },
  exportTasks(filter) {
    return axios.post(`${ROOT}/download`, filter, {
      responseType: 'arraybuffer'
    });
  },
  create(task) {
    return axios.post(`${ROOT}/study-sites/${task.studySiteId}`, task, {
      hideLoader: true
    });
  },
  update(task) {
    return axios.put(`${ROOT}/${task.id}/study-sites/${task.studySiteId}/update`, task, {
      hideLoader: true
    });
  },
  addComment(taskId, ssuId, comment) {
    return axios.post(
      `${ROOT}/${taskId}/study-sites/${ssuId}/comments`,
      { comment },
      {
        hideLoader: true
      }
    );
  },
  getTasksForCalendarView(calendarRequest) {
    return axios.post(`${ROOT}/calendar`, calendarRequest, {
      hideLoader: true
    });
  },
  getUserRolesAllowedForViewTasksPerSsu(taskId, ssuId, userId) {
    return axios.get(`${ROOT}/${taskId}/study-sites/${ssuId}/users/${userId}`);
  },
  getTasksByPatientIdAndSSU(patientId, ssuId) {
    return axios.get(`${ROOT}/patients/${patientId}/ssu/${ssuId}`);
  },
  getTasksByPatientIdAndEncounter(patientId, encounterId) {
    return axios.get(`${ROOT}/patients/${patientId}/encounters/${encounterId}`);
  },
  getTasksByPatientIdForLogs(patientId, itemGroupKey) {
    return axios.get(`${ROOT}/patients/${patientId}/logs/${itemGroupKey}`);
  }
};

export default TaskApi;
