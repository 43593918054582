import axios from 'axios';

import { mapStudySite } from '../../services/studySiteMapper';

const ROOT = 'api/v1/study-sites';

const StudySiteApi = {
  getAllStudySites() {
    return axios.get(ROOT, { hideLoader: true });
  },
  getAllStudySitesAndMap() {
    return this.getAllStudySites().then(res => {
      const studySites = res.data.map(ssu => mapStudySite(ssu));
      return { ...res, data: studySites };
    });
  },
  getStudySitesEnrollment() {
    return axios.get(`${ROOT}/enrollment`, { hideLoader: true }).then(res => {
      const studySites = res.data.map(ssu => mapStudySite(ssu));
      return { ...res, data: studySites };
    });
  },
  getAllNonCanceledSsuProjections() {
    return axios.get(`${ROOT}/non-canceled`, { hideLoader: true });
  },
  getStudySiteWithNonProtocolEncounter() {
    return axios.get(`${ROOT}/study-site-for-worklist`);
  },
  getStudySitesWithProtocolAssigned() {
    return axios.get(`${ROOT}/protocol-assigned`);
  },
  setPrimarySM(primaryStudyManager) {
    return axios.put(`${ROOT}/primary-study-manager`, primaryStudyManager);
  },
  setPrimaryInvestigator(primaryInvestigator) {
    return axios.put(`${ROOT}/primary-investigator`, primaryInvestigator);
  },
  getMetricsById(studySiteId) {
    return axios.get(`${ROOT}/${studySiteId}/expected-ssu-success-metrics`);
  },
  saveOrUpdateMetrics(studySiteId, request) {
    return axios.put(`${ROOT}/${studySiteId}/expected-ssu-success-metrics`, request);
  }
};

export default StudySiteApi;
