import React, { useCallback, useMemo } from 'react';
import { sortBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import Input from '../../../../../../common/data-entry/Input';
import Select from '../../../../../../common/data-entry/Select';
import { ledgerEventTypes } from '../../finPageFilterTypes';
import { StartEndDatePicker } from '../StartEndDatePicker';
import StudySiteSelector from '../StudySiteSelector';

import './MainFilters.scss';

const EVENT_ID_MAX_NUMBER_LENGTH = 18;

export default function MainFilters(props) {
  const {
    setFewFilterProperty,
    filterProperty,
    calendarLabels,
    invoiceFilterConfig,
    pcnFlag,
    setIsEventIdFormatInvalid,
    children,
    enableAutoCompleteSite = false,
    moreThanTenInputs = false
  } = props;
  const types = sortBy(ledgerEventTypes, 'name');
  const onChangeType = type => {
    const typeName = type ? type.name : null;
    setFewFilterProperty({ type: typeName });
  };

  const onChangeInvoiceNumber = number => {
    const id = number ? number.id : null;
    setFewFilterProperty({ invoiceNumber: id });
  };

  const onChangeStartDate = moment => {
    const startDateMoment = moment.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    setFewFilterProperty({ startDate: startDateMoment });
  };

  const onChangeEndDate = moment => {
    const endDateMoment = moment.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
    setFewFilterProperty({ endDate: endDateMoment });
  };

  const onPatientSubjectId = event => {
    setFewFilterProperty({
      [event.target.name]: event.target.value
    });
  };

  const onPatientId = event => {
    setFewFilterProperty({
      [event.target.name]: event.target.value
    });
  };

  const onStudyChange = useCallback(
    study => {
      if (study) {
        const { uniqueIdentifier, studyName, projectCode } = study;
        const pcnCode = pcnFlag ? projectCode : undefined;
        setFewFilterProperty({ studyId: uniqueIdentifier, studyName: studyName, projectCode: pcnCode });
      } else {
        setFewFilterProperty({ studyId: null, studyName: null });
      }
    },
    [setFewFilterProperty, pcnFlag]
  );

  const onPcnChange = useCallback(
    (study, sites) => {
      if (study) {
        const { uniqueIdentifier, studyName, projectCode } = study;
        setFewFilterProperty({ studyId: uniqueIdentifier, studyName: studyName, projectCode: projectCode });
        if (sites.length === 1 && !filterProperty.siteId && enableAutoCompleteSite) {
          const { uniqueIdentifier, siteName } = sites[0];
          setFewFilterProperty({ siteId: uniqueIdentifier, siteName: siteName });
        }
      } else {
        setFewFilterProperty({ studyId: null, studyName: null, projectCode: null });
      }
    },
    [enableAutoCompleteSite, filterProperty.siteId, setFewFilterProperty]
  );

  const onSiteChange = useCallback(
    site => {
      if (site) {
        const { uniqueIdentifier, siteName } = site;
        setFewFilterProperty({ siteId: uniqueIdentifier, siteName: siteName });
      } else {
        setFewFilterProperty({ siteId: null, siteName: null });
      }
    },
    [setFewFilterProperty]
  );
  const type = useMemo(
    function() {
      return types.find(type => type.name === filterProperty?.type);
    },
    [filterProperty, types]
  );
  const startDate = useMemo(
    function() {
      return filterProperty?.startDate;
    },
    [filterProperty]
  );
  const endDate = useMemo(
    function() {
      return filterProperty?.endDate;
    },
    [filterProperty]
  );

  const isEventIdFormatValid = useMemo(
    function() {
      const finEventIdFormatValidationExpresion = new RegExp(/^[1-9]+[0-9]*(\.[1-9]+[0-9]*)?$/);
      const eventId = filterProperty?.finLedgerEventId;
      if (
        eventId?.number?.length > EVENT_ID_MAX_NUMBER_LENGTH ||
        eventId?.adjustmentSequence?.length > EVENT_ID_MAX_NUMBER_LENGTH
      ) {
        setIsEventIdFormatInvalid(true);
        return 'Event ID is too large';
      } else if (
        isEmpty(filterProperty?.eventIdFromInput) ||
        (!isEmpty(filterProperty?.eventIdFromInput) &&
          finEventIdFormatValidationExpresion.test(filterProperty?.eventIdFromInput))
      ) {
        setIsEventIdFormatInvalid(false);
      } else {
        setIsEventIdFormatInvalid(true);
        return 'Invalid Event ID format';
      }
    },
    [setIsEventIdFormatInvalid, filterProperty]
  );
  return (
    <div
      className={`general-header-group-container more-than-five-fields-wrapper 
      ${moreThanTenInputs && 'more-than-ten-fields'}`}
    >
      <StudySiteSelector
        onSiteChange={onSiteChange}
        onStudyChange={onStudyChange}
        onPcnChange={onPcnChange}
        studyId={filterProperty.studyId}
        siteId={filterProperty.siteId}
        pcnFlag={pcnFlag}
        enableAutoCompleteSite={enableAutoCompleteSite}
        setFewFilterProperty={setFewFilterProperty}
      />
      <Select onChange={onChangeType} dataSource={types} value={type} label="Budget Event Type" searchable />
      {!!invoiceFilterConfig && (
        <Select {...invoiceFilterConfig} onChange={onChangeInvoiceNumber} label="Invoice" searchable />
      )}
      <StartEndDatePicker
        onChangeStartDate={onChangeStartDate}
        startDate={startDate}
        calendarLabels={calendarLabels}
        onChangeEndDate={onChangeEndDate}
        endDate={endDate}
      />
      <Input
        label="Patient ID"
        value={filterProperty?.patientId}
        name="patientId"
        onChange={event => onPatientId(event)}
      />
      <Input
        label="Subject ID"
        value={filterProperty?.subjectId}
        name="subjectId"
        onChange={event => onPatientSubjectId(event)}
      />
      <Input
        label="Event ID"
        value={filterProperty?.eventIdFromInput}
        name="finLedgerEventId"
        onChange={event => onEventIdChange(event)}
        validationMessage={isEventIdFormatValid}
      />
      {children}
    </div>
  );
  function onEventIdChange(event) {
    const [number, adjustmentSequence] = event.target.value.split('.');

    if (event.target.value === '') {
      return setFewFilterProperty({
        [event.target.name]: null,
        eventIdFromInput: null
      });
    }
    return setFewFilterProperty({
      [event.target.name]: {
        number: number,
        adjustmentSequence: adjustmentSequence
      },
      eventIdFromInput: event.target.value
    });
  }
}
MainFilters.propTypes = {
  setFewFilterProperty: PropTypes.func,
  filterProperty: PropTypes.object,
  calendarLabels: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  })
};
