import { useContext, useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { isEqual } from 'lodash/lang';

import PatientTravelApi from '../../../../../../../api/patient/PatientTravelApi';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { PatientInfoContext } from '../../PatientInfoContext';

import AddressFields from './AddressFields/AddressFields';
import AddressValidator from './AddressFields/AddressValidator';
import useAddressFieldsReducer from './AddressFields/useAddressFieldsReducer';

export default function PreferredPickupAddress({ initialData, setTravelPreferences }) {
  const { patientInfo } = useContext(PatientInfoContext);
  const { state, setAddressField, setAddressFields, setCountryId, setCity, setAddressType } = useAddressFieldsReducer(
    initialData
  );
  const [saving, setSaving] = useState(false);
  const patientAddress = patientInfo?.address;

  const { saveAllowed, discardAllowed } = useMemo(
    function() {
      let saveAllowed = false;
      let discardAllowed = false;
      if (!saving && !addressIsEqual(initialData, state)) {
        saveAllowed = true;
        discardAllowed = true;
      }
      return {
        saveAllowed,
        discardAllowed
      };
    },
    [initialData, state, saving]
  );

  useEffect(
    function() {
      setTravelPreferences(function(state) {
        return { ...state, preferredPickupAddressEdited: saveAllowed };
      });
    },
    [saveAllowed, setTravelPreferences]
  );

  useEffect(
    function() {
      setAddressType({
        addressType: 'PATIENT_HOME_ADDRESS',
        address: {
          countryId: patientAddress.country,
          regionId: patientAddress.state,
          city: patientAddress.city,
          address1: patientAddress.addressLine1,
          address2: patientAddress.addressLine2,
          zipCode: patientAddress.zipCode
        },
        initial: true
      });
    },
    [setAddressType, patientAddress]
  );

  return (
    <>
      <AddressValidator data={state} setAddressFields={setAddressFields} />
      <Box sx={{ flexGrow: 1 }} component="form">
        <Grid container spacing={2} alignItems="center">
          <Grid xs={6}>
            <Typography variant="subtitle2">Preferred Pickup address</Typography>
          </Grid>
          <Grid xs={6}>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <IconButton aria-label="save" disabled={!saveAllowed} color="primary" onClick={onSave} title="Save">
                <SaveIcon />
              </IconButton>
              <IconButton
                aria-label="discard"
                disabled={!discardAllowed}
                color="primary"
                onClick={onDiscard}
                title="Discard changes"
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid xs={7}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      value={state.type}
                      onChange={function({ target }) {
                        const payload = { addressType: target.value };
                        if (payload.addressType === 'PATIENT_HOME_ADDRESS') {
                          payload.address = {
                            countryId: patientAddress.country,
                            regionId: patientAddress.state,
                            city: patientAddress.city,
                            address1: patientAddress.addressLine1,
                            address2: patientAddress.addressLine2,
                            zipCode: patientAddress.zipCode
                          };
                        }
                        setAddressType(payload);
                        AddressValidator.revalidate();
                      }}
                    >
                      <FormControlLabel
                        sx={{ margin: 0 }}
                        value="PATIENT_HOME_ADDRESS"
                        control={<Radio />}
                        label="Home Address"
                      />
                      <FormControlLabel sx={{ margin: 0 }} value="OTHER" control={<Radio />} label="Other" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <AddressFields
                  data={state}
                  setAddressField={setAddressField}
                  setCity={setCity}
                  setCountryId={setCountryId}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  function onSave() {
    setSaving(true);
    PatientTravelApi.savePreferredPickupAddress(patientInfo?.id, state)
      .then(
        function() {
          setTravelPreferences(function(prevState) {
            return { ...prevState, preferredPickupAddress: state };
          });
          NotificationManager.success('Preferred Pickup address saved successfully');
        },
        function(error) {
          NotificationManager.error('Preferred Pickup address save error');
        }
      )
      .finally(function() {
        setSaving(false);
      });
  }

  function onDiscard() {
    setAddressFields(initialData);
  }
}

function addressIsEqual(a, b) {
  return isEqual(
    {
      type: a.type,
      countryId: a.countryId,
      regionId: a.regionId,
      city: a.city,
      address1: a.address1,
      address2: a.address2,
      zipCode: a.zipCode
    },
    {
      type: b.type,
      countryId: b.countryId,
      regionId: b.regionId,
      city: b.city,
      address1: b.address1,
      address2: b.address2,
      zipCode: b.zipCode
    }
  );
}
