import * as React from 'react';
import { useCallback, useContext, useMemo } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import moment from 'moment';

import { FinLedgerEventApi } from '../../../../../api';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';
import { MANAGE_LEDGER_EVENTS } from '../../../../../constants/userOperations';
import { ROLE_FINANCE } from '../../../../../constants/userRoles';
import { userHasAccessTo, userHasRole } from '../../../../../services/auth';
import { toBill } from '../../../../../services/financial';
import { onRequestDefaultError } from '../../../../../services/handlers';
import { eventNumberComparator } from '../../NewInvoice/InvoiceTable/InvoiceTableService';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { DefaultFinanceCell } from '../../shared/FinanseTableMUI/DefaultFinanceCell';
import { FinanceTableMUI } from '../../shared/FinanseTableMUI/FinanceTableMUI';
import { formatDateForCSV, prepareNameForCSV } from '../../shared/FinanseTableMUI/FinanceTableServices';
import { LedgerContext } from '../LedgerContext';

import { PatientLedgerMenu } from './LadgerPatientMenu/PatientLedgerMenu';

import './LedgerTable.scss';

export const LedgerTable = () => {
  const { ledgerEvents } = useContext(LedgerContext);

  const columns = useMemo(
    () => [
      {
        field: 'eventNumber',
        headerName: 'Event ID',
        flex: 1,
        minWidth: 125,
        sortComparator: eventNumberComparator,
        valueGetter: ({ row }) => {
          if (row.adjustmentSequence === 0) {
            return `${row.eventNumber}`;
          }
          return `${row.eventNumber}.${row.adjustmentSequence}`;
        }
      },
      {
        field: 'studyName',
        headerName: 'Study',
        flex: 1,
        minWidth: 110,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'siteName',
        headerName: 'Site',
        flex: 1,
        minWidth: 95,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'patientId',
        headerName: 'Patient ID',
        flex: 1,
        minWidth: 135,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'subjectId',
        headerName: 'Subject ID',
        flex: 1,
        minWidth: 135,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => (value != null ? `[${value}]` : '')
      },
      {
        field: 'encounterName',
        headerName: 'Encounter',
        flex: 1,
        minWidth: 135,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'elligoEventType',
        headerName: 'Budget Event Type',
        flex: 1,
        minWidth: 190,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'itemGroupName',
        headerName: 'Item Group',
        flex: 1,
        minWidth: 140,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'eventName',
        headerName: 'Event Name',
        flex: 1,
        minWidth: 150,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'ledgerEventStatuses.eventStatus',
        headerName: 'Status',
        flex: 1,
        minWidth: 115,
        valueGetter: ({ row }) => row.ledgerEventStatuses.eventStatus,
        renderCell: ({ row }) => <StatusColumn {...row} />
      },
      {
        field: 'eventDate',
        headerName: 'Date',
        flex: 1,
        minWidth: 115,
        type: 'date',
        valueFormatter: ({ value }) => formatDateForCSV(value),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row }) => (
          <div className="MuiDataGrid-cellContent">{moment(row.eventDate).format(DD_SLASH_MMM_SLASH_YYYY)}</div>
        )
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: 1,
        minWidth: 120,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.amountForDisplay),
        renderCell: ({ row }) => (
          <Amount className="MuiDataGrid-cellContent" coinsAmount={row.amountForDisplay} showDollarSign />
        )
      },
      {
        field: 'ledgerEventStatuses.eventItemStatuses.CLIENT',
        headerName: 'Client',
        flex: 1,
        minWidth: 110,
        valueGetter: ({ row }) => row.ledgerEventStatuses.eventItemStatuses.CLIENT,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'ledgerEventStatuses.eventItemStatuses.SITE',
        headerName: 'Site',
        flex: 1,
        minWidth: 95,
        valueGetter: ({ row }) => row.ledgerEventStatuses.eventItemStatuses.SITE,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'ledgerEventStatuses.eventItemStatuses.PATIENT',
        headerName: 'Patient',
        flex: 1,
        minWidth: 185,
        valueGetter: ({ row }) => row.ledgerEventStatuses.eventItemStatuses.PATIENT,
        renderCell: ({ value, row }) => (
          <div className="MuiDataGrid-cellContent">
            {value || '—'}
            {value === 'PAYMENT_FAILED' && !userHasRole(ROLE_FINANCE) && <PatientLedgerMenu row={row} />}
          </div>
        )
      },
      {
        field: 'ledgerEventStatuses.eventItemStatuses.VENDOR',
        headerName: 'Vendor',
        flex: 1,
        minWidth: 115,
        valueGetter: ({ row }) => row.ledgerEventStatuses.eventItemStatuses.VENDOR,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'ledgerEventStatuses.eventItemStatuses.ELLIGO',
        headerName: 'Net',
        flex: 1,
        minWidth: 95,
        valueGetter: ({ row }) => row.ledgerEventStatuses.eventItemStatuses.ELLIGO,
        renderCell: DefaultFinanceCell
      }
    ],
    []
  );
  return (
    <FinanceTableMUI
      localStorageTableName="LEDGER"
      rows={ledgerEvents}
      columns={columns}
      footerElementsCount={ledgerEvents.length}
      disableRowSelectionOnClick
      sx={{ padding: '0 40px 0 40px' }}
      localeText={{ noRowsLabel: 'No Record Found' }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'eventNumber', sort: 'desc' }]
        }
      }}
      tableVersion="1.0"
    />
  );
};

const ConfirmationInfo = ({ lostAmount, eventName }) => {
  return (
    <div className="lt-deactivated">
      <span> Are you sure you want to deactivate the expense "{eventName}"?</span>
      {lostAmount && (
        <div className="lt-deactivated-additional-info">
          <b>${lostAmount} </b>has already been recognized as revenue and will be removed from revenue recognition.
        </div>
      )}
    </div>
  );
};

const StatusColumn = ({
  eventName,
  elligoAmountWithOverhead,
  id,
  ledgerEventStatuses: { eventItemStatuses, eventStatus },
  elligoEventType
}) => {
  const { applyFilters } = useContext(LedgerContext);
  const isInvoiced = useMemo(() => Object.values(eventItemStatuses).some(el => el === 'INVOICED'), [eventItemStatuses]);
  const isPending = useMemo(() => Object.values(eventItemStatuses).every(el => el === 'PENDING'), [eventItemStatuses]);
  const isActive = useMemo(() => eventStatus === 'ACTIVE', [eventStatus]);
  const isExpenseEvent = useMemo(() => ['Expense - Fixed', 'Expense - Variable'].includes(elligoEventType), [
    elligoEventType
  ]);
  const hasAccess = userHasAccessTo(MANAGE_LEDGER_EVENTS);

  const openConfirmationDialog = useCallback(
    (id, eventName, lostAmount = null) => {
      ModalBoxes.confirm({
        content: <ConfirmationInfo lostAmount={lostAmount} eventName={eventName} elligoEventType={elligoEventType} />,
        cancelButton: 'NO',
        confirmButton: 'YES',
        size: 'w500'
      }).then(
        () =>
          FinLedgerEventApi.inactivateExpenseEvent(id).then(() => {
            ModalBoxes.closeAll();
            applyFilters();
          }, onRequestDefaultError),
        () => {}
      );
    },
    [applyFilters, elligoEventType]
  );

  return (
    <div className="MuiDataGrid-cellContent" title={eventStatus || '—'}>
      <span>{eventStatus || '—'}</span>
      {hasAccess && isExpenseEvent && !isInvoiced && isActive && (
        <IconButton
          sx={{ marginLeft: '5px' }}
          title="Deactivate ledger event"
          onClick={() => {
            isPending
              ? openConfirmationDialog(id, eventName)
              : openConfirmationDialog(id, eventName, toBill(elligoAmountWithOverhead));
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
};
