import { uniqBy } from 'lodash/array';
import { orderBy } from 'lodash/collection';
import { cloneDeep } from 'lodash/lang';

import { LOGS } from '../components/pages/patient-source/shared/Review/reviewConstants';
import { UNEXPECTED } from '../constants/encounterConstants';

export function createEncountersCollection(epochs) {
  const encounters = epochs.flatMap(({ encounters }) => encounters);
  const unexpectedEncounter = {
    encounterIdentifier: UNEXPECTED,
    encounterName: 'Unexpected Encounter',
    displayName: 'UE'
  };
  return [...encounters, unexpectedEncounter];
}

function defineItemGroupsWithSequence(itemGroupsFromEncounter, itemGroups) {
  const itemGroupsWithUniqueUniqueIdentifier = uniqBy(itemGroupsFromEncounter, 'elementUniqueIdentifier');
  const itemGroupOrderedBySequence = orderBy(itemGroupsWithUniqueUniqueIdentifier, 'itemGroupSequence');
  return itemGroupOrderedBySequence
    .map(({ elementUniqueIdentifier }) => {
      return itemGroups.find(({ uniqueIdentifier }) => elementUniqueIdentifier === uniqueIdentifier);
    })
    .filter(itemGroup => itemGroup);
}

function extractLogItemGroupsWithoutSequenceOrderedByName(itemGroupsWithSequence, itemGroups) {
  const itemGroupsWithSequenceUniqueIdentifiers = itemGroupsWithSequence.map(
    ({ uniqueIdentifier }) => uniqueIdentifier
  );
  const itemGroupWithoutSequence = itemGroups
    ?.filter(({ uniqueIdentifier }) => !itemGroupsWithSequenceUniqueIdentifiers.includes(uniqueIdentifier))
    .filter(({ elementIcon }) => elementIcon === 'Logs');
  return orderBy(itemGroupWithoutSequence, 'elementName');
}

export function sortItemGroupsBySequence(epochs, itemGroups) {
  const itemGroupsFromEncounter = epochs.flatMap(({ encounters }) => {
    return encounters.flatMap(({ elements }) => elements);
  });
  const itemGroupsWithSequence = defineItemGroupsWithSequence(itemGroupsFromEncounter, itemGroups);
  const itemGroupWithoutSequenceOrderedByName = extractLogItemGroupsWithoutSequenceOrderedByName(
    itemGroupsWithSequence,
    itemGroups
  );
  return [...itemGroupsWithSequence, ...itemGroupWithoutSequenceOrderedByName];
}

export function getItemGroupEncounterGrantedMap(itemGroupsList, encountersList) {
  let itemGroupEncounterGrantedMap = {};
  itemGroupsList?.forEach(({ uniqueIdentifier, itemGroupKey: itemGroupTemplateId, elementIcon }) => {
    const isLogItemGroup = elementIcon === LOGS;
    itemGroupEncounterGrantedMap = {
      ...itemGroupEncounterGrantedMap,
      [itemGroupTemplateId]: {
        isLogItemGroup: isLogItemGroup
      }
    };
    const encounterListWhichIncludeCurrentItemGroup = encountersList.filter(
      ({ elements, encounterIdentifier }) =>
        encounterIdentifier === UNEXPECTED ||
        elements.map(({ elementUniqueIdentifier }) => elementUniqueIdentifier).includes(uniqueIdentifier)
    );
    if (isLogItemGroup) {
      itemGroupEncounterGrantedMap[itemGroupTemplateId] = {
        ...itemGroupEncounterGrantedMap[itemGroupTemplateId],
        granted: null
      };
    } else {
      encounterListWhichIncludeCurrentItemGroup?.forEach(({ encounterIdentifier }) => {
        itemGroupEncounterGrantedMap[itemGroupTemplateId].encounters = {
          ...itemGroupEncounterGrantedMap[itemGroupTemplateId].encounters,
          [encounterIdentifier]: {
            granted: null
          }
        };
      });
    }
  });
  return itemGroupEncounterGrantedMap;
}
function resolveGrantedForLogItemGroups(itemGroupsConfig, itemGroupsList, itemGroupsEncounterGrantedMapCopy) {
  itemGroupsConfig
    .filter(
      itemGroupConfig =>
        itemGroupsList.find(itemGroup => itemGroup.itemGroupKey === itemGroupConfig.itemGroupTemplateId)
          ?.elementIcon === LOGS
    )
    .forEach(logItemGroup => {
      itemGroupsEncounterGrantedMapCopy[logItemGroup.itemGroupTemplateId].granted = logItemGroup.granted;
    });
}
function resolveGrantedForUnexpectedEncounterItemGroups(
  itemGroupsConfig,
  itemGroupsList,
  itemGroupsEncounterGrantedMapCopy
) {
  itemGroupsConfig
    .filter(
      logItemGroupConfig =>
        itemGroupsList.find(itemGroup => itemGroup.itemGroupKey === logItemGroupConfig.itemGroupTemplateId)
          ?.elementIcon !== LOGS
    )
    .forEach(logItemGroup => {
      const grantedMap = itemGroupsEncounterGrantedMapCopy[logItemGroup.itemGroupTemplateId];
      if (!!grantedMap) {
        grantedMap.encounters[UNEXPECTED].granted = logItemGroup.granted;
      }
    });
}
function resolveGrantedForEncounterItemGroups(encountersItemGroupsConfig, itemGroupsEncounterGrantedMapCopy) {
  encountersItemGroupsConfig.forEach(itemGroupEncounter => {
    const itemGroup = itemGroupsEncounterGrantedMapCopy[itemGroupEncounter.itemGroupTemplateId];
    const encounter = itemGroup?.encounters[itemGroupEncounter.encounterTemplateId];
    if (!!encounter) {
      encounter.granted = itemGroupEncounter.granted;
    }
  });
}
export function fillConfiguredGrantedMap(
  itemGroupsEncounterGrantedMap,
  { itemGroupsConfig, encountersItemGroupsConfig },
  itemGroupsList
) {
  const itemGroupsEncounterGrantedMapCopy = cloneDeep(itemGroupsEncounterGrantedMap);
  resolveGrantedForLogItemGroups(itemGroupsConfig, itemGroupsList, itemGroupsEncounterGrantedMapCopy);
  resolveGrantedForUnexpectedEncounterItemGroups(itemGroupsConfig, itemGroupsList, itemGroupsEncounterGrantedMapCopy);
  resolveGrantedForEncounterItemGroups(encountersItemGroupsConfig, itemGroupsEncounterGrantedMapCopy);
  return itemGroupsEncounterGrantedMapCopy;
}
