import React, { useContext, useEffect } from 'react';
import { isEmpty, isEqual } from 'lodash/lang';

import FinClientApi from '../../../../../../api/finance/FinClientApi';
import FinPayeeApi from '../../../../../../api/finance/FinPayeeApi';
import Input from '../../../../../../common/data-entry/Input';
import Select from '../../../../../../common/data-entry/Select';
import { InvoiceContext } from '../../NewInvoiceContext';
import { CONTACT_NAMES, TERMS } from '../CreateInvoiceConstants';
import { payeeToString, transformedPayees } from '../CreateInvoiceServices';

export const ConfigurationSection = ({
  billToList,
  setBillToList,
  selectedBillTo,
  setSelectedBillTo,
  invoiceCreated,
  savingType,
  payees,
  setPayees,
  selectedPayee,
  setSelectedPayee,
  selectedTerms,
  setSelectedTerms,
  remit,
  setRemit,
  selectedContactName,
  setSelectedContactName,
  billToLocalStorage,
  payeeLocalStorage,
  mainLedgerEvents
}) => {
  const { createInvoiceItems } = useContext(InvoiceContext);

  useEffect(() => {
    if (
      isEmpty(createInvoiceItems.createdInvoiceDetails) &&
      isEmpty(createInvoiceItems.pdf) &&
      (!isEmpty(createInvoiceItems.originalLedgerEvents) ||
        !isEmpty(createInvoiceItems.withholdingLedgerEvents) ||
        createInvoiceItems.creditMemoLedgerEvents ||
        createInvoiceItems.debitMemoLedgerEvents) &&
      isEmpty(billToList)
    ) {
      FinClientApi.viewUsage().then(({ data }) => {
        const clientName = billToLocalStorage[mainLedgerEvents?.[0].ssuId]
          ? billToLocalStorage[mainLedgerEvents?.[0].ssuId]
          : mainLedgerEvents?.[0].clientName;
        const billToList = data.map(el => el.client);
        setBillToList(billToList.sort((a, b) => a.name.localeCompare(b.name)));
        setSelectedBillTo(billToList.find(el => el.name === clientName));
      });
    }
  }, [
    billToList,
    billToLocalStorage,
    createInvoiceItems.createdInvoiceDetails,
    createInvoiceItems.creditMemoLedgerEvents,
    createInvoiceItems.debitMemoLedgerEvents,
    createInvoiceItems.originalLedgerEvents,
    createInvoiceItems.pdf,
    createInvoiceItems.withholdingLedgerEvents,
    mainLedgerEvents,
    setBillToList,
    setSelectedBillTo
  ]);

  useEffect(() => {
    if (!isEmpty(createInvoiceItems.createdInvoiceDetails) && !isEmpty(createInvoiceItems.pdf)) {
      FinClientApi.viewUsage().then(({ data }) => {
        const billToList = data.map(el => el.client);
        setBillToList(billToList);
      });
    }
  }, [createInvoiceItems.createdInvoiceDetails, createInvoiceItems.pdf, setBillToList]);

  useEffect(() => {
    if (
      isEmpty(createInvoiceItems.createdInvoiceDetails) &&
      isEmpty(createInvoiceItems.pdf) &&
      (!isEmpty(createInvoiceItems.originalLedgerEvents) ||
        !isEmpty(createInvoiceItems.withholdingLedgerEvents) ||
        createInvoiceItems.creditMemoLedgerEvents ||
        createInvoiceItems.debitMemoLedgerEvents)
    ) {
      FinPayeeApi.findAllActive().then(({ data }) => {
        const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name));
        const ssuId = mainLedgerEvents?.[0].ssuId;
        const localStoragePayee = payeeLocalStorage[ssuId];
        const payee = localStoragePayee
          ? sortedData.find(data => data.id === localStoragePayee?.id)
          : sortedData.find(el => el.default) || sortedData[0];
        setPayees(sortedData);
        setSelectedPayee(payee);
      });
    }
  }, [
    payeeLocalStorage,
    createInvoiceItems.createdInvoiceDetails,
    createInvoiceItems.originalLedgerEvents,
    createInvoiceItems.pdf,
    createInvoiceItems.withholdingLedgerEvents,
    mainLedgerEvents,
    setPayees,
    setSelectedPayee,
    createInvoiceItems.creditMemoLedgerEvents,
    createInvoiceItems.debitMemoLedgerEvents
  ]);

  useEffect(() => {
    if (!isEmpty(createInvoiceItems.createdInvoiceDetails) && !isEmpty(createInvoiceItems.pdf)) {
      setSelectedPayee(JSON.parse(createInvoiceItems.createdInvoiceDetails?.payee));
      setRemit(createInvoiceItems.createdInvoiceDetails.remit);
      setSelectedContactName(
        CONTACT_NAMES.find(el => el.name === createInvoiceItems.createdInvoiceDetails.contactName)
      );
      setSelectedTerms(TERMS.find(el => el.name === createInvoiceItems.createdInvoiceDetails.terms));
      setSelectedBillTo({
        name: createInvoiceItems.createdInvoiceDetails.clientName,
        id: createInvoiceItems.createdInvoiceDetails.clientId,
        email: createInvoiceItems.createdInvoiceDetails.clientEmail
      });
    }
  }, [
    createInvoiceItems.createdInvoiceDetails,
    createInvoiceItems.pdf,
    setRemit,
    setSelectedBillTo,
    setSelectedContactName,
    setSelectedPayee,
    setSelectedTerms
  ]);
  return (
    <>
      <div className="general-header-wrapper">
        <Select
          className="create-invoice-dropdown"
          label="Bill to"
          dataSource={billToList}
          onChange={newValue => !isEqual(newValue, selectedBillTo) && setSelectedBillTo(newValue)}
          value={selectedBillTo}
          data-testid="bill-to-selector"
          clearable={false}
          searchable={true}
          validate={false}
          disabled={invoiceCreated || !!savingType}
        />
        <Select
          className="create-invoice-dropdown"
          label="Payee"
          dataSource={payees}
          onChange={newValue => !isEqual(newValue, selectedPayee) && setSelectedPayee(newValue)}
          value={selectedPayee}
          customSelectedValueTemplateFunction={payees => transformedPayees(payees)}
          customOptionTemplateFunction={selectedPayee => payeeToString(selectedPayee)}
          data-testid="payee-selector"
          clearable={false}
          validate={false}
          disabled={invoiceCreated || !!savingType}
        />
      </div>
      <div className="general-header-wrapper">
        <Select
          className="create-invoice-dropdown terms-dropdown"
          label="Terms"
          dataSource={TERMS}
          onChange={newValue => !isEqual(newValue, selectedPayee) && setSelectedTerms(newValue)}
          value={selectedTerms}
          data-testid="terms-selector"
          clearable={false}
          validate={false}
          disabled={invoiceCreated || !!savingType}
        />
        <Input
          className="create-invoice-input"
          label="Remit"
          value={remit}
          onChange={({ target: { value } }) => setRemit(value)}
          validate={false}
          disabled={invoiceCreated || !!savingType}
        />
        <Select
          className="create-invoice-dropdown"
          label="Contact Name"
          dataSource={CONTACT_NAMES}
          onChange={newValue => !isEqual(newValue, selectedContactName) && setSelectedContactName(newValue)}
          value={selectedContactName}
          data-testid="contact-name-selector"
          clearable={false}
          validate={false}
          disabled={invoiceCreated || !!savingType}
        />
      </div>
    </>
  );
};
