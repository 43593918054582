import React from 'react';

import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../common/general/Button';
import Icon from '../../../../../../../../common/general/Icon';
import { getFormattedSubElementName } from '../../../itemGroupSetupService';

import { CustomFieldBlock } from './CustomFieldBlock';

export function CustomFieldLabelClosableBlock({
  terminologyVersionGroupNumber,
  editMode,
  customFieldData,
  domain,
  labelIndex,
  getCheckboxForTableView,
  inputs,
  updateInputsAndPreviewData,
  getInvestigatorFieldCheckboxForTableView,
  getFixedItemCheckboxForTableView,
  updateState,
  onChangeLabelTextHandler,
  isInvestigatorFieldAvailable,
  isAdverseEventLog,
  isLogViewCheckboxAvailable
}) {
  customFieldData.itemDefinitionList = []; // Don't know why it is in code, but it was.
  const isCustomFieldsExist = customFieldData && customFieldData.customItemDefinationList;

  return (
    <div className="item-group-field-row row col-xs-12 col-md-12 position-relative custom-item">
      <label className="vital-label-heading">{getFormattedSubElementName(customFieldData.formLabelName)}</label>
      <span className="pl-1 custom-close">
        <Button
          size="h28"
          priority="low"
          type="button"
          className="float-right"
          onClick={() => onCloseFormLabel(labelIndex)}
        >
          <Icon>clear</Icon>
        </Button>
      </span>
      {isCustomFieldsExist && (
        <CustomFieldBlock
          editMode={editMode}
          terminologyVersionGroupNumber={terminologyVersionGroupNumber}
          customFieldData={customFieldData}
          domain={domain}
          labelIndex={labelIndex}
          getCheckboxForTableView={getCheckboxForTableView}
          getInvestigatorFieldCheckboxForTableView={getInvestigatorFieldCheckboxForTableView}
          getFixedItemCheckboxForTableView={getFixedItemCheckboxForTableView}
          inputs={inputs}
          updateInputsAndPreviewData={updateInputsAndPreviewData}
          updateState={updateState}
          onChangeLabelTextHandler={onChangeLabelTextHandler}
          isInvestigatorFieldAvailable={isInvestigatorFieldAvailable}
          isAdverseEventLog={isAdverseEventLog}
          isLogViewCheckboxAvailable={isLogViewCheckboxAvailable}
        />
      )}
    </div>
  );
  function onCloseFormLabel(labelIndex) {
    if (labelIndex > -1) {
      const currentCustomFieldSequence = inputs[0].custom[labelIndex].customItemDefinationList[0].sequence;
      const customItemsWhichUsesCurrentFieldInConfiguration = inputs[0]?.custom.flatMap(
        e => e.customItemDefinationList
      );
      const allItemsWhichUsesCurrentFieldInConfiguration = [
        ...inputs[0]?.optional[0]?.itemDefinitionList,
        ...customItemsWhichUsesCurrentFieldInConfiguration
      ]
        .filter(e => !!e.fieldConfigurationResponse)
        .filter(e => e.fieldConfigurationResponse.conditionFieldSequence === currentCustomFieldSequence);
      if (allItemsWhichUsesCurrentFieldInConfiguration.length > 0) {
        ModalBoxes.confirm({
          content: 'Deleting this toggle will remove field configurations in this Item Group. Delete anyway?',
          confirmButton: 'Yes',
          cancelButton: 'No',
          className: 'edit-custom-field-logic-modal',
          title: 'Toggle Configuration'
        }).then(() => {
          inputs && inputs[0].custom.splice(labelIndex, 1);
          allItemsWhichUsesCurrentFieldInConfiguration.forEach(item => (item.fieldConfigurationResponse = null));
          updateInputsAndPreviewData(inputs);
        });
      } else {
        inputs && inputs[0].custom.splice(labelIndex, 1);
        updateInputsAndPreviewData(inputs);
      }
    }
  }
}
