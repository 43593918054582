import React, { useState } from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { find } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import { useCurrentRoute } from '../../root/router';

import ModeReportsFilters from './ModeReportsFilters/ModeReportsFilters';
import ModeReportsList from './ModeReportsList/ModeReportsList';
import { useAllowedDashboards } from './modeReportsAccessService';
import withAccessToModeReports from './modeReportsAccessService';
import { useModeReportsState, withModeReportsProvider } from './ModeReportsContext';

function ModeReports() {
  const currentRoute = useCurrentRoute();
  const filteredDashboards = useAllowedDashboards();
  const [isCollapsed, setCollapsed] = useState(false);
  const { data, reportKey, isFetching, embedUrl } = useModeReportsState();
  const currentDashboard =
    currentRoute?.name === 'Dashboards' &&
    find(filteredDashboards, (item, key) => key?.toLowerCase() === currentRoute?.params?.reportKey?.toLowerCase());

  return (
    <div style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto 1fr',
          gridGap: '0.5rem',
          minHeight: '85vh'
        }}
      >
        <div
          style={{
            width: (isCollapsed && '0rem') || '100%',
            overflowX: 'hidden',
            transition: 'width 0.125s linear'
          }}
        >
          <ModeReportsList filteredDashboards={filteredDashboards} currentDashboardName={currentDashboard?.name} />
        </div>
        <Container
          onClick={() => {
            setCollapsed(!isCollapsed);
          }}
          style={{ padding: '0' }}
          sx={{
            cursor: 'initial',
            '&:hover': {
              cursor: 'pointer',
              'button:first-child': {
                backgroundColor: '#e7e7e7'
              }
            }
          }}
        >
          <Box boxShadow={3} borderRadius="50%" display="inline-block" sx={{ marginTop: '6rem' }}>
            <IconButton
              sx={{
                color: '#691E44',
                border: '.03125rem solid #691E44',
                borderRadius: '50%',
                width: '1.5rem',
                height: '1.5rem'
              }}
            >
              {(isCollapsed && <ChevronRight />) || <ChevronLeft />}
            </IconButton>
          </Box>
        </Container>
        <div>
          {(currentDashboard && (
            <>
              <h3>{currentDashboard?.name}</h3>
              <h6>{currentDashboard?.description}</h6>
            </>
          )) || <h4>No Report Selected</h4>}
          {!isEmpty(data?.filters) && reportKey && (
            <div style={{ paddingTop: '1rem' }}>
              <ModeReportsFilters />
            </div>
          )}
          {!isFetching && embedUrl && (
            <div style={{ alignSelf: 'start' }}>
              <iframe title={'report Report'} src={embedUrl} width="100%" height="1200" frameBorder="0" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withAccessToModeReports(withModeReportsProvider(ModeReports));
