import { useCallback, useEffect } from 'react';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { isEmpty } from 'lodash/lang';

import { TaskApi } from '../../../../../../../api';
import Divider from '../../../../../../../common/elements/Divider/Divider';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import Icon from '../../../../../../../common/general/Icon';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { useActiveTask, useActiveTaskUpdate } from '../../../../../../../store/activeTask';
import { useCurrentUser } from '../../../../CurrentUserContainer';
import TasksSupervisor from '../../TasksSupervisor/TasksSupervisor';
import TaskAccessDeniedWithRoleChangeModal from '../TaskAccessDeniedModal/TaskAccessDeniedWithRoleChangeModal';
import { TasksStatuses, TasksStatusesMap, TaskTopicsMap } from '../taskConstants';
import TaskBody from '../TaskContainer/TaskBody/TaskBody';
import TaskContainer from '../TaskContainer/TaskContainer';
import TaskFooter from '../TaskContainer/TaskFooter/TaskFooter';
import TaskHeader from '../TaskContainer/TaskHeader/TaskHeader';
import { prepareCreateRequestData, prepareTaskData, prepareUpdateRequestData } from '../taskDataProxy';
import { defaultFieldsValidation, validateTask } from '../taskServices';
import useTaskForm from '../useTaskForm';

import TaskDetailsAssignees from './TaskDetailsAssignees/TaskDetailsAssignees';
import TaskDetailsComments from './TaskDetailsComments/TaskDetailsComments';
import TaskDetailsDescription from './TaskDetailsDescription/TaskDetailsDescription';
import TaskDetailsPatientInfo from './TaskDetailsPatientInfo/TaskDetailsPatientInfo';
import TaskDetailsUpdates from './TaskDetailsUpdates/TaskDetailsUpdates';
import TaskNavigationBar from './TaskNavigationBar/TaskNavigationBar';
import useRelatedTasks from './useRelatedTasks';

import './TaskView.scss';

export default function TaskView({ allStudySites, allAssignees }) {
  const activeTask = useActiveTask();
  const updateActiveTask = useActiveTaskUpdate();
  const relatedTasks = useRelatedTasks();
  const user = useCurrentUser();
  const {
    taskForm,
    originTaskForm,
    isChanged,
    taskFormIsLoading,
    setTaskFormIsLoading,
    taskFormValidationMap,
    setTaskFormValidationMap,
    linkAccess,
    matchedSsuIds,
    updateTaskForm,
    setDescription,
    setTopicTitle,
    setAssignees,
    setDueDate,
    setStatus,
    setTaskCommentField
  } = useTaskForm(allStudySites);

  const handleForbiddenTaskError = useCallback(
    (error, taskId, studySiteId) => {
      TaskApi.getUserRolesAllowedForViewTasksPerSsu(taskId, studySiteId, user.personnelIdentifier).then(resp => {
        if (error.response?.data?.message === 'Forbidden VIEW_TASKS') {
          if (user) {
            ModalBoxes.open({
              component: (
                <TaskAccessDeniedWithRoleChangeModal
                  userHasOnlyCurrentRole={
                    (user.roles.length === 1 && user.activeRole === user.roles[0]) || resp.data.length === 0
                  }
                  userRoles={user.roles}
                  taskId={taskId}
                  studySiteId={studySiteId}
                />
              )
            });
          }
        }
      });
    },
    [user]
  );

  const loadTaskDetails = useCallback(
    function() {
      setTaskFormIsLoading(true);
      const loadingStartTime = +new Date();
      TaskApi.getTaskDetails(activeTask?.taskId, activeTask?.ssuId)
        .then(({ data }) => {
          setTaskFormValidationMap({ ...defaultFieldsValidation });
          updateTaskForm(prepareTaskData(data));
          const loadingEndTime = +new Date();
          const loadingDeltaTime = loadingEndTime - loadingStartTime;
          setTimeout(function() {
            setTaskFormIsLoading(false);
          }, Math.max(700 - loadingDeltaTime, 0));
        })
        .catch(error => {
          handleForbiddenTaskError(error, activeTask?.taskId, activeTask?.ssuId);
          TasksSupervisor.close();
        });
    },
    [
      setTaskFormIsLoading,
      activeTask?.taskId,
      activeTask?.ssuId,
      setTaskFormValidationMap,
      updateTaskForm,
      handleForbiddenTaskError
    ]
  );

  useEffect(() => {
    loadTaskDetails();
  }, [loadTaskDetails, activeTask?.status]);

  const { topic, status } = taskForm;

  return (
    <TaskContainer className="eds-task-container-view">
      <TaskHeader>
        <div id="displayed-topic">{TaskTopicsMap[topic]?.name}</div>
        {status && (
          <Menu
            menuButton={
              <Button priority="medium" size="h28" className="menu-button">
                {TasksStatusesMap[status]?.name}
                <Icon>arrow_drop_down</Icon>
              </Button>
            }
          >
            {TasksStatuses.map(s => (
              <MenuItem value={s.id} key={s.id} onClick={e => setStatus(e.value)}>
                {s.name}
              </MenuItem>
            ))}
          </Menu>
        )}
        {relatedTasks && relatedTasks?.length > 1 && <TaskNavigationBar relatedTasks={relatedTasks} />}
      </TaskHeader>
      <TaskBody isLoading={taskFormIsLoading}>
        <TaskDetailsDescription
          taskForm={taskForm}
          setDueDate={setDueDate}
          setDescription={setDescription}
          setTopicTitle={setTopicTitle}
          taskFormValidationMap={taskFormValidationMap}
        />
        <TaskDetailsAssignees
          taskForm={taskForm}
          setAssignees={setAssignees}
          allAssignees={allAssignees}
          access={linkAccess}
          matchedSsuIds={matchedSsuIds}
        />
        <Divider />
        <TaskDetailsComments taskForm={taskForm} setTaskCommentField={setTaskCommentField} />
        <Divider />
        <TaskDetailsPatientInfo taskForm={taskForm} />
        <Divider />
        <TaskDetailsUpdates taskForm={taskForm} />
      </TaskBody>
      <TaskFooter>
        <ButtonGroup>
          <Button variant="rounded-outlined" priority="medium" onClick={TasksSupervisor.close}>
            Cancel
          </Button>
          <Button variant="rounded" disabled={!isChanged} onClick={onSave}>
            Save
          </Button>
        </ButtonGroup>
      </TaskFooter>
    </TaskContainer>
  );

  function onSave() {
    const requestData = prepareCreateRequestData(taskForm);
    if (validateTask(requestData, setTaskFormValidationMap)) {
      saveTask(requestData);
    }
  }

  async function saveTask() {
    try {
      if (!isEmpty(taskForm.taskCommentField)) {
        await TaskApi.addComment(activeTask.taskId, activeTask.ssuId, taskForm.taskCommentField);
      }
      if (isChanged) {
        await TaskApi.update(prepareUpdateRequestData(taskForm, originTaskForm));
      }
      updateActiveTask({ updateTimestamp: +new Date() });
      NotificationManager.success('Task updated successfully!');
    } catch (error) {
      NotificationManager.error('This task cannot be edited because the study site selected for it is closed.');
    }
  }
}
