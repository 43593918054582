import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import { scBlue10, scGray85, scGreen10, scRed10, scYellow10 } from '../../constants/systemColors';
import { performPostponedNotifications } from '../../services/postponedNotifications';

import 'react-toastify/dist/ReactToastify.css';
import './NotificationContainer.scss';

export default function NotificationContainer() {
  useEffect(function() {
    performPostponedNotifications();
  }, []);

  return (
    <ToastContainer
      position="top-right"
      autoClose={4000}
      toastStyle={{
        '--font-family': 'sans-serif',
        '--toastify-color-success': scGreen10,
        '--toastify-color-info': scBlue10,
        '--toastify-color-warning': scYellow10,
        '--toastify-color-error': scRed10,
        color: scGray85
      }}
      theme="colored"
      hideProgressBar
      limit={3}
    />
  );
}
