import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash/function';
import { isEmpty, isEqual } from 'lodash/lang';
import { get, pick } from 'lodash/object';
import moment from 'moment-timezone';
import Collapse from 'rc-collapse';

import { CalendarInstanceApi, CalendarPageSupplierApi, EventFinderApi, TaskApi } from '../../../api';
import Common from '../../../common/common';
import ModalBoxes from '../../../common/feedback/ModalBoxes/ModalBoxes';
import FloatingPanel from '../../../common/fullScreenLayout/FloatingPanel';
import { ContentPanel, FullScreenLayout, NavigationPanel } from '../../../common/fullScreenLayout/FullScreenLayout';
import Button from '../../../common/general/Button';
import NotificationManager from '../../../common/notifications/NotificationManager';
import { EVENTS_DISPLAY } from '../../../constants/notificationMessages';
import { MANAGE_PATIENT_APPOINTMENTS, VIEW_TASKS_CALENDAR } from '../../../constants/userOperations';
import { userHasAccessTo } from '../../../services/auth';
import { onRequestError } from '../../../services/handlers';
import { getRoleDescriptionByUserRoles } from '../../../services/personnelService';
import { useActiveTask } from '../../../store/activeTask';
import { PageInfoHeader } from '../../PageInfoHeader/PageInfoHeader';
import { useCurrentUser } from '../../root/Container/CurrentUserContainer';
import { TASKS_TYPES } from '../../root/Container/Layout/Tasks/Task/taskConstants';
import TasksSupervisor from '../../root/Container/Layout/Tasks/TasksSupervisor/TasksSupervisor';
import { generateUrlByKey, useCurrentRoute } from '../../root/router';
import CancelAppointmentModal from '../patient-source/Patients/PatientInfo/EncountersSection/EncountersTab/EncountersTabContent/EncounterSegment/CancelAppointmentModal/CancelAppointmentModal';

import AppointmentView from './AppointmentView/AppointmentView';
import { ViewAppointmentButtons } from './AppointmentView/ViewAppointmentButtons';
import CalendarBig from './CalendarBig/CalendarBig';
import { prepareEvents, prepareTimeZones } from './CalendarBig/CalendarBigService';
import { Toolbar } from './CalendarBig/Components/Toolbar';
import CalendarDateTimePicker from './CalendarDateTimePicker/CalendarDateTimePicker';
import { CalendarAppointmentFilter } from './form-components/CalendarAppointmentFilter';
import { CalendarSelect } from './form-components/CalendarsSelector';
import { CalendarStatusFilter } from './form-components/CalendarStatusFilter';
import { MilestoneEventTypeSelector } from './form-components/MilestoneEventTypeSelector';
import { AddAppointmentButtons } from './PatientAppointmentEdit/AddAppointmentButtons';
import { EditAppointmentButtons } from './PatientAppointmentEdit/EditAppointmentButtons';
import AppointmentEdit from './AppointmentEdit';
import {
  APPOINTMENT_TYPES,
  NON_PATIENT_EVENT,
  PATIENT_ENCOUNTER_EVENT,
  PATIENT_SITUATIONAL_ENCOUNTER_EVENT
} from './CalendarEventType';
import { CalendarPageContext, CalendarPageContextProvider } from './CalendarPageContext';
import { getInitialCalendars, setInitialCalendars } from './CalendarsProvider';
import { toTimeZone, toTimeZoneWithoutNormalizingToCurrent } from './CalendarTimeZoneService';
import { buildSiteCalendarBasedOnEvent, daysToEndOfWeek, weekOfDate } from './CalendarUtils';
import { buildDraftPatientEvent, DRAFT_EVENT_ID, draftEventProvider, isDraft } from './DraftEventProvider';
import { eventCRUDService } from './EventCRUDService';
import { fromBackendCalendars, fromBackendModel, toBackendCalendar } from './EventTransformer';
import {
  getInitialTimeZone,
  setInitialEventStatus,
  setInitialEventTypes,
  setInitialMilestoneTypes,
  setInitialNonPatientEventTypes,
  setInitialTimeZone
} from './FiltersProvider';
import WithMilestoneEvents from './WithMilestoneEvents';

import './CalendarPage.scss';

const CalendarDatePickerWithMilestoneEvents = WithMilestoneEvents(CalendarDateTimePicker);

function moveEvent(event, start, duration) {
  return { ...event, start, duration };
}

function retrievePatientEncounterTimeRange(sitePatientId, encounterId) {
  return !sitePatientId || !encounterId
    ? Promise.resolve(undefined)
    : CalendarPageSupplierApi.timeRange(sitePatientId, encounterId).then(response => response.data);
}

function dateHasChanged(event, previousEvent) {
  const currentDate = event.start.clone().startOf('day');
  const previousDate = previousEvent.start.clone().startOf('day');
  return !currentDate.isSame(previousDate);
}

function changeOrganizer(event, currentUser) {
  return {
    ...event,
    organizer: {
      userId: currentUser.personnelIdentifier,
      type: 'USER',
      label: getRoleDescriptionByUserRoles(currentUser.roles)
    }
  };
}
const requestDateFormat = 'YYYY-MM-DDTHH:mm:ss.000Z';

export const ADD_APPOINTMENT = 'ADD_APPOINTMENT';
export const VIEW_APPOINTMENT = 'VIEW_APPOINTMENT';
export const EDIT_APPOINTMENT = 'EDIT_APPOINTMENT';

function CalendarPageComponent({ encounterId, sitePatientId, encounterEventType }) {
  const store = useStore().getState();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const CalendarContext = useContext(CalendarPageContext);
  const { storedEvent, resetStoredEvent } = CalendarContext;

  const [displayedTimeRange, setDisplayedTimeRange] = useState(storedEvent?.displayedTimeRange || weekOfDate(moment()));
  const [displayedDate, setDisplayedDate] = useState(storedEvent?.displayedDate || moment());

  const [calendars, setCalendars] = useState(null);
  const [filteredEventTypes, setFilteredEventTypes] = useState([]);
  const [filteredNonPatientEventTypes, setFilteredNonPatientEventTypes] = useState([]);
  const [filteredEventStatuses, setFilteredEventStatuses] = useState([]);
  const [filteredMilestoneEventTypes, setFilteredMilestoneEventTypes] = useState([]);
  const [filteredUserCalendars, setFilteredUserCalendars] = useState(null);
  const [encounterTimeRange, setEncounterTimeRange] = useState();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [calendarPreviewFilter, setCalendarPreviewFilter] = useState(null);
  const [appointmentPanelMode, setAppointmentPanelMode] = useState('NONE');
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [preparedCalendars, setPreparedCalendars] = useState([]);
  const [preparedTimeZones, setPreparedTimeZones] = useState([]);
  const userPreferences = useSelector(({ userPreferences }) => userPreferences);
  const currentRoute = useCurrentRoute();

  const activeTask = useActiveTask();

  useEffect(() => {
    CalendarInstanceApi.getAvailableSiteTimeZones().then(res => {
      const siteTimeZones = processSiteTimeZones(res.data);
      const savedTimeZone = getInitialTimeZone();
      const timeZone = prepareTimeZones(siteTimeZones).filter(e => e.id === savedTimeZone?.id);
      if (savedTimeZone && !isEmpty(timeZone)) {
        setSelectedTimeZone(timeZone[0]);
      } else {
        setSelectedTimeZone(prepareTimeZones(siteTimeZones)[0]);
      }
      setPreparedTimeZones(prepareTimeZones(siteTimeZones));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedTimeZone) {
      setPreparedCalendars(prepareEvents(calendars));
    } else {
      const calendarWithSelectedTimezone = updateCalendarDueSelectedTimeZone(
        selectedTimeZone,
        prepareEvents(calendars)
      );
      setPreparedCalendars(calendarWithSelectedTimezone);
      if (
        selectedEvent?.eventTimezone &&
        selectedTimeZone.timeZoneNameToDisplay !== selectedEvent.timeZoneNameToDisplay
      ) {
        setSelectedEvent({
          ...selectedEvent,
          start: toTimeZone(
            selectedEvent.start,
            selectedEvent.eventTimezone,
            selectedTimeZone.timeZoneId,
            preparedTimeZones[0].timeZoneId
          ),
          end: toTimeZone(
            selectedEvent.end,
            selectedEvent.eventTimezone,
            selectedTimeZone.timeZoneId,
            preparedTimeZones[0].timeZoneId
          ),
          eventTimezone: selectedTimeZone.timeZoneId
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendars, selectedTimeZone?.timeZoneNameToDisplay]);

  useEffect(() => {
    if (appointmentPanelMode === 'EDIT') {
      if (selectedEvent.siteName) {
        const studyTimeZone = preparedTimeZones.filter(e => e.siteName === selectedEvent.siteName);
        setSelectedTimeZone(studyTimeZone[0]);
      } else {
        const studyTimeZone = preparedTimeZones.filter(e => e.id === selectedEvent.studySite.site.id);
        setSelectedTimeZone(studyTimeZone[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentPanelMode, selectedEvent?.siteName]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedTimeZone?.timeZoneId) {
        setSelectedTimeZone({
          ...selectedTimeZone
        });
      }
    }, 60000);
    if (selectedTimeZone) {
      setInitialTimeZone(selectedTimeZone);
    }
    return () => clearInterval(interval);
  }, [selectedTimeZone]);

  useEffect(() => {
    if (sitePatientId && encounterId) {
      buildDraftPatientEvent(currentUser, sitePatientId, encounterId, draftEventProvider(), encounterEventType).then(
        draft => {
          setSelectedEvent(draft);
          setAppointmentPanelMode(ADD_APPOINTMENT);
        }
      );
    }
  }, [sitePatientId, encounterId, currentUser, encounterEventType]);

  const selectedEncounterId = selectedEvent?.encounter?.id;
  const selectedSitePatientId = selectedEvent?.sitePatientId;

  useEffect(() => {
    let canceled = false;
    if (selectedSitePatientId && selectedEncounterId && selectedEvent?.type !== PATIENT_SITUATIONAL_ENCOUNTER_EVENT) {
      retrievePatientEncounterTimeRange(selectedSitePatientId, selectedEncounterId).then(timeRange => {
        if (canceled) {
          return;
        }
        setEncounterTimeRange(timeRange);
      });
    } else {
      setEncounterTimeRange(undefined);
    }
    return function() {
      canceled = true;
    };
  }, [selectedSitePatientId, selectedEncounterId, selectedEvent?.type]);

  function getChecked(collection) {
    return collection ? collection.filter(e => e.checked) : [];
  }

  function getCheckedCodes(collection) {
    return getChecked(collection).map(e => e.code);
  }

  const updateSelectedEventsDueSelectedTimeZone = (currentTimeZone, newTimeZone) => {
    setSelectedEvent({
      ...selectedEvent,
      start: toTimeZoneWithoutNormalizingToCurrent(selectedEvent.start, currentTimeZone, newTimeZone)
    });
  };

  const loadCalendars = displayedTimeRange => calendars => {
    const eventTypes = getCheckedCodes(filteredEventTypes);
    const nonPatientEventTypes = getCheckedCodes(filteredNonPatientEventTypes);
    const eventStatuses = getCheckedCodes(filteredEventStatuses);

    const calendarsForRequest = calendars.map(toBackendCalendar);
    !isEmpty(nonPatientEventTypes) && eventTypes.push(NON_PATIENT_EVENT);

    const baseRequest = {
      calendars: calendarsForRequest,
      startDate: displayedTimeRange.start.format(requestDateFormat),
      endDate: displayedTimeRange.end.format(requestDateFormat),
      eventStatuses
    };
    const milestoneRequest = {
      ...baseRequest,
      calendars: calendarsForRequest.filter(calendar => calendar.type !== 'USER'),
      milestoneTypes: getCheckedCodes(filteredMilestoneEventTypes)
    };
    const requests = [
      EventFinderApi.getEventsByFilterParams({ ...baseRequest, eventTypes, nonPatientEventTypes }, moment.tz.guess()),
      EventFinderApi.getMilestonesByFilterParams(milestoneRequest)
    ];
    Common.showLoader();
    if (userHasAccessTo(VIEW_TASKS_CALENDAR)) {
      requests.push(TaskApi.getTasksForCalendarView({ ...baseRequest }));
      return Promise.all(requests).then(
        ([{ data: calendars }, { data: milestoneCalendars }, { data: tasksCalendars }]) => {
          Common.hideLoader();
          calendars.forEach(calendar => {
            const taskCalendar = tasksCalendars.find(e => e.id === calendar.id);
            const milestoneCalendar = milestoneCalendars.find(e => e.id === calendar.id);
            calendar.tasks = taskCalendar.tasks;
            calendar.milestones = milestoneCalendar ? milestoneCalendar.events : [];
          });
          setCalendars(fromBackendCalendars(calendars));
        }
      );
    } else {
      return Promise.all(requests).then(([{ data: calendars }, { data: milestoneCalendars }]) => {
        Common.hideLoader();
        calendars.forEach(calendar => {
          const milestoneCalendar = milestoneCalendars.find(e => e.id === calendar.id);
          calendar.milestones = milestoneCalendar ? milestoneCalendar.events : [];
        });
        setCalendars(fromBackendCalendars(calendars));
      });
    }
  };

  const applyFilter = () => {
    setInitialEventTypes(getCheckedCodes(filteredEventTypes));
    setInitialNonPatientEventTypes(getCheckedCodes(filteredNonPatientEventTypes));
    setInitialEventStatus(getCheckedCodes(filteredEventStatuses));
    setInitialMilestoneTypes(getCheckedCodes(filteredMilestoneEventTypes));
    setCalendarPreviewFilter({
      milestoneTypes: getCheckedCodes(filteredMilestoneEventTypes),
      eventStatuses: getCheckedCodes(filteredEventStatuses)
    });
    loadCalendars(displayedTimeRange)(filteredUserCalendars.filter(e => e.checked));
  };

  const resetFilter = () => {
    const checkAll = collection => {
      collection.forEach(e => (e.checked = true));
      return collection;
    };
    setFilteredEventStatuses(checkAll(filteredEventStatuses));
    setFilteredNonPatientEventTypes(checkAll(filteredNonPatientEventTypes));
    setFilteredEventTypes(checkAll(filteredEventTypes));
    setFilteredMilestoneEventTypes(checkAll(filteredMilestoneEventTypes));
    setCalendarPreviewFilter({
      milestoneTypes: checkAll(filteredMilestoneEventTypes),
      eventStatuses: checkAll(filteredEventStatuses)
    });
    const calendars = checkAll([...filteredUserCalendars]);
    setFilteredUserCalendars(calendars);
    setInitialCalendars(calendars);
    applyFilter();
  };

  const onView = view => {
    const displayedTimeRange =
      view === 'agenda' ? daysToEndOfWeek(moment(displayedDate)) : weekOfDate(moment(displayedDate));
    loadCalendars(displayedTimeRange)(calendars);
  };

  const onDateChange = currentDate => {
    setDisplayedDate(moment(currentDate));
    const calendarView = get(userPreferences, 'view');
    const displayedTimeRange =
      calendarView === 'agenda' ? daysToEndOfWeek(moment(currentDate)) : weekOfDate(moment(currentDate));
    setDisplayedTimeRange(displayedTimeRange);
    loadCalendars(displayedTimeRange)(calendars);
  };

  //this debouce is needed to prevent full calendar update on typing in text fields on appoinment edit dialog
  //full update on each key press make typing to slow
  //now all changes in appointment edit dialogs popup to calendar in bulks
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCurrentEvent = useCallback(
    debounce(updatedEvent => {
      setSelectedEvent(updatedEvent);
    }, 200),
    []
  );

  const mergeEvents = (event, newEvent) => {
    const { calendarId, calendarIndex } = event;
    event.eventLoaded = true;
    return {
      ...fromBackendModel(newEvent, calendarId, calendarIndex),
      ...pick(event, ['start', 'end', 'duration', 'id', 'color', 'customStyle', 'title', 'eventLoaded'])
    };
  };

  const editEvent = (event, previousEvent) => {
    TasksSupervisor.setHidden(true);
    if (event.id === DRAFT_EVENT_ID || (selectedEvent && selectedEvent.id === event.id)) {
      if (dateHasChanged(event, previousEvent)) {
        const eventWithNewOrganizer = changeOrganizer(event, currentUser);
        setSelectedEvent(eventWithNewOrganizer);
      } else {
        setSelectedEvent(event);
      }
      setAppointmentPanelMode(isDraft(event) ? ADD_APPOINTMENT : EDIT_APPOINTMENT);
    } else {
      EventFinderApi.getEventDetails(event.eventId).then(({ data }) => {
        event = mergeEvents(event, data);
        if (dateHasChanged(event, previousEvent)) {
          const eventWithNewOrganizer = changeOrganizer(event, currentUser);
          setSelectedEvent(eventWithNewOrganizer);
        } else {
          setSelectedEvent(event);
        }
        setAppointmentPanelMode(isDraft(event) ? ADD_APPOINTMENT : EDIT_APPOINTMENT);
      });
    }
  };

  useEffect(
    function() {
      if (activeTask.isClosed && selectedEvent && TASKS_TYPES.includes(selectedEvent?.type)) {
        setSelectedEvent(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTask.isClosed]
  );

  const showEvent = event => {
    if (appointmentPanelMode === EDIT_APPOINTMENT) {
      setAppointmentPanelMode(VIEW_APPOINTMENT);
    }
    if (TASKS_TYPES.includes(event.type)) {
      setSelectedEvent(event);
      setAppointmentPanelMode('NONE');
      TasksSupervisor.open(event.taskId, event.studySiteId);
    } else {
      TasksSupervisor.setHidden(true);
      if (APPOINTMENT_TYPES.includes(event.type)) {
        EventFinderApi.getEventDetails(event.eventId).then(({ data }) => {
          event = mergeEvents(event, data);
          setSelectedEvent(event);
          setAppointmentPanelMode(isDraft(event) ? ADD_APPOINTMENT : VIEW_APPOINTMENT);
        });
      } else {
        setSelectedEvent(event);
        setAppointmentPanelMode(isDraft(event) ? ADD_APPOINTMENT : VIEW_APPOINTMENT);
      }
    }
  };
  const addEvent = async (start, duration) => {
    TasksSupervisor.setHidden(true);
    const draftEventProvider =
      !selectedEvent || !isDraft(selectedEvent)
        ? await buildDraftPatientEvent(
            currentUser,
            undefined,
            undefined,
            () => ({
              start,
              duration
            }),
            userHasAccessTo(MANAGE_PATIENT_APPOINTMENTS) ? PATIENT_ENCOUNTER_EVENT : NON_PATIENT_EVENT
          )
        : moveEvent(selectedEvent, start, duration);

    setSelectedEvent(draftEventProvider);
    setAppointmentPanelMode(ADD_APPOINTMENT);
  };

  const createEvent = event => {
    const normalizedTime = toTimeZoneWithoutNormalizingToCurrent(
      event.start,
      selectedTimeZone?.timeZoneId,
      preparedTimeZones[0].timeZoneId
    );
    event = { ...event, start: normalizedTime };
    return eventCRUDService
      .create(event)
      .then(() => {
        if (storedEvent?.sourcePage) {
          resetStoredEvent();
          setTimeout(() => navigate(generateUrlByKey(storedEvent.sourcePage, currentRoute.params)));
        } else {
          loadCalendars(displayedTimeRange)(calendars).then(deselectEvent);
        }
      })
      .catch(onRequestError);
  };

  function deselectEvent() {
    setAppointmentPanelMode('NONE');
    setSelectedEvent(undefined);
  }

  const updateEvent = event => {
    const normalizedTime = toTimeZoneWithoutNormalizingToCurrent(
      event.start,
      selectedTimeZone?.timeZoneId,
      preparedTimeZones[0].timeZoneId
    );
    event = { ...event, start: normalizedTime };
    return eventCRUDService.update(event).then(() => {
      if (storedEvent?.eventId && storedEvent?.sourcePage) {
        resetStoredEvent();
        setTimeout(() => navigate(generateUrlByKey(storedEvent.sourcePage, currentRoute.params)));
      } else {
        loadCalendars(displayedTimeRange)(calendars).then(deselectEvent);
      }
    });
  };

  const cancelEvent = () => {
    ModalBoxes.open({
      component: (
        <CancelAppointmentModal
          event={{ id: selectedEvent.eventId, type: selectedEvent.type, ssuPatientId: selectedEvent.sitePatientId }}
          onSuccess={() => loadCalendars(displayedTimeRange)(calendars).then(deselectEvent)}
        />
      )
    });
  };

  const stopEditing = () => {
    if (storedEvent?.eventId || storedEvent?.sourcePage) {
      resetStoredEvent();
      setTimeout(() => navigate(generateUrlByKey(storedEvent.sourcePage, currentRoute.params)));
    } else {
      setCalendars([...calendars]);
      deselectEvent();
    }
  };

  const onEventsFilterChange = filter => {
    setFilteredEventTypes(filter.types);
    setFilteredNonPatientEventTypes(filter.nonPatientEventTypes);
  };

  const userCalendarsCriteriaCount = useMemo(
    function() {
      return filteredUserCalendars?.filter(e => e.checked).length;
    },
    [filteredUserCalendars]
  );

  const formatFilterCount = filterCount => {
    if (filterCount === 0) {
      return '–';
    }
    return filterCount;
  };

  useEffect(() => {
    if (
      !isEmpty(filteredEventStatuses) &&
      !isEmpty(filteredEventTypes) &&
      !isEmpty(filteredNonPatientEventTypes) &&
      !isEmpty(filteredUserCalendars) &&
      !isEmpty(filteredMilestoneEventTypes) &&
      !calendars
    ) {
      setCalendarPreviewFilter({
        milestoneTypes: getCheckedCodes(filteredMilestoneEventTypes),
        eventStatuses: getCheckedCodes(filteredEventStatuses)
      });
      const checkedCalendars = getChecked(filteredUserCalendars);
      loadCalendars(displayedTimeRange)(checkedCalendars);
    }
    //Found this as the only way to trigger loadCalendars if filter options were changed.
    // eslint-disable-next-line
  }, [
    filteredEventTypes,
    filteredEventStatuses,
    filteredNonPatientEventTypes,
    filteredUserCalendars,
    filteredMilestoneEventTypes
  ]);

  useEffect(
    function() {
      if (storedEvent?.eventId && !isEmpty(preparedCalendars)) {
        let eventToSelect = preparedCalendars.find(e => e.eventId === storedEvent.eventId);
        EventFinderApi.getEventDetails(storedEvent.eventId).then(({ data }) => {
          if (eventToSelect) {
            eventToSelect = mergeEvents(eventToSelect, data);
          } else {
            eventToSelect = fromBackendModel(data, storedEvent.siteId, 0);
          }
          eventToSelect.start = moment(eventToSelect.start);
          setSelectedEvent(eventToSelect);
          setAppointmentPanelMode(EDIT_APPOINTMENT);
          resetStoredEvent();
        });
      }
    },
    [preparedCalendars, storedEvent?.eventId, storedEvent?.siteId, resetStoredEvent]
  );

  return (
    <>
      <PageInfoHeader
        className="calendar-page-info-header"
        pageInfo={
          <Toolbar
            e={{
              view: get(userPreferences, 'view'),
              date: displayedDate,
              onView: e => {
                onView(e);
              },
              onNavigate: e => {
                let view = get(userPreferences, 'view');
                let dateRange = view === 'day' ? view : 'week';
                let newDate = displayedDate.clone();
                if (e === 'PREV') {
                  newDate.subtract(1, dateRange);
                }

                if (e === 'NEXT') {
                  newDate.add(1, dateRange);
                }
                if (e === 'TODAY') {
                  newDate = moment();
                }

                onDateChange(newDate);
              }
            }}
            getTimeZoneName={getTimeZoneName}
            selectedTimeZone={selectedTimeZone}
            siteTimeZones={preparedTimeZones}
            onChangeTimeZone={timeZone => {
              if (timeZone) {
                setSelectedTimeZone(timeZone);
                if (selectedTimeZone?.timeZoneNameToDisplay !== timeZone?.timeZoneNameToDisplay) {
                  NotificationManager.success(`${timeZone.timeZoneNameToDisplay}`);
                }
              } else {
                setSelectedTimeZone(preparedTimeZones[0]);
              }
            }}
          />
        }
      />
      <FullScreenLayout className="calendar-page">
        <NavigationPanel>
          <Collapse
            expandIcon={({ isActive }) => (
              <span className="rc-collapse-header-expand-icon material-icons">
                {`keyboard_arrow_${isActive ? 'up' : 'down'}`}
              </span>
            )}
            defaultActiveKey={[1]}
            className="calendar-filter-collapse"
          >
            <div className="calendar-date-picker-container">
              <CalendarDatePickerWithMilestoneEvents
                onChange={onDateChange}
                highlightPeriod={encounterTimeRange}
                initialDate={displayedDate}
                inline
                eventStatuses={calendarPreviewFilter?.eventStatuses || null}
                calendars={filteredUserCalendars ? filteredUserCalendars.map(toBackendCalendar) : []}
                milestoneTypes={calendarPreviewFilter?.milestoneTypes || null}
              />
            </div>
            <Collapse.Panel
              header={panelHeaderWithCount('CALENDARS', userCalendarsCriteriaCount)}
              key="3"
              forceRender={true}
            >
              <div className="filter-container pb-3 mt-2">
                <CalendarSelect
                  onCalendarsChanged={calendars => {
                    if (!isEqual(filteredUserCalendars, calendars)) {
                      setFilteredUserCalendars(calendars);
                      !storedEvent && setInitialCalendars(calendars);
                    }
                  }}
                  initialCalendarsProvider={
                    storedEvent ? () => [buildSiteCalendarBasedOnEvent(storedEvent)] : getInitialCalendars
                  }
                />
              </div>
            </Collapse.Panel>
            <Collapse.Panel
              header={panelHeaderWithCount('STATUS', getChecked(filteredEventStatuses).length)}
              key="2"
              forceRender={true}
            >
              <CalendarStatusFilter onChange={setFilteredEventStatuses} />
            </Collapse.Panel>
            <Collapse.Panel
              header={panelHeaderWithCount(
                'APPOINTMENTS',
                getChecked(filteredNonPatientEventTypes).length + getChecked(filteredEventTypes).length
              )}
              key="4"
              forceRender={true}
            >
              <CalendarAppointmentFilter onChange={onEventsFilterChange} />
            </Collapse.Panel>
            <Collapse.Panel
              header={panelHeaderWithCount('MILESTONES', getChecked(filteredMilestoneEventTypes).length)}
              key="5"
              forceRender={true}
            >
              <MilestoneEventTypeSelector onChange={setFilteredMilestoneEventTypes} />
            </Collapse.Panel>
          </Collapse>
          <div className="filter-buttons">
            <Button priority={'medium'} size={'h56'} onClick={resetFilter}>
              Reset
            </Button>
            <Button priority={'high'} size={'h56'} onClick={applyFilter}>
              Apply
            </Button>
          </div>
        </NavigationPanel>
        <FloatingPanel show={appointmentPanelMode !== 'NONE'} close={stopEditing}>
          {appointmentPanelMode === ADD_APPOINTMENT && (
            <AppointmentEdit
              mode={ADD_APPOINTMENT}
              initialAppointment={selectedEvent}
              timeRange={encounterTimeRange}
              appointmentChanged={updateCurrentEvent}
              selectedTimeZone={selectedTimeZone}
              timeZones={preparedTimeZones}
              changeSelectedTimeZone={setSelectedTimeZone}
              updateSelectedEventsDueSelectedTimeZone={updateSelectedEventsDueSelectedTimeZone}
            >
              <AddAppointmentButtons onSave={createEvent} onClose={stopEditing} />
            </AppointmentEdit>
          )}
          {appointmentPanelMode === EDIT_APPOINTMENT && (
            <AppointmentEdit
              mode={EDIT_APPOINTMENT}
              initialAppointment={selectedEvent}
              timeRange={encounterTimeRange}
              appointmentChanged={updateCurrentEvent}
              selectedTimeZone={selectedTimeZone}
              eventId={storedEvent?.eventId}
              timeZones={preparedTimeZones}
              changeSelectedTimeZone={setSelectedTimeZone}
              updateSelectedEventsDueSelectedTimeZone={updateSelectedEventsDueSelectedTimeZone}
            >
              <EditAppointmentButtons onSave={updateEvent} onClose={stopEditing} />
            </AppointmentEdit>
          )}
          {appointmentPanelMode === VIEW_APPOINTMENT && (
            <AppointmentView initialAppointment={selectedEvent}>
              <ViewAppointmentButtons
                onEdit={() => setAppointmentPanelMode(EDIT_APPOINTMENT)}
                onCancel={cancelEvent}
                event={selectedEvent}
              />
            </AppointmentView>
          )}
        </FloatingPanel>
        <ContentPanel
          showPanelSpace={
            (selectedEvent && APPOINTMENT_TYPES.includes(selectedEvent.type)) ||
            (!activeTask.isClosed && !activeTask.isHidden)
          }
        >
          <CalendarBig
            timeRange={encounterTimeRange}
            calendars={preparedCalendars}
            appointmentPanelMode={appointmentPanelMode}
            newEvent={addEvent}
            onNavigate={onDateChange}
            eventDrop={editEvent}
            onView={onView}
            moveEvent={editEvent}
            resizeEvent={editEvent}
            onSelectEvent={showEvent}
            selectedEvent={selectedEvent}
            displayedDate={displayedDate}
            siteTimeZones={preparedTimeZones}
            onChangeTimeZone={timeZone => {
              if (timeZone) {
                setSelectedTimeZone(timeZone);
                if (selectedTimeZone?.timeZoneNameToDisplay !== timeZone?.timeZoneNameToDisplay) {
                  NotificationManager.success(`${EVENTS_DISPLAY} ${timeZone.timeZoneNameToDisplay}`);
                }
              } else {
                setSelectedTimeZone(preparedTimeZones[0]);
              }
            }}
            selectedTimeZone={selectedTimeZone}
            userPreferences={get(store, 'userPreferences')}
          />
        </ContentPanel>
      </FullScreenLayout>
    </>
  );

  function getTimeZoneName(siteTimeZone) {
    return `${siteTimeZone?.timeZoneNameToDisplay} - ${getActualTimeByTimeZone(siteTimeZone)}`;
  }

  function getActualTimeByTimeZone(timeZone) {
    if (timeZone?.timeZoneIdValid) {
      return moment()
        .tz(timeZone?.timeZoneId)
        .format('h:mm A');
    }
    return '';
  }

  function panelHeaderWithCount(title, count) {
    return (
      <React.Fragment>
        <span>{title}</span>
        <span className="count">{formatFilterCount(count)}</span>
      </React.Fragment>
    );
  }
}

export default function CalendarPage(props) {
  return (
    <CalendarPageContextProvider>
      <CalendarPageComponent {...props} />
    </CalendarPageContextProvider>
  );
}

export function processSiteTimeZones(siteTimeZones) {
  return siteTimeZones?.map(siteTimeZone => {
    return {
      ...siteTimeZone,
      timeZoneNameToDisplay: siteTimeZone.timeZoneName?.replace(/Standard Time/, '')
    };
  });
}

export const updateCalendarDueSelectedTimeZone = (timeZone, events) => {
  return events.map(e => {
    if (APPOINTMENT_TYPES.includes(e.type)) {
      e.start = new Date(moment.tz(moment(e.originStart), timeZone?.timeZoneId).format('YYYY-MM-DDTHH:mm:ss'));
      e.end = new Date(moment.tz(moment(e.originEnd), timeZone?.timeZoneId).format('YYYY-MM-DDTHH:mm:ss'));
      e.eventTimezone = timeZone.timeZoneId;
    }
    return e;
  });
};
