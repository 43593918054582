import { ROLE_CRA, ROLE_EXTERNAL_AUDITOR } from '../../../../../../../../constants/userRoles';
import { userHasRole } from '../../../../../../../../services/auth';

export default function TaskCreatePopulatedSection({ taskForm, isPopulated }) {
  const userCraOrAuditor = userHasRole([ROLE_CRA, ROLE_EXTERNAL_AUDITOR]);
  const {
    patientFirstName,
    patientLastName,
    patientSubjectId,
    study,
    site,
    protocolEncounter,
    protocolItemGroup
  } = taskForm;
  return (
    <div className="etc-body-details">
      <div className="etc-body-details-block">
        <div className="etc-body-details-block-label">{userCraOrAuditor ? 'Subject ID' : 'Patient'}</div>
        <div className="etc-body-details-block-value">
          {userCraOrAuditor ? patientSubjectId || 'No Subject ID' : `${patientFirstName} ${patientLastName}`}
        </div>
      </div>
      <div className="etc-body-details-block">
        <div className="etc-body-details-block-label">Study</div>
        <div className="etc-body-details-block-value">{study?.name}</div>
      </div>
      <div className="etc-body-details-block">
        <div className="etc-body-details-block-label">Site</div>
        <div className="etc-body-details-block-value">{site?.name}</div>
      </div>
      {isPopulated.encounter && (
        <div className="etc-body-details-block">
          <div className="etc-body-details-block-label">Encounter</div>
          <div className="etc-body-details-block-value">{protocolEncounter}</div>
        </div>
      )}
      {isPopulated.itemGroup && (
        <div className="etc-body-details-block">
          <div className="etc-body-details-block-label">Procedure / Logs</div>
          <div className="etc-body-details-block-value">{protocolItemGroup}</div>
        </div>
      )}
    </div>
  );
}
