import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { isEmpty, omit } from 'lodash';
import { cloneDeep, isEqual } from 'lodash/lang';

import { FinBudgetFixedItemApi } from '../../../../../../api';
import Loader from '../../../../../../common/elements/Loader/Loader';
import { MANAGE_FIXED_ITEMS } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';
import { onRequestError } from '../../../../../../services/handlers';
import { CommentSection } from '../../ExpenseFixedList/FullFixedItemInformation/CommentSection/CommentSection';
import { ExpenseInfoItem } from '../../ExpenseFixedList/FullFixedItemInformation/ExpenseInfoItem/ExpenseInfoItem';
import { getNormalizedDate } from '../../ExpensesServices';
import { ExpenseVariableUploadedFiles } from '../AddExpenseVariable/ExpenceVariableUploadedFiles/ExpenseVariableUploadedFiles';
import { ExpenseVariableAmountSection } from '../AddExpenseVariable/ExpenseVariableAmountSection/ExpenseVariableAmountSection';

export const VariableExpenseDetails = ({
  selectedVariableExpenseId,
  setUpdatedExpenseVariableData,
  setIsExpenseWasUpdated,
  selectedSSU
}) => {
  const [expenseVariableData, setExpenseVariableData] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [addCommentMode, setAddCommentMode] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [initialFiles, setInitialFies] = useState([]);

  useEffect(() => {
    if (selectedVariableExpenseId) {
      FinBudgetFixedItemApi.getExpenseVariableDetails(selectedVariableExpenseId, selectedSSU.ssuIdentifier).then(
        ({ data }) => {
          const files = data.files.map(file => ({
            contentForPreview: file.content,
            ...file
          }));
          setExpenseVariableData(omit(data, 'files'));
          setUploadedFiles(files);
          setInitialFies(cloneDeep(files));
        },
        onRequestError
      );
    }
  }, [selectedVariableExpenseId, selectedSSU.ssuIdentifier]);

  useEffect(() => {
    const normalizedFiles = uploadedFiles.map(file => {
      return file.fileData ? omit(file, ['id', 'contentForPreview']) : omit(file, ['contentForPreview']);
    });
    setUpdatedExpenseVariableData({ newComment: newComment.trim(), uploadedFiles: normalizedFiles });
    setIsExpenseWasUpdated(!!newComment.trim() || !isEqual(initialFiles, uploadedFiles));
  }, [initialFiles, newComment, setIsExpenseWasUpdated, setUpdatedExpenseVariableData, uploadedFiles]);

  if (isEmpty(expenseVariableData)) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Loader dark={true} />
      </Box>
    );
  }

  return (
    <>
      <CommentSection
        comments={expenseVariableData?.comments}
        setEditedComment={setNewComment}
        editedComment={newComment}
        editCommentMode={addCommentMode}
        setEditCommentMode={setAddCommentMode}
      />
      <ExpenseInfoItem label="Event Date" text={getNormalizedDate(expenseVariableData?.eventDate)} />
      <ExpenseInfoItem label="Revenue Date" text={getNormalizedDate(expenseVariableData?.revenueDate)} />
      <ExpenseInfoItem label="Variable Expense - Event Name" text={expenseVariableData?.eventName || 'N/A'} />
      {userHasAccessTo(MANAGE_FIXED_ITEMS) && (
        <ExpenseVariableAmountSection
          sx={{ marginBottom: '12px' }}
          amount={expenseVariableData?.clientAmount}
          maximumEventAmount={expenseVariableData?.maxAmount}
          totalAmount={expenseVariableData?.totalAmount}
          variance={expenseVariableData?.varianceAmount}
        />
      )}
      <ExpenseInfoItem label="Status" text={expenseVariableData?.eventStatus || 'N/A'} />
      <ExpenseInfoItem label="Added By" text={expenseVariableData?.addedBy || 'N/A'} />
      {userHasAccessTo(MANAGE_FIXED_ITEMS) && (
        <ExpenseVariableUploadedFiles
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          ssuId={selectedSSU.ssuIdentifier}
        />
      )}
    </>
  );
};
