import * as yup from 'yup';

import { RBIT_MILEAGE } from '../../EncountersSection/PaymentsSection/reimbursementConstants';

const addressSchema = yup.object({
  type: yup.string('Choose address type').required('Address type is required'),
  countryId: yup.string('Choose country').required('Country is required'),
  regionId: yup.string('Choose region').required(' is required'),
  city: yup.string('Choose city').required('City is required'),
  address1: yup.string('Choose address 1').required('Address 1 is required'),
  address2: yup.string('Enter address 2'),
  zipCode: yup.string('Enter Zip Code').required('Zip Code is required')
});

export const validationSchema = yup.object({
  note: yup.string('Enter note'),
  amount: yup
    .number('Enter amount')
    .positive('The amount should only be positive number')
    .required('Amount is required'),
  type: yup.string('Enter type').required('Type is required'),
  encounterDate: yup.date('Enter Encounter Date').required('Encounter Date is required'),
  encounter: yup.object().required('Encounter is required'),
  files: yup.array().when('type', {
    is: RBIT_MILEAGE,
    then: schema => schema.min(0),
    otherwise: schema => schema.min(1, 'Files are required')
  }),
  mileageAddresses: yup.object({}).when('type', {
    is: RBIT_MILEAGE,
    then: schema =>
      schema.shape({
        travelDate: yup.date('Enter Travel Date').required('Travel Date is required'),
        calculatedDistance: yup.number('Enter Travel Date'),
        distance: yup.number('Enter Distance').required('Distance is required'),
        roundTrip: yup.boolean('Enter Round Trip'),
        startAddress: addressSchema,
        endAddress: addressSchema
      })
  })
});
