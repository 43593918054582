import React from 'react';

import Checkbox from '../../../../../../common/data-entry/InputSelectors/Checkbox';
import { BUDGET_EVENT_NAME_LENGTH } from '../budgetConstant';

import AmountInput from './help-component/AmountInput';
import ExpenseRadioInputs from './help-component/ExpenseRadioInputs';
import { StatusChangeRows } from './help-component/StatusChangeRows';

import './BudgetEventFrom.scss';

export const UpdatedBudgetEventForm = ({
  isEventRanges,
  isEdit,

  name,
  overhead,
  withholding,
  managementFee,
  managementFeePercent,
  finVendors,
  selectedVendorId,
  budgetEventType,
  onChangeFormControls,

  eventRows,
  maxCount,
  onChangeEventRow,
  handleAddEventRow,
  handleRemoveEventRow,
  showOverlapsErrorMsg,

  clientAmount,
  siteAmount,
  siteAmountType,
  vendorAmount,
  vendorAmountType,

  patientAmount,
  patientAmountType,
  currentStudyBudget,
  triggerName
}) => {
  const isEditingForbidden = currentStudyBudget && !currentStudyBudget.editPossibility;
  return (
    <div className="form-row py-3">
      {isEdit() && (
        <div className="form-group col-12">
          <label htmlFor="bem-event">Protocol Event</label>
          <input id="bem-event" className="form-control" disabled value={triggerName} />
        </div>
      )}
      <div className="form-group col-12">
        <label htmlFor="bem-event-name">Event Name</label>
        <input
          id="bem-event-name"
          className="form-control reqfeild"
          name="name"
          value={name}
          disabled={isEditingForbidden}
          onChange={onChangeFormControls}
        />
        {name.length > BUDGET_EVENT_NAME_LENGTH && (
          <p className="event-name-length-notification">
            {`Event Name cannot exceed ${BUDGET_EVENT_NAME_LENGTH} characters`}
          </p>
        )}
      </div>
      <Checkbox.Group className="col-12 mb-3" onChange={onChangeFormControls}>
        <Checkbox label="Overhead" id="bem-overhead" name="overhead" checked={overhead} disabled={isEditingForbidden} />
        <Checkbox
          label="Withholding"
          id="bem-withholding"
          name="withholding"
          checked={withholding}
          disabled={isEditingForbidden}
        />
        <Checkbox
          label="Management Fee"
          id="bem-management-fee"
          name="managementFee"
          checked={managementFee}
          disabled={isEditingForbidden || +managementFeePercent === 0}
        />
      </Checkbox.Group>
      {!isEventRanges && (
        <div className="form-group col-5">
          <AmountInput
            label="Client"
            name="clientAmount"
            value={clientAmount}
            onChange={onChangeFormControls}
            required
            disabled={isEditingForbidden}
            dollar
          />
        </div>
      )}
      {!isEventRanges && budgetEventType !== 'ITEM_GROUP_PATIENT_STIPEND' && (
        <div className="form-group col-5">
          <AmountInput
            name="siteAmount"
            value={siteAmount}
            onChange={onChangeFormControls}
            label="Site"
            disabled={isEditingForbidden}
            dollar
          />
          <ExpenseRadioInputs
            value={siteAmountType}
            name="siteAmountType"
            onChangeFormControls={onChangeFormControls}
            disabled={isEditingForbidden}
          />
        </div>
      )}
      {budgetEventType === 'ITEM_GROUP_PATIENT_STIPEND' && (
        <div className="form-group col-5">
          <AmountInput
            name="patientAmount"
            value={patientAmount}
            required
            onChange={onChangeFormControls}
            label="Patient"
            dollar
            disabled={isEditingForbidden}
          />
          <ExpenseRadioInputs
            value={patientAmountType}
            name="patientAmountType"
            onChangeFormControls={onChangeFormControls}
            disabled={isEditingForbidden}
          />
        </div>
      )}
      {budgetEventType !== 'ITEM_GROUP_PATIENT_STIPEND' && (
        <div className="form-group col-12">
          <label htmlFor="bem-vendor">Vendor</label>
          <div className="form-row">
            <div className="col-7">
              <select
                className="form-control"
                id="bem-vendor-select"
                name="selectedVendorId"
                value={selectedVendorId || ''}
                onChange={onChangeFormControls}
                disabled={isEditingForbidden}
                data-testid="vendor-selector"
              >
                <option value="NOT_SELECTED">Select vendor</option>
                {finVendors.map(v => (
                  <option key={v.id} value={v.id}>
                    {v.name}
                  </option>
                ))}
              </select>
            </div>
            {!isEventRanges && (
              <div className="col-5">
                <AmountInput
                  name="vendorAmount"
                  value={vendorAmount}
                  required={!!selectedVendorId}
                  onChange={onChangeFormControls}
                  dollar
                  disabled={isEditingForbidden || !selectedVendorId}
                  eventType={budgetEventType}
                />
                <ExpenseRadioInputs
                  name="vendorAmountType"
                  value={vendorAmountType}
                  onChangeFormControls={onChangeFormControls}
                  disabled={!selectedVendorId || isEditingForbidden}
                />
              </div>
            )}
          </div>
          {isEventRanges && (
            <div>
              <StatusChangeRows
                eventRows={eventRows}
                isVendorSelected={selectedVendorId !== 'NOT_SELECTED'}
                maxCount={maxCount}
                isEditingForbidden={isEditingForbidden}
                onChangeEventRow={onChangeEventRow}
                handleAddEventRow={handleAddEventRow}
                handleRemoveEventRow={handleRemoveEventRow}
                showOverlapsErrorMsg={showOverlapsErrorMsg}
                managementFee={managementFee}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
