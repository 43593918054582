import React, { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash/lang';
import { get } from 'lodash/object';

import { FinBudgetFixedItemApi } from '../../../../api';
import Button from '../../../../common/general/Button';
import useSessionStorage from '../../../../common/hooks/useSessionStorage';
import { EXPORT_FAILED } from '../../../../constants/notificationMessages';
import { EXPORT_FIXED_ITEMS } from '../../../../constants/userOperations';
import { ROLE_OPERATION_MANAGER } from '../../../../constants/userRoles';
import { userHasAccessTo, userHasRole } from '../../../../services/auth';
import { onFileSave, onRequestError } from '../../../../services/handlers';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { SSUFilter } from '../../../SSUFilter/SSUFilter';
import { SSUPCNFilter } from '../../../SSUFilter/SSUPCNFilter';

import StudyEventForm from './StudyEventForm/StudyEventForm';

const FixedItem = () => {
  const [savedFixedItemsFilters, setSavedFixedItemsFilters] = useSessionStorage('STUDY_FIXED_ITEMS_FILTERS', {});

  const [filters, setFilters] = useState(savedFixedItemsFilters);

  useEffect(() => {
    if (!isEqual(savedFixedItemsFilters, filters)) {
      setSavedFixedItemsFilters(filters);
    }
  }, [filters, savedFixedItemsFilters, setSavedFixedItemsFilters]);

  const handleSSUFilterChange = (studySites, study, site) => {
    if (study) {
      setFilters(prevState => ({ ...prevState, study: isEqual(prevState.study, study) ? prevState.study : study }));
    } else {
      setFilters(prevState => ({ ...prevState, study: undefined }));
    }
    if (site) {
      setFilters(prevState => ({ ...prevState, site: isEqual(prevState.site, site) ? prevState.site : site }));
    } else {
      setFilters(prevState => ({ ...prevState, site: undefined }));
    }
    if (studySites.length === 1 && (study || site)) {
      setFilters(prevState => ({
        ...prevState,
        ssu: isEqual(prevState.ssu, studySites[0]) ? prevState.ssu : studySites[0]
      }));
    } else {
      setFilters(prevState => ({ ...prevState, ssu: undefined }));
    }
  };

  const downloadCSV = () => {
    let filter = {
      studyId: null,
      siteId: null
    };
    if (filters.ssu) {
      filter = { ...filter, studyId: filters.ssu.study.uniqueIdentifier, siteId: filters.ssu.site.uniqueIdentifier };
    } else if (filters.study) {
      filter = { ...filter, studyId: filters.study.uniqueIdentifier };
    } else if (filters.site) {
      filter = { ...filter, siteId: filters.site.uniqueIdentifier };
    }
    FinBudgetFixedItemApi.findAllFixedItems(filter)
      .then(onFileSave)
      .catch(err => onRequestError(err, { customMessage: EXPORT_FAILED }));
  };

  const selectedSSU = useMemo(
    () =>
      filters.ssu
        ? {
            studyIdentifier: filters.ssu.studyIdentifier,
            siteIdentifier: filters.ssu.siteIdentifier,
            ssuIdentifier: filters.ssu.uniqueIdentifier
          }
        : undefined,
    [filters.ssu]
  );

  return (
    <div className="footpadb">
      <PageInfoHeader
        right={
          userHasAccessTo(EXPORT_FIXED_ITEMS) && (
            <Button
              priority="medium"
              type="button"
              size="h28"
              onClick={downloadCSV}
              disabled={userHasRole(ROLE_OPERATION_MANAGER) && !selectedSSU}
            >
              Export
            </Button>
          )
        }
      >
        <div className="general-header-group-container general-header-wrapper">
          <SSUFilter
            handleSSUFilterChange={handleSSUFilterChange}
            ssuIdProvider={() => get(filters, 'ssu.uniqueIdentifier')}
            studyIdProvider={() => get(filters, 'study.uniqueIdentifier')}
            siteIdProvider={() => get(filters, 'site.uniqueIdentifier')}
          >
            <SSUPCNFilter />
          </SSUFilter>
        </div>
      </PageInfoHeader>
      <div className="m-3">
        <StudyEventForm selectedSSU={selectedSSU} />
      </div>
    </div>
  );
};

export default FixedItem;
