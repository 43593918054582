import React from 'react';

import Button from '../../../../common/general/Button';
import AccessDeniedTooltip from '../../../AccessDeniedTooltip/AccessDeniedTooltip';
import WithMultiRoleCheck from '../../../UserRoleSwitcher/WithMultiRoleCheck';

import { viewAllProgressNotes } from './NoteService';

export default function SummaryColumnContent({
  hasAccessToView,
  hasAccessToEdit,
  link,
  sitePatientIdentifier,
  studySiteStatus,
  isBlinded,
  reviewButtonDisabled,
  notesInfo
}) {
  return (
    <React.Fragment>
      {isBlinded ? (
        <AccessDeniedTooltip showContentOnly={false} placement="top">
          <span className="tooltip-cell">
            <Button size="h28" priority="high" disabled={true}>
              Review
            </Button>
          </span>
        </AccessDeniedTooltip>
      ) : (
        <span className="tooltip-cell">
          <WithMultiRoleCheck
            Component={Button}
            ssuPatientId={sitePatientIdentifier}
            disabled={reviewButtonDisabled}
            size="h28"
            to={link}
            priority="high"
          >
            Review
          </WithMultiRoleCheck>
        </span>
      )}

      {hasAccessToView && (
        <Button
          size="h28"
          priority="high"
          className="ml-2"
          onClick={() => viewAllProgressNotes(sitePatientIdentifier, studySiteStatus, hasAccessToEdit, notesInfo)}
        >
          Notes
        </Button>
      )}
    </React.Fragment>
  );
}

SummaryColumnContent.defaultProps = {
  reviewButtonDisabled: false
};
