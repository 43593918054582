import { useEffect } from 'react';
import { reduce } from 'lodash/collection';
import { isEqual } from 'lodash/lang';
import { has } from 'lodash/object';

import MultiSelect from '../../../../../../common/data-entry/MultiSelect/MultiSelect';
import { FRS_LOADED, FRS_READY, FRS_UNINITIALIZED } from '../../../../../eui/EuiFiltersContext/reducer';
import {
  RBS_PENDING,
  reimbursementStatuses
} from '../../../../patient-source/Patients/PatientInfo/EncountersSection/PaymentsSection/reimbursementConstants';
import { initializeFlow } from '../StudySite/processors';

export default function Statuses({ uniqKey, state, dispatch }) {
  const fState = state[uniqKey];
  const fCache = state.cache[uniqKey];
  const status = state.schema[uniqKey].status;

  useEffect(
    function() {
      if (status === FRS_UNINITIALIZED) {
        dispatch({
          type: 'PERFORM',
          processor: initializeProcessor,
          payload: {
            key: uniqKey,
            source: reimbursementStatuses,
            value: [{ id: RBS_PENDING, name: 'Submitted - Pending' }]
          }
        });
      }
    },
    [uniqKey, dispatch, status]
  );

  useEffect(
    function() {
      if (status !== FRS_LOADED) {
        return;
      }

      dispatch({
        type: 'PERFORM',
        processor: syncProcessor,
        payload: { [uniqKey]: fCache }
      });
    },
    [dispatch, uniqKey, status, fCache]
  );

  if (status !== FRS_READY) return null;

  const { source, value } = fState;
  return (
    <MultiSelect
      label="Status"
      searchable
      clearSearchOnSelection
      dataSource={source}
      onChange={selectedStatuses => {
        dispatch({
          type: 'PERFORM',
          processor: setValueProcessor,
          payload: {
            key: uniqKey,
            value: selectedStatuses
          }
        });
      }}
      value={value}
      validate={false}
    />
  );
}

function initializeProcessor(state, action) {
  const { type, payload } = action;
  const { key, source, value } = payload;
  const previousFilterState = state[key];
  return {
    ...state,
    schema: {
      ...state.schema,
      [key]: {
        ...state.schema[key],
        status: FRS_LOADED
      }
    },
    [key]: {
      ...previousFilterState,
      source,
      value
    }
  };
}

function setValueProcessor(state, action) {
  const { type, payload } = action;
  const { key, value } = payload;
  const previousFilterState = state[key];
  if (isEqual(value, previousFilterState.value)) {
    return state;
  }
  return {
    ...state,
    [key]: {
      ...previousFilterState,
      value
    }
  };
}

function syncProcessor(state, action) {
  const { type, payload } = action;

  return reduce(
    state,
    function(accumulator, value, key) {
      if (key === 'schema' || key === 'cache') return accumulator;
      if (has(payload, key)) {
        if (key === 'ssu') {
          accumulator[key] = initializeFlow({
            ...accumulator[key],
            ...payload[key]
          });
        } else {
          accumulator[key] = {
            ...accumulator[key],
            ...payload[key]
          };
        }
        accumulator.schema = {
          ...accumulator.schema,
          [key]: {
            ...accumulator.schema[key],
            status: FRS_READY
          }
        };
      }
      return accumulator;
    },
    { ...state }
  );
}
