import React from 'react';

import Button from '../../../../../../../common/general/Button';
import Icon from '../../../../../../../common/general/Icon';

import { RowCalculationData } from './RowCalculationData';

import './StatusChangeRows.scss';

export const StatusChangeRows = ({
  isVendorSelected,
  eventRows,
  maxCount,
  onChangeEventRow,
  handleAddEventRow,
  showOverlapsErrorMsg,
  handleRemoveEventRow,
  isEditingForbidden,
  managementFee
}) => {
  const RANGE_ERROR = "Wrong range, please correct fields 'From' and 'To'.";
  return (
    <React.Fragment>
      {eventRows.map((eventRow, idx) => (
        <React.Fragment key={idx}>
          <div id="rangeGroup" data-testid="rangeGroup" className="form-row col-12 px-2 pt-2 pb-0 position-relative">
            <div className="bg-light-grey1 width100 mt-1 p-3 pt-4">
              {idx > 0 && !isEditingForbidden && (
                <Button priority="low" size="h28" className="remove-row" onClick={handleRemoveEventRow(idx)}>
                  <Icon>clear</Icon>
                </Button>
              )}
              <RowCalculationData
                eventRow={eventRow}
                onChangeEventRow={onChangeEventRow}
                isEditingForbidden={isEditingForbidden}
                idx={idx}
                maxCount={maxCount}
                isVendorSelected={isVendorSelected}
                managementFee={managementFee}
              />
            </div>
            <div className="add-more">
              {idx === eventRows.length - 1 && !isEditingForbidden && (
                <Button priority="low" size="h28" id="addEncounterStatusChangeRange" onClick={handleAddEventRow}>
                  <Icon>add</Icon>
                  Add more
                </Button>
              )}
            </div>
            {showOverlapsErrorMsg[idx] && (
              <div className="alert alert-warning" role="alert" aria-label={RANGE_ERROR}>
                {RANGE_ERROR}
              </div>
            )}
          </div>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};
