import React, { useContext, useEffect, useMemo } from 'react';
import { find } from 'lodash/collection';

import Loader from '../../../../../../common/elements/Loader/Loader';
import { ReviewContext } from '../ReviewContext';
import {
  extractNotBlindedItemGroupsFromList,
  itemGroupSnapshotReviewStateHasRecordsOrNotPerformed,
  logCheckSnapshotReviewStateHasRecords,
  logSnapshotReviewStateHasRecords
} from '../reviewService';

import ItemGroupElement from './ItemGroupElement/ItemGroupElement';
import { ItemGroupElementHeaderWithIcon } from './ItemGroupElement/ItemGroupElementHeaderWithIcon/ItemGroupElementHeaderWithIcon';
import LogItemGroupElement from './LogItemGroupElement/LogItemGroupElement';

import './ReviewContent.scss';

export function ReviewContent() {
  const {
    chosenItem,
    itemGroupSnapshotReviewStates,
    logSnapshotReviewStates,
    logCheckSnapshotReviewStates,
    itemGroups,
    logCheckItemGroups,
    logItemGroups,
    reviewContentRefContainer
  } = useContext(ReviewContext);

  const isAllDataLoaded = useMemo(
    function() {
      const allItemGroup = [...logItemGroups, ...itemGroups, ...logCheckItemGroups];

      const lengthOfLogChecksWhichHasSnapshot = Object.values({ ...logCheckSnapshotReviewStates }).length;
      const lengthOfItemGroupWhichHasSnapshot =
        Object.values({
          ...itemGroupSnapshotReviewStates,
          ...logSnapshotReviewStates
        }).length + lengthOfLogChecksWhichHasSnapshot;
      return (
        itemGroups &&
        logItemGroups &&
        logCheckItemGroups &&
        lengthOfItemGroupWhichHasSnapshot === extractNotBlindedItemGroupsFromList(allItemGroup).length
      );
    },
    [
      itemGroupSnapshotReviewStates,
      itemGroups,
      logItemGroups,
      logCheckItemGroups,
      logSnapshotReviewStates,
      logCheckSnapshotReviewStates
    ]
  );

  const marginItemGroup = 8;
  const current = reviewContentRefContainer?.current;
  const children = current?.children;

  function findChosenChild() {
    if (children) {
      return find(children, el => {
        return el.id === chosenItem;
      });
    }
  }

  const selectedChild = findChosenChild();
  useEffect(
    function() {
      if (!!selectedChild) {
        selectedChild.scrollIntoView();
        current.scrollBy(0, -marginItemGroup);
      }
    },
    [current, selectedChild]
  );

  function getLogCheckItemByItemGroupTemplateId(itemGroupTemplateId) {
    if (logCheckItemGroups) {
      return logCheckItemGroups.find(item => item.itemGroupTemplateId === itemGroupTemplateId);
    }
    return {};
  }

  return (
    <div className="eds-review-content" ref={reviewContentRefContainer}>
      {itemGroups &&
        itemGroups.map(item => {
          const patientItemGroupId = item.patientItemGroupId;
          const itemGroupSnapshotReviewState = itemGroupSnapshotReviewStates[patientItemGroupId];
          const isItemGroupPresent = !!itemGroupSnapshotReviewStateHasRecordsOrNotPerformed(
            itemGroupSnapshotReviewState
          );
          const blinded = item.blinded;
          return (
            <React.Fragment key={patientItemGroupId}>
              {blinded && (
                <div className="eds-review-item-group-element" key={patientItemGroupId} id={patientItemGroupId}>
                  <ItemGroupElementHeaderWithIcon
                    itemGroupType={item.patientItemGroupType}
                    content={item.patientItemGroupName}
                    accessDenied
                  />
                </div>
              )}
              {isItemGroupPresent && !blinded && (
                <ItemGroupElement
                  key={patientItemGroupId}
                  itemGroupSnapshotState={itemGroupSnapshotReviewState?.itemGroupSnapshotState}
                  reviewStatus={itemGroupSnapshotReviewState?.reviewState.reviewStatus}
                />
              )}
            </React.Fragment>
          );
        })}
      {logItemGroups &&
        logItemGroups.map(item => {
          const itemGroupTemplateId = item.itemGroupTemplateId;
          const logSnapshotReviewState = logSnapshotReviewStates[itemGroupTemplateId];
          const logCheckSnapshotReviewState = logCheckSnapshotReviewStates[itemGroupTemplateId];
          const isLogPresent = !!logSnapshotReviewStateHasRecords(logSnapshotReviewState);
          const isLogCheckPresent = logCheckSnapshotReviewStateHasRecords(logCheckSnapshotReviewState);
          const logCheckItem = getLogCheckItemByItemGroupTemplateId(itemGroupTemplateId);

          const blinded = item.blinded;
          return (
            <React.Fragment key={itemGroupTemplateId}>
              {blinded && (
                <div className="eds-review-item-group-element" id={itemGroupTemplateId} key={itemGroupTemplateId}>
                  <ItemGroupElementHeaderWithIcon itemGroupType={'Logs'} content={item.name} accessDenied />
                </div>
              )}
              {(isLogPresent || isLogCheckPresent) && !blinded && (
                <LogItemGroupElement
                  key={itemGroupTemplateId}
                  isLogPresent={isLogPresent}
                  isLogCheckPresent={isLogCheckPresent}
                  logSnapshotState={logSnapshotReviewState?.logSnapshotState}
                  logCheckSnapshotState={logCheckSnapshotReviewState?.logCheckSnapshotState}
                  reviewStatus={logSnapshotReviewState?.reviewState.reviewStatus}
                  logCheckItem={logCheckItem}
                  item={item}
                />
              )}
            </React.Fragment>
          );
        })}
      {!isAllDataLoaded && (
        <div className="eds-review-item-group-element">
          <div className="review-element-data-loader">
            <Loader dark={true} />
          </div>
        </div>
      )}
    </div>
  );
}
