import { orderBy } from 'lodash';

export function resolveRootKey(currentRoute) {
  let rootKey = currentRoute?.parent?.name;
  if (rootKey === 'Investigator Review' || rootKey === 'SM Review') {
    rootKey = 'Patients';
  }
  return rootKey;
}

export function reshapeAndOrderSourceData(data) {
  /*
    Reshape data so that all instances of source 'Other' will now include their `type` in the source value
    i.e. change:
    {id: 'a3-b1-3c', source: 'Other', type: 'Social Media'}
    to:
    {id: 'a3-b1-3c', source: 'Other - Social Media', type: 'Social Media'}
  */
  const dataReshaped = data?.map(d => (d?.source === 'Other' ? { ...d, source: `${d?.source} - ${d?.type}` } : d));

  return orderBy(
    dataReshaped.filter(source => source.type !== 'API'),
    d => d?.source?.toLowerCase()
  );
}
