import { isString } from 'lodash/lang';

export function caseInsensitiveStringIncludes(a, b) {
  if (isString(a) && isString(b)) {
    return a.toUpperCase().includes(b.toUpperCase());
  }
  return false;
}

/**
 * removeQuotesIfExist()
 * @param str
 * @returns str, unless it both begins and ends with a single quote (') or double quote ("), in
 * which case, return str without the quotes.
 */
export function removeQuotesIfExist(str) {
  if (typeof str !== 'string') {
    return str;
  }
  const regexArray = [/^"(.*)"$/, /^'(.*)'$/, /^(.*)$/];
  return regexArray.map(regex => str.match(regex)).filter(result => result != null)[0][1];
}

export function transformToFirstCapitalLetter(status) {
  const [firstLetter, ...restLetters] = status.toLowerCase();
  return firstLetter.toUpperCase() + restLetters.join('');
}

export const doesStringContainNullByte = stringToCheck => {
  const regxNullByte = /\0|%00|\\x00/;
  return regxNullByte.test(stringToCheck);
};
