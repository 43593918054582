export const CONTACT_NAMES = [
  {
    name: 'Cynthia Herrera',
    id: 'cynthia.herrera@elligodirect.com'
  }
];

export const TERMS = [
  {
    name: 'Net 30',
    id: 'net30'
  },
  {
    name: 'Net 45',
    id: 'net45'
  },
  {
    name: 'Net 60',
    id: 'net60'
  },
  {
    name: 'Net 90',
    id: 'net90'
  },
  {
    name: 'Upon Receipt',
    id: 'upon_receipt'
  }
];

export const DRAFT = 'Draft';

export const ACTION_BUTTON_COLOR = '#666666';

export const tabsStyles = {
  color: 'rgba(0, 0, 0, 0.60)',
  textTransform: 'none',
  '&.Mui-selected': {
    color: '#691E44',
    outline: 'none'
  }
};

export const CREATE_EXISTING_INVOICES_ERROR_VALIDATION_MESSAGE =
  'One or more selections already have an invoice number';
export const CREATE_INVOICE_ERROR_VALIDATION_MESSAGE =
  "Client events are not from the same study site or don't belong to the same client";

export const CREATE_INVOICE_BILL_TO = 'CREATE_INVOICE_BILL_TO';
export const CREATE_INVOICE_PAYEE = 'CREATE_INVOICE_PAYEE';
