import React, { useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';
import { isEqual } from 'lodash/lang';

import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import ButtonGroup from '../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { STUDY_COST_PER_MILEAGE_UPDATED } from '../../../../constants/notificationMessages';
import { handleFinLedgerEventIdFieldValidationError } from '../../../../services/handlers';

const CostPerMileageUpdateModal = ({ modalBox, currentCostPerMileage, handleCostPerMileageUpdate }) => {
  const [costPerMileage, setCostPerMileage] = useState(currentCostPerMileage ?? '');

  const isDisabledButton = useMemo(
    function() {
      return isEqual(currentCostPerMileage, costPerMileage);
    },
    [currentCostPerMileage, costPerMileage]
  );

  return (
    <>
      <ModalBoxes.Header onClose={closeModal}>Update Cost per Mileage</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div>
          <TextField
            label="Cost per Mileage"
            value={costPerMileage ? costPerMileage / 100 : ''}
            onChange={value => setCostPerMileage(value * 100)}
            name="numberformat"
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: AmountNumericFormat
            }}
            variant="standard"
          />
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority={'medium'} onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={onSave} disabled={isDisabledButton}>
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function onSave() {
    handleCostPerMileageUpdate(costPerMileage)
      .then(function() {
        NotificationManager.success(STUDY_COST_PER_MILEAGE_UPDATED);
        closeModal();
      })
      .catch(handleFinLedgerEventIdFieldValidationError);
  }

  function closeModal() {
    modalBox.close();
  }
};

function AmountNumericFormat(props) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      onValueChange={values => {
        onChange(values.value);
      }}
      thousandSeparator
      decimalScale={2}
      prefix="$"
    />
  );
}

CostPerMileageUpdateModal.className = 'cost-per-mileage-update-modal';
CostPerMileageUpdateModal.size = 'w350';

export default CostPerMileageUpdateModal;
