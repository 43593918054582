export const DRAFT = 'DRAFT';
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const BUDGET_EVENT_NAME_LENGTH = 500;

export const eventTypes = {
  ENCOUNTER: { BudgetRowType: 'ENCOUNTER' },
  UNEXPECTED_ENCOUNTER: { BudgetRowType: 'UNEXPECTED_ENCOUNTER' },
  SITUATIONAL_ENCOUNTER: { BudgetRowType: 'SITUATIONAL_ENCOUNTER' },

  ENCOUNTER_ITEM_GROUP: { BudgetRowType: 'ENCOUNTER_ITEM_GROUP' },
  UNEXPECTED_ITEM_GROUP: { BudgetRowType: 'UNEXPECTED_ITEM_GROUP' },
  SITUATIONAL_ITEM_GROUP: { BudgetRowType: 'SITUATIONAL_ITEM_GROUP' },

  ITEM_GROUP_GENERAL: { BudgetRowType: 'ITEM_GROUP_GENERAL' },

  ITEM_GROUP_PATIENT_STIPEND: { BudgetRowType: 'ITEM_GROUP_PATIENT_STIPEND' },

  FIXED_ITEM: { BudgetRowType: 'FIXED_ITEM' },

  STATUS_CHANGE: { BudgetRowType: 'STATUS_CHANGE' },

  RATIO_STATUS_CHANGE: { BudgetRowType: 'RATIO_STATUS_CHANGE' },

  ENCOUNTER_STATUS_CHANGE: { BudgetRowType: 'ENCOUNTER_STATUS_CHANGE' },
  PATIENT_REIMBURSEMENT: { BudgetRowType: 'PATIENT_REIMBURSEMENT' }
};

export const eventTypeLabel = {
  FIXED_ITEM: 'Expense - Fixed',
  EXPENSE_VARIABLE: 'Expense - Variable',
  ENCOUNTER: 'Encounter',
  UNEXPECTED_ENCOUNTER: 'Encounter',
  SITUATIONAL_ENCOUNTER: 'Encounter',
  ENCOUNTER_ITEM_GROUP: 'Encounter - Item Group',
  SITUATIONAL_ITEM_GROUP: 'Encounter - Item Group',
  STATUS_CHANGE: 'Status Change',
  RATIO_STATUS_CHANGE: 'Status Change with Ratio',
  PATIENT_REIMBURSEMENT: 'Patient Reimbursement',
  ITEM_GROUP_PATIENT_STIPEND: 'Patient Stipend',
  ITEM_GROUP_GENERAL: 'Item Group',
  UNEXPECTED_ITEM_GROUP: 'Encounter - Item Group',
  ENCOUNTER_STATUS_CHANGE: 'Encounter - Status Change'
};
