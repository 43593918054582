import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash/lang';
import { omit } from 'lodash/object';
import moment from 'moment';

import { FinInvoiceApi, FinLedgerEventApi } from '../../../../api';
import useSessionStorage from '../../../../common/hooks/useSessionStorage';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { EXPORT_FAILED, SOMETHING_WENT_WRONG } from '../../../../constants/notificationMessages';
import { handleFinLedgerEventIdFieldValidationError, onFileSave, onRequestError } from '../../../../services/handlers';

import { resolveEventSuffix } from './CreateInvoice/CreateInvoiceServices';
import { INVOICE_FILTERS, INVOICE_SELECTED_SEARCH_RESULT } from './InvoiceTable/TableConstants';
import { ACTIVE, INVOICED, PENDING, UNKNOWN } from './invoceConstants';

export const InvoiceContext = React.createContext(null);

export function InvoiceProvider({ children }) {
  const [savedFilters, setSavedFilters] = useSessionStorage(INVOICE_FILTERS, {});
  const [savedSelectedSearchResult, setSavedSelectedSearchResult] = useSessionStorage(
    INVOICE_SELECTED_SEARCH_RESULT,
    null
  );
  const emptyFilters = useMemo(
    () => ({
      studyName: null,
      finTypeForComparingStatuses: 'CLIENT',
      siteName: null,
      siteId: null,
      studyId: null,
      projectCode: null,
      statuses: [ACTIVE, INVOICED],
      startDate: moment('2019-01-01').set({ hour: 0, minute: 0, second: 0, millisecond: 1 }),
      endDate: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }),
      subjectId: null,
      finLedgerEventId: null,
      invoiceNumber: null
    }),
    []
  );
  const initialFilter = useMemo(
    () =>
      !isEmpty(savedFilters)
        ? {
            ...savedFilters,
            startDate: moment(savedFilters.startDate),
            endDate: moment(savedFilters.endDate)
          }
        : { ...emptyFilters },
    [emptyFilters, savedFilters]
  );

  const [selectedEventIds, setSelectedEventIds] = useState([]);

  const [filterProperty, setFilterProperty] = useState(initialFilter);
  const [ledgerEvents, setLedgerEvents] = useState([]);

  const [appliedFilters, setAppliedFilters] = useState(null);
  const [selectedSearchResult, setSelectedSearchResult] = useState(savedSelectedSearchResult);

  const [tableLoading, setTableLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);

  const [createInvoiceItems, setCreateInvoiceItems] = useState({});

  const [automaticallyGeneratedInvoice, setAutomaticallyGeneratedInvoice] = useState(null);

  const modifyFilters = useCallback(filterProperty => {
    let { statuses, ...filtersWithoutStatuses } = filterProperty;
    if (isEmpty(statuses)) {
      statuses.push(UNKNOWN);
    } else {
      if (statuses.includes(ACTIVE)) {
        statuses = statuses.map(status => {
          if (status === ACTIVE) {
            return PENDING;
          }
          return status;
        });
      }
    }
    return {
      statuses,
      ...filtersWithoutStatuses
    };
  }, []);

  const setFewFilterProperty = useCallback(
    function(filters) {
      setFilterProperty(prevFilters => ({
        ...prevFilters,
        ...filters
      }));
    },
    [setFilterProperty]
  );

  const resolveApiForTableData = useCallback((selectedSearchResult, filters) => {
    if (!isEmpty(selectedSearchResult)) {
      return FinInvoiceApi.getAllInvoicesByFilter({ ...filters, ledgerEventUniqueId: selectedSearchResult.id });
    } else {
      return FinInvoiceApi.getAllInvoicesByFilter(filters);
    }
  }, []);

  const updateTable = useCallback(
    (selectedSearchResult, filters) => {
      return resolveApiForTableData(selectedSearchResult, filters)
        .then(({ data }) => {
          setLedgerEvents(
            data.map(ledgerEvent => ({
              ...ledgerEvent,
              tableId: resolveEventSuffix(ledgerEvent.eventId, ledgerEvent.invoiceType)
            }))
          );
          setFirstLoading(false);
        })
        .catch(error => {
          handleFinLedgerEventIdFieldValidationError(error);
        })
        .finally(() => setTableLoading(false));
    },
    [resolveApiForTableData]
  );

  const getDataForCreateInvoice = useCallback(
    eventsForCreateInvoice => {
      const normalizedEventsForGetData = ledgerEvents
        .filter(legerEvent => eventsForCreateInvoice.includes(legerEvent.tableId))
        .map(item => ({ eventId: item.eventId, invoiceType: item.invoiceType }));
      FinInvoiceApi.getDataForCreateInvoice(normalizedEventsForGetData).then(({ data }) => {
        setCreateInvoiceItems({
          originalLedgerEvents: data.originalLedgerEvents,
          withholdingLedgerEvents: data.withholdingLedgerEvents,
          creditMemoLedgerEvents: data.creditMemoLedgerEvents,
          debitMemoLedgerEvents: data.debitMemoLedgerEvents
        });
      });
    },
    [ledgerEvents]
  );

  const loadInvoice = useCallback(
    async (invoiceNumber, invoiceFileId, invoiceType) => {
      if (invoiceNumber && invoiceFileId) {
        try {
          const { data } = await FinLedgerEventApi.getCreatedInvoice(invoiceNumber, invoiceType);
          const {
            data: { content, fileName }
          } = await FinInvoiceApi.getInvoiceFile(invoiceFileId);
          setCreateInvoiceItems({
            originalLedgerEvents: data.createdInvoiceEvents,
            createdInvoiceDetails: {
              ...data.createdInvoiceDetails,
              client: data.client,
              invoiceNumber,
              invoiceType
            },
            pdf: `data:application/pdf;base64,${content}`,
            fileName
          });
        } catch {
          NotificationManager.error(SOMETHING_WENT_WRONG);
        }
      }
    },
    [setCreateInvoiceItems]
  );

  const getDataForAutomaticallyCreatedInvoice = useCallback(async (invoiceNumber, invoiceType, invoiceFileId) => {
    try {
      const { data } = await FinLedgerEventApi.getCreatedInvoice(invoiceNumber, invoiceType);
      const client = isEmpty(data.client)
        ? { name: data.createdInvoiceDetails.clientName, email: data.createdInvoiceDetails.clientEmail }
        : data.client;
      setAutomaticallyGeneratedInvoice({
        createdInvoiceDetails: { ...data.createdInvoiceDetails, client },
        createdInvoiceEvents: data.createdInvoiceEvents,
        invoiceFileId
      });
    } catch {
      NotificationManager.error(SOMETHING_WENT_WRONG);
    }
  }, []);

  const applyFilters = useCallback(() => {
    setTableLoading(true);
    setSavedFilters(filterProperty);
    const filters = modifyFilters(filterProperty);
    updateTable(selectedSearchResult, filters).then(() => {
      setAppliedFilters({ ...filterProperty });
    });
  }, [filterProperty, modifyFilters, selectedSearchResult, setSavedFilters, updateTable]);

  const resetFilters = useCallback(() => {
    setFilterProperty(emptyFilters);
    setSelectedSearchResult(null);
    setLedgerEvents([]);
    setAppliedFilters(null);
    setFirstLoading(true);
    setSavedSelectedSearchResult(null);
    setSavedFilters(null);
  }, [emptyFilters, setSavedFilters, setSavedSelectedSearchResult]);

  const modifyFiltersForNFExport = useCallback(filterProperty => {
    let { statuses, ...properties } = filterProperty;
    if (isEmpty(statuses)) {
      statuses.push(UNKNOWN);
    } else {
      if (statuses.includes(ACTIVE)) {
        statuses = statuses.map(status => {
          if (status === ACTIVE) {
            return PENDING;
          }
          return status;
        });
      }
    }

    const studyId = filterProperty.studyId || null;
    const siteId = filterProperty.siteId || null;

    const studyName = filterProperty.studyName || null;
    const siteName = filterProperty.siteName || null;
    const projectCode = filterProperty.projectCode || null;

    return {
      statuses,
      studyId,
      siteId,
      studyName,
      siteName,
      projectCode,
      ...omit(properties, ['studyIds', 'siteIds', 'studyName', 'siteName', 'projectCode', 'pcnIds', 'types'])
    };
  }, []);

  useEffect(() => {
    setSelectedSearchResult(null);
  }, [
    filterProperty.studyName,
    filterProperty.finTypeForComparingStatuses,
    filterProperty.siteName,
    filterProperty.siteId,
    filterProperty.studyId,
    filterProperty.projectCode,
    filterProperty.statuses,
    filterProperty.startDate,
    filterProperty.subjectId,
    filterProperty.finLedgerEventId,
    filterProperty.invoiceNumber
  ]);

  useEffect(() => {
    setSavedSelectedSearchResult(selectedSearchResult);
  }, [selectedSearchResult, setSavedSelectedSearchResult]);

  useEffect(() => {
    if (!isEmpty(savedFilters)) {
      applyFilters();
    }
    // eslint-disable-next-line
  }, []);
  const downloadCSV = useCallback(() => {
    let filters = modifyFilters(appliedFilters);
    if (ledgerEvents.length === 1) {
      filters = { ...filters, ledgerEventUniqueId: ledgerEvents[0]?.id };
    }
    FinInvoiceApi.exportInvoices(filters)
      .then(onFileSave)
      .catch(err => onRequestError(err, { customMessage: EXPORT_FAILED }));
  }, [appliedFilters, ledgerEvents, modifyFilters]);

  const downloadNSCSV = useCallback(() => {
    const filters = modifyFiltersForNFExport(filterProperty);
    FinInvoiceApi.exportNS(filters)
      .then(onFileSave)
      .catch(err => onRequestError(err, { customMessage: EXPORT_FAILED }));
  }, [filterProperty, modifyFiltersForNFExport]);

  const value = useMemo(
    () => ({
      downloadCSV,
      applyFilters,
      resetFilters,
      modifyFilters,
      filterProperty,
      tableLoading,
      setFewFilterProperty,
      setSelectedSearchResult,
      ledgerEvents,
      appliedFilters,
      firstLoading,
      downloadNSCSV,
      createInvoiceItems,
      setCreateInvoiceItems,
      selectedEventIds,
      setSelectedEventIds,
      getDataForCreateInvoice,
      loadInvoice,
      getDataForAutomaticallyCreatedInvoice,
      automaticallyGeneratedInvoice,
      setAutomaticallyGeneratedInvoice
    }),
    [
      appliedFilters,
      applyFilters,
      createInvoiceItems,
      downloadCSV,
      downloadNSCSV,
      filterProperty,
      firstLoading,
      getDataForCreateInvoice,
      ledgerEvents,
      loadInvoice,
      modifyFilters,
      resetFilters,
      selectedEventIds,
      setFewFilterProperty,
      tableLoading,
      getDataForAutomaticallyCreatedInvoice,
      automaticallyGeneratedInvoice,
      setAutomaticallyGeneratedInvoice
    ]
  );

  return <InvoiceContext.Provider value={value}>{children}</InvoiceContext.Provider>;
}

export function withNewInvoiceContext(Component) {
  return function WrapperComponent(props) {
    return (
      <InvoiceProvider>
        <Component {...props} />
      </InvoiceProvider>
    );
  };
}
