import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash/lang';

import { BlindingConfigApi, ProtocolApi } from '../../../../../../api';
import {
  createEncountersCollection,
  getItemGroupEncounterGrantedMap,
  sortItemGroupsBySequence
} from '../../../../../../services/protocolBlinding';
import { useCurrentRoute } from '../../../../../root/router';

import { BlindingGroupConfig } from './BlindingGroupConfig/BlindingGroupConfig';

export default function BlindingPreview({ epochs, isGroupAssignStudy }) {
  const {
    params: { studyId, protocolIdentity }
  } = useCurrentRoute();
  const [blindingGroups, setBlindingGroups] = useState([]);
  const [itemGroups, setItemGroups] = useState([]);
  const encounters = useMemo(() => createEncountersCollection(epochs), [epochs]);
  const [itemGroupsEncounterGrantedMap, setItemGroupsEncounterGrantedMap] = useState({});
  const [listOfItemGroupsTemplateId, setListOfItemGroupsTemplateId] = useState(null);

  useEffect(
    function() {
      if (!isEmpty(epochs) && !!listOfItemGroupsTemplateId) {
        ProtocolApi.getAllItemGroupsByStudyAndProtocolVersion(studyId, protocolIdentity).then(resEle => {
          const itemGroups = resEle.data.response;
          if (Array.isArray(itemGroups)) {
            setItemGroups(
              sortItemGroupsBySequence(
                epochs,
                itemGroups.filter(({ itemGroupKey }) => listOfItemGroupsTemplateId.includes(itemGroupKey))
              )
            );
          }
        });
      }
    },
    [epochs, studyId, listOfItemGroupsTemplateId, protocolIdentity]
  );

  useEffect(
    function() {
      if (!isEmpty(encounters) && !isEmpty(itemGroups)) {
        setItemGroupsEncounterGrantedMap(getItemGroupEncounterGrantedMap(itemGroups, encounters));
      }
    },
    [itemGroups, encounters]
  );
  useEffect(
    function() {
      BlindingConfigApi.getAllBlindingGroupsForStudy(studyId).then(function({ data: { blindingGroups } }) {
        setBlindingGroups(blindingGroups);
      });
    },
    [studyId]
  );

  const isNotEmptyBlindingGroupList = useMemo(() => !isEmpty(blindingGroups), [blindingGroups]);
  return (
    <React.Fragment>
      {isNotEmptyBlindingGroupList &&
        // todo [mr][groupAssignMigration][ED-8243]: remove once all studies are migrated
        !isGroupAssignStudy &&
        blindingGroups.map(blindingGroup => {
          const blindingGroupId = blindingGroup.key.blindingGroupId;
          return (
            <BlindingGroupConfig
              itemGroupsEncounterGrantedMap={itemGroupsEncounterGrantedMap}
              key={blindingGroupId}
              blindingGroupId={blindingGroupId}
              name={blindingGroup.name}
              epochs={epochs}
              encounters={encounters}
              itemGroups={itemGroups}
              setListOfItemGroupsTemplateId={setListOfItemGroupsTemplateId}
              listOfItemGroupsTemplateId={listOfItemGroupsTemplateId}
            />
          );
        })}
    </React.Fragment>
  );
}
