import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { reject } from 'lodash/collection';

export default function CheckboxSelect({ group, selectedTypeIds, setSelectedTypeIds }) {
  const { typeParent, types } = group;
  return (
    <FormGroup name={typeParent + '_LABEL_ID'}>
      {types?.map(({ id, name }) => {
        return (
          <FormControlLabel
            key={id}
            control={
              <Checkbox
                checked={selectedTypeIds.includes(id)}
                onChange={() =>
                  setSelectedTypeIds(function(prevIds) {
                    return prevIds.includes(id) ? reject(prevIds, pId => pId === id) : [...prevIds, id].sort();
                  })
                }
              />
            }
            label={name}
            sx={{ m: 0 }}
          />
        );
      })}
    </FormGroup>
  );
}
