import React, { useCallback, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { DataGridPro } from '@mui/x-data-grid-pro';
import moment from 'moment';

import FinBudgetFixedItemApi from '../../../../../api/finance/FinBudgetFixedItemApi';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';
import { MANAGE_FIXED_ITEMS } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';
import { toBill, toCoins } from '../../../../../services/financial';
import { onRequestError } from '../../../../../services/handlers';
import { Amount } from '../../../finance/shared/amount-view/Amount/Amount';
import { CustomStudyBudgetToolbar } from '../../../setup/Budget/BudgetDetails/CustomStudyBudgetToolbar';
import RightPanel from '../../../setup/shared/ElementSetupNew/TableSetup/TableSettings/EditTableLabel/RightPanel';
import { ADD_FIXED_EXPENSE, SHOW_FIXED_EXPENSE } from '../FixedItemsConstants';

import { AddFixedExpense } from './AddFixedExpense/AddFixedExpense';
import { FullFixedExpenseInformation } from './FullFixedItemInformation/FullFixedExpenseInformation';

export const ExpenseFixedList = ({
  expensesFixed,
  updateAvailableFixedItems,
  selectedSSU,
  showRightPanel,
  setShowRightPanel
}) => {
  const [editedFixedItem, setEditedFixedItem] = useState(null);
  const [editCommentMode, setEditCommentMode] = useState(false);
  const [editedComment, setEditedComment] = useState('');
  const [newFixedExpenseData, setNewFixedExpenseData] = useState(null);

  const onEditComment = useCallback(
    row => {
      setShowRightPanel(SHOW_FIXED_EXPENSE);
      setEditedFixedItem(row);
      setEditCommentMode(false);
      setEditedComment('');
    },
    [setShowRightPanel]
  );

  const closeRightPanel = useCallback(() => {
    setShowRightPanel(null);
    setEditedComment('');
    setEditedFixedItem(null);
    setNewFixedExpenseData(null);
  }, [setShowRightPanel]);

  const saveNewFixedExpense = useCallback(() => {
    FinBudgetFixedItemApi.create(selectedSSU.ssuIdentifier, {
      studyId: selectedSSU.studyIdentifier,
      siteId: selectedSSU.siteIdentifier,
      ...newFixedExpenseData
    }).then(() => {
      setShowRightPanel(null);
      updateAvailableFixedItems();
    }, onRequestError);
  }, [
    newFixedExpenseData,
    selectedSSU.siteIdentifier,
    selectedSSU.ssuIdentifier,
    selectedSSU.studyIdentifier,
    setShowRightPanel,
    updateAvailableFixedItems
  ]);

  const saveUpdatedComment = useCallback(() => {
    FinBudgetFixedItemApi.updateCommentForFixedItem(selectedSSU?.ssuIdentifier, {
      comment: editedComment.trim(),
      finBudgetFixedItemId: editedFixedItem?.fixedItemId
    }).then(() => {
      setShowRightPanel(null);
      setEditCommentMode(false);
      setEditedComment('');
      setEditedFixedItem(null);
      updateAvailableFixedItems();
    }, onRequestError);
  }, [
    selectedSSU?.ssuIdentifier,
    editedComment,
    editedFixedItem?.fixedItemId,
    setShowRightPanel,
    updateAvailableFixedItems
  ]);

  const columns = useMemo(() => {
    const columns = [
      {
        field: 'date',
        headerName: 'Event Date',
        minWidth: 140,
        type: 'date',
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { date } }) => <div>{date ? moment(date).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
      },
      {
        field: 'elligoRevenueDate',
        headerName: 'Revenue Date',
        minWidth: 160,
        type: 'date',
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { elligoRevenueDate } }) => (
          <div>{elligoRevenueDate ? moment(elligoRevenueDate).format(DD_SLASH_MMM_SLASH_YYYY) : 'N/A'}</div>
        )
      },
      {
        field: 'event',
        headerName: 'Fixed Expense - Event Name',
        flex: 1,
        minWidth: 280
      },
      {
        field: 'clientAmount',
        headerName: 'Amount',
        flex: 1,
        minWidth: 175,
        type: 'number',
        valueGetter: ({ value }) => toBill(toCoins(value)),
        renderCell: ({ row }) => <Amount coinsAmount={toCoins(row.clientAmount)} showDollarSign />
      },
      {
        field: 'eventStatus',
        headerName: 'Status',
        flex: 1,
        minWidth: 110
      },
      {
        field: 'addedBy',
        headerName: 'Added By',
        flex: 1,
        minWidth: 130
      },
      {
        field: 'comments',
        headerName: 'Comments',
        flex: 1,
        minWidth: 400,
        valueGetter: ({ row: { comments } }) => comments[0]?.comment,
        renderCell: ({ value }) => (
          <Tooltip title={value} placement="top" arrow>
            <div className="MuiDataGrid-cellContent">{value}</div>
          </Tooltip>
        )
      }
    ];

    if (!userHasAccessTo(MANAGE_FIXED_ITEMS)) {
      return columns.filter(column => !['clientAmount'].includes(column.field));
    }
    return columns;
  }, []);

  return (
    <div>
      <Box display="flex" justifyContent="flex-end">
        {userHasAccessTo(MANAGE_FIXED_ITEMS) && (
          <Button
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            sx={{ textTransform: 'none', lineHeight: 'unset' }}
            onClick={() => setShowRightPanel(ADD_FIXED_EXPENSE)}
          >
            Fixed Expense
          </Button>
        )}
      </Box>
      <div>
        <DataGridPro
          data-testid="expenses-fixed-table"
          sx={{
            height: '500px',
            border: 'none'
          }}
          columns={columns}
          rows={expensesFixed}
          getRowId={row => row?.fixedItemId}
          rowHeight={38}
          localeText={{ noRowsLabel: 'No Record Found' }}
          slots={{ toolbar: CustomStudyBudgetToolbar }}
          onRowClick={({ row }) => {
            onEditComment(row);
          }}
          disableRowSelectionOnClick
        />
      </div>
      {showRightPanel === SHOW_FIXED_EXPENSE && (
        <RightPanel
          header="Fixed Expense Details"
          toggleClose={closeRightPanel}
          onSave={saveUpdatedComment}
          hideFooter={!userHasAccessTo(MANAGE_FIXED_ITEMS)}
          disableSaveButton={!editCommentMode}
        >
          <FullFixedExpenseInformation
            editCommentMode={editCommentMode}
            editedComment={editedComment}
            setEditedComment={setEditedComment}
            setEditCommentMode={setEditCommentMode}
            editedFixedItem={editedFixedItem}
          />
        </RightPanel>
      )}
      {showRightPanel === ADD_FIXED_EXPENSE && (
        <RightPanel
          header="Add Expense - Fixed"
          toggleClose={closeRightPanel}
          onSave={saveNewFixedExpense}
          hideFooter={!userHasAccessTo(MANAGE_FIXED_ITEMS)}
          disableSaveButton={!newFixedExpenseData?.finTriggerId}
          saveButtonText="Add"
        >
          <AddFixedExpense selectedSSU={selectedSSU} setNewFixedExpenseData={setNewFixedExpenseData} />
        </RightPanel>
      )}
    </div>
  );
};
