import React from 'react';

import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
export const CANCEL_CONFIGURATION = 'CANCEL_CONFIGURATION';
export const CHANGE_BLINDING_GROUP = 'CHANGE_BLINDING_GROUP';
function getInfo(actionType, blindedGroupName) {
  function getActionInfo() {
    if (actionType === CANCEL_CONFIGURATION) {
      return 'cancel configuration of this blinding group';
    }
    if (actionType === CHANGE_BLINDING_GROUP) {
      return 'change Blinded Group and lose current configuration';
    }
  }

  const actionInfo = getActionInfo();
  return (
    <div>
      You have not saved configuration for Blinding Group <b>{blindedGroupName}.</b> Are you sure want to {actionInfo}?
    </div>
  );
}

export function openConfirmationDialog(actionType, blindedGroupName) {
  const content = getInfo(actionType, blindedGroupName);
  return ModalBoxes.confirm({
    content: content,
    title: 'Not saved changes',
    confirmButton: 'Yes',
    cancelButton: 'No'
  });
}
