import { isEmpty } from 'lodash/lang';
import { assign } from 'lodash/object';

import { defaultAddress } from '../constans';

export default function setAddressType(state, { type, payload: { addressType, address, initial } }) {
  if (initial && !addressIsDefault(state)) {
    return state;
  }

  const obj = {
    type: addressType
  };

  if (addressType === 'OTHER') {
    assign(obj, defaultAddress);
  }

  if (!isEmpty(address)) {
    assign(obj, address);
  }

  return {
    ...state,
    ...obj
  };
}

function addressIsDefault({ countryId, regionId, city, address1, address2, zipCode }) {
  return countryId === 'US' && !regionId && !city && !address1 && !address2 && !zipCode;
}
