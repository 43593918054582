import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const TAB_ACTIVE = 'Active';
export const TAB_PROJECTED = 'Projected';
export const TAB_CANCELED = 'Canceled';
export const TAB_PAYMENTS = 'Patient Payments';

const allTabs = [TAB_ACTIVE, TAB_PROJECTED, TAB_CANCELED, TAB_PAYMENTS];
const encounterTabs = [TAB_ACTIVE, TAB_PROJECTED, TAB_CANCELED];

export default function useActiveTab(showEncounterTabs, showPaymentsSection) {
  let [searchParams, setSearchParams] = useSearchParams();
  const urlActiveTab = searchParams.get('encounterTab');
  const activeTab = useMemo(
    function() {
      return getValidTab(urlActiveTab, showEncounterTabs, showPaymentsSection);
    },
    [showEncounterTabs, showPaymentsSection, urlActiveTab]
  );

  useEffect(
    function() {
      if (activeTab !== urlActiveTab) {
        setSearchParams(
          function(searchParams) {
            searchParams.set('encounterTab', activeTab);
            return searchParams;
          },
          { replace: true }
        );
      }
    },
    [activeTab, setSearchParams, urlActiveTab]
  );

  const setActiveTab = useCallback(
    function(tab) {
      setSearchParams(function(searchParams) {
        searchParams.set('encounterTab', tab);
        return searchParams;
      });
    },
    [setSearchParams]
  );
  return { activeTab, setActiveTab };
}

function getValidTab(tab, showEncounterTabs, showPaymentsSection) {
  if (!showEncounterTabs && !showPaymentsSection) {
    return null;
  }
  if (!allTabs.includes(tab)) {
    return getValidTab(TAB_ACTIVE, showEncounterTabs, showPaymentsSection);
  }
  if (!showEncounterTabs && encounterTabs.includes(tab)) {
    return TAB_PAYMENTS;
  }
  if (!showPaymentsSection && tab === TAB_PAYMENTS) {
    return TAB_ACTIVE;
  }
  return tab;
}
