import React, { useCallback, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, IconButton } from '@mui/material';
import { uniqWith } from 'lodash/array';
import { isEmpty } from 'lodash/lang';
import uuid from 'uuid/v4';

import FinBudgetFixedItemApi from '../../../../../../../api/finance/FinBudgetFixedItemApi';
import { scGray15 } from '../../../../../../../constants/systemColors';
import { FileViewerContext } from '../../../../../../../contexts/FileViewerContext';
import EuiDropzone from '../../../../../../eui/EuiDropzone/EuiDropzone';
import { decodeFilename, getBlobContent } from '../../../../../finance/NewInvoice/CreateInvoice/CreateInvoiceServices';

export const ExpenseVariableUploadedFiles = ({ uploadedFiles, setUploadedFiles, ssuId }) => {
  const fileViewer = useContext(FileViewerContext);

  const removeAttachment = useCallback(
    id => {
      setUploadedFiles(prevState => prevState.filter(file => file.id !== id));
    },
    [setUploadedFiles]
  );

  const handleFileChange = async files => {
    const uploadedFiles = Array.from(files).map(file => {
      let reader = new FileReader();
      return new Promise(resolve => {
        reader.onload = () =>
          resolve({
            contentForPreview: file,
            fileUploadName: file.name,
            fileData: getBlobContent(reader.result),
            type: file.type,
            id: uuid()
          });
        reader.readAsDataURL(file);
      });
    });
    const allFiles = await Promise.all(uploadedFiles);
    setUploadedFiles(prevState => {
      return uniqWith(
        [...prevState, ...allFiles],
        (firstValue, secondValue) =>
          !isEmpty(firstValue.fileData) &&
          !isEmpty(secondValue.fileData) &&
          firstValue.fileData === secondValue.fileData &&
          firstValue.fileUploadName === secondValue.fileUploadName
      );
    });
  };

  const openUploadedFile = useCallback(
    (type, fileId) => {
      return fileViewer.openFileByPromise(
        new Promise((resolve, reject) => {
          FinBudgetFixedItemApi.getAttachedFile(fileId, ssuId)
            .then(({ data: blob, headers }) => {
              resolve({
                data: blob,
                headers: {
                  'content-disposition': decodeFilename(headers['content-disposition'])
                }
              });
            })
            .catch(err => {
              reject(err);
            });
        })
      );
    },
    [fileViewer, ssuId]
  );

  return (
    <Box>
      <EuiDropzone multiple={true} accept={['application/pdf', 'image/png', 'image/jpeg']} onDrop={handleFileChange} />
      <Box display="flex" gap="7px" flexDirection="column" marginTop="16px" marginBottom="25px">
        {uploadedFiles.map(file => (
          <Box
            key={file.id}
            sx={{ border: '1px solid', borderColor: scGray15, borderRadius: '4px' }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            data-testId="uploaded-file"
          >
            <Box display="flex" alignItems="center" gap="10px">
              <InsertDriveFileOutlinedIcon fontSize="small" sx={{ marginLeft: '4px' }} />
              <span>{file.fileUploadName}</span>
            </Box>
            <Box display="flex">
              <IconButton
                size="small"
                onClick={() =>
                  file.contentForPreview
                    ? fileViewer.openFileByPromise(
                        new Promise(resolve => {
                          resolve({
                            data: file.contentForPreview,
                            headers: {
                              'content-disposition': `attachment; filename=${file.fileUploadName}`
                            },
                            id: file.id
                          });
                        })
                      )
                    : openUploadedFile(file.type, file.id)
                }
              >
                <VisibilityIcon fontSize="inherit" />
              </IconButton>
              <IconButton size="small" onClick={() => removeAttachment(file.id)}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
