import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import * as PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';

import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import SituationalEncounterModal from '../../ProtocolSetup/SituationalEncounterModal/SituationalEncounterModal';

import './../EncountersHeaders/EncountersHeaders.scss';

export default function SituationalEncounterHeaders(props) {
  const { encounters, updateSituationalEncounters } = props;
  return (
    <React.Fragment>
      {encounters
        ?.sort((a, b) => a.sequence - b.sequence)
        .map((encounter, key) => {
          return (
            <th
              height="38px"
              width="90"
              key={key}
              id={key}
              className="eett-encounter-cell eett-sticky-encounter"
              onClick={() => {
                openSituationalEncounterModal(encounter);
              }}
            >
              <div className="delete-situational-enc-btn">
                {updateSituationalEncounters && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={e => {
                      e.stopPropagation();
                      openDeleteModal(encounter);
                    }}
                  >
                    <ClearIcon fontSize={'small'} />
                  </IconButton>
                )}
              </div>
              <Tooltip
                overlay={encounter.name}
                placement="top"
                mouseEnterDelay={0}
                mouseLeaveDelay={0.1}
                destroyTooltipOnHide={false}
                trigger={Object.keys({ hover: 1 })}
                align={{
                  offset: [50, -5]
                }}
              >
                <span className="eett-encounter">
                  <span className="eett-encounter-board eett-front-encounter-board">{encounter.shortName}</span>
                  <span className="eett-non-protocol-encounter eett-back-encounter-board eett-encounter-board" />
                </span>
              </Tooltip>
            </th>
          );
        })}
    </React.Fragment>
  );

  function openDeleteModal(encounterToDelete) {
    ModalBoxes.confirm({
      content: `Are you sure you want to delete "${encounterToDelete.name}"?`,
      confirmButton: 'Yes',
      cancelButton: 'No'
    })
      .then(() => {
        const encountersToUpdate = encounters.filter(enc => enc.name !== encounterToDelete.name);
        updateSituationalEncounters(encountersToUpdate);
      })
      .catch(() => {});
  }

  function openSituationalEncounterModal(encounter) {
    ModalBoxes.open({
      component: (
        <SituationalEncounterModal
          situationalEncounters={encounters}
          updateSituationalEncounter={newEncounter => {
            const encountersToUpdate = encounters.map(enc => {
              if (enc.name === encounter.name) {
                return { ...enc, name: newEncounter.name, shortName: newEncounter.shortName, smsOptIn: enc.smsOptIn };
              }
              return enc;
            });
            updateSituationalEncounters(encountersToUpdate);
          }}
          initialName={encounter.name}
        />
      )
    });
  }
}

SituationalEncounterHeaders.propTypes = { encounters: PropTypes.array };
