import axios from 'axios';

const ROOT = 'api/v1/finance/contact';

const FinContactApi = {
  getContactPersonsForEntity(id, enabled) {
    return axios.get(`${ROOT}/entity/${enabled}/${id}`);
  },
  update(entity, id) {
    return axios.put(`${ROOT}/${id}`, entity);
  },
  save(entity) {
    return axios.post(ROOT, entity);
  },
  updateEnabled(id) {
    return axios.put(`${ROOT}/false/${id}`);
  }
};

export default FinContactApi;
