import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../constants/dateFormat';
import { ITEM_GROUP_DOMAIN } from '../../../../../../constants/itemGroupDomainConstants';
import { SIGNED } from '../../../../../../constants/notificationMessages';
import { CLOSED } from '../../../../../../constants/ssuStatuses';
import {
  ROLE_CLINICAL_QUALITY_COORDINATOR,
  ROLE_FINANCE_ANALYST,
  ROLE_OPERATION_MANAGER,
  ROLE_OPERATIONS_ANALYST,
  ROLE_STUDY_LEAD,
  ROLE_SYSTEM_ADMINISTRATOR
} from '../../../../../../constants/userRoles';
import { userHasRole } from '../../../../../../services/auth';
import store from '../../../../../../store';
import { isUserAbleToSeeSignEncounterButton } from '../reviewAccessService';
import {
  generateResearchConnectStatus,
  getConfirmedItemGroupsCount,
  getRequireAttentionItemGroupsCount
} from '../ReviewContent/reviewContentService';
import { ReviewContext } from '../ReviewContext';
import ReviewDataResolver from '../ReviewDataResolver';
import { extractNotBlindedItemGroupsFromList, isSmOrPiReview } from '../reviewService';

import ReviewLogHistory from './ReviewLogHistory/ReviewLogHistory';
import SmPiReviewSignModal from './SmPiReviewSignModal/SmPiReviewSignModal';

import './ReviewControl.scss';

export default function ReviewControl() {
  const {
    setSignatureId,
    itemGroupSnapshotReviewStates,
    logSnapshotReviewStates,
    logCheckSnapshotReviewStates,
    ssuPatientId,
    patientEncounterId,
    patientItemGroupId,
    reviewType,
    isHistoricalData,
    requiredAttentionItemGroupsConfirmStatuses,
    itemGroups,
    logItemGroups,
    logCheckItemGroups,
    reviewMetaData
  } = useContext(ReviewContext);

  const studySiteStatus = reviewMetaData?.studySiteStatus;
  const [reviewHistory, setReviewHistory] = useState([]);
  const requireAttentionItemGroupsCount = getRequireAttentionItemGroupsCount(
    requiredAttentionItemGroupsConfirmStatuses
  );
  const confirmedItemGroupsCount = getConfirmedItemGroupsCount(requiredAttentionItemGroupsConfirmStatuses);
  const allItemGroups = useMemo(
    function() {
      return [...logItemGroups, ...itemGroups, ...logCheckItemGroups];
    },
    [itemGroups, logItemGroups, logCheckItemGroups]
  );
  const isDisableSignButton = useMemo(
    function() {
      const isAllItemGroupsBlinded = allItemGroups
        .filter(itemGroup => itemGroup.domainCode !== ITEM_GROUP_DOMAIN.CO)
        .every(itemGroup => itemGroup.blinded);
      const lengthOfLogChecksWhichHasSnapshot = Object.values({ ...logCheckSnapshotReviewStates }).length;
      const lengthOfItemGroupWhichHasSnapshot =
        Object.values({
          ...itemGroupSnapshotReviewStates,
          ...logSnapshotReviewStates
        }).length + lengthOfLogChecksWhichHasSnapshot;
      const isAllDataLoaded =
        lengthOfItemGroupWhichHasSnapshot === extractNotBlindedItemGroupsFromList(allItemGroups).length;
      return isHistoricalData || allItemGroups.length === 0 || !isAllDataLoaded || isAllItemGroupsBlinded;
    },
    [
      isHistoricalData,
      itemGroupSnapshotReviewStates,
      allItemGroups,
      logSnapshotReviewStates,
      logCheckSnapshotReviewStates
    ]
  );

  const userWithReadOnlyAccess = [
    ROLE_STUDY_LEAD,
    ROLE_OPERATION_MANAGER,
    ROLE_FINANCE_ANALYST,
    ROLE_OPERATIONS_ANALYST,
    ROLE_CLINICAL_QUALITY_COORDINATOR
  ];

  const showItemGroupConfirmationStatus =
    isSmOrPiReview(reviewType) && !isHistoricalData && !userHasRole(userWithReadOnlyAccess);

  const isAtLeastOneItemGroupBlinded = allItemGroups.some(itemGroup => itemGroup.blinded);
  useEffect(
    function() {
      ReviewDataResolver.getReviewHistoryByReviewType(reviewType, ssuPatientId, patientEncounterId).then(({ data }) => {
        setReviewHistory(data.items);
      });
    },
    [ssuPatientId, patientEncounterId, reviewType]
  );

  const signHandlerFn = res => {
    const signatureItem = res?.data;
    setReviewHistory([...reviewHistory, signatureItem]);
    setSignatureId(signatureItem?.signatureId);
    NotificationManager.success(SIGNED);
    const afterburnerStatus = generateResearchConnectStatus(res?.data?.afterburnerResponses);
    if (afterburnerStatus) {
      NotificationManager.info(afterburnerStatus);
    }
  };

  const modalProps = {
    itemGroupSnapshotReviewStates,
    logSnapshotReviewStates,
    logCheckSnapshotReviewStates,
    ssuPatientId,
    patientEncounterId,
    patientItemGroupId,
    reviewType
  };

  const openLoginModal = () => {
    const currentDate = moment().format(DD_SLASH_MMM_SLASH_YYYY);
    let signAsAdmin = store.getState().currentUser.activeRole === ROLE_SYSTEM_ADMINISTRATOR;

    ModalBoxes.open({
      component: (
        <SmPiReviewSignModal
          {...modalProps}
          showReason={signAsAdmin}
          isPartialSign={isAtLeastOneItemGroupBlinded}
          signHandlerFn={signHandlerFn}
          currentDate={currentDate}
        />
      ),
      className: 'eds-review-sign-modal-box'
    });
  };

  return (
    <div className="eds-review-control">
      {showItemGroupConfirmationStatus && !!requireAttentionItemGroupsCount && (
        <div className="eds-confirm-item-groups-count">
          {confirmedItemGroupsCount}/{requireAttentionItemGroupsCount} Confirmed
        </div>
      )}
      <div className="eds-review-history">
        <ReviewLogHistory reviewHistory={reviewHistory} />
      </div>
      {isSmOrPiReview(reviewType) &&
        isUserAbleToSeeSignEncounterButton() &&
        studySiteStatus &&
        studySiteStatus !== CLOSED && (
          <Button
            disabled={isDisableSignButton || requireAttentionItemGroupsCount !== confirmedItemGroupsCount}
            onClick={openLoginModal}
          >
            Sign
          </Button>
        )}
    </div>
  );
}
