import React, { useCallback, useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import { sortBy } from 'lodash/collection';
import moment from 'moment';

import { SiteBillApi } from '../../../../api';
import { EXPORT_FAILED } from '../../../../constants/notificationMessages';
import { onFileSave, onRequestError } from '../../../../services/handlers';

export const SiteBillContext = React.createContext(null);

export function SiteBillProvider({ children }) {
  const [tableData, setTableData] = useState([]);
  const [checkedForBill, setCheckedForBill] = useState([]);
  const [site, setSite] = useState();
  const [study, setStudy] = useState();
  const [pcn, setPcn] = useState();
  const [type, setType] = useState();
  const [patientId, setPatientId] = useState();
  const [subjectId, setSubjectId] = useState();
  const [eventId, setEventId] = useState();
  const [withholding, setWithholding] = useState(true);
  const [inputEventId, setInputEventId] = useState();
  const [finTypeForComparingStatuses] = useState();
  const [billNumber, setBillNumber] = useState();
  const [bills, setBills] = useState([]);
  const [isPending, setPending] = useState(true);
  const [isBilled, setBilled] = useState(true);
  const [startDate, setStartDate] = useState(
    moment()
      .startOf('month')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 1 })
  );
  const [endDate, setEndDate] = useState(moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }));

  useEffect(() => {
    setCheckedForBill([]);
  }, [tableData]);

  function assemblerFilters() {
    const statuses = [];
    isPending && statuses.push('PENDING');
    isBilled && statuses.push('BILLED');
    return {
      studyName: study?.studyName,
      siteName: site?.siteName,
      studyId: study?.uniqueIdentifier,
      siteId: site?.uniqueIdentifier,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      billNumber: billNumber?.id,
      statuses: statuses,
      finTypeForComparingStatuses: 'SITE',
      type: type?.name,
      subjectId: subjectId,
      patientId: patientId,
      finLedgerEventId: eventId,
      projectCode: pcn
    };
  }

  const filterApply = () => {
    const filters = filtersAssembler();
    SiteBillApi.getSiteBills(filters).then(({ data }) => {
      setTableData(data);
      const numbers = uniqBy(
        data
          .filter(bill => bill.billNumber)
          .map(item => {
            return { id: item.billNumber, name: item.billNumber };
          }),
        e => e.id
      );
      setBills(sortBy(numbers, 'name'));
    });
  };

  function billExport() {
    const filter = assemblerFilters();
    SiteBillApi.exportSiteBill(filter)
      .then(onFileSave)
      .catch(err => onRequestError(err, { customMessage: EXPORT_FAILED }));
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const exportBill = useCallback(billExport, [
    site,
    study,
    billNumber,
    isPending,
    isBilled,
    startDate,
    finTypeForComparingStatuses,
    endDate,
    type,
    patientId,
    subjectId,
    eventId,
    pcn
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filtersAssembler = useCallback(assemblerFilters, [
    site,
    study,
    billNumber,
    isPending,
    isBilled,
    startDate,
    finTypeForComparingStatuses,
    endDate,
    type,
    patientId,
    subjectId,
    eventId,
    pcn
  ]);
  const applyFilter = useCallback(filterApply, [filtersAssembler]);

  return (
    <SiteBillContext.Provider
      value={{
        setSite,
        setStudy,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        isPending,
        setPending,
        isBilled,
        setBilled,
        setBillNumber,
        bills,
        tableData,
        checkedForBill,
        setCheckedForBill,
        setTableData,
        setBills,
        applyFilter,
        exportBill,
        study,
        site,
        pcn,
        type,
        setPcn,
        setType,
        patientId,
        setPatientId,
        subjectId,
        setSubjectId,
        eventId,
        setEventId,
        inputEventId,
        setInputEventId,
        setWithholding,
        withholding
      }}
    >
      {children}
    </SiteBillContext.Provider>
  );
}
