import { useEffect, useMemo, useState } from 'react';
import { last } from 'lodash/array';
import { isEqual } from 'lodash/lang';

import { useActiveTaskUpdate } from '../../../../../../store/activeTask';

import { getPageAccessByPathname } from './taskServices';

export default function useTaskForm(allStudySites) {
  const updateActiveTask = useActiveTaskUpdate();
  const [taskFormIsLoading, setTaskFormIsLoading] = useState(true);
  const [taskForm, setTaskForm] = useState({});
  const [originTaskForm, setOriginTaskForm] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [taskFormValidationMap, setTaskFormValidationMap] = useState({});
  const lastLink = last(taskForm.taskLinks)?.link;

  const linkAccess = useMemo(
    function() {
      if (!lastLink) return null;
      return getPageAccessByPathname(lastLink);
    },
    [lastLink]
  );

  const { study, site } = taskForm;

  const studyId = study?.id;
  const siteId = site?.id;

  const matchedSsuIds = useMemo(
    function() {
      if (!studyId && !siteId) return null;
      return allStudySites
        .filter(function({ study, site }) {
          const matchedByStudyIdIfNotNull = !studyId || studyId === study?.id;
          const matchedBySiteIdIfNotNull = !siteId || siteId === site?.id;
          return matchedByStudyIdIfNotNull && matchedBySiteIdIfNotNull;
        })
        .map(({ id }) => id);
    },
    [allStudySites, studyId, siteId]
  );

  useEffect(
    function() {
      if (isEqual(taskForm, originTaskForm)) {
        setIsChanged(false);
      } else {
        setIsChanged(true);
      }
    } /* TODO: fires two times, can be optimized */,
    [taskForm, originTaskForm]
  );

  useEffect(
    function() {
      updateActiveTask({ isChanged });
    },
    [isChanged, updateActiveTask]
  );

  const {
    updateTaskForm,
    setTopic,
    setTopicTitle,
    setDescription,
    setAssignees,
    setDueDate,
    setEncounter,
    setItemGroup,
    setStatus,
    setTaskCommentField,
    setStudySite
  } = useMemo(function() {
    return {
      updateTaskForm,
      setTopic,
      setTopicTitle,
      setDescription,
      setAssignees,
      setDueDate,
      setEncounter,
      setItemGroup,
      setStatus,
      setTaskCommentField,
      setStudySite
    };

    function updateTaskForm(taskForm) {
      setTaskForm(taskForm);
      setOriginTaskForm(taskForm);
    }

    function setTopic(value) {
      const topic = value?.id || null;
      setTaskForm(function(taskForm) {
        return { ...taskForm, topic, topicTitle: null };
      });
    }

    function setTopicTitle({ target }) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, topicTitle: target.value };
      });
    }

    function setDescription({ target }) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, description: target.value };
      });
    }

    function setAssignees(assignees) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, assignees };
      });
    }

    function setDueDate(date) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, dueDate: date ? date.format('DD/MMM/YYYY') : null };
      });
    }

    function setEncounter(protocolEncounter) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, protocolEncounter };
      });
    }

    function setItemGroup(protocolItemGroup) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, protocolItemGroup };
      });
    }

    function setStatus(status) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, status };
      });
    }

    function setTaskCommentField(taskCommentField) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, taskCommentField };
      });
    }

    function setStudySite(state) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, ...state };
      });
    }
  }, []);

  return useMemo(
    function() {
      return {
        taskForm,
        originTaskForm,
        isChanged,
        taskFormIsLoading,
        setTaskFormIsLoading,
        taskFormValidationMap,
        setTaskFormValidationMap,
        linkAccess,
        matchedSsuIds,
        updateTaskForm,
        setTopic,
        setTopicTitle,
        setDescription,
        setAssignees,
        setDueDate,
        setEncounter,
        setItemGroup,
        setStatus,
        setTaskCommentField,
        setStudySite
      };
    },
    [
      taskForm,
      originTaskForm,
      isChanged,
      taskFormIsLoading,
      taskFormValidationMap,
      linkAccess,
      matchedSsuIds,
      updateTaskForm,
      setTopic,
      setTopicTitle,
      setDescription,
      setAssignees,
      setDueDate,
      setEncounter,
      setItemGroup,
      setStatus,
      setTaskCommentField,
      setStudySite
    ]
  );
}
