import { createContext, useContext, useReducer } from 'react';

import reducer from './reducer';

const StateContext = createContext(null);
const DispatchContext = createContext(null);

export function EuiFiltersProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {});

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export function withEuiFiltersProvider(Component) {
  return function WithEuiFiltersProvider(props) {
    return (
      <EuiFiltersProvider>
        <Component {...props} />
      </EuiFiltersProvider>
    );
  };
}

export function useEuiFiltersState() {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('useEuiFiltersState must be used within a EuiFiltersContext');
  }
  return context;
}

export function useEuiFiltersDispatch() {
  const dispatch = useContext(DispatchContext);

  if (dispatch === undefined) {
    throw new Error('useEuiFiltersDispatch must be used within a EuiFiltersContext');
  }

  return dispatch;
}
