import axios from 'axios';

const ROOT = 'api/v1/travels';

const PatientTravelApi = {
  getTravelPreferences(patientId) {
    return axios.post(`${ROOT}/patients/${patientId}/preferences`, {}, { hideLoader: true }).then(function({ data }) {
      return {
        data: {
          preferredPickupAddress: mapPickupAddressResponse(data.pickupAddress),
          airlinePreferences: mapAirlinePreferencesResponse(data.airline),
          hotelPreferences: mapHotelPreferencesResponse(data.hotel)
        }
      };
    });
    function mapPickupAddressResponse(pickupAddress) {
      return {
        address1: pickupAddress.address,
        valid: pickupAddress.valid,
        address2: pickupAddress.address2,
        city: pickupAddress.city,
        regionId: pickupAddress.region,
        zipCode: pickupAddress.zip,
        countryId: pickupAddress.country,
        type: pickupAddress.otherAddress ? 'OTHER' : 'PATIENT_HOME_ADDRESS'
      };
    }
    function mapAirlinePreferencesResponse(airline) {
      return {
        preferredTravelTime: airline?.travelTime || [],
        preferredAirline: airline?.airline || '',
        frequentFlightNumber: airline?.frequentFlightNumber || '',
        seatingPreference: airline?.seating || '',
        preferredAirport: airline?.airport || '',
        specialAccommodation: airline?.specialAccommodation || ''
      };
    }
    function mapHotelPreferencesResponse(hotel) {
      return {
        preferredHotelChain: hotel?.hotelChain || '',
        specialAccommodation: hotel?.specialAccommodation || ''
      };
    }
  },
  savePreferredPickupAddress(patientId, pickupAddress) {
    return axios.put(`${ROOT}/patients/${patientId}/preferences/pickup-address`, mapRequest(pickupAddress), {
      hideLoader: false
    });
    function mapRequest(pickupAddress) {
      return {
        address: pickupAddress.address1,
        valid: pickupAddress.valid,
        address2: pickupAddress.address2,
        city: pickupAddress.city,
        region: pickupAddress.regionId,
        zip: pickupAddress.zipCode,
        country: pickupAddress.countryId,
        isOtherAddress: pickupAddress.type === 'OTHER'
      };
    }
  },
  saveAirlinePreferences(patientId, preferences) {
    return axios.put(`${ROOT}/patients/${patientId}/preferences/airlines`, mapRequest(preferences), {
      hideLoader: false
    });
    function mapRequest(preferences) {
      return {
        airline: preferences.preferredAirline,
        frequentFlightNumber: preferences.frequentFlightNumber,
        airport: preferences.preferredAirport,
        specialAccommodation: preferences.specialAccommodation,
        seating: preferences.seatingPreference || null,
        travelTime: preferences.preferredTravelTime
      };
    }
  },
  saveHotelPreferences(patientId, preferences) {
    return axios.put(`${ROOT}/patients/${patientId}/preferences/hotels`, mapRequest(preferences), {
      hideLoader: false
    });
    function mapRequest(preferences) {
      return {
        hotelChain: preferences.preferredHotelChain,
        specialAccommodation: preferences.specialAccommodation
      };
    }
  }
};

export default PatientTravelApi;
