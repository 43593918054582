import React, { useContext } from 'react';
import { isEmpty } from 'lodash/lang';

import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import { MANAGE_SITE_BILL } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';
import { toFormattedBill } from '../../../../../services/financial';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import CreateSiteBillDialog from '../CreateSiteBillDialog/CreateSiteBillDialog';
import { SiteBillContext } from '../SiteBillContext';
import { SiteBillFilters } from '../SiteBillFilters/SiteBillFilters';

import './SiteBillControl.scss';

export function SiteBillControl() {
  const { tableData, checkedForBill, applyFilter, exportBill } = useContext(SiteBillContext);

  const eligibleTotal = tableData?.reduce(
    (accumulator, currentValue) => (!currentValue.billNumber ? accumulator + currentValue.amount : accumulator),
    0
  );

  const createBill = () => {
    ModalBoxes.open({
      component: <CreateSiteBillDialog checkedForBill={checkedForBill} applyFilter={applyFilter} />
    });
  };

  const checkedBill = checkedForBill?.reduce(
    (accumulator, currentValue) => (!currentValue.billNumber ? accumulator + currentValue?.amount : accumulator),
    0
  );

  return (
    <PageInfoHeader
      right={
        <ButtonGroup>
          {userHasAccessTo(MANAGE_SITE_BILL) && (
            <Button priority="high" size="h28" disabled={isEmpty(checkedForBill)} onClick={createBill}>
              Create Bill
            </Button>
          )}
          <Button priority="medium" size="h28" onClick={exportBill}>
            Export
          </Button>
        </ButtonGroup>
      }
      pageInfo={
        <React.Fragment>
          <PageInfoHeader.AdditionalInfo title="Bill Eligible">
            <Amount highlightIntegerValue={true} coinsAmount={eligibleTotal} customCoinsFormat={toFormattedBill} />
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Selected for Bill">
            <Amount highlightIntegerValue={true} coinsAmount={checkedBill} customCoinsFormat={toFormattedBill} />
          </PageInfoHeader.AdditionalInfo>
        </React.Fragment>
      }
    >
      <SiteBillFilters />
    </PageInfoHeader>
  );
}
