import React from 'react';
import { isString } from 'lodash/lang';

import { HOME_PAGE_DASHBOARDS } from '../../../constants/accessToPages';
import {
  ROLE_CLINICAL_QUALITY_COORDINATOR,
  ROLE_CRA,
  ROLE_ELLIGO_MANAGEMENT,
  ROLE_EXTERNAL_AUDITOR,
  ROLE_FINANCE,
  ROLE_FINANCE_ADMINISTRATOR,
  ROLE_FINANCE_ANALYST,
  ROLE_OPERATION_MANAGER,
  ROLE_OPERATIONS_ANALYST,
  ROLE_PATIENT_LIAISON,
  ROLE_PATIENT_REPRESENTATIVE,
  ROLE_PRINCIPAL_INVESTIGATOR,
  ROLE_RECRUITER,
  ROLE_SITE_COORDINATOR,
  ROLE_SITE_FINANCIAL_COORDINATOR,
  ROLE_STUDY_ADMINISTRATOR,
  ROLE_STUDY_LEAD,
  ROLE_STUDY_MANAGER,
  ROLE_SUB_INVESTIGATOR,
  ROLE_SYSTEM_ADMINISTRATOR
} from '../../../constants/userRoles';
import store from '../../../store';
import CalendarPage from '../Calendar/CalendarPage';
import Ledger from '../finance/Ledger/Ledger';
import HomePageDashboards from '../HomePageDashboards/HomePageDashboards';
import ModeReports from '../ModeReports/ModeReports';
import CraReview from '../patient-source/CraReview/CraReview';
import InvestigatorWorklistNew from '../patient-source/InvestigatorWorklistNew/InvestigatorWorklistNew';
import Patients from '../patient-source/Patients/Patients';
import WorkList from '../pre-screen/Worklist/Worklist';
import StudyBudgetSetup from '../setup/Budget/Budget';
import ProtocolDetails from '../setup/Protocol/ProtocolDetails';

const homePageMapping = {
  [ROLE_SYSTEM_ADMINISTRATOR]: { name: 'Calendar', Component: CalendarPage },
  [ROLE_STUDY_ADMINISTRATOR]: { name: 'Setup Protocol', Component: ProtocolDetails },
  [ROLE_PRINCIPAL_INVESTIGATOR]: { name: 'Investigator Worklist', Component: InvestigatorWorklistNew },
  [ROLE_SUB_INVESTIGATOR]: { name: 'Investigator Worklist', Component: InvestigatorWorklistNew },
  [ROLE_STUDY_LEAD]: { name: 'Calendar', Component: CalendarPage },
  [ROLE_STUDY_MANAGER]: { name: 'Calendar', Component: CalendarPage },
  [ROLE_CRA]: { name: 'CRA Review', Component: CraReview },
  [ROLE_EXTERNAL_AUDITOR]: { name: 'CRA Review', Component: CraReview },
  [ROLE_FINANCE_ADMINISTRATOR]: { name: 'Study Budget', Component: StudyBudgetSetup },
  [ROLE_FINANCE_ANALYST]: { name: 'Ledger', Component: Ledger },
  [ROLE_FINANCE]: { name: 'Ledger', Component: Ledger },
  [ROLE_ELLIGO_MANAGEMENT]: { name: 'Study Dashboard', Component: ModeReports },
  [ROLE_RECRUITER]: { name: 'Worklist', Component: WorkList },
  [ROLE_OPERATION_MANAGER]: { name: 'Calendar', Component: CalendarPage },
  [ROLE_PATIENT_REPRESENTATIVE]: { name: 'Worklist', Component: WorkList },
  [ROLE_PATIENT_LIAISON]: { name: 'Worklist', Component: WorkList },
  [ROLE_OPERATIONS_ANALYST]: { name: 'Calendar', Component: CalendarPage },
  [ROLE_CLINICAL_QUALITY_COORDINATOR]: { name: 'Patients', Component: Patients },
  [ROLE_SITE_COORDINATOR]: { name: 'Patients', Component: Patients },
  [ROLE_SITE_FINANCIAL_COORDINATOR]: { name: 'Calendar', Component: CalendarPage }
};

const dashboardsMapping = {
  [ROLE_SYSTEM_ADMINISTRATOR]: { name: 'Home Page', Component: HomePageDashboards },
  [ROLE_PRINCIPAL_INVESTIGATOR]: { name: 'Home Page', Component: HomePageDashboards },
  [ROLE_SUB_INVESTIGATOR]: { name: 'Home Page', Component: HomePageDashboards },
  [ROLE_STUDY_MANAGER]: { name: 'Home Page', Component: HomePageDashboards },
  [ROLE_OPERATION_MANAGER]: { name: 'Home Page', Component: HomePageDashboards },
  [ROLE_OPERATIONS_ANALYST]: { name: 'Home Page', Component: HomePageDashboards },
  [ROLE_SITE_COORDINATOR]: { name: 'Home Page', Component: HomePageDashboards },
  [ROLE_SITE_FINANCIAL_COORDINATOR]: { name: 'Home Page', Component: HomePageDashboards }
};

function Home() {
  const { Component } = getHomePageConfigurationForCurrentUserRole();

  if (Component) {
    return <Component />;
  }

  return function() {
    return <div>Home page not defined</div>;
  };
}

export function getHomePageConfigurationForCurrentUserRole() {
  const state = store.getState();
  const currentUser = state?.currentUser;
  const activeRole = currentUser?.activeRole;
  const homePageDashboardsConfig = state?.homePageDashboardsConfig;

  if (isString(activeRole)) {
    if (currentUser?.accessToPages.includes(HOME_PAGE_DASHBOARDS) && homePageDashboardsConfig?.dashboard?.landingPage) {
      return dashboardsMapping[activeRole];
    }
    return homePageMapping[activeRole];
  }
  return {};
}

export const homeRouterConfig = {
  name: 'Home',
  path: '/',
  breadcrumb: function({ key }) {
    const { name } = getHomePageConfigurationForCurrentUserRole();
    if (name) {
      return [{ current: true, name: name, key }];
    }
  },
  component: Home
};
