import { SITE_PAYMENTS } from '../../../../constants/accessToPages';

import SitePayments from './SitePayments';

export const sitePaymentRouterConfig = {
  name: 'Site Payments',
  path: '/site-payments',
  rootBreadcrumbName: 'Finance',
  component: SitePayments,
  access: [SITE_PAYMENTS]
};
