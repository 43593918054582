import axios from 'axios';

const ROOT = 'api/v1/feature-flags';

const FeatureFlagApi = {
  featureFlags() {
    return axios.get(ROOT);
  }
};

export default FeatureFlagApi;
