import React from 'react';

import { MANAGE_FIXED_ITEMS } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';
import { getNormalizedDate } from '../../ExpensesServices';

import { CommentSection } from './CommentSection/CommentSection';
import { ExpenseInfoItem } from './ExpenseInfoItem/ExpenseInfoItem';

export const FullFixedExpenseInformation = ({
  editCommentMode,
  editedComment,
  setEditedComment,
  setEditCommentMode,
  editedFixedItem
}) => {
  return (
    <>
      <CommentSection
        editCommentMode={editCommentMode}
        editedComment={editedComment}
        setEditedComment={setEditedComment}
        comments={editedFixedItem?.comments}
        setEditCommentMode={setEditCommentMode}
      />
      <ExpenseInfoItem label="Event Date" text={getNormalizedDate(editedFixedItem?.date)} />
      <ExpenseInfoItem label="Revenue Date" text={getNormalizedDate(editedFixedItem?.elligoRevenueDate)} />
      <ExpenseInfoItem label="Fixed Expense - Event Name" text={editedFixedItem?.event || 'N/A'} />
      {userHasAccessTo(MANAGE_FIXED_ITEMS) && (
        <ExpenseInfoItem label="Amount" text={`$${editedFixedItem?.clientAmount.toLocaleString()}` || 'N/A'} />
      )}
      <ExpenseInfoItem label="Status" text={editedFixedItem?.eventStatus || 'N/A'} />
      <ExpenseInfoItem label="Added By" text={editedFixedItem?.addedBy || 'N/A'} />
    </>
  );
};
