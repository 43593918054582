import React from 'react';

import { CustomField } from './CustomField';

export function CustomFieldBlock({
  terminologyVersionGroupNumber,
  editMode,
  customFieldData,
  domain,
  labelIndex,
  getCheckboxForTableView,
  inputs,
  updateInputsAndPreviewData,
  getInvestigatorFieldCheckboxForTableView,
  getFixedItemCheckboxForTableView,
  updateState,
  onChangeLabelTextHandler,
  isInvestigatorFieldAvailable,
  isAdverseEventLog,
  isLogViewCheckboxAvailable
}) {
  return customFieldData.customItemDefinationList.map((item, index) => {
    return (
      <CustomField
        editMode={editMode}
        terminologyVersionGroupNumber={terminologyVersionGroupNumber}
        key={index}
        formLabelData={customFieldData}
        domain={domain}
        labelIndex={labelIndex}
        item={item}
        index={index}
        getCheckboxForTableView={getCheckboxForTableView}
        getInvestigatorFieldCheckboxForTableView={getInvestigatorFieldCheckboxForTableView}
        getFixedItemCheckboxForTableView={getFixedItemCheckboxForTableView}
        inputs={inputs}
        updateInputsAndPreviewData={updateInputsAndPreviewData}
        updateState={updateState}
        onChangeLabelTextHandler={onChangeLabelTextHandler}
        isInvestigatorFieldAvailable={isInvestigatorFieldAvailable}
        isAdverseEventLog={isAdverseEventLog}
        isLogViewCheckboxAvailable={isLogViewCheckboxAvailable}
      />
    );
  });
}
