import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { Menu, MenuGroup, MenuItem } from '@szhsin/react-menu';
import { isEmpty } from 'lodash';

import PatientStipendApi from '../../../../../../../../api/patient/PatientStipendApi';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { STTS_ESOURCE_TYPES } from '../../../../../../../../constants/study/studyTypes';
import { MANAGE_CTMS_PATIENT_STIPEND } from '../../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../../services/auth';
import { PatientInfoContext } from '../../../PatientInfoContext';

import PaidStipendItemModal from './PaidStipendItemModal';

export function AddUnexpectedVisitButton({ onStipendPay }) {
  const [situationalEncounters, setSituationalEncounters] = useState([]);
  const [encounterItems, setEncounterItems] = useState([]);
  const { ssuPatientId } = useParams();
  const { currentStudy, patientInfo } = useContext(PatientInfoContext);
  const studyTypes = currentStudy.types;

  useEffect(() => {
    if (ssuPatientId && !isESourceType(studyTypes) && userHasAccessTo(MANAGE_CTMS_PATIENT_STIPEND)) {
      PatientStipendApi.getSituationalEncounterInfoBySitePatient(ssuPatientId).then(res => {
        setEncounterItems(res?.data?.itemsByEncounterId);
        setSituationalEncounters(res.data?.encounters);
      });
    }
  }, [ssuPatientId, studyTypes]);

  function createSituationalEncounter(encounter) {
    const items = encounterItems[encounter?.situationalEncounterId];
    ModalBoxes.open({
      component: (
        <PaidStipendItemModal
          viewOnlyMod={isESourceType(studyTypes)}
          encounter={encounter}
          itemsData={items}
          onStipendPay={onStipendPay}
          patientInfo={patientInfo}
        />
      )
    });
  }

  if (isESourceType(studyTypes)) {
    return <></>;
  }

  if (isEmpty(situationalEncounters)) {
    return (
      <Button variant="outlined" disabled={true}>
        Add unexpected visit
      </Button>
    );
  }

  if (situationalEncounters.length === 1) {
    return (
      <Button variant="outlined" onClick={() => createSituationalEncounter(situationalEncounters[0])}>
        Add unexpected visit
      </Button>
    );
  } else {
    return (
      <Menu
        align="start"
        direction="left"
        portal={true}
        menuButton={<Button variant="outlined">Add unexpected visit</Button>}
      >
        <MenuGroup>
          {situationalEncounters.map(enc => (
            <MenuItem
              key={enc.situationalEncounterId}
              onClick={() => createSituationalEncounter(enc)}
              title={enc.encounterName.length > 50 ? enc.encounterName : null}
              className="bold-text"
            >
              {enc.encounterName}
            </MenuItem>
          ))}
        </MenuGroup>
      </Menu>
    );
  }
}

function isESourceType(types) {
  return types.some(function(type) {
    return STTS_ESOURCE_TYPES.includes(type);
  });
}
