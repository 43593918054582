import { ACTIVITY, STUDY_FIXED_ITEM } from '../../../../constants/accessToPages';

import FixedItem from './FixedItem';

export const fixedItemRouterConfig = {
  name: 'Expenses',
  rootBreadcrumbName: 'Study',
  path: '/study-expenses',
  component: FixedItem,
  access: [STUDY_FIXED_ITEM, ACTIVITY]
};
