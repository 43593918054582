import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { isEmpty, isEqual } from 'lodash/lang';
import moment from 'moment/moment';
import printJS from 'print-js';

import { FinLedgerEventApi } from '../../../../../api';
import FinInvoiceApi from '../../../../../api/finance/FinInvoiceApi';
import Input from '../../../../../common/data-entry/Input';
import Button from '../../../../../common/general/Button';
import Icon from '../../../../../common/general/Icon';
import useLocalStorage from '../../../../../common/hooks/useLocalStorage';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import StyledTooltip from '../../../../../common/widgets/StyledToolTip';
import { SOMETHING_WENT_WRONG } from '../../../../../constants/notificationMessages';
import { ReactComponent as InfoIcon } from '../../../../../images/icons/green-info-icon.svg';
import { onRequestError } from '../../../../../services/handlers';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { CREDIT_MEMO, DEBIT_MEMO, PARENT, WITHHOLDING } from '../InvoiceTable/TableConstants';
import { InvoiceContext } from '../NewInvoiceContext';

import { ChipSection } from './ChipSection/ChipSection';
import { ConfigurationSection } from './ConfigurationSection/ConfigurationSection';
import { CreateInvoiceActionPanel } from './CreateInvoiceActionPanel/CreateInvoiceActionPanel';
import { CreateInvoiceTable } from './CreateInvoiceTable/CreateInvoiceTable';
import { InvoiceHistory } from './InvoiceHistory/InvoiceHistory';
import { EncounterInvoiceTemplate } from './InvoiceTamplates/EncounterInvoiceTemplate';
import { PDFViewer } from './PDFViewer/PDFViewer';
import {
  CONTACT_NAMES,
  CREATE_EXISTING_INVOICES_ERROR_VALIDATION_MESSAGE,
  CREATE_INVOICE_BILL_TO,
  CREATE_INVOICE_ERROR_VALIDATION_MESSAGE,
  CREATE_INVOICE_PAYEE,
  DRAFT,
  tabsStyles,
  TERMS
} from './CreateInvoiceConstants';
import {
  allInvoiceItemsFromSameSsu,
  allInvoiceItemsWithSameClient,
  areAnyInvoicedItem,
  createMultiplePDFFiles,
  createPDF,
  CustomTabPanel,
  downloadFile,
  getBlobContent,
  getFileForSandingEmail,
  getNInvoiceNumbers,
  openSendmailModal
} from './CreateInvoiceServices';

import './CreateInvoice.scss';

export const CreateInvoice = () => {
  const { createInvoiceItems, applyFilters, setCreateInvoiceItems, loadInvoice } = useContext(InvoiceContext);
  const reportTemplateRef = useRef(null);
  const reportTemplateRefWH = useRef(null);
  const creditMemoTemplateRefs = useRef(null);
  const debitMemoTemplateRefs = useRef(null);

  const [selectedPayee, setSelectedPayee] = useState(null);
  const [payees, setPayees] = useState([]);
  const [remit, setRemit] = useState('remittance@elligodirect.com');
  const [billToList, setBillToList] = useState([]);
  const [selectedBillTo, setSelectedBillTo] = useState(null);
  const [selectedContactName, setSelectedContactName] = useState(CONTACT_NAMES[0]);
  const [selectedTerms, setSelectedTerms] = useState(TERMS[0]);
  const [comment, setComment] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState(DRAFT);
  const [creditMemoInvoiceNumbers, setCreditMemoInvoiceNumbers] = useState([]);
  const [debitMemoInvoiceNumbers, setDebitMemoInvoiceNumbers] = useState([]);
  const [savingType, setSavingType] = useState(null);

  const [PDFFile, setPDFFile] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [totalPagesWH, setTotalPagesWH] = useState(0);
  const [totalPagesCM, setTotalPagesCM] = useState({});
  const [totalPagesDM, setTotalPagesDM] = useState({});

  const [currentTab, setCurrentTab] = useState(0);

  const [generationInProgress, setGenerationInProgress] = useState(false);

  const [billToLocalStorage, setBillToLocalStorage] = useLocalStorage(CREATE_INVOICE_BILL_TO, {});
  const [payeeLocalStorage, setPayeeLocalStorage] = useLocalStorage(CREATE_INVOICE_PAYEE, {});

  const generateOriginalPDF = useMemo(
    () => !isEmpty(createInvoiceItems.originalLedgerEvents) && isEmpty(createInvoiceItems.withholdingLedgerEvents),
    [createInvoiceItems.originalLedgerEvents, createInvoiceItems.withholdingLedgerEvents]
  );
  const generateWithholdingPDF = useMemo(
    () => isEmpty(createInvoiceItems.originalLedgerEvents) && !isEmpty(createInvoiceItems.withholdingLedgerEvents),
    [createInvoiceItems.originalLedgerEvents, createInvoiceItems.withholdingLedgerEvents]
  );
  const generateCreditMemoPDF = useMemo(
    () => isEmpty(createInvoiceItems.originalLedgerEvents) && !isEmpty(createInvoiceItems.creditMemoLedgerEvents),
    [createInvoiceItems.originalLedgerEvents, createInvoiceItems.creditMemoLedgerEvents]
  );
  const generateDebitMemoPDF = useMemo(
    () => isEmpty(createInvoiceItems.originalLedgerEvents) && !isEmpty(createInvoiceItems.debitMemoLedgerEvents),
    [createInvoiceItems.originalLedgerEvents, createInvoiceItems.debitMemoLedgerEvents]
  );
  const generateMultiplePDFs = useMemo(
    () =>
      !isEmpty(createInvoiceItems.originalLedgerEvents) &&
      (!isEmpty(createInvoiceItems.withholdingLedgerEvents) ||
        !isEmpty(createInvoiceItems.creditMemoLedgerEvents) ||
        !isEmpty(createInvoiceItems.debitMemoLedgerEvents)),
    [
      createInvoiceItems.creditMemoLedgerEvents,
      createInvoiceItems.debitMemoLedgerEvents,
      createInvoiceItems.originalLedgerEvents,
      createInvoiceItems.withholdingLedgerEvents
    ]
  );

  const mainLedgerEvents = useMemo(() => {
    if (generateWithholdingPDF) return createInvoiceItems.withholdingLedgerEvents;
    if (generateCreditMemoPDF) return createInvoiceItems.creditMemoLedgerEvents;
    if (generateDebitMemoPDF) return createInvoiceItems.debitMemoLedgerEvents;
    return createInvoiceItems.originalLedgerEvents;
  }, [
    createInvoiceItems.creditMemoLedgerEvents,
    createInvoiceItems.debitMemoLedgerEvents,
    createInvoiceItems.originalLedgerEvents,
    createInvoiceItems.withholdingLedgerEvents,
    generateCreditMemoPDF,
    generateDebitMemoPDF,
    generateWithholdingPDF
  ]);

  const investigatorName = useMemo(() => mainLedgerEvents?.[0].investigator?.split(', ').join(' '), [mainLedgerEvents]);
  const studyName = useMemo(() => mainLedgerEvents?.[0].studyName, [mainLedgerEvents]);
  const pcnName = useMemo(() => mainLedgerEvents?.[0].pcnName, [mainLedgerEvents]);
  const siteName = useMemo(() => mainLedgerEvents?.[0].siteName, [mainLedgerEvents]);
  const siteNumber = useMemo(() => mainLedgerEvents?.[0].siteNumber, [mainLedgerEvents]);
  const eventNumber = useMemo(() => mainLedgerEvents?.[0].eventNumber, [mainLedgerEvents]);
  const adjustmentSequence = useMemo(() => mainLedgerEvents?.[0].adjustmentSequence, [mainLedgerEvents]);

  const invoiceNumberForDisplay = useMemo(() => {
    if (generateWithholdingPDF || createInvoiceItems?.createdInvoiceDetails?.invoiceType === WITHHOLDING)
      return `${createInvoiceItems?.createdInvoiceDetails?.displayNumber ?? invoiceNumber}-WH`;
    if (generateCreditMemoPDF) return `${mainLedgerEvents[0].invoiceNumber ?? invoiceNumber}-CM`;
    if (generateDebitMemoPDF) return `${mainLedgerEvents[0].invoiceNumber ?? invoiceNumber}-DM`;
    if (createInvoiceItems?.createdInvoiceDetails?.invoiceType === CREDIT_MEMO)
      return `${createInvoiceItems?.createdInvoiceDetails?.displayNumber ?? invoiceNumber}-CM`;
    if (createInvoiceItems?.createdInvoiceDetails?.invoiceType === DEBIT_MEMO)
      return `${createInvoiceItems?.createdInvoiceDetails?.displayNumber ?? invoiceNumber}-DM`;
    return createInvoiceItems?.createdInvoiceDetails?.displayNumber ?? invoiceNumber;
  }, [
    createInvoiceItems?.createdInvoiceDetails?.displayNumber,
    createInvoiceItems?.createdInvoiceDetails?.invoiceType,
    generateCreditMemoPDF,
    generateDebitMemoPDF,
    generateWithholdingPDF,
    invoiceNumber,
    mainLedgerEvents
  ]);

  const invoiceCreated = useMemo(() => !isEmpty(createInvoiceItems.createdInvoiceDetails), [
    createInvoiceItems.createdInvoiceDetails
  ]);

  const initialSubject = useMemo(() => `Invoice #${pcnName}-${invoiceNumberForDisplay}`, [
    pcnName,
    invoiceNumberForDisplay
  ]);
  const initialMailText = useMemo(
    () =>
      `<p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">Hello,</span></p><p class="editor-paragraph" dir="ltr"><br></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">Please find Invoice #${pcnName}-${invoiceNumberForDisplay} attached. For any questions, please contact ${remit}. We appreciate your business.</span></p><p class="editor-paragraph" dir="ltr"><br></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">Best,</span></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">The Elligo Health Research Finance Team</span></p>`,
    [pcnName, invoiceNumberForDisplay, remit]
  );
  const emailModalBoxHeader = useMemo(() => `New Message - Invoice #${pcnName}-${invoiceNumberForDisplay}`, [
    pcnName,
    invoiceNumberForDisplay
  ]);

  const memoizedViewer = useMemo(() => {
    return PDFFile && <PDFViewer file={PDFFile} />;
  }, [PDFFile]);

  const balanceDue = useMemo(
    () => createInvoiceItems.originalLedgerEvents?.reduce((sum, el) => el.invoiceAmount + sum, 0),
    [createInvoiceItems.originalLedgerEvents]
  );
  const balanceDueWH = useMemo(
    () => createInvoiceItems.withholdingLedgerEvents?.reduce((sum, el) => el.invoiceAmount + sum, 0),
    [createInvoiceItems.withholdingLedgerEvents]
  );
  const currentInvoiceType = useMemo(() => {
    if (generateWithholdingPDF || createInvoiceItems?.createdInvoiceDetails?.invoiceType === WITHHOLDING)
      return WITHHOLDING;
    if (generateCreditMemoPDF || createInvoiceItems?.createdInvoiceDetails?.invoiceType === CREDIT_MEMO)
      return CREDIT_MEMO;
    if (generateDebitMemoPDF || createInvoiceItems?.createdInvoiceDetails?.invoiceType === DEBIT_MEMO)
      return DEBIT_MEMO;
    return PARENT;
  }, [
    createInvoiceItems?.createdInvoiceDetails?.invoiceType,
    generateCreditMemoPDF,
    generateDebitMemoPDF,
    generateWithholdingPDF
  ]);

  const emailParams = useMemo(
    () => ({
      currentInvoiceType,
      invoiceNumber,
      initialSubject,
      initialMailText,
      emailTo: selectedBillTo?.email ?? [],
      clientName: selectedBillTo?.name ?? '',
      emailFrom: selectedPayee?.email ?? '',
      emailCc: '',
      emailBcc: '',
      emailModalBoxHeader,
      billToList
    }),
    [
      currentInvoiceType,
      invoiceNumber,
      initialSubject,
      initialMailText,
      selectedBillTo?.email,
      selectedBillTo?.name,
      selectedPayee?.email,
      emailModalBoxHeader,
      billToList
    ]
  );

  const generateFileName = useCallback(
    (invoiceType, number = eventNumber, eventSequence = adjustmentSequence) => {
      switch (invoiceType) {
        case CREDIT_MEMO:
        case DEBIT_MEMO:
          let suffix = '';
          if (currentInvoiceType === PARENT) {
            suffix = invoiceType === CREDIT_MEMO ? '-CM' : '-DM';
          }
          const eventSuffix = invoiceType === CREDIT_MEMO ? '-CM' : '-DM';
          return `${pcnName}_${siteName}_${number}.${eventSequence}${eventSuffix}_${invoiceNumberForDisplay}${suffix}_${moment()
            .format('DD-MMM-YYYY')
            .toLowerCase()}`;
        default:
          const withholdingSuffix = invoiceType === WITHHOLDING && currentInvoiceType === PARENT ? '-WH' : '';
          return `${pcnName}_${siteName}_${invoiceNumberForDisplay}${withholdingSuffix}_${moment()
            .format('DD-MMM-YYYY')
            .toLowerCase()}`;
      }
    },
    [adjustmentSequence, currentInvoiceType, eventNumber, invoiceNumberForDisplay, pcnName, siteName]
  );

  const getMapCreditMemo = () => {
    if (!creditMemoTemplateRefs.current) {
      creditMemoTemplateRefs.current = new Map();
    }
    return creditMemoTemplateRefs.current;
  };

  const getMapDebitMemo = () => {
    if (!debitMemoTemplateRefs.current) {
      debitMemoTemplateRefs.current = new Map();
    }
    return debitMemoTemplateRefs.current;
  };

  const generatePDF = useCallback(async () => {
    const originalFile = isEmpty(createInvoiceItems.originalLedgerEvents)
      ? null
      : await createPDF(reportTemplateRef.current);
    const withholdingFile = isEmpty(createInvoiceItems.withholdingLedgerEvents)
      ? null
      : await createPDF(reportTemplateRefWH.current);
    const creditMemoFiles = isEmpty(createInvoiceItems.creditMemoLedgerEvents)
      ? null
      : await createMultiplePDFFiles(getMapCreditMemo());
    const debitMemoFiles = isEmpty(createInvoiceItems.debitMemoLedgerEvents)
      ? null
      : await createMultiplePDFFiles(getMapDebitMemo());
    return Promise.all([originalFile, withholdingFile, creditMemoFiles, debitMemoFiles]);
  }, [
    createInvoiceItems.creditMemoLedgerEvents,
    createInvoiceItems.debitMemoLedgerEvents,
    createInvoiceItems.originalLedgerEvents,
    createInvoiceItems.withholdingLedgerEvents
  ]);

  const downloadFileAndLog = useCallback(
    fileURL => {
      downloadFile(fileURL, createInvoiceItems?.fileName ?? `${generateFileName(currentInvoiceType)}.pdf`);
      FinInvoiceApi.saveInvoiceLog(invoiceNumber, null, 'DOWNLOAD', currentInvoiceType).then(() => {}, onRequestError);
    },
    [createInvoiceItems?.fileName, generateFileName, currentInvoiceType, invoiceNumber]
  );

  const cancelEmailSanding = useCallback(() => {
    setInvoiceNumber(DRAFT);
    setSavingType(null);
  }, []);

  const handlePrint = useCallback(
    file => {
      printJS({ printable: file.split(',')[1], type: 'pdf', base64: true });
      FinInvoiceApi.saveInvoiceLog(invoiceNumber, null, 'PRINT', currentInvoiceType).then(() => {}, onRequestError);
    },
    [currentInvoiceType, invoiceNumber]
  );

  const closeCreateInvoiceAfterSave = useCallback(() => {
    applyFilters();
    setCreateInvoiceItems({});
    setBillToList([]);
    setCurrentTab(0);
    setGenerationInProgress(false);
    const savedBillToForCurrentSSU = billToLocalStorage[createInvoiceItems.originalLedgerEvents?.[0].ssuId];
    const savedPayeeForCurrentSSU = payeeLocalStorage[createInvoiceItems.originalLedgerEvents?.[0].ssuId];
    if (!isEqual(savedBillToForCurrentSSU, selectedBillTo.name)) {
      setBillToLocalStorage({
        ...billToLocalStorage,
        [createInvoiceItems.originalLedgerEvents?.[0].ssuId]: selectedBillTo.name
      });
    }
    if (!isEqual(savedPayeeForCurrentSSU, selectedPayee)) {
      setPayeeLocalStorage({
        ...payeeLocalStorage,
        [createInvoiceItems.originalLedgerEvents?.[0].ssuId]: selectedPayee
      });
    }
  }, [
    applyFilters,
    billToLocalStorage,
    createInvoiceItems.originalLedgerEvents,
    payeeLocalStorage,
    selectedBillTo?.name,
    selectedPayee,
    setBillToLocalStorage,
    setCreateInvoiceItems,
    setPayeeLocalStorage
  ]);

  const resolveActionAfterSave = useCallback(
    (type, invoiceNumber, mainFile) => {
      switch (type) {
        case 'SEND':
          break;
        case 'PRINT':
          NotificationManager.success(
            `Invoice #${invoiceNumberForDisplay} was created and printed for the selected Event IDs.`
          );
          handlePrint(mainFile);
          closeCreateInvoiceAfterSave();
          break;
        case 'DOWNLOAD':
          NotificationManager.success(
            `Invoice #${invoiceNumberForDisplay} was created and downloaded for the selected Event IDs.`
          );
          downloadFileAndLog(mainFile);
          closeCreateInvoiceAfterSave();
          break;
        default:
          closeCreateInvoiceAfterSave();
          NotificationManager.success(` Invoice #${invoiceNumberForDisplay} was created for the selected Event IDs.`);
          break;
      }
      if (generateMultiplePDFs) {
        if (!isEmpty(createInvoiceItems.withholdingLedgerEvents)) {
          FinInvoiceApi.saveInvoiceLog(invoiceNumber, null, 'SAVE', WITHHOLDING).then(() => {}, onRequestError);
        }
        if (!isEmpty(createInvoiceItems.debitMemoLedgerEvents)) {
          debitMemoInvoiceNumbers.forEach(number =>
            FinInvoiceApi.saveInvoiceLog(number, null, 'SAVE', DEBIT_MEMO).then(() => {}, onRequestError)
          );
        }
        if (!isEmpty(createInvoiceItems.creditMemoLedgerEvents)) {
          creditMemoInvoiceNumbers.forEach(number =>
            FinInvoiceApi.saveInvoiceLog(number, null, 'SAVE', CREDIT_MEMO).then(() => {}, onRequestError)
          );
        }
      }
    },
    [
      closeCreateInvoiceAfterSave,
      createInvoiceItems.creditMemoLedgerEvents,
      createInvoiceItems.debitMemoLedgerEvents,
      createInvoiceItems.withholdingLedgerEvents,
      creditMemoInvoiceNumbers,
      debitMemoInvoiceNumbers,
      downloadFileAndLog,
      generateMultiplePDFs,
      handlePrint,
      invoiceNumberForDisplay
    ]
  );

  const getMainFile = useCallback(
    ({ originalFile, withholdingFile, creditMemoFiles, debitMemoFiles }) => {
      if (generateWithholdingPDF) return withholdingFile;
      if (generateCreditMemoPDF) return creditMemoFiles[0].content;
      if (generateDebitMemoPDF) return debitMemoFiles[0].content;
      return originalFile;
    },
    [generateCreditMemoPDF, generateDebitMemoPDF, generateWithholdingPDF]
  );

  const saveNewInvoice = useCallback(
    (originalFile, withholdingFile, creditMemoFiles, debitMemoFiles) => {
      const ledgerEventIds = mainLedgerEvents.map(item => ({
        adjustmentSequence: item.adjustmentSequence,
        eventNumber: item.eventNumber,
        invoiceType: item.invoiceType
      }));
      const createdInvoiceDetails = {
        clientId: selectedBillTo?.id,
        clientName: selectedBillTo?.name,
        clientEmail: selectedBillTo?.email,
        terms: selectedTerms.name,
        remit,
        contactName: selectedContactName.name,
        comment,
        payee: JSON.stringify(selectedPayee),
        invoiceType: currentInvoiceType,
        displayNumber: invoiceNumber
      };
      const invoiceFile = originalFile
        ? {
            content: getBlobContent(originalFile),
            fileName: `${generateFileName(PARENT)}.pdf`,
            invoiceType: PARENT,
            invoiceNumber
          }
        : null;
      const invoiceWithholdingFile = withholdingFile
        ? {
            content: getBlobContent(withholdingFile),
            fileName: `${generateFileName(WITHHOLDING)}.pdf`,
            invoiceType: WITHHOLDING,
            invoiceNumber
          }
        : null;
      const invoiceCreditMemoFiles = creditMemoFiles
        ? creditMemoFiles.map(({ content, eventId }, index) => ({
            content: getBlobContent(content),
            fileName: `${generateFileName(
              CREDIT_MEMO,
              createInvoiceItems?.creditMemoLedgerEvents?.find(ledgerEvent => ledgerEvent.id === eventId)?.eventNumber,
              createInvoiceItems?.creditMemoLedgerEvents?.find(ledgerEvent => ledgerEvent.id === eventId)
                ?.adjustmentSequence
            )}.pdf`,
            eventId,
            invoiceType: CREDIT_MEMO,
            invoiceNumber: creditMemoInvoiceNumbers[index] ?? invoiceNumber,
            adjustmentDate: createInvoiceItems?.creditMemoLedgerEvents?.find(ledgerEvent => ledgerEvent.id === eventId)
              ?.adjustmentDate
          }))
        : null;

      const invoiceDebitMemoFiles = debitMemoFiles
        ? debitMemoFiles.map(({ content, eventId }, index) => ({
            content: getBlobContent(content),
            fileName: `${generateFileName(
              DEBIT_MEMO,
              createInvoiceItems?.debitMemoLedgerEvents?.find(ledgerEvent => ledgerEvent.id === eventId)?.eventNumber,
              createInvoiceItems?.debitMemoLedgerEvents?.find(ledgerEvent => ledgerEvent.id === eventId)
                ?.adjustmentSequence
            )}.pdf`,
            eventId,
            invoiceType: DEBIT_MEMO,
            invoiceNumber: debitMemoInvoiceNumbers[index] ?? invoiceNumber,
            adjustmentDate: createInvoiceItems?.debitMemoLedgerEvents?.find(ledgerEvent => ledgerEvent.id === eventId)
              ?.adjustmentDate
          }))
        : null;

      return FinLedgerEventApi.createInvoice({
        ledgerEventIds,
        createdInvoiceDetails,
        invoiceFile,
        invoiceWithholdingFile,
        invoiceCreditMemoFiles,
        invoiceDebitMemoFiles
      }).then(response => {
        if (savingType === 'SAVE') {
          FinInvoiceApi.saveInvoiceLog(invoiceNumber, null, 'SAVE', currentInvoiceType).then(() => {}, onRequestError);
        }
        const mainFile = getMainFile({ originalFile, withholdingFile, creditMemoFiles, debitMemoFiles });
        resolveActionAfterSave(savingType, invoiceNumber, mainFile);
        return response;
      }, onRequestError);
    },
    [
      comment,
      createInvoiceItems?.creditMemoLedgerEvents,
      createInvoiceItems?.debitMemoLedgerEvents,
      creditMemoInvoiceNumbers,
      currentInvoiceType,
      debitMemoInvoiceNumbers,
      generateFileName,
      getMainFile,
      invoiceNumber,
      mainLedgerEvents,
      remit,
      resolveActionAfterSave,
      savingType,
      selectedBillTo?.email,
      selectedBillTo?.id,
      selectedBillTo?.name,
      selectedContactName.name,
      selectedPayee,
      selectedTerms.name
    ]
  );

  const onSave = useCallback(
    (type = 'SAVE') => {
      if (
        areAnyInvoicedItem(mainLedgerEvents) ||
        generateWithholdingPDF ||
        generateDebitMemoPDF ||
        generateCreditMemoPDF
      ) {
        if (allInvoiceItemsFromSameSsu(mainLedgerEvents) && allInvoiceItemsWithSameClient(mainLedgerEvents)) {
          if (generateWithholdingPDF) {
            setInvoiceNumber(mainLedgerEvents[0].invoiceNumber);
            setSavingType(type);
          } else {
            const invoicesLength = generateMultiplePDFs
              ? createInvoiceItems.creditMemoLedgerEvents.length + createInvoiceItems.debitMemoLedgerEvents.length + 1
              : 1;
            getNInvoiceNumbers(invoicesLength).then(
              invoiceNumbers => {
                invoiceNumbers.sort((a, b) => a - b);
                setInvoiceNumber(invoiceNumbers[0]);
                const CMNumbers = invoiceNumbers.slice(1, createInvoiceItems.creditMemoLedgerEvents.length + 1);
                const DMNumbers = invoiceNumbers.slice(createInvoiceItems.creditMemoLedgerEvents.length + 1);
                setCreditMemoInvoiceNumbers(CMNumbers);
                setDebitMemoInvoiceNumbers(DMNumbers);
                setSavingType(type);
              },
              () => {
                NotificationManager.error(SOMETHING_WENT_WRONG);
              }
            );
          }
        } else {
          NotificationManager.error(CREATE_INVOICE_ERROR_VALIDATION_MESSAGE);
        }
      } else {
        NotificationManager.error(CREATE_EXISTING_INVOICES_ERROR_VALIDATION_MESSAGE);
      }
    },
    [
      createInvoiceItems.creditMemoLedgerEvents?.length,
      createInvoiceItems.debitMemoLedgerEvents?.length,
      generateCreditMemoPDF,
      generateDebitMemoPDF,
      generateMultiplePDFs,
      generateWithholdingPDF,
      mainLedgerEvents
    ]
  );

  useEffect(() => {
    if (isEmpty(createInvoiceItems)) {
      setComment('');
      setPDFFile('');
      setRemit('remittance@elligodirect.com');
      setInvoiceNumber(DRAFT);
      setSavingType(null);
      setSelectedTerms(TERMS[0]);
    }
  }, [createInvoiceItems]);

  useEffect(() => {
    if (!isEmpty(createInvoiceItems.createdInvoiceDetails) && !isEmpty(createInvoiceItems.pdf)) {
      setComment(createInvoiceItems.createdInvoiceDetails.comment);
      setInvoiceNumber(createInvoiceItems.createdInvoiceDetails.invoiceNumber);
      setPDFFile(createInvoiceItems.pdf);
    }
  }, [createInvoiceItems.createdInvoiceDetails, createInvoiceItems.pdf]);

  useEffect(() => {
    if (
      (createInvoiceItems.originalLedgerEvents ||
        createInvoiceItems.withholdingLedgerEvents ||
        createInvoiceItems.creditMemoLedgerEvents ||
        createInvoiceItems.debitMemoLedgerEvents) &&
      isEmpty(createInvoiceItems.createdInvoiceDetails) &&
      isEmpty(createInvoiceItems.pdf)
    ) {
      setGenerationInProgress(true);
      const delayForGeneration = setTimeout(async () => {
        const [originalFile, withholdingFile, creditMemoFiles, debitMemoFiles] = await generatePDF();
        const mainFile = getMainFile({ originalFile, withholdingFile, creditMemoFiles, debitMemoFiles });
        if (invoiceNumber !== DRAFT && savingType) {
          savingType === 'SEND'
            ? await openSendmailModal({
                ...emailParams,
                initialFiles: getFileForSandingEmail(`${generateFileName(currentInvoiceType)}.pdf`, mainFile),
                closeCreateInvoiceAfterSave,
                saveNewInvoice: () => saveNewInvoice(originalFile, withholdingFile, creditMemoFiles, debitMemoFiles),
                cancelEmailSanding,
                loadInvoice,
                createInvoiceItems,
                setInvoiceNumber,
                setSavingType
              })
            : saveNewInvoice(originalFile, withholdingFile, creditMemoFiles, debitMemoFiles);
        } else {
          setGenerationInProgress(false);
          setPDFFile(mainFile);
        }
      }, 500);
      return () => clearTimeout(delayForGeneration);
    }
  }, [
    cancelEmailSanding,
    closeCreateInvoiceAfterSave,
    createInvoiceItems.createdInvoiceDetails,
    createInvoiceItems.originalLedgerEvents,
    createInvoiceItems.pdf,
    createInvoiceItems.withholdingLedgerEvents,
    emailParams,
    generatePDF,
    generateWithholdingPDF,
    invoiceNumber,
    loadInvoice,
    saveNewInvoice,
    savingType,
    generateCreditMemoPDF,
    generateDebitMemoPDF,
    getMainFile,
    createInvoiceItems.creditMemoLedgerEvents,
    createInvoiceItems.debitMemoLedgerEvents,
    generateFileName,
    currentInvoiceType,
    createInvoiceItems
  ]);

  return (
    <div className="create-invoice-full-screen">
      <div className="invoice-setup">
        <div className="panel-header">
          <div className="ful-screen-modal-name">Create Invoice</div>
          <div className="create-invoice-info">
            {`${mainLedgerEvents?.length} Event IDs • `}
            <Amount
              coinsAmount={
                [CREDIT_MEMO, DEBIT_MEMO].includes(currentInvoiceType)
                  ? mainLedgerEvents[0].invoiceAmount
                  : generateWithholdingPDF
                  ? balanceDueWH
                  : balanceDue
              }
              showDollarSign={true}
            />
            Eligible
          </div>
          <StyledTooltip
            className="tooltip-bank-info"
            overlay={
              <div className="info-value">
                {selectedPayee?.name} <br />
                {selectedPayee?.bankName} <br />
                {`Routing: ${selectedPayee?.bankRoutingNumber}`}
                <br />
                {`Account: ${selectedPayee?.bankAccountNumber}`}
                <br />
                {`SWIFT: ${selectedPayee?.bankSwiftCode}`}
              </div>
            }
            placement="bottom"
          >
            <div className="create-invoice-bank-info">
              <InfoIcon />
              Banking Information
            </div>
          </StyledTooltip>
        </div>
        <div className="panel-content">
          <ConfigurationSection
            billToList={billToList}
            setBillToList={setBillToList}
            selectedBillTo={selectedBillTo}
            setSelectedBillTo={setSelectedBillTo}
            invoiceCreated={invoiceCreated}
            savingType={savingType}
            payees={payees}
            setPayees={setPayees}
            selectedPayee={selectedPayee}
            setSelectedPayee={setSelectedPayee}
            selectedTerms={selectedTerms}
            setSelectedTerms={setSelectedTerms}
            remit={remit}
            setRemit={setRemit}
            selectedContactName={selectedContactName}
            setSelectedContactName={setSelectedContactName}
            billToLocalStorage={billToLocalStorage}
            payeeLocalStorage={payeeLocalStorage}
            mainLedgerEvents={mainLedgerEvents}
          />
          <ChipSection
            invoiceNumber={invoiceNumber !== DRAFT ? invoiceNumberForDisplay : invoiceNumber}
            investigatorName={investigatorName}
            studyName={studyName}
            pcnName={pcnName}
            siteName={siteName}
            siteNumber={siteNumber}
          />
          <div className="events-section">
            <CreateInvoiceTable
              invoiceCreated={invoiceCreated || !!savingType}
              generateWithholdingPDF={generateWithholdingPDF}
              mainLedgerEvents={mainLedgerEvents}
            />
            <div className="amount-calculation">
              {[CREDIT_MEMO, DEBIT_MEMO].includes(currentInvoiceType) ? (
                <div>
                  Total
                  <Amount coinsAmount={mainLedgerEvents[0].invoiceAmount} showDollarSign={true} />
                </div>
              ) : (
                <>
                  <div data-testid="subtotal">
                    Subtotal
                    <Amount coinsAmount={generateWithholdingPDF ? balanceDueWH : balanceDue} showDollarSign={true} />
                  </div>
                  <div data-testid="less-payments">
                    Less Payments
                    <Amount coinsAmount={0} showDollarSign={true} />
                  </div>
                  <div data-testid="balance-due">
                    Balance Due
                    <Amount coinsAmount={generateWithholdingPDF ? balanceDueWH : balanceDue} showDollarSign={true} />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="comment-section">
            <Input
              className="create-invoice-input"
              label="Comment"
              value={comment}
              maxLength={400}
              onChange={({ target: { value } }) => setComment(value)}
              validate={false}
              disabled={invoiceCreated || !!savingType}
            />
          </div>
        </div>
      </div>
      <div className="pdf-preview-section">
        <div className="pdf-preview-header">
          <Button
            priority="low"
            size="h40"
            onClick={() => {
              setCreateInvoiceItems({});
              setBillToList([]);
              setCurrentTab(0);
              setGenerationInProgress(false);
            }}
          >
            <Icon>close</Icon>
          </Button>
          <Tabs
            value={currentTab}
            onChange={(event, newValue) => setCurrentTab(newValue)}
            sx={{
              width: '592px',
              marginTop: '8px',
              '.MuiTab-root': {
                width: '50%',
                maxWidth: '50%'
              }
            }}
            TabIndicatorProps={{
              sx: {
                backgroundColor: '#691E44'
              }
            }}
          >
            <Tab label="Current Invoice" sx={tabsStyles} />
            <Tab label="Invoice History" sx={tabsStyles} disabled={invoiceNumber === DRAFT || !!savingType} />
          </Tabs>
        </div>
        <CustomTabPanel value={currentTab} index={0} className="tab-content">
          {memoizedViewer}
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={1} className="tab-content-invoice-history">
          {invoiceNumber !== DRAFT && !savingType && (
            <InvoiceHistory
              invoiceNumber={invoiceNumber}
              invoiceNumberForDisplay={invoiceNumberForDisplay}
              emailModalBoxHeader={emailModalBoxHeader}
              currentInvoiceType={currentInvoiceType}
              invoiceId={createInvoiceItems?.createdInvoiceDetails?.id}
              billToList={billToList}
            />
          )}
        </CustomTabPanel>
        {currentTab === 0 && (
          <CreateInvoiceActionPanel
            invoiceCreated={invoiceCreated}
            fileName={generateFileName(currentInvoiceType)}
            PDFFile={PDFFile}
            selectedBillTo={selectedBillTo}
            selectedPayee={selectedPayee}
            onSave={onSave}
            savingType={savingType}
            handlePrint={handlePrint}
            downloadFile={downloadFileAndLog}
            emailParams={emailParams}
            generationInProgress={generationInProgress}
            setInvoiceNumber={setInvoiceNumber}
          />
        )}
      </div>

      {(generateMultiplePDFs || generateOriginalPDF) && isEmpty(createInvoiceItems.pdf) && (
        <div ref={reportTemplateRef} className="hided-invoice-template">
          <EncounterInvoiceTemplate
            comment={comment}
            invoices={createInvoiceItems.originalLedgerEvents}
            invoiceNumber={invoiceNumber}
            terms={selectedTerms.name}
            payee={selectedPayee}
            billTo={selectedBillTo}
            contact={selectedContactName}
            remit={remit}
            balanceDue={balanceDue}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            invoiceType={PARENT}
          />
        </div>
      )}
      {(generateMultiplePDFs || generateWithholdingPDF) && (
        <div ref={reportTemplateRefWH} className="hided-invoice-template">
          <EncounterInvoiceTemplate
            comment={comment}
            invoices={createInvoiceItems.withholdingLedgerEvents}
            invoiceNumber={`${invoiceNumber}-WH`}
            terms={selectedTerms.name}
            payee={selectedPayee}
            billTo={selectedBillTo}
            contact={selectedContactName}
            remit={remit}
            balanceDue={balanceDueWH}
            totalPages={totalPagesWH}
            setTotalPages={setTotalPagesWH}
            invoiceType={WITHHOLDING}
          />
        </div>
      )}
      {(generateMultiplePDFs || generateCreditMemoPDF) && !isEmpty(createInvoiceItems.creditMemoLedgerEvents) && (
        <>
          {createInvoiceItems.creditMemoLedgerEvents.map(ledgerEvent => (
            <div
              key={ledgerEvent.id + ledgerEvent.eventType}
              ref={node => {
                const map = getMapCreditMemo();
                if (node) {
                  map.set(ledgerEvent.id, node);
                } else {
                  map.delete(ledgerEvent.id);
                }
              }}
              className="hided-invoice-template"
            >
              <EncounterInvoiceTemplate
                comment={comment}
                invoices={[ledgerEvent]}
                invoiceNumber={`${
                  generateCreditMemoPDF && invoiceNumber !== DRAFT ? ledgerEvent.invoiceNumber : invoiceNumber
                }-CM`}
                terms={selectedTerms.name}
                payee={selectedPayee}
                billTo={selectedBillTo}
                contact={selectedContactName}
                remit={remit}
                balanceDue={ledgerEvent.invoiceAmount}
                totalPages={totalPagesCM[ledgerEvent.id] ?? 0}
                setTotalPages={pageCount => {
                  if (totalPagesCM[ledgerEvent.id] !== pageCount) {
                    setTotalPagesCM(prevState => ({
                      ...prevState,
                      [ledgerEvent.id]: pageCount
                    }));
                  }
                }}
                invoiceType={CREDIT_MEMO}
                adjustmentDate={ledgerEvent?.adjustmentDate}
              />
            </div>
          ))}
        </>
      )}
      {(generateMultiplePDFs || generateDebitMemoPDF) && !isEmpty(createInvoiceItems.debitMemoLedgerEvents) && (
        <>
          {createInvoiceItems.debitMemoLedgerEvents.map(ledgerEvent => (
            <div
              key={ledgerEvent.id + ledgerEvent.eventType}
              ref={node => {
                const map = getMapDebitMemo();
                if (node) {
                  map.set(ledgerEvent.id, node);
                } else {
                  map.delete(ledgerEvent.id);
                }
              }}
              className="hided-invoice-template"
            >
              <EncounterInvoiceTemplate
                comment={comment}
                invoices={[ledgerEvent]}
                invoiceNumber={`${
                  generateDebitMemoPDF && invoiceNumber !== DRAFT ? ledgerEvent.invoiceNumber : invoiceNumber
                }-DM`}
                terms={selectedTerms.name}
                payee={selectedPayee}
                billTo={selectedBillTo}
                contact={selectedContactName}
                remit={remit}
                balanceDue={ledgerEvent.invoiceAmount}
                totalPages={totalPagesDM[ledgerEvent.id] ?? 0}
                setTotalPages={pageCount => {
                  if (totalPagesDM[ledgerEvent.id] !== pageCount) {
                    setTotalPagesDM(prevState => ({
                      ...prevState,
                      [ledgerEvent.id]: pageCount
                    }));
                  }
                }}
                invoiceType={DEBIT_MEMO}
                adjustmentDate={ledgerEvent?.adjustmentDate}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};
