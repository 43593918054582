import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash/lang';
import moment from 'moment/moment';

import { eventTypeLabel } from '../../../../setup/Budget/BudgetDetails/budgetConstant';
import { Amount } from '../../../shared/amount-view/Amount/Amount';
import { CREDIT_MEMO, DEBIT_MEMO } from '../../InvoiceTable/TableConstants';

import { InvoiceHeader, tableHeaderItem } from './InvoiceHeader/InvoiceHeader';

const styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    width: '612px',
    height: '791px',
    fontSize: '12px',
    fontFamily: 'Roboto, Roboto-Regular',
    padding: '20px',
    boxSizing: 'border-box'
  },
  hiddenPage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    width: '572px',
    height: '0',
    fontSize: '12px',
    fontFamily: 'Roboto, Roboto-Regular',
    boxSizing: 'border-box',
    overflowY: 'hidden',
    visibility: 'hidden'
  },
  section: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflowY: 'auto'
  },
  pageCountWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end',
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.15px',
    color: '#828282',
    bankingInformation: {
      width: '168px',
      display: 'flex',
      position: 'absolute',
      left: 0,
      flexDirection: 'column',
      padding: '16px',
      borderRadius: '4px',
      borderTop: '1px solid #FAFAFA',
      borderLeft: '2px solid #F3F3F3',
      borderRight: '2px solid #F3F3F3',
      borderBottom: '2px solid #B7B7B7',
      fontFamily: 'Roboto, Roboto-Regular',
      fontWeight: '400',
      lineHeight: '12px',
      fontSize: '8px',
      color: '#323232'
    }
  },
  balance: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '20px',
    paddingBottom: '30px',
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #DBDBDB',
      alignItems: 'flex-end',
      paddingBottom: '4px',
      marginBottom: '10px',
      comment: {
        width: '330px',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.4px',
        color: '#323232'
      },
      subtotal: {
        display: 'flex',
        gap: '20px',
        fontSize: '14px',
        lineHeight: '24px',
        fontFamily: 'Roboto, Roboto-Medium',
        fontWeight: '500'
      }
    },
    calculationWrapper: {
      display: 'flex',
      flexDirection: 'row-reverse',
      calculation: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        subtotal: {
          display: 'flex',
          gap: '20px',
          fontSize: '14px',
          lineHeight: '24px'
        },
        balanceDue: {
          display: 'flex',
          gap: '20px',
          fontSize: '14px',
          lineHeight: '24px',
          fontFamily: 'Roboto, Roboto-Medium',
          fontWeight: '500',
          color: '#691E44',
          marginTop: '10px'
        }
      }
    }
  },
  amountWrapper: {
    letterSpacing: '0.17px',
    minWidth: '110px',
    textAlign: 'end'
  }
};

const stylesContent = {
  groupHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'baseline',
    paddingTop: '13px',
    groupItem: {
      ...tableHeaderItem,
      textAlign: 'center',
      fontSize: '12px',
      color: '#323232',
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: '20px',
      height: '20px'
    },
    groupItemName: {
      ...tableHeaderItem,
      width: '330px',
      fontSize: '12px',
      color: '#323232',
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: '20px'
    },
    tableHeaderItemAmount: {
      ...tableHeaderItem,
      textAlign: 'right',
      fontSize: '12px',
      color: '#323232',
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: '20px',
      height: '20px'
    }
  },
  invoiceItem: {
    paddingLeft: '10px',
    color: '#323232',
    lineHeight: '20px',
    fontSize: '12px'
  }
};

export const InvoicePageGenerator = ({
  normalizedGroups,
  pageNumber,
  totalPages,
  setTotalPages,
  study,
  pcn,
  investigator,
  site,
  siteNumber,
  invoiceNumber,
  invoiceDate,
  terms,
  payee,
  remit,
  contact,
  billTo,
  invoiceType
}) => {
  const mainRef = useRef();
  const shadowRef = useRef();

  const [maxIndex, setMaxIndex] = useState(null);

  const getMax = useCallback((elements, height) => {
    const lastIndex = elements.length - 1;
    for (let i = 0, w = 0; i <= lastIndex; ++i) {
      w = w + elements[i];
      if (w > height) {
        return i - 1;
      }
    }
    return lastIndex;
  }, []);

  const listForCurrentPage = useMemo(() => {
    const cutInvoiceItems = normalizedGroups[maxIndex]?.typeHeader
      ? normalizedGroups.slice(0, maxIndex)
      : normalizedGroups.slice(0, maxIndex + 1);
    const lastElement = cutInvoiceItems[cutInvoiceItems.length - 1];
    const countForLastElementGroup = cutInvoiceItems.filter(
      el => el.groupName === lastElement.groupName && el.invoiceAmount === lastElement.invoiceAmount
    ).length;
    if (
      maxIndex &&
      countForLastElementGroup !== cutInvoiceItems.find(i => i.groupName === lastElement.groupName).groupLength
    ) {
      return cutInvoiceItems.map(invoice =>
        invoice.groupHeader === lastElement.groupName && invoice.amountForItem === lastElement.invoiceAmount
          ? {
              ...invoice,
              groupLength:
                invoice.headerBudgetEventType === eventTypeLabel.PATIENT_REIMBURSEMENT ? 1 : countForLastElementGroup
            }
          : invoice
      );
    }
    return cutInvoiceItems;
  }, [maxIndex, normalizedGroups]);

  const listPorNextPage = useMemo(() => {
    const cutInvoiceItems = normalizedGroups[maxIndex]?.typeHeader
      ? normalizedGroups.slice(maxIndex)
      : normalizedGroups.slice(maxIndex + 1);
    if (!isEmpty(cutInvoiceItems) && !cutInvoiceItems[0].typeHeader && !cutInvoiceItems[0].typeBalance && maxIndex) {
      const reimbursementItem = cutInvoiceItems[0]?.budgetEventType === eventTypeLabel.PATIENT_REIMBURSEMENT;
      const groupLength = reimbursementItem
        ? 1
        : cutInvoiceItems.filter(
            el => el.groupName === cutInvoiceItems[0].groupName && el.invoiceAmount === cutInvoiceItems[0].invoiceAmount
          ).length;
      return [
        {
          typeHeader: true,
          groupHeader: cutInvoiceItems[0].groupName,
          groupHeaderSuffix: '(cont.)',
          groupLength,
          amountForItem: cutInvoiceItems[0].invoiceAmount,
          headerBudgetEventType: cutInvoiceItems[0].budgetEventType
        },
        ...cutInvoiceItems
      ];
    }
    return cutInvoiceItems;
  }, [maxIndex, normalizedGroups]);

  useLayoutEffect(() => {
    if (!mainRef.current || !shadowRef.current) {
      return;
    }
    updateSize();

    function updateSize() {
      const { clientHeight } = mainRef.current;
      const shadowItems = shadowRef.current.querySelectorAll('.row-item');
      const shadowItemsHeight = [...shadowItems].map(({ offsetHeight }) => offsetHeight);

      setMaxIndex(getMax(shadowItemsHeight, clientHeight));
    }
  });

  useEffect(() => {
    if (isEmpty(listPorNextPage)) {
      setTotalPages(pageNumber);
    }
  }, [listPorNextPage, pageNumber, setTotalPages]);

  const generateItems = useCallback(
    items =>
      items.map((item, index) => {
        if (item?.typeHeader) {
          return (
            <div
              style={stylesContent.groupHeader}
              key={`${item.groupHeader}${index}`}
              className="row-item"
              data-testid="event-group-amount-calculation"
            >
              <div style={stylesContent.groupHeader.groupItemName}>
                {item.groupHeader}
                {item.groupHeaderSuffix ?? ''}
              </div>
              <div style={stylesContent.groupHeader.groupItem}>{item.groupLength}</div>
              <div style={stylesContent.groupHeader.tableHeaderItemAmount}>
                <Amount coinsAmount={item.amountForItem} showDollarSign={true} />
              </div>
              <div style={stylesContent.groupHeader.tableHeaderItemAmount}>
                <Amount coinsAmount={item.amountForItem * item.groupLength} showDollarSign={true} />
              </div>
            </div>
          );
        }
        if (['Expense - Fixed', 'Expense - Variable'].includes(item.budgetEventType)) {
          return (
            <div
              style={stylesContent.invoiceItem}
              key={item.id + index}
              className="row-item"
              data-testid="ledger-event-item"
            >
              {`${item.expenseComment ? item.expenseComment : ''} ${
                item?.eventDate ? `(${moment(item.eventDate).format('DD-MMM-YYYY')})` : '—'
              }
                  `}
            </div>
          );
        }
        if (item.templateRowType === 'REIMBURSEMENT_EPOCH_ENCOUNTER') {
          return (
            <div
              style={{
                ...stylesContent.invoiceItem,
                fontFamily: 'Roboto, Roboto-Medium',
                fontWeight: '500'
              }}
              key={item.id + index}
              className="row-item"
              data-testid="ledger-event-item"
            >
              {item.content}
            </div>
          );
        }
        if (item.templateRowType === 'REIMBURSEMENT_SUBJECT') {
          return (
            <div
              style={stylesContent.invoiceItem}
              key={item.id + index}
              className="row-item"
              data-testid="ledger-event-item"
            >
              {item.content}
            </div>
          );
        }
        if (item.templateRowType === 'REIMBURSEMENT_ITEM') {
          return (
            <div
              style={{ ...stylesContent.invoiceItem, display: 'flex', gap: '3px' }}
              key={item.id + index}
              className="row-item"
              data-testid="ledger-event-item"
            >
              {`${item?.type || '—'} (${moment(item.eventDate).format('DD-MMM-YYYY')})`}
              <Amount coinsAmount={item.amount} showDollarSign={true} />
            </div>
          );
        }
        if (item?.typeBalance) {
          return [CREDIT_MEMO, DEBIT_MEMO].includes(invoiceType) ? (
            <div
              style={styles.balance}
              className="row-item"
              key={item.balanceDue + index}
              data-testid="ledger-event-item"
            >
              <div style={styles.balance.header}>
                <div style={styles.balance.header.comment}>{item.comment}</div>
                <div style={styles.balance.header.subtotal}>
                  Total
                  <div style={styles.amountWrapper}>
                    <Amount coinsAmount={item.balanceDue} showDollarSign={true} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={styles.balance}
              className="row-item"
              key={item.balanceDue + index}
              data-testid="ledger-event-item"
            >
              <div style={styles.balance.header}>
                <div style={styles.balance.header.comment}>{item.comment}</div>
                <div style={styles.balance.header.subtotal}>
                  Subtotal
                  <div style={styles.amountWrapper}>
                    <Amount coinsAmount={item.balanceDue} showDollarSign={true} />
                  </div>
                </div>
              </div>
              <div style={styles.balance.calculationWrapper}>
                <div style={styles.balance.calculationWrapper.calculation}>
                  <div style={styles.balance.calculationWrapper.calculation.subtotal}>
                    Subtotal
                    <div style={styles.amountWrapper}>
                      <Amount coinsAmount={item.balanceDue} showDollarSign={true} />
                    </div>
                  </div>
                  <div style={styles.balance.calculationWrapper.calculation.subtotal}>
                    Less Payments
                    <div style={styles.amountWrapper}>
                      <Amount coinsAmount={0} showDollarSign={true} />
                    </div>
                  </div>
                  <div style={styles.balance.calculationWrapper.calculation.balanceDue}>
                    Balance Due
                    <div style={styles.amountWrapper}>
                      <Amount coinsAmount={item.balanceDue} showDollarSign={true} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }

        return (
          <div
            style={stylesContent.invoiceItem}
            key={item.id + index}
            className="row-item"
            data-testid="ledger-event-item"
          >
            {`Subject ID ${item?.subjectNumber ? item?.subjectNumber : '—'} ${
              item?.eventDate ? `(${moment(item.eventDate).format('DD-MMM-YYYY')})` : '—'
            }`}
          </div>
        );
      }),
    [invoiceType]
  );

  return (
    <>
      <div style={styles.page} data-testid="invoice-pdf-page">
        <InvoiceHeader
          study={study}
          pcn={pcn}
          investigator={investigator?.split(', ').join(' ')}
          site={site}
          siteNumber={siteNumber}
          invoiceNumber={invoiceNumber}
          invoiceDate={invoiceDate}
          terms={terms}
          payee={payee}
          contact={contact}
          remit={remit}
          pageNumber={pageNumber}
          billTo={billTo}
          lastPageWithoutTable={listForCurrentPage.length === 1}
          invoiceType={invoiceType}
        />
        <div style={styles.section} ref={mainRef}>
          {generateItems(listForCurrentPage)}
        </div>
        <div style={styles.pageCountWrapper}>
          {pageNumber === totalPages && ![CREDIT_MEMO, DEBIT_MEMO].includes(invoiceType) && (
            <div style={styles.pageCountWrapper.bankingInformation}>
              <div>{payee?.name}</div>
              <div>{payee?.bankName}</div>
              <div>{`Routing: ${payee?.bankRoutingNumber}`}</div>
              <br />
              <div>{`Account: ${payee?.bankAccountNumber}`}</div>
              <div>{`SWIFT: ${payee?.bankSwiftCode}`}</div>
            </div>
          )}
          <div style={styles.pageCountWrapper.pageCount}>
            {pageNumber} of {totalPages}
          </div>
        </div>
      </div>

      <div style={styles.hiddenPage} className="invoice-generator-hidden">
        <div style={styles.section} ref={shadowRef}>
          {generateItems(normalizedGroups)}
        </div>
      </div>

      {!isEmpty(listPorNextPage) && (
        <InvoicePageGenerator
          normalizedGroups={listPorNextPage}
          pageNumber={pageNumber + 1}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          study={study}
          pcn={pcn}
          investigator={investigator}
          site={site}
          siteNumber={siteNumber}
          invoiceNumber={invoiceNumber}
          invoiceDate={invoiceDate}
          terms={terms}
          payee={payee}
          contact={contact}
          remit={remit}
          billTo={billTo}
          invoiceType={invoiceType}
        />
      )}
    </>
  );
};
