import React, { Component } from 'react';
import { connect } from 'react-redux';
import { flattenDeep } from 'lodash/array';
import { isArray, isEmpty, isEqual } from 'lodash/lang';
import { get, pickBy } from 'lodash/object';
import { parse, stringify } from 'query-string';
import { bindActionCreators } from 'redux';

import { PatientApi } from '../../../../api';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import ButtonGroup from '../../../../common/general/ButtonGroup';
import Icon from '../../../../common/general/Icon';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { EXPORT_FAILED } from '../../../../constants/notificationMessages';
import { ADD_PATIENT, VIEW_PATIENTS } from '../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../services/auth';
import { onFileSave } from '../../../../services/handlers';
import { update as updatePatientListFilters } from '../../../../store/filters/patientList/actions';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { withLocation, withNavigate, withNavigator } from '../../../root/router/legacyComponentCompatability';
import PatientDetailsSSUFilter from '../../../SSUFilter/PatientDetailsSSUFilter';
import { SSUFilter } from '../../../SSUFilter/SSUFilter';

import AddOrEditPatientInfoModal from './PatientInfo/PatientInfoMainSection/AddOrEditPatientInfoModal/AddOrEditPatientInfoModal';
import PatientList from './PatientList';

import './Patients.scss';

const sortingPath = {
  patientName: ['patient.lastName', 'patient.firstName'],
  subjectId: ['subjectId'],
  studyName: ['studySite.study.studyName'],
  siteName: ['studySite.site.siteName'],
  statusCode: ['currentStatus.statusCode']
};
const PATIENTS_TABLE_DATA = 'PATIENTS_TABLE_DATA';
class Patients extends Component {
  prevSearchQuery = {};
  pathname = undefined;
  unlisten = undefined;

  constructor(props, context) {
    super(props, context);
    this.state = {
      patientList: [],
      currentStatuses: undefined,
      ssuIds: undefined,
      totalPages: 1,
      pageSize: sessionStorage.getItem(PATIENTS_TABLE_DATA)
        ? JSON.parse(sessionStorage.getItem(PATIENTS_TABLE_DATA))?.pageSize
        : 25 //oneOf([25, 50, 100])
    };
  }

  componentWillMount() {
    this.pathname = this.props.location.pathname;
    this.syncReduxStateWithRouter(this.props.patientListFilters, null);
    this.onHistoryLocationChange(this.props.location);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.syncReduxStateWithRouter(this.props.patientListFilters, prevProps.patientListFilters);
    this.onHistoryLocationChange(this.props.location);
  }

  syncReduxStateWithRouter = (patientListFilters, prevPatientListFilters) => {
    const patientListFilterSearchQuery = parseFilters(patientListFilters),
      prevPatientListFilterSearchQuery = parseFilters(prevPatientListFilters),
      prevFilterSearchQuery = parseFilters(parse(this.props.location.search));

    if (
      !isEqual(patientListFilterSearchQuery, prevPatientListFilterSearchQuery) &&
      !isEqual(patientListFilterSearchQuery, prevFilterSearchQuery)
    ) {
      this.setFilterSearchQueryToUrl(patientListFilterSearchQuery);
    }
  };

  onHistoryLocationChange = location => {
    const searchQuery = parseFilters(parse(location.search));
    if (isEqual(searchQuery, this.prevSearchQuery) || this.pathname !== location.pathname) {
      return;
    }
    const { updatePatientListFilters } = this.props;
    updatePatientListFilters(prepareFilterSearchQueryForSave(searchQuery));

    this.prevSearchQuery = searchQuery;
  };

  setFilterSearchQueryToUrl(newValues) {
    const currentQueryParams = parse(this.props.location.search);
    const newQueryParams = { ...currentQueryParams, ...newValues };
    if (isEqual(currentQueryParams, newQueryParams)) {
      return;
    }
    setTimeout(() => {
      this.props.navigate(`/patients?${stringify(newQueryParams)}`, { replace: true });
    });
  }

  handleSSUFilterChange = (ssus, study, site) => {
    const stateToUpdate = {},
      { studyUniqueIdentifier, siteUniqueIdentifier, statuses } = this.prevSearchQuery,
      selectedStudyUniqueIdentifier = get(study, 'uniqueIdentifier'),
      selectedSiteUniqueIdentifier = get(site, 'uniqueIdentifier'),
      ssuIds = isArray(ssus) ? ssus.map(ssu => ssu.uniqueIdentifier) : [];

    if (!isEqual(selectedStudyUniqueIdentifier, studyUniqueIdentifier)) {
      stateToUpdate.studyUniqueIdentifier = selectedStudyUniqueIdentifier;
    }

    if (!isEqual(selectedSiteUniqueIdentifier, siteUniqueIdentifier)) {
      stateToUpdate.siteUniqueIdentifier = selectedSiteUniqueIdentifier;
    }

    if (!isEmpty(stateToUpdate)) {
      this.setFilterSearchQueryToUrl(stateToUpdate);
    }
    PatientApi.getPatients(ssuIds, statuses, 0, this.state.pageSize).then(({ data }) => {
      this.setState({ patientList: data.patients, totalPages: data.totalPages, ssuIds });
    });
  };

  handleStatusChange = newStatuses => {
    const { statuses } = this.prevSearchQuery;
    const selectedStatuses = isArray(newStatuses) && newStatuses.length > 0 ? [...newStatuses].map(e => e.id) : [];
    if (JSON.stringify(selectedStatuses) !== JSON.stringify(statuses)) {
      this.setFilterSearchQueryToUrl({ statuses: selectedStatuses });
    }
    if (this.state.ssuIds) {
      this.setState({ currentStatuses: selectedStatuses }, () => {
        PatientApi.getPatients(this.state.ssuIds, selectedStatuses, 0, this.state.pageSize).then(({ data }) => {
          this.setState({
            patientList: data.patients,
            totalPages: data.totalPages
          });
        });
      });
    }
  };

  updatePatientList() {
    const { patientListFilters } = this.props,
      statuses = get(patientListFilters, 'statuses');
    PatientApi.getPatients(this.state.ssuIds, statuses, 0, this.state.pageSize).then(({ data }) => {
      this.setState({
        patientList: data.patients,
        totalPages: data.totalPages
      });
    });
  }

  downloadCSV = () => {
    const { ssuIds } = this.state,
      {
        patientListFilters: { statuses }
      } = this.props;

    PatientApi.exportPatients({ statuses: statuses, ssuIds })
      .then(onFileSave)
      .catch(() => {
        NotificationManager.error(EXPORT_FAILED);
      });
  };

  onPatientButtonClick = () => {
    const addPatientInfoModal = ModalBoxes.open({
      component: (
        <AddOrEditPatientInfoModal
          onSave={() => {
            this.updatePatientList();
            addPatientInfoModal.close();
          }}
        />
      ),
      title: `Add Patient Info`
    });
  };

  onFetchData = ({ page, pageSize, sorted }) => {
    if (this.state.ssuIds) {
      const sortProperties = sorted.flatMap(({ id, desc }) => {
        return sortingPath[id].map(attr => ({ property: attr, direction: desc ? 'DESC' : 'ASC' }));
      });

      PatientApi.getPatients(
        this.state.ssuIds,
        get(this.props.patientListFilters, 'statuses'),
        page,
        pageSize,
        sortProperties
      ).then(({ data }) => {
        this.setState({
          patientList: data.patients,
          totalPages: data.totalPages,
          pageSize: pageSize
        });
        sessionStorage.setItem(PATIENTS_TABLE_DATA, JSON.stringify({ pageSize: pageSize }));
      });
    }
  };

  render() {
    const { patientList } = this.state,
      {
        patientListFilters: { studyUniqueIdentifier, siteUniqueIdentifier, statuses }
      } = this.props;
    return (
      <div className="footpadb">
        <PageInfoHeader
          right={
            <ButtonGroup>
              {userHasAccessTo(ADD_PATIENT) && (
                <Button size="h28" onClick={this.onPatientButtonClick}>
                  <Icon>add</Icon> Patient
                </Button>
              )}
              {userHasAccessTo(VIEW_PATIENTS) && (
                <Button size="h28" onClick={this.downloadCSV} priority="medium">
                  Export
                </Button>
              )}
            </ButtonGroup>
          }
        >
          <SSUFilter
            handleSSUFilterChange={this.handleSSUFilterChange}
            studyIdProvider={() => studyUniqueIdentifier}
            siteIdProvider={() => siteUniqueIdentifier}
          >
            <PatientDetailsSSUFilter
              statusIdProvider={() => statuses || []}
              handleStatusChange={this.handleStatusChange}
            />
          </SSUFilter>
        </PageInfoHeader>
        <PatientList
          patientList={patientList}
          onFetchData={this.onFetchData}
          totalPages={this.state.totalPages}
          pageSize={this.state.pageSize}
          patientListFilters={this.props.patientListFilters}
        />
      </div>
    );
  }
}

function parseFilters(patientListFilters) {
  const filters = pickBy(patientListFilters, (v, k) => {
    return ['studyUniqueIdentifier', 'siteUniqueIdentifier', 'statuses'].includes(k) && !isEmpty(v);
  });
  filters.statuses = filters.statuses ? flattenDeep([filters.statuses]) : [];
  return filters;
}

function prepareFilterSearchQueryForSave(searchQuery) {
  return {
    siteUniqueIdentifier: undefined,
    studyUniqueIdentifier: undefined,
    statuses: undefined,
    ...searchQuery
  };
}

function mapStateToProps(state) {
  return {
    patientListFilters: state.filters.patientList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatientListFilters: bindActionCreators(updatePatientListFilters, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocation(withNavigate(withNavigator(Patients))));
