import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import cx from 'classnames';
import { uniqBy } from 'lodash/array';
import { sortBy } from 'lodash/collection';
import { cloneDeep, isEmpty, isEqual, isString } from 'lodash/lang';
import { get } from 'lodash/object';
import { trim } from 'lodash/string';
import moment from 'moment';

import { PatientStatusApi, PreScreenApi } from '../../../../api';
import TableIconButton from '../../../../common/buttons/TableIconButton/TableIconButton';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { EXPORT_FAILED, PATIENT_STATUS_UPDATED } from '../../../../constants/notificationMessages';
import { CLOSED } from '../../../../constants/ssuStatuses';
import { MANAGE_SSU_PATIENT_STATUSES, PENDING_SCHEDULE_WORK_LIST } from '../../../../constants/userOperations';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../constants/userRoles';
import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';
import { userHasAccessTo, userHasRole } from '../../../../services/auth';
import { onFileSave, onRequestError } from '../../../../services/handlers';
import { caseInsensitiveStringIncludes } from '../../../../services/string';
import AccessDeniedTooltip from '../../../AccessDeniedTooltip/AccessDeniedTooltip';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { generateUrlByKey } from '../../../root/router';
import PatientStatusChangeModal from '../../patient-source/Patients/PatientInfo/PatientInfoSidebar/PatientStatusChangeModal';

import AppointmentColumn from './AppointmentColumn';
import { ScheduleAppointmentColumn } from './ScheduleAppointmentColumn';
import StatusChangePortal from './StatusChangePortal';
import WorklistButtonOrLink from './WorklistButtonOrLink';
import WorkListFilter from './WorklistFilter';
import { prepareRenderData, showScheduleAppointmentPlus } from './worklistService';

import './Worklist.scss';

const defaultSorted = [
  {
    id: 'fullName',
    desc: false
  }
];

export class Worklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      renderData: [],
      workListStatuses: [],
      selectedStudy: null,
      selectedSite: null,
      searchString: '',
      originalSearchString: '',
      selectedWorkListStatus: null
    };
  }

  onChangeStatus = (option, item) => {
    item.selectedStatus = option;
    this.setState(prevState => {
      return { renderData: prevState.renderData };
    });
  };

  columns = [
    {
      Header: 'Patient',
      accessor: 'fullName',
      Cell: row => {
        return (
          <Link
            to={generateUrlByKey('Worklist.Patient Profile.Patient Studies', {
              patientId: row.original.patientId,
              ssuPatientId: row.original.sitePatientId
            })}
          >
            {row.value}
            {row.original.preferredName && (
              <span className="patient-preferred-name"> ({row.original.preferredName})</span>
            )}
          </Link>
        );
      }
    },
    {
      Header: 'Site',
      accessor: 'site',
      Cell: ({ original }) => original.site.name
    },
    {
      Header: 'Ready for',
      accessor: 'readyFor'
    },
    {
      Header: 'Last Updated (Days)',
      accessor: 'idleTime',
      headerClassName: 'content-centered',
      className: 'content-centered',
      Cell: row => (row.value >= 0 ? row.value : '')
    }
  ];

  prepareColumns = itemGroupAccessors => {
    let columns = cloneDeep(this.columns);
    if (itemGroupAccessors && Object.keys(itemGroupAccessors).length > 0) {
      Object.keys(itemGroupAccessors).forEach(accessor => {
        columns.push({
          Header: itemGroupAccessors[accessor],
          accessor: `${accessor}.state`,
          headerClassName: 'content-centered',
          className: 'work-list-icon-column',
          Cell: row => this.renderCellInfoBasedOnAccess(row, accessor)
        });
      });
    }

    columns.push({
      Header: 'Appointment',
      id: 'appointment',
      headerClassName: 'content-centered',
      className: 'content-centered',
      width: 170,
      accessor: ({ appointment }) => appointment.type,
      Cell: ({ original }) => (
        <AppointmentColumn
          studySiteStatus={original.studySiteStatus}
          studyId={this.props.workListFilteringData.selectedStudyUniqueIdentifier}
          sitePatientId={original.sitePatientId}
          patientIdentifier={original.patientId}
          appointment={original.appointment}
        />
      )
    });

    columns.push({
      Header: 'Schedule Appointments',
      id: 'schedule',
      headerClassName: 'content-centered',
      className: 'content-centered',
      width: 170,
      show: userHasAccessTo(PENDING_SCHEDULE_WORK_LIST),
      accessor: showScheduleAppointmentPlus,
      Cell: ({ original }) =>
        showScheduleAppointmentPlus(original) && (
          <ScheduleAppointmentColumn
            selectedStudySites={{ id: original.studySiteIdentifier }}
            studySiteStatus={original.studySiteStatus}
            selectedStudies={original.study}
            selectedSites={original.site}
            patient={`${original.firstName} ${original.lastName}`}
          />
        )
    });
    columns.push({
      Header: 'Patient Status',
      accessor: 'status',
      headerStyle: { paddingLeft: '16px' },
      className: 'status-edit-cell',
      width: 230,
      Cell: row => {
        const isAllowedToEdit =
          userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
          (row.original.studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_SSU_PATIENT_STATUSES));
        return (
          <React.Fragment>
            <StatusChangePortal
              row={row}
              isAllowedToEdit={isAllowedToEdit}
              screeningStatuses={screeningStatuses}
              onChangeEditText={this.onChangeStatus}
            />
            {!row.original.allowStatusEdit && isAllowedToEdit && (
              <TableIconButton
                suit="glyphicon"
                color="blue"
                title="Edit status"
                onClick={() => this.editStatus(row.original)}
              >
                edit
              </TableIconButton>
            )}
            {row.original.allowStatusEdit && (
              <span>
                <TableIconButton
                  suit="material"
                  color="blue"
                  title="Edit status"
                  onClick={() => this.editStatusSave(row.original)}
                >
                  save
                </TableIconButton>
                <TableIconButton
                  suit="material"
                  color="blue"
                  title="Edit status"
                  onClick={() => this.editStatusCancel(row.original)}
                >
                  clear
                </TableIconButton>
              </span>
            )}
          </React.Fragment>
        );
      }
    });

    return columns;
  };

  componentDidMount() {
    const { workListFilteringData } = this.props;
    if (!isEmpty(workListFilteringData)) {
      this.loadWorkListPatients(workListFilteringData);
      this.getWorkListPatientsCounts(workListFilteringData);
    }
  }

  renderCellInfoBasedOnAccess(row, accessor) {
    const disabled = isAccessRestricted(row.original[accessor]);
    if (disabled) {
      return (
        <AccessDeniedTooltip>
          <div className={cx('worklist-icon', { 'blinding-cell-grey': disabled })}>
            {row.original[accessor]
              ? this.getTemplateByElementState(row.original[accessor], row.original, disabled)
              : null}
          </div>
        </AccessDeniedTooltip>
      );
    } else {
      return (
        <div className={cx('worklist-icon', { 'blinding-cell-grey': disabled })}>
          {row.original[accessor]
            ? this.getTemplateByElementState(row.original[accessor], row.original, disabled)
            : null}
        </div>
      );
    }
  }

  componentDidUpdate({ workListFilteringData: prevWorkListFilteringData }) {
    const { workListFilteringData: thisWorkListFilteringData } = this.props;
    if (!isEqual(prevWorkListFilteringData, thisWorkListFilteringData)) {
      this.reLoadOrReFilterWorkListPatients(prevWorkListFilteringData, thisWorkListFilteringData);
      this.getWorkListPatientsCounts(thisWorkListFilteringData);
    }
  }

  getWorkListPatientsCounts = res => {
    const selectedSite = res.selectedSite ? res.selectedSite.id : null;
    res.selectedStudyUniqueIdentifier &&
      PreScreenApi.getWorkListPatientsCounts(res.selectedStudyUniqueIdentifier, selectedSite).then(({ data }) => {
        this.setState({
          consentedPatientsProgressCount: data.consentedPatientsProgressCount,
          statusCounts: data.statusCounts,
          toDoItemGroupsCount: data.toDoItemGroupsCount
        });
      });
  };

  reLoadOrReFilterWorkListPatients(prevWorkListFilteringData, thisWorkListFilteringData) {
    const isSelectedStudyNotTheSameAsPreviously = isEqual(
      prevWorkListFilteringData && prevWorkListFilteringData.selectedStudyUniqueIdentifier,
      thisWorkListFilteringData && thisWorkListFilteringData.selectedStudyUniqueIdentifier
    );
    isSelectedStudyNotTheSameAsPreviously && this.state.originalRenderData
      ? this.reFilterWorkListPatients(thisWorkListFilteringData)
      : this.loadWorkListPatients(thisWorkListFilteringData);
  }

  reFilterWorkListPatients(workListFilteringData) {
    this.setState(({ originalRenderData }) => {
      const renderData = this.getFilteredWorkListPatients(originalRenderData, workListFilteringData);
      return { renderData };
    });
  }

  loadWorkListPatients = ({ selectedStudyUniqueIdentifier, ...siteStatusFilters }) => {
    selectedStudyUniqueIdentifier &&
      PreScreenApi.getWorkListPatients(selectedStudyUniqueIdentifier).then(res => {
        const renderData = prepareRenderData(res.data);
        const wlStatuses = this.generateUniqueWorkListStatuses(renderData.patients);
        this.setState({
          columns: this.prepareColumns(renderData.itemGroupAccessors),
          originalRenderData: renderData.patients,
          renderData: this.getFilteredWorkListPatients(renderData.patients, siteStatusFilters),
          workListStatuses: wlStatuses
        });
      });
  };

  getFilteredWorkListPatients = (allWorkListPatients, { selectedSite, selectedWorkListStatus }) => {
    const filteredBySite = this.filterWorkListPatientsBySite(selectedSite, allWorkListPatients);
    return this.filterWorkListPatientsByStatus(selectedWorkListStatus, filteredBySite);
  };

  editStatus = item => {
    this.setState(prevState => {
      prevState.renderData.filter(item => item.allowStatusEdit).map(this.resetValues);
      item.allowStatusEdit = true;
      return { renderData: prevState.renderData };
    });
  };

  resetValues = item => {
    item.allowStatusEdit = false;
    delete item.selectedStatus;
  };

  editStatusCancel = item => {
    this.resetValues(item);
    this.setState(prevState => {
      return { renderData: prevState.renderData };
    });
  };

  editStatusSave = item => {
    if (item.selectedStatus) {
      this.updateStatus(item);
    } else {
      item.allowStatusEdit = false;
      this.setState(prevState => {
        return { renderData: prevState.renderData };
      });
    }
  };

  updateStatus(item) {
    if (item.selectedStatus.isDropStatus) {
      this.openDropStatusModal(item);
    } else {
      this.updateEndingStatusForPatient(item);
    }
  }

  updateEndingStatusForPatient(item) {
    const id = item.selectedStatus.id;
    PatientStatusApi.changeStatus(item.sitePatientId, {
      statusCodeId: id,
      statusChangeLocation: 'PRE_SCREEN_WORKLIST',
      statusDate: moment().format('YYYY-MM-DD')
    }).then(() => this.successStatusChangeCallback(item), onRequestError);
  }

  getTemplateByElementState = (el, original, disabled) => {
    return <WorklistButtonOrLink studySiteStatus={original.studySiteStatus} {...el} disabled={disabled} />;
  };

  generateUniqueWorkListStatuses = renderData => {
    const workListStatuses = renderData.map(patient => {
      return { id: patient.readyFor, name: patient.readyFor };
    });
    return sortBy(sortBy(uniqBy(workListStatuses, 'id'), 'name'), e => e.name === 'Complete');
  };

  filterWorkListPatientsBySite = (site, filteredByStudy) => {
    const name = get(site, 'name');
    if (isString(name)) {
      return filteredByStudy.filter(p => p.site.name === site.name);
    } else {
      return filteredByStudy;
    }
  };

  filterWorkListPatientsByStatus = (workListStatus, filteredBySite) => {
    const name = get(workListStatus, 'name');
    if (isString(name)) {
      return filteredBySite.filter(p => p.readyFor === name);
    } else {
      return filteredBySite;
    }
  };

  onSearch = ({ target }) => {
    this.setState({
      searchString: target.value,
      originalSearchString: target.value
    });
  };

  prepareFilteredRenderData = (searchString, renderData) => {
    if (renderData && renderData.length > 0 && searchString) {
      return renderData.filter(({ firstName, lastName }) => {
        const fullName = `${lastName}, ${firstName}`;
        return (
          caseInsensitiveStringIncludes(firstName, searchString) ||
          caseInsensitiveStringIncludes(lastName, searchString) ||
          caseInsensitiveStringIncludes(fullName, searchString)
        );
      });
    } else {
      return renderData;
    }
  };

  downloadCSV = () => {
    const { workListFilteringData } = this.props;
    const status = get(workListFilteringData, `selectedWorkListStatus.id`);
    const trimSearchString = trim(this.state.searchString);
    const patientName = isEmpty(trimSearchString) ? null : trimSearchString;
    const selectedSite = get(workListFilteringData, `selectedSite.id`);
    PreScreenApi.exportPatients(workListFilteringData.selectedStudyUniqueIdentifier, selectedSite, status, patientName)
      .then(onFileSave)
      .catch(() => {
        NotificationManager.error(EXPORT_FAILED);
      });
  };

  render({ state } = this) {
    const { searchString, renderData } = state;
    const filteredRenderData = this.prepareFilteredRenderData(searchString, renderData);

    return (
      <>
        <PageInfoHeader
          pageInfo={
            state.toDoItemGroupsCount &&
            state.statusCounts &&
            state.consentedPatientsProgressCount && [
              <React.Fragment>
                <PageInfoHeader.AdditionalInfo title="Queue">
                  {state.toDoItemGroupsCount.queue} ( Chart Review: {state.toDoItemGroupsCount.chartReview} , Outreach:{' '}
                  {state.toDoItemGroupsCount.outreach} )
                </PageInfoHeader.AdditionalInfo>
                <PageInfoHeader.AdditionalInfo title="Pre-screened">
                  {state.statusCounts.preScreened}
                </PageInfoHeader.AdditionalInfo>
                <PageInfoHeader.AdditionalInfo title="Pre-screen Failed">
                  {state.statusCounts.preScreenFailed}
                </PageInfoHeader.AdditionalInfo>
                <PageInfoHeader.AdditionalInfo title="Consented">
                  {state.consentedPatientsProgressCount.enrollments}/{state.consentedPatientsProgressCount.consented}
                </PageInfoHeader.AdditionalInfo>
              </React.Fragment>
            ]
          }
          right={
            <Button size="h28" priority="medium" onClick={this.downloadCSV}>
              Export
            </Button>
          }
        >
          <WorkListFilter
            workListStatuses={state.workListStatuses}
            onSearch={this.onSearch}
            searchValue={this.state?.originalSearchString}
          />
        </PageInfoHeader>
        <div className="footpadb iefixflex work-list">
          <section>
            <div className="table-container col-12 border p-3 m-0 mt-2">
              <ReactTable
                data={filteredRenderData || []}
                defaultSorted={defaultSorted}
                columns={state.columns}
                minRows={1}
                showPagination
                nextText=">>"
                previousText="<<"
                onSortedChange={pendoTrackDefaultSortingChange}
                noDataText="No Record Found"
                pageSizeOptions={[25, 50, 100]}
                defaultPageSize={25}
              />
            </div>
          </section>
        </div>
      </>
    );
  }
  successStatusChangeCallback(item) {
    item.allowStatusEdit = false;
    item.status = item.selectedStatus;
    this.updatePage();
    NotificationManager.success(PATIENT_STATUS_UPDATED);
  }

  openDropStatusModal(tableRow) {
    const patientStatusesChangeModal = ModalBoxes.open({
      component: (
        <PatientStatusChangeModal
          initialStatus={tableRow.selectedStatus}
          allowedStatusesPredicate={onlyPreScreenStatus}
          updateStatus={(status, reason, comment) =>
            this.updatePreScreenStatus(tableRow.sitePatientId, status, reason, comment).then(() => {
              this.successStatusChangeCallback(tableRow);
              patientStatusesChangeModal.close();
            }, onRequestError)
          }
        />
      )
    });

    function onlyPreScreenStatus(status) {
      return screeningStatuses.map(screeningStatus => screeningStatus.id).includes(status.id);
    }
  }

  updatePreScreenStatus(sitePatientId, status, reason, comment) {
    return PatientStatusApi.changeStatus(sitePatientId, {
      statusCodeId: status?.id,
      withdrawReason: reason?.id,
      comment,
      statusChangeLocation: 'PRE_SCREEN_WORKLIST',
      statusDate: moment().format('YYYY-MM-DD')
    });
  }

  updatePage = () => {
    this.loadWorkListPatients(this.props.workListFilteringData);
    this.getWorkListPatientsCounts(this.props.workListFilteringData);
  };
}

function mapStateToProps({ workListFilteringData }) {
  return { workListFilteringData };
}

function isAccessRestricted(props) {
  if (props?.state === 'TO_DO') {
    return props?.accessRestricted;
  } else {
    return false;
  }
}

export default connect(mapStateToProps, null)(Worklist);

const screeningStatuses = [
  {
    name: 'Identified',
    code: 'IDENTIFIED',
    id: 'IDENTIFIED',
    isDropStatus: false
  },
  {
    name: 'Pre-screened',
    code: 'PRE_SCREENED',
    id: 'PRE-SCREENED',
    isDropStatus: false
  },
  {
    name: 'Pre-screen Failed',
    code: 'PRE_SCREEN_FAILED',
    id: 'PRE-SCREEN-FAILED',
    isDropStatus: true
  },
  {
    name: 'Canceled',
    code: 'CANCELED',
    id: 'CANCELED',
    isDropStatus: true
  }
];
