import React, { useState } from 'react';
import cx from 'classnames';

import Divider from '../../common/elements/Divider/Divider';
import Icon from '../../common/general/Icon';
import { useCurrentRoute } from '../root/router';

import AdditionalInfo from './AdditionalInfo/AdditionalInfo';
import CollapsibleList from './CollapsibleList/CollapsibleList';

import './PageInfoHeader.scss';

export const PageInfoHeader = ({ children, right, pageInfo, name, objectRecordLabel, className, withLabel }) => {
  const currentRoute = useCurrentRoute();

  const [collapsed, setCollapsed] = useState(false);

  return (
    <div
      className={cx('page-info-header', className, {
        'page-info-header-with-record-label': objectRecordLabel,
        'filters-section-collapsed': collapsed
      })}
    >
      <div className="page-info">
        {withLabel && (
          <div className="left-section">
            <h1>
              {name} {currentRoute.name}
              {objectRecordLabel && (
                <>
                  <Divider type="vertical" />
                  <span>{objectRecordLabel}</span>
                </>
              )}
            </h1>
          </div>
        )}
        <div className="additional-info">{pageInfo}</div>
        <div className="right-section">{right}</div>
      </div>
      {children && (
        <>
          <div className="filters-section">{children}</div>
          <div className="page-info-header-collapse-btn" onClick={() => setCollapsed(v => !v)}>
            <Icon>{collapsed ? 'expand_more' : 'expand_less'}</Icon>
          </div>
        </>
      )}
    </div>
  );
};

PageInfoHeader.defaultProps = {
  withLabel: true
};
PageInfoHeader.AdditionalInfo = AdditionalInfo;
PageInfoHeader.CollapsibleList = CollapsibleList;
