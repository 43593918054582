import React, { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash/lang';

import { useCurrentRoute } from '../../../../../root/router';
import { getItemGroupType } from '../ReviewContent/reviewContentService';
import { ReviewContext } from '../ReviewContext';
import {
  itemGroupSnapshotReviewStateHasRecordsOrNotPerformed,
  logSnapshotReviewStateHasRecords
} from '../reviewService';

import ReviewSidebarItem from './ReviewSidebarItem/ReviewSidebarItem';
import * as logCheckStatusService from './logCheckStatusService';
import * as logStatusService from './logStatusService';
import * as procedureLikeService from './procedureLikeStatusService';

import './ReviewSideBar.scss';

export function ReviewSideBar() {
  const {
    itemGroups,
    logItemGroups,
    logCheckItemGroups,
    setChosenItem,
    itemGroupSnapshotReviewStates,
    logSnapshotReviewStates,
    logCheckSnapshotReviewStates
  } = useContext(ReviewContext);
  const currentRoute = useCurrentRoute();
  const isInvestigatorOrStudyManagerReview = useMemo(() => {
    return currentRoute.key.includes('Investigator Review') || currentRoute.key.includes('SM Review');
  }, [currentRoute]);
  const isCraReview = useMemo(() => {
    return currentRoute.key.includes('CRA Review');
  }, [currentRoute]);
  return (
    <div className="eds-review-sidebar">
      {!isEmpty(itemGroups) && (
        <div className="eds-review-sidebar-list">
          <h4>Encounter Items</h4>
          <div className="eds-review-sidebar-items">
            {itemGroups.map(item => {
              const itemGroupSnapshotReviewState = itemGroupSnapshotReviewStates[item.patientItemGroupId];
              const highlightedAnswersCount =
                itemGroupSnapshotReviewState?.itemGroupSnapshotState?.dataStatus?.highlightedAnswersCount;
              return (
                <ReviewSidebarItem
                  onClick={() => setChosenItem(item.patientItemGroupId)}
                  hasRecords={itemGroupSnapshotReviewStateHasRecordsOrNotPerformed(itemGroupSnapshotReviewState)}
                  key={item.patientItemGroupId}
                  name={item.patientItemGroupName}
                  loading={!item.blinded && !itemGroupSnapshotReviewState}
                  highlightedAnswersCount={highlightedAnswersCount}
                  type={getItemGroupType(item)}
                  itemGroupSnapshotReviewState={itemGroupSnapshotReviewState}
                  blinded={item.blinded}
                  isNotPerformed={procedureLikeService.isNotPerformed(itemGroupSnapshotReviewStates, item)}
                  isSigned={procedureLikeService.isSigned(itemGroupSnapshotReviewStates, item)}
                  isDisabled={procedureLikeService.isDisabled(itemGroupSnapshotReviewStates, item)}
                />
              );
            })}
          </div>
        </div>
      )}
      {!isEmpty(logCheckItemGroups) && (
        <div className="eds-review-sidebar-list">
          <h4>Log Checks</h4>
          <div className="eds-review-sidebar-items">
            {logCheckItemGroups.map(item => {
              const logCheckSnapshotReviewState = logCheckSnapshotReviewStates[item.itemGroupTemplateId];
              return (
                <ReviewSidebarItem
                  onClick={() => {
                    setChosenItem(item.itemGroupTemplateId);
                  }}
                  key={item.itemGroupTemplateId}
                  item={item}
                  name={item.patientItemGroupName}
                  loading={!item.blinded && !logCheckSnapshotReviewState}
                  logCheckSnapshotReviewState={logCheckSnapshotReviewState}
                  type="LogChecks"
                  blinded={item.blinded}
                  isNotPerformed={logCheckStatusService.isNotPerformed(logCheckSnapshotReviewState)} // NP status check it for logcheck
                  isSigned={logCheckStatusService.isSigned(logCheckSnapshotReviewState)} // is signed
                  isDisabled={logCheckStatusService.isDisabled(logCheckSnapshotReviewState)}
                />
              );
            })}
          </div>
        </div>
      )}
      {!isEmpty(logItemGroups) && (
        <div className="eds-review-sidebar-list">
          <h4>Log Entries</h4>
          <div className="eds-review-sidebar-items">
            {logItemGroups.map(item => {
              const logSnapshotReviewState = logSnapshotReviewStates[item.itemGroupTemplateId];
              const highlightedAnswersCount =
                logSnapshotReviewState?.logSnapshotState?.dataStatus?.highlightedAnswersCount;
              return (
                <ReviewSidebarItem
                  onClick={() => {
                    if (isInvestigatorOrStudyManagerReview && item.domainCode !== 'AE') {
                      setChosenItem(item.itemGroupTemplateId);
                    }
                    if (isCraReview) {
                      setChosenItem(item.itemGroupTemplateId);
                    }
                  }}
                  hasRecords={logSnapshotReviewStateHasRecords(logSnapshotReviewState)}
                  key={item.itemGroupTemplateId}
                  name={item.name}
                  loading={!item.blinded && !logSnapshotReviewState}
                  highlightedAnswersCount={highlightedAnswersCount}
                  type="Logs"
                  item={item}
                  blinded={item.blinded}
                  isNotPerformed={logStatusService.isNotPerformed(logSnapshotReviewStates, item)}
                  isSigned={logStatusService.isSigned(logSnapshotReviewStates, item)}
                  isDisabled={logStatusService.isDisabled(logSnapshotReviewStates, item)}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
