import { useEffect, useMemo, useState } from 'react';

import { FvCsvViewer } from '../../FileViewer/FvCsvViewer/FvCsvViewer';
import { FvNoViewer } from '../../FileViewer/FvNoViewer/FvNoViewer';
import { FvPdfViewer } from '../../FileViewer/FvPdfViewer/FvPdfViewer';
import EuiImageViewer from '../EuiImageViewer/EuiImageViewer';

import { useEuiFileUpload } from './EuiFileUploadProvider';

import './EuiFileUploadPreview.scss';
export default function EuiFileUploadPreview() {
  const { files, selectedFileId, fileUrlGetter } = useEuiFileUpload();
  const [file, setFile] = useState(null);

  useEffect(
    function() {
      if (!selectedFileId) return null;
      const file = files.find(function({ id }) {
        return id === selectedFileId;
      });
      if (file.fileInstance) {
        const fileUrl = URL.createObjectURL(file.fileInstance);
        setFile({ ...file, fileUrl });
        return;
      }
      fileUrlGetter(file).then(function(fileUrl) {
        setFile({ ...file, fileUrl });
      });
    },
    [fileUrlGetter, files, selectedFileId]
  );

  const Viewer = useMemo(
    function() {
      if (!file) return null;
      return componentResolver(file && file.fileType);
    },
    [file]
  );

  return (
    <div className="eui-file-upload-preview">
      {Viewer ? <Viewer file={file} /> : <div className="eui-file-upload-preview-empty">No files for preview yet.</div>}
    </div>
  );
}

function componentResolver(type) {
  switch (type) {
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
      return EuiImageViewer;
    case 'application/pdf':
      return withPropsProxy(FvPdfViewer);
    case 'text/csv':
      return withPropsProxy(FvCsvViewer);
    default:
      return withPropsProxy(FvNoViewer);
  }
}

function withPropsProxy(Component) {
  return function WithPropsProxy({ file }) {
    const fileProxy = useMemo(
      function() {
        return { url: file.fileUrl, name: file.fileName, type: file.fileType, file: file.fileInstance };
      },
      [file]
    );
    return <Component file={fileProxy} />;
  };
}
