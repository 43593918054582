import { cycleTimeReportRouterConfig } from './CycleTimeReport/routerConfig';
import { studyEnrollmentRouterConfig } from './Enrollment/routerConfig';
import { fixedItemRouterConfig } from './FixedItem/routerConfig';
import { milestoneRouterConfig } from './MilestoneReport/routerConfig';
import { studyDetailsRouterConfig } from './StudyDetails/routerConfig';
import { timelineRouterConfig } from './Timeline/routerConfig';

export const studyRouterConfig = [
  cycleTimeReportRouterConfig,
  studyDetailsRouterConfig,
  timelineRouterConfig,
  milestoneRouterConfig,
  studyEnrollmentRouterConfig,
  fixedItemRouterConfig
];
