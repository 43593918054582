import React, { useContext } from 'react';
import cx from 'classnames';

import { ProtocolBlindingSetupContext } from '../ProtocolBlindingSetupContext';
import { CHANGE_BLINDING_GROUP } from '../сonfirmationDialogService';

import './BlindingGroups.scss';

export default function BlindingGroups() {
  const { blindingGroups, activeBlindingGroupId, setActiveBlindingGroup, cancelConfiguration } = useContext(
    ProtocolBlindingSetupContext
  );
  return (
    <div className="pbs-blinding-groups">
      <span>Blinding Groups</span>
      <ul className="pbs-blinding-groups-list">
        {blindingGroups.map(blindingGroup => {
          const blindingGroupId = blindingGroup.key.blindingGroupId;
          const isActive = activeBlindingGroupId === blindingGroupId;
          return (
            <li
              onClick={() => cancelConfiguration(() => setActiveBlindingGroup(blindingGroup), CHANGE_BLINDING_GROUP)}
              className={cx({ 'pbs-active-blinding-group': isActive })}
              key={blindingGroupId}
            >
              {blindingGroup.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
