import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';

import { GridToolbarTableViewState } from './GridToolbarTableViewState';

export const CustomToolbar = ({ localStorageTableName }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} csvOptions={{ utf8WithBom: true }} />
      <GridToolbarTableViewState localStorageTableName={localStorageTableName} />
      <GridToolbarQuickFilter sx={{ margin: '0 0 0 auto' }} />
    </GridToolbarContainer>
  );
};
