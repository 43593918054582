import { lowerCase } from 'lodash';
import { get } from 'lodash/object';
import moment from 'moment';

class Common {
  constructor() {
    this.loaderIns = null;
  }

  static dateComparator(a, b) {
    const moment1 = moment(a, 'DD/MMM/YYYY hh:mm');
    const moment2 = moment(b, 'DD/MMM/YYYY hh:mm');
    if (a === null) return -1;
    if (b === null) return 1;
    return moment1.diff(moment2);
  }

  static formatDate(d, format) {
    return d ? moment(d).format(format || 'DD/MMM/YYYY') : null;
  }

  static getRandomNumber() {
    return `${Math.floor(Math.random() * 9000000000) + 1000000000}${new Date().getTime()}`;
  }

  static getTimefromDate(d, type) {
    if (type === 12 || type === '12') {
      return moment(d).format('hh:mm a');
    }

    return moment(d).format('HH:mm');
  }

  static setLoaderRef(loaderIns) {
    this.loaderIns = loaderIns;
  }

  static getLoaderRef() {
    return this.loaderIns;
  }

  static showLoader() {
    return this.loaderIns ? this.loaderIns.showLoader() : '';
  }

  static hideLoader() {
    return this.loaderIns ? this.loaderIns.hideLoader() : '';
  }

  static sortByOrder(array, by, feild) {
    if (array) {
      if (feild) {
        if (by && by === 'ASC') {
          return array.sort(this.generateSortFuncObjectFieldAlphabetical(feild));
        }
        if (by && by === 'DESC') {
          return array.sort(this.generateSortFuncObjectFieldAlphabetical(feild, true));
        }
        return array;
      }
      if (by && by === 'ASC') {
        return array.sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence));
      }
      if (by && by === 'DESC') {
        // Isn't this backwards?!!
        // Never used with DESC param need to cleanup
        return array.sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence));
      }
      return array;
    }
  }

  static generateSortFuncObjectFieldAlphabetical(field, desc) {
    let multiplier = 1;
    if (desc) {
      multiplier = -1;
    }
    return (a, b) => {
      const a1 = lowerCase(get(a, field));
      const b1 = lowerCase(get(b, field));
      return multiplier * (a1 < b1 ? -1 : a1 > b1 ? 1 : 0);
    };
  }

  static getSubString(str, count) {
    if (str && str.length > count) {
      return `${str.substring(0, count)}...`;
    }
    return str;
  }

  // TODO: need to delete this method and replace usages with formDefinitionService.js:collectLabelListDefinitions
  static getSortedBySequenceFields(data) {
    let array = [];
    data &&
      data.forEach((item, index) => {
        if (item.hasOwnProperty('itemDefinitionList') && item.itemDefinitionList && item.itemDefinitionList.length) {
          array = array.concat(item.itemDefinitionList);
        }
        if (
          item.hasOwnProperty('customItemDefinationList') &&
          item.customItemDefinationList &&
          item.customItemDefinationList.length
        ) {
          array = array.concat(item.customItemDefinationList);
        }
      });

    return array;
  }
}

export default Common;
