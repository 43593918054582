import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import { BlindingConfigApi } from '../../../../../../../api';
import { fillConfiguredGrantedMap } from '../../../../../../../services/protocolBlinding';
import { useCurrentRoute } from '../../../../../../root/router';
import BlindingConfigurationTable from '../../../BlindingConfigurationTable/BlindingConfigurationTable';

import './BlindingGroupConfig.scss';

export function BlindingGroupConfig(props) {
  const {
    blindingGroupId,
    name,
    itemGroupsEncounterGrantedMap,
    epochs,
    encounters,
    itemGroups,
    setListOfItemGroupsTemplateId,
    listOfItemGroupsTemplateId
  } = props;
  const {
    params: { studyId, protocolIdentity }
  } = useCurrentRoute();
  const [itemGroupsEncounterGrantedMapForBlindingGroup, setItemGroupsEncounterGrantedMapForBlindingGroup] = useState(
    {}
  );
  const [blindingConfiguration, setBlindingConfiguration] = useState({});
  const isEmptyBlindingConfiguration = useMemo(
    function() {
      const { logItemGroupsConfig, encountersItemGroupsConfig } = blindingConfiguration;
      return isEmpty(logItemGroupsConfig) && isEmpty(encountersItemGroupsConfig);
    },
    [blindingConfiguration]
  );
  useEffect(() => {
    BlindingConfigApi.getBlindingGroupConfiguration(studyId, blindingGroupId, protocolIdentity).then(
      ({ data: { setup: blindingConfiguration } }) => {
        setBlindingConfiguration(blindingConfiguration);
      }
    );
  }, [blindingGroupId, protocolIdentity, studyId]);

  useEffect(() => {
    if (!isEmpty(blindingConfiguration) && !listOfItemGroupsTemplateId) {
      setListOfItemGroupsTemplateId(extractListOfItemGroupsTemplateId(blindingConfiguration));
    }
  }, [blindingConfiguration, listOfItemGroupsTemplateId, setListOfItemGroupsTemplateId]);

  useEffect(() => {
    if (!isEmpty(itemGroupsEncounterGrantedMap) && !isEmptyBlindingConfiguration) {
      setItemGroupsEncounterGrantedMapForBlindingGroup(
        fillConfiguredGrantedMap(itemGroupsEncounterGrantedMap, blindingConfiguration, itemGroups)
      );
    }
  }, [blindingConfiguration, itemGroupsEncounterGrantedMap, isEmptyBlindingConfiguration, itemGroups]);

  return (
    <React.Fragment>
      {!isEmptyBlindingConfiguration && !isEmpty(itemGroups) && (
        <div className="blinding-group-config lower-then-full-page" id={blindingGroupId}>
          <div className="blinding-group-config-header">
            <h5>Blinding Setup: {name}</h5>
          </div>
          <BlindingConfigurationTable
            itemGroupsList={itemGroups}
            encounters={encounters}
            itemGroupsEncounterGrantedMapForActiveBlindingGroup={itemGroupsEncounterGrantedMapForBlindingGroup}
            epochs={epochs}
            blindingGroupId={blindingGroupId}
          />
        </div>
      )}
    </React.Fragment>
  );
}

BlindingGroupConfig.propTypes = {
  blindingGroupId: PropTypes.string,
  name: PropTypes.string,
  itemGroupsEncounterGrantedMap: PropTypes.object,
  epochs: PropTypes.array,
  encounters: PropTypes.array,
  itemGroups: PropTypes.array,
  setListOfItemGroupsTemplateId: PropTypes.func
};

function extractListOfItemGroupsTemplateId({ itemGroupsConfig, encountersItemGroupsConfig }) {
  const itemGroupTemplateIdExtracting = ({ itemGroupTemplateId }) => itemGroupTemplateId;
  return [
    ...itemGroupsConfig.map(itemGroupTemplateIdExtracting),
    ...encountersItemGroupsConfig.map(itemGroupTemplateIdExtracting)
  ];
}
