import React from 'react';
import cx from 'classnames';
import cloneDeep from 'lodash/cloneDeep';

import common from '../../../../../../../../common/common';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import CustomFieldConfiguration from '../../../CustomFieldConfiguration/CustomFieldConfiguration';
import EditCustomFieldModal from '../../../EditCustomFieldModal';
import { customClassObject } from '../../customClassObject';
import { dynamicClassesForFieldName } from '../../itemGroupSetupRenderHelpService';
import { EditFormAnswerView } from '../OptionalFields/EditFormAnswerView';

import FieldPreview from './FieldPreview';

import './CustomField.scss';

export function CustomField({
  terminologyVersionGroupNumber,
  editMode,
  formLabelData,
  domain,
  labelIndex,
  item,
  index,
  getCheckboxForTableView,
  getInvestigatorFieldCheckboxForTableView,
  getFixedItemCheckboxForTableView,
  inputs,
  updateInputsAndPreviewData,
  updateState,
  onChangeLabelTextHandler,
  isInvestigatorFieldAvailable,
  isAdverseEventLog,
  isLogViewCheckboxAvailable
}) {
  const tableChckUniqId = `table${common.getRandomNumber()}`;
  const fixedFieldChckUuid = `table${common.getRandomNumber()}`;
  const isTypeBlockLong = item.inputType === 'textBlockLong';
  const isTypeReminder = item.inputType === 'reminder';
  const isTypeCheckBox = item.inputType === 'checkbox';
  const isLabelShown = !isTypeReminder && !isTypeCheckBox;
  const questionName = item.updatedQuestion ? item.updatedQuestion : item.question;

  if (item.updatedQuestion) {
    item.question = item.updatedQuestion;
  }
  return (
    <div key={index} className="mt-0 col-md-12 col-lg-12 pl-0 pr-0">
      <div className="row">
        {isLabelShown && (
          <div
            className={cx({
              'col-md-5': domain !== 'ICF',
              'col-md-7': domain === 'ICF'
            })}
          >
            <div className="row">
              <i
                style={{ fontSize: '17px', lineHeight: '32px' }}
                className="glyphicon glyphicon-edit editicn pl-0 pr-2"
                onClick={() => onEditCustomFormField(formLabelData, labelIndex)}
              />
              {isTypeBlockLong ? (
                <div className="col-md-6 p-0 overflow-ellipsis-multiple-line">
                  <label style={{ lineHeight: '33px' }} className={dynamicClassesForFieldName(item)}>
                    {questionName}
                  </label>
                </div>
              ) : (
                <div className="custom-field-wrapper pl-0 overflow-ellipsis">
                  <label style={{ lineHeight: '33px' }} className={dynamicClassesForFieldName(item)}>
                    {questionName}
                  </label>
                </div>
              )}
            </div>
          </div>
        )}

        {isLabelShown ? (
          <FieldPreview
            labelText={item.label}
            data={item}
            disabled={true}
            formId={inputs[0].formId}
            formLabelId={formLabelData.formLableId}
            customClassObject={customClassObject}
            itemDefIndex={index}
            onChangeLabelTextHandler={e => onChangeLabelTextHandler(e.target.value, labelIndex, index, true)}
          />
        ) : (
          <div className="col-md-8">
            <div className="row">
              <i
                style={{ fontSize: '17px', lineHeight: '32px' }}
                className="glyphicon glyphicon-edit editicn pl-0 pr-2"
                onClick={() => onEditCustomFormField(formLabelData, labelIndex)}
              />
              <FieldPreview
                labelText={item.label}
                data={item}
                disabled={true}
                formId={inputs[0].formId}
                formLabelId={formLabelData.formLableId}
                customClassObject={customClassObject}
                itemDefIndex={index}
                onChangeLabelTextHandler={e => onChangeLabelTextHandler(e.target.value, labelIndex, index, true)}
              />
            </div>
          </div>
        )}

        <div
          className={`col-md-auto pr-4 d-flex ${
            item.isExtensible === 0 ? 'pl19' : 'pl-0'
          } itemSeq itemMinWidth align-items-center`}
        >
          <EditFormAnswerView
            isExtensible={item.isExtensible}
            updateInputsAndPreviewData={updateInputsAndPreviewData}
            item={item}
            labelIndex={labelIndex}
            index={index}
            inputs={inputs}
            terminologyVersionGroupNumber={terminologyVersionGroupNumber}
            editMode={editMode}
            custom={true}
          />
          <CustomFieldConfiguration
            item={item}
            allInputs={inputs}
            onChange={() => updateState(updateInputsAndPreviewData(inputs))}
          />
          {domain !== 'ICF' && (
            <>
              <i className="material-icons formseqicon" title="Form View">
                library_books
              </i>
              <input
                type="text"
                readOnly
                value={item.sequence ? Number(item.sequence) : ''}
                title="Form Item Sequence"
                className="form-control itemSeqWidth"
              />
            </>
          )}
        </div>
        {isAdverseEventLog() && getFixedItemCheckboxForTableView(item, fixedFieldChckUuid, labelIndex, index, 'custom')}
        {isInvestigatorFieldAvailable() && getInvestigatorFieldCheckboxForTableView(item, labelIndex, index, 'custom')}
        {isLogViewCheckboxAvailable &&
          domain !== 'ICF' &&
          getCheckboxForTableView(item, tableChckUniqId, labelIndex, index, 'custom')}
      </div>
    </div>
  );
  function onEditCustomFormField(customFieldData, index) {
    const customQuestion = customFieldData.customItemDefinationList[0];
    ModalBoxes.open({
      component: (
        <EditCustomFieldModal
          onSave={updatedCustomFieldName => {
            const originInputs = cloneDeep(inputs);
            if (originInputs && originInputs[0] && originInputs[0].custom) {
              const customDomainElementToUpdate = originInputs[0].custom.find((customElement, innerIndex) => {
                return index === innerIndex;
              });

              const customElement = customDomainElementToUpdate.customItemDefinationList[0];
              if (customDomainElementToUpdate && customElement) {
                customElement.updatedQuestion = updatedCustomFieldName;
                // For check box we need also update code element that is reflection of custom element //TODO
                if (
                  customElement.inputType === 'checkbox' &&
                  customElement.codeDefinationList &&
                  customElement.codeDefinationList.length === 1
                ) {
                  customElement.codeDefinationList[0].codedValue = updatedCustomFieldName;
                  customElement.codeDefinationList[0].decode = updatedCustomFieldName;
                  customElement.codeDefinationList[0].name = updatedCustomFieldName;
                }
                originInputs[0].custom[index] = customDomainElementToUpdate;
                updateInputsAndPreviewData(originInputs);
              }
            }
          }}
          originalCustomFieldName={
            customQuestion.updatedQuestion ? customQuestion.updatedQuestion : customQuestion.question
          }
        />
      )
    });
  }
}
