/** ScopeType.GLOBAL **/
export const COPY_PROTOCOL_VERSION_HELPER_VIEW_STUDIES_WITHOUT_PROTOCOL =
  'COPY_PROTOCOL_VERSION_HELPER_VIEW_STUDIES_WITHOUT_PROTOCOL';
export const VIEW_BUDGETS = 'VIEW_BUDGETS';
export const MANAGE_BUDGETS = 'MANAGE_BUDGETS';
export const VIEW_BUDGET_SITE_MAPPINGS = 'VIEW_BUDGET_SITE_MAPPINGS';
export const ADD_NEW_BUDGET_SITE_MAPPING = 'ADD_NEW_BUDGET_SITE_MAPPING';
export const VIEW_CLIENT_ACCOUNTS = 'VIEW_CLIENT_ACCOUNTS';
export const MANAGE_CLIENT_ACCOUNTS = 'MANAGE_CLIENT_ACCOUNTS';
export const VIEW_PAYEE_ACCOUNTS = 'VIEW_PAYEE_ACCOUNTS';
export const MANAGE_PAYEE_ACCOUNTS = 'MANAGE_PAYEE_ACCOUNTS';
export const VIEW_SITE_ACCOUNTS = 'VIEW_SITE_ACCOUNTS';
export const MANAGE_SITE_ACCOUNTS = 'MANAGE_SITE_ACCOUNTS';
export const VIEW_VENDOR_ACCOUNTS = 'VIEW_VENDOR_ACCOUNTS';
export const MANAGE_VENDOR_ACCOUNTS = 'MANAGE_VENDOR_ACCOUNTS';
export const VIEW_FIN_ACCOUNT_CONTACTS = 'VIEW_FIN_ACCOUNT_CONTACTS';
export const MANAGE_FIN_ACCOUNT_CONTACTS = 'MANAGE_FIN_ACCOUNT_CONTACTS';
export const VIEW_CALENDARS_AND_APPOINTMENTS = 'VIEW_CALENDARS_AND_APPOINTMENTS';
export const MANAGE_NON_PATIENT_APPOINTMENTS = 'MANAGE_NON_PATIENT_APPOINTMENTS';
export const VIEW_PRE_SCREEN_WORK_LIST = 'VIEW_PRE_SCREEN_WORK_LIST';
export const VIEW_PATIENTS = 'VIEW_PATIENTS';
export const VIEW_CRA_REVIEW_HELPER_VIEW_ENCOUNTERS = 'VIEW_CRA_REVIEW_HELPER_VIEW_ENCOUNTERS';
export const VIEW_PI_REVIEW_HELPER_GET_PI_WORK_LIST = 'VIEW_PI_REVIEW_HELPER_GET_PI_WORK_LIST';
export const VIEW_IG_AND_ENCOUNTER_AS_LINKS_AT_PI_WORK_LIST_PI_REVIEW =
  'VIEW_IG_AND_ENCOUNTER_AS_LINKS_AT_PI_WORK_LIST_PI_REVIEW';
export const EDIT_PRIMARY_STUDY_MANAGER_AND_INVESTIGATOR = 'EDIT_PRIMARY_STUDY_MANAGER_AND_INVESTIGATOR';
export const MANAGE_SITE_PERSONNEL = 'MANAGE_SITE_PERSONNEL';
export const MANAGE_EXPECTED_SSU_SUCCESS_METRICS = 'MANAGE_EXPECTED_SSU_SUCCESS_METRICS';
export const VIEW_STUDY_TIMELINES = 'VIEW_STUDY_TIMELINES';
export const MANAGE_STUDY_TIMELINES = 'MANAGE_STUDY_TIMELINES';
export const VIEW_MILESTONES = 'VIEW_MILESTONES';
export const VIEW_CYCLE_TIMES = 'VIEW_CYCLE_TIMES';
export const VIEW_STUDY_ENROLLMENT_PREDICTIONS = 'VIEW_STUDY_ENROLLMENT_PREDICTIONS';
export const EXPORT_FIXED_ITEMS = 'EXPORT_FIXED_ITEMS';
export const VIEW_LEDGER_EVENTS = 'VIEW_LEDGER_EVENTS';
export const MANAGE_LEDGER_EVENTS = 'MANAGE_LEDGER_EVENTS';
export const VIEW_REVENUE_EVENTS = 'VIEW_REVENUE_EVENTS';
export const MANAGE_REVENUE_EVENTS = 'MANAGE_REVENUE_EVENTS';
export const VIEW_INVOICE_EVENTS = 'VIEW_INVOICE_EVENTS';
export const MANAGE_INVOICE = 'MANAGE_INVOICE';
export const VIEW_SITE_BILL_EVENTS = 'VIEW_SITE_BILL_EVENTS';
export const MANAGE_SITE_BILL = 'MANAGE_SITE_BILL';
export const VIEW_FINANCE_ENROLLMENT_PREDICTIONS = 'VIEW_FINANCE_ENROLLMENT_PREDICTIONS';
export const LOCK_ENROLLMENT_PREDICTIONS_MONTH = 'LOCK_ENROLLMENT_PREDICTIONS_MONTH';
export const VIEW_PROJECTED_REVENUES = 'VIEW_PROJECTED_REVENUES';
export const VIEW_STUDY_DASHBOARDS = 'VIEW_STUDY_DASHBOARDS';
export const VIEW_ENCOUNTERS_DASHBOARDS = 'VIEW_ENCOUNTERS_DASHBOARDS';
export const VIEW_MILESTONES_DASHBOARDS = 'VIEW_MILESTONES_DASHBOARDS';
export const VIEW_CALENDAR_DASHBOARDS = 'VIEW_CALENDAR_DASHBOARDS';
export const VIEW_FINANCE_DASHBOARDS = 'VIEW_FINANCE_DASHBOARDS';
export const VIEW_PROCEDURES_DASHBOARDS = 'VIEW_PROCEDURES_DASHBOARDS';
export const VIEW_PRE_SCREENING_ENCOUNTERS_DASHBOARDS = 'VIEW_PRE_SCREENING_ENCOUNTERS_DASHBOARDS';
export const VIEW_SSU_METRICS_DASHBOARD = 'VIEW_SSU_METRICS_DASHBOARD';
export const VIEW_LEADERSHIP_METRICS_DASHBOARDS = 'VIEW_LEADERSHIP_METRICS_DASHBOARD';
export const VIEW_CRA_AUDIT_DASHBOARDS = 'VIEW_CRA_AUDIT_DASHBOARD';
export const VIEW_AUDIT_LOG_ALL_STUDY_DATA_DASHBOARD = 'VIEW_AUDIT_LOG_ALL_STUDY_DATA_DASHBOARD';
export const VIEW_ALL_USERS_OTHER_THAN_CRA_DASHBOARD = 'VIEW_ALL_USERS_OTHER_THAN_CRA_DASHBOARD';
export const VIEW_FINANCE_AND_FINANCE_ANALYST_DASHBOARD = 'VIEW_FINANCE_AND_FINANCE_ANALYST_DASHBOARD';
export const VIEW_SITE_PERFORMANCE_DASHBOARD = 'VIEW_SITE_PERFORMANCE_DASHBOARD';
export const VIEW_INVOICE_DETAILS_EXPORT_REPORT = 'VIEW_INVOICE_DETAILS_EXPORT_REPORT';
export const COMMON_SERVICE_OPERATION = 'COMMON_SERVICE_OPERATION';
export const CREATE_PRECONDITIONS_FOR_UI_TESTS = 'CREATE_PRECONDITIONS_FOR_UI_TESTS';
export const RUN_ADMIN_JOB = 'RUN_ADMIN_JOB';
export const VIEW_TERMINOLOGY_VERSIONS = 'VIEW_TERMINOLOGY_VERSIONS';
export const MANAGE_ITEM_GROUP_TEMPLATES = 'MANAGE_ITEM_GROUP_TEMPLATES';
export const VIEW_AND_EDIT_HOME_PAGE_DASHBOARDS_WIDGET_CONFIG = 'VIEW_AND_EDIT_HOME_PAGE_DASHBOARDS_WIDGET_CONFIG';
export const ADD_PATIENT_FROM_INTAKE_API = 'ADD_PATIENT_FROM_INTAKE_API';
export const TRANSFER_PATIENTS = 'TRANSFER_PATIENTS';
export const VIEW_SSU_KPI_DASHBOARD = 'VIEW_SSU_KPI_DASHBOARD';
export const VIEW_OPEN_ENCOUNTERS_DASHBOARD = 'VIEW_OPEN_ENCOUNTERS_DASHBOARD';

/** ScopeType.PATIENT **/
export const VIEW_PATIENT_OVERALL_INFORMATION = 'VIEW_PATIENT_OVERALL_INFORMATION';
export const MANAGE_PATIENT_OVERALL_INFORMATION = 'MANAGE_PATIENT_OVERALL_INFORMATION';
export const VIEW_FULL_SSN = 'VIEW_FULL_SSN';
export const VIEW_CARD_BALANCE = 'VIEW_CARD_BALANCE';
export const VIEW_PATIENT_INSTRUCTIONS = 'VIEW_PATIENT_INSTRUCTIONS';
export const MANAGE_PATIENT_INSTRUCTIONS = 'MANAGE_PATIENT_INSTRUCTIONS';
export const VIEW_PATIENT_FILES = 'VIEW_PATIENT_FILES';
export const MANAGE_PATIENT_FILES = 'MANAGE_PATIENT_FILES';
export const VIEW_TRAVEL_PREFERENCES = 'VIEW_TRAVEL_PREFERENCES';
export const MANAGE_TRAVEL_PREFERENCES = 'MANAGE_TRAVEL_PREFERENCES';
export const MANAGE_FINANCE_DETAILS_ACCESS = 'MANAGE_FINANCE_DETAILS_ACCESS';

/** ScopeType.STUDY **/
export const VIEW_PROTOCOL_VERSIONS = 'VIEW_PROTOCOL_VERSIONS';
export const VIEW_PROTOCOL_VERSIONS_AUDIT_TRAIL = 'VIEW_PROTOCOL_VERSIONS_AUDIT_TRAIL';
export const MANAGE_PROTOCOL_VERSIONS = 'MANAGE_PROTOCOL_VERSIONS';
export const COPY_PROTOCOL_VERSION = 'COPY_PROTOCOL_VERSION';
export const ADD_EDIT_INFORMED_CONSENT_ITEMS = 'ADD_EDIT_INFORMED_CONSENT_ITEMS';
export const GET_SITUATIONAL_ENCOUNTER_NAME = 'GET_SITUATIONAL_ENCOUNTER_NAME';

/** ScopeType.SSU **/
export const MANAGE_PATIENT_APPOINTMENTS = 'MANAGE_PATIENT_APPOINTMENTS';
export const UPLOAD_PATIENTS = 'UPLOAD_PATIENTS';
export const VIEW_PRE_SCREEN_WORK_LIST_HELPER_WITH_SSU_SCOPE = 'VIEW_PRE_SCREEN_WORK_LIST_HELPER_WITH_SSU_SCOPE';
export const MANAGE_PRE_SCREEN_WORK_LIST = 'MANAGE_PRE_SCREEN_WORK_LIST';
export const ADD_PATIENT = 'ADD_PATIENT';
export const VIEW_SSU_PATIENT_INFO = 'VIEW_SSU_PATIENT_INFO';
export const ADD_PATIENT_TO_STUDY = 'ADD_PATIENT_TO_STUDY';
export const MANAGE_SSU_PATIENT_INFORMATION = 'MANAGE_SSU_PATIENT_INFORMATION';
export const MANAGE_MRN = 'MANAGE_MRN';
export const VIEW_PROGRESS_NOTES = 'VIEW_PROGRESS_NOTES';
export const MANAGE_PROGRESS_NOTES = 'MANAGE_PROGRESS_NOTES';
export const MANAGE_PROGRESS_NOTES_IN_CLOSED_SSU = 'MANAGE_PROGRESS_NOTES_IN_CLOSED_SSU';
export const EXPORT_CASE_REPORT_FORM = 'EXPORT_CASE_REPORT_FORM';
export const VIEW_SSU_PATIENT_STATUSES = 'VIEW_SSU_PATIENT_STATUSES';
export const MANAGE_SSU_PATIENT_STATUSES = 'MANAGE_SSU_PATIENT_STATUSES';
export const ONLY_PRE_SCREEN_STATUSES_ALLOWED = 'ONLY_PRE_SCREEN_STATUSES_ALLOWED';
export const VIEW_PROTOCOL_ENCOUNTER_DATA = 'VIEW_PROTOCOL_ENCOUNTER_DATA';
export const MANAGE_PROTOCOL_ENCOUNTER_DATA = 'MANAGE_PROTOCOL_ENCOUNTER_DATA';
export const VIEW_UNEXPECTED_ENCOUNTER_DATA = 'VIEW_UNEXPECTED_ENCOUNTER_DATA';
export const MANAGE_UNEXPECTED_ENCOUNTER_DATA = 'MANAGE_UNEXPECTED_ENCOUNTER_DATA';
export const CLOSE_NON_PROTOCOL_ENCOUNTER = 'CLOSE_NON_PROTOCOL_ENCOUNTER';
export const VIEW_LOG_DATA = 'VIEW_LOG_DATA';
export const MANAGE_LOG_DATA = 'MANAGE_LOG_DATA';
export const VIEW_CRA_REVIEW = 'VIEW_CRA_REVIEW';
export const VIEW_CRA_REVIEW_AUDIT = 'VIEW_CRA_REVIEW_AUDIT';
export const VIEW_SM_REVIEW = 'VIEW_SM_REVIEW';
export const SIGN_SM_REVIEW = 'SIGN_SM_REVIEW';
export const VIEW_PI_REVIEW = 'VIEW_PI_REVIEW';
export const SIGN_PI_REVIEW = 'SIGN_PI_REVIEW';
export const MANAGE_SM_PI_REVIEW_COMMENT = 'MANAGE_SM_PI_REVIEW_COMMENT';
export const VIEW_SM_PI_REVIEW_COMMENT = 'VIEW_SM_PI_REVIEW_COMMENT';
export const VIEW_SM_PI_REVIEW_SIGN_BUTTON = 'VIEW_SM_PI_REVIEW_SIGN_BUTTON';
export const VIEW_DATA_ROW_HISTORY = 'VIEW_DATA_ROW_HISTORY';
export const VIEW_SIGNED_REVIEW_STATE = 'VIEW_SIGNED_REVIEW_STATE';
export const TD_VIEW_ANY_ENCOUNTER_DATA = 'TD_VIEW_ANY_ENCOUNTER_DATA';
export const TD_MANAGE_ANY_ENCOUNTER_DATA = 'TD_MANAGE_ANY_ENCOUNTER_DATA';
export const TD_DOWNLOAD_ANY_ENCOUNTER_DATA_FILES = 'TD_DOWNLOAD_ANY_ENCOUNTER_DATA_FILES';
export const VIEW_STUDY_SITE_DETAILS = 'VIEW_STUDY_SITE_DETAILS';
export const VIEW_STUDY_DETAILS = 'VIEW_STUDY_DETAILS';
export const MANAGE_STUDY_DETAILS = 'MANAGE_STUDY_DETAILS';
export const MANAGE_STUDY_TYPES = 'MANAGE_STUDY_TYPES';

export const MANAGE_STUDY_COST_PER_MILEAGE = 'MANAGE_STUDY_COST_PER_MILEAGE';
export const MANAGE_STUDY_ENROLLMENT_PREDICTIONS = 'MANAGE_STUDY_ENROLLMENT_PREDICTIONS';
export const VIEW_FIXED_ITEMS = 'VIEW_FIXED_ITEMS';
export const MANAGE_FIXED_ITEMS = 'MANAGE_FIXED_ITEMS';
export const ADD_PATIENT_FROM_83BAR = 'ADD_PATIENT_FROM_83BAR';
export const EDIT_PATIENT_FROM_83BAR = 'EDIT_PATIENT_FROM_83BAR';
export const GET_PATIENT_STATES_FROM_83BAR = 'GET_PATIENT_STATES_FROM_83BAR';
export const ADD_PATIENT_DATA_FROM_83BAR = 'ADD_PATIENT_DATA_FROM_83BAR';
export const MANAGE_DATA_IN_CLOSED_SSU = 'MANAGE_DATA_IN_CLOSED_SSU';
export const VIEW_SM_WORKLIST = 'VIEW_SM_WORKLIST';
export const MANAGE_TASKS = 'MANAGE_TASKS';
export const MANAGE_TASKS_GLOBAL = 'MANAGE_TASKS_GLOBAL';
export const VIEW_TASKS = 'VIEW_TASKS';
export const EXPORT_TASKS = 'EXPORT_TASKS';
export const ACCESS_TASK_WORK_LIST = 'ACCESS_TASK_WORK_LIST';
export const LOAD_PATIENTS_STUDY_SITES_FOR_MANAGE_TASKS = 'LOAD_PATIENTS_STUDY_SITES_FOR_MANAGE_TASKS';
export const VIEW_TASKS_CALENDAR = 'VIEW_TASKS_CALENDAR';
export const FORCE_PATIENT_STIPEND_PAYMENT = 'FORCE_PATIENT_STIPEND_PAYMENT';
export const ALL_AVAILABLE_USER_ROLES = 'ALL_AVAILABLE_USER_ROLES';
export const VIEW_NOTIFICATIONS = 'VIEW_NOTIFICATIONS';
export const AFTERBURNER_MAPPING_OPERATION = 'AFTERBURNER_MAPPING_OPERATION';
export const PENDING_SCHEDULE_WORK_LIST = 'PENDING_SCHEDULE_WORK_LIST';
export const ADD_FILE_FROM_IGNITE_API = 'ADD_FILE_FROM_IGNITE_API';
export const GET_LOG_FROM_IGNITE_API = 'GET_LOG_FROM_IGNITE_API';
export const ADD_PATIENT_FROM_IGNITE_API = 'ADD_PATIENT_FROM_IGNITE_API';
export const EDIT_PATIENT_FROM_IGNITE_API = 'EDIT_PATIENT_FROM_IGNITE_API';
export const VIEW_AND_EDIT_HOME_PAGE_ENCOUNTER_SIGN_OFF_WIDGET = 'VIEW_AND_EDIT_HOME_PAGE_ENCOUNTER_SIGN_OFF_WIDGET';
export const SIGN_INVESTIGATOR_FIELDS = 'SIGN_INVESTIGATOR_FIELDS';
export const SIGN_ADVERSE_EVENT_LOGS_GROUP = 'SIGN_ADVERSE_EVENT_LOGS_GROUP';
export const ADVERSE_EVENTS_SM_LOG_ROW_PUSH_TO_INVESTIGATOR_OPERATION =
  'ADVERSE_EVENTS_SM_LOG_ROW_PUSH_TO_INVESTIGATOR_OPERATION';
export const ADVERSE_EVENTS_SM_LOG_ROW_SM_CONFIRM_OPERATION = 'ADVERSE_EVENTS_SM_LOG_ROW_SM_CONFIRM_OPERATION';
export const ACCESS_TO_ADVERSE_EVENT_LOG_ROW_STATUS_OPERATIONS = 'ACCESS_TO_ADVERSE_EVENT_LOG_ROW_STATUS_OPERATIONS';
export const ACCESS_TO_ADVERSE_EVENT_SM_OPERATIONS = 'ACCESS_TO_ADVERSE_EVENT_SM_OPERATIONS';
export const ACCESS_TO_ADVERSE_EVENT_INVESTIGATOR_OPERATIONS = 'ACCESS_TO_ADVERSE_EVENT_INVESTIGATOR_OPERATIONS';
export const ACCESS_TO_ADVERSE_EVENT_HISTORY_OPERATIONS = 'ACCESS_TO_ADVERSE_EVENT_HISTORY_OPERATIONS';
export const UPLOAD_AND_VIEW_INFORMED_CONSENT_FORM = 'UPLOAD_AND_VIEW_INFORMED_CONSENT_FORM';
export const UPLOAD_INFORMED_CONSENT_FORM = 'UPLOAD_INFORMED_CONSENT_FORM';
export const ADVERSE_EVENTS_SM_LOG_ROW_CANCEL_REOPEN_OPERATION = 'ADVERSE_EVENTS_SM_LOG_ROW_CANCEL_REOPEN_OPERATION';
export const SYS_ADMIN_SIGN_INVESTIGATOR_FIELDS = 'SYS_ADMIN_SIGN_INVESTIGATOR_FIELDS';
export const NEED_TO_BE_BLINDED_GLOBAL_ROLE = 'NEED_TO_BE_BLINDED_GLOBAL_ROLE';
export const GET_TOKEN_FOR_SIGNATURE = 'GET_TOKEN_FOR_SIGNATURE';
export const ACCESS_GLOBAL_ENVIRONMENT_SETTING = 'ACCESS_GLOBAL_ENVIRONMENT_SETTING';
export const VIEW_PATIENT_REIMBURSEMENTS = 'VIEW_PATIENT_REIMBURSEMENTS';
export const MANAGE_PATIENT_REIMBURSEMENTS = 'MANAGE_PATIENT_REIMBURSEMENTS';
export const MANAGE_PATIENT_BULK_UPDATE = 'MANAGE_PATIENT_BULK_UPDATE';
export const MANAGE_REIMBURSEMENT_WORKLIST = 'MANAGE_REIMBURSEMENT_WORKLIST';
export const ACCESS_MAPS_API = 'ACCESS_MAPS_API';
export const MANAGE_CTMS_PATIENT_STIPEND = 'MANAGE_CTMS_PATIENT_STIPEND';
