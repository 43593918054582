import React, { useCallback, useContext, useMemo, useState } from 'react';
import moment from 'moment';

import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';
import { toBill } from '../../../../../services/financial';
import { resolveEventSuffix } from '../../NewInvoice/CreateInvoice/CreateInvoiceServices';
import {
  eventNumberComparator,
  isCompleteStatus,
  isOpenStatus,
  isPaidStatus,
  isPartialStatus,
  isPastDueStatus
} from '../../NewInvoice/InvoiceTable/InvoiceTableService';
import {
  ACTIONS_BUTTON,
  COMPLETE,
  OPEN,
  PAID,
  PARTIAL,
  PAST_DUE,
  TABLE_CHECKBOX
} from '../../NewInvoice/InvoiceTable/TableConstants';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { DefaultFinanceCell } from '../../shared/FinanseTableMUI/DefaultFinanceCell';
import { FinanceTableMUI } from '../../shared/FinanseTableMUI/FinanceTableMUI';
import { formatDateForCSV, prepareNameForCSV } from '../../shared/FinanseTableMUI/FinanceTableServices';
import { SitePaymentsContext } from '../SitePaymentsContext';

import { SITE_PAYMENTS_COLUMNS_HIDDEN_BY_DEFAULT } from './SitePaymentsConstants';

export function SitePaymentsTable() {
  const { tableData = [], checkedEvents, setCheckedEvents, firstLoading } = useContext(SitePaymentsContext);

  const stateFromLocalStorage = JSON.parse(localStorage?.getItem('SITE_PAYMENTS_TABLE_CONFIGURATION'));
  const [pinnedColumns, setPinnedColumns] = useState(
    stateFromLocalStorage?.pinnedColumns || {
      left: [TABLE_CHECKBOX],
      right: [ACTIONS_BUTTON]
    }
  );

  const handlePinnedColumnsChange = useCallback(updatedPinnedColumns => {
    setPinnedColumns({
      ...updatedPinnedColumns,
      left: [TABLE_CHECKBOX, ...updatedPinnedColumns.left.filter(column => column !== TABLE_CHECKBOX)],
      right: [...updatedPinnedColumns.right.filter(column => column !== ACTIONS_BUTTON), ACTIONS_BUTTON]
    });
  }, []);

  const getTogglableColumns = useCallback(
    columns =>
      columns.filter(column => ![ACTIONS_BUTTON, TABLE_CHECKBOX].includes(column.field)).map(column => column.field),
    []
  );

  const columns = useMemo(
    () => [
      {
        field: 'eventNumber',
        headerName: 'Event ID',
        flex: 1,
        minWidth: 125,
        sortComparator: eventNumberComparator,
        valueGetter: ({ row }) => {
          if (row.adjustmentSequence === 0) {
            return resolveEventSuffix(row.eventNumber, row.invoiceType);
          }
          return resolveEventSuffix(`${row.eventNumber}.${row.adjustmentSequence}`, row.invoiceType);
        }
      },
      {
        field: 'eventName',
        headerName: 'Event Name',
        flex: 1,
        minWidth: 170,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'revenueDate',
        headerName: 'Revenue Date',
        flex: 1,
        minWidth: 160,
        type: 'date',
        valueFormatter: ({ value }) => formatDateForCSV(value),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { revenueDate } }) => (
          <div>{revenueDate ? moment(revenueDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'invoiceDate',
        headerName: 'Invoice Date',
        flex: 1,
        minWidth: 160,
        type: 'date',
        valueFormatter: ({ value }) => formatDateForCSV(value),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { revenueDate } }) => (
          <div>{revenueDate ? moment(revenueDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'invoiceAmount',
        headerName: 'Invoice Amount',
        flex: 1,
        minWidth: 170,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.invoiceAmount),
        renderCell: ({ row }) => <Amount coinsAmount={row.invoiceAmount} showDollarSign />
      },
      {
        field: 'paymentStatus',
        headerName: 'Payment Status',
        flex: 1,
        minWidth: 170,
        valueGetter: ({ row }) => {
          if (isCompleteStatus(row)) return COMPLETE;
          else if (isOpenStatus(row)) return OPEN;
          else if (isPastDueStatus(row)) return PAST_DUE;
          else if (isPartialStatus(row)) return PARTIAL;
          else if (isPaidStatus(row)) return PAID;
          return '';
        },
        renderCell: DefaultFinanceCell
      },
      {
        field: 'siteAmount',
        headerName: 'Site Amount',
        flex: 1,
        minWidth: 150,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.siteAmount),
        renderCell: ({ row }) => <Amount coinsAmount={row.siteAmount} showDollarSign />
      },
      {
        field: 'sitePaidAmount',
        headerName: 'Site Paid Amount',
        flex: 1,
        minWidth: 180,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.sitePaidAmount),
        renderCell: ({ row }) => <Amount coinsAmount={row.sitePaidAmount} showDollarSign />
      },
      {
        field: 'siteApprovalStatus',
        headerName: 'Site Approval Status',
        flex: 1,
        minWidth: 170,
        valueGetter: ({ row }) => {
          if (isCompleteStatus(row)) return COMPLETE;
          else if (isOpenStatus(row)) return OPEN;
          else if (isPastDueStatus(row)) return PAST_DUE;
          else if (isPartialStatus(row)) return PARTIAL;
          else if (isPaidStatus(row)) return PAID;
          return '';
        },
        renderCell: DefaultFinanceCell
      },
      {
        field: 'siteApprovalDate',
        headerName: 'Site Approval Date',
        flex: 1,
        minWidth: 160,
        type: 'date',
        valueFormatter: ({ value }) => formatDateForCSV(value),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { siteApprovalDate } }) => (
          <div>{siteApprovalDate ? moment(siteApprovalDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'sitePaidVariance',
        headerName: 'Site Paid Variance',
        flex: 1,
        minWidth: 170,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.sitePaidVariance),
        renderCell: ({ row }) => <Amount coinsAmount={row.sitePaidVariance} showDollarSign />
      },
      {
        field: 'sitePaymentId',
        headerName: 'Site Payment ID',
        flex: 1,
        minWidth: 150,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'sitePaymentStatus',
        headerName: 'Site Payment Status',
        flex: 1,
        minWidth: 170,
        valueGetter: ({ row }) => {
          if (isCompleteStatus(row)) return COMPLETE;
          else if (isOpenStatus(row)) return OPEN;
          else if (isPastDueStatus(row)) return PAST_DUE;
          else if (isPartialStatus(row)) return PARTIAL;
          else if (isPaidStatus(row)) return PAID;
          return '';
        },
        renderCell: DefaultFinanceCell
      },
      {
        field: 'billNumber',
        headerName: 'Bill #',
        flex: 1,
        minWidth: 105,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'billingDate',
        headerName: 'Bill Date',
        minWidth: 140,
        type: 'date',
        valueFormatter: ({ value }) => formatDateForCSV(value),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { billingDate } }) => (
          <div>{billingDate ? moment(billingDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'studyName',
        headerName: 'Study',
        flex: 1,
        minWidth: 110,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'pcn',
        headerName: 'PCN',
        flex: 1,
        minWidth: 100,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'siteName',
        headerName: 'Site',
        flex: 1,
        minWidth: 100,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'siteNumber',
        headerName: 'Site Number',
        flex: 1,
        minWidth: 150,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'clientName',
        headerName: 'Client',
        flex: 1,
        minWidth: 150,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'type',
        headerName: 'Budget Event Type',
        flex: 1,
        minWidth: 145,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'epochName',
        headerName: 'Epoch',
        flex: 1,
        minWidth: 145,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'encounterName',
        headerName: 'Encounter',
        flex: 1,
        minWidth: 135,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'itemGroupName',
        headerName: 'Item Group',
        flex: 1,
        minWidth: 135,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'eventDate',
        headerName: 'Event Date',
        flex: 1,
        minWidth: 150,
        type: 'date',
        valueFormatter: ({ value }) => formatDateForCSV(value),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row }) => {
          const content = moment(row.eventDate).format(DD_SLASH_MMM_SLASH_YYYY);
          return (
            <div className="MuiDataGrid-cellContent" title={content}>
              {content}
            </div>
          );
        }
      },
      {
        field: 'invoice',
        headerName: 'Invoice',
        flex: 1,
        minWidth: 175,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'depositNumber',
        headerName: 'Deposit Number',
        flex: 1,
        minWidth: 175,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'daysOpen',
        headerName: 'Days Open',
        flex: 1,
        minWidth: 175,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'amountPaid',
        headerName: 'Amount Paid',
        flex: 1,
        minWidth: 170,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.amountPaid),
        renderCell: ({ row }) => <Amount coinsAmount={row.amountPaid} showDollarSign />
      },
      {
        field: 'datePosted',
        headerName: 'Date Posted',
        flex: 1,
        minWidth: 160,
        type: 'date',
        valueFormatter: ({ value }) => formatDateForCSV(value),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { datePosted } }) => (
          <div>{datePosted ? moment(datePosted).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'variance',
        headerName: 'Variance',
        flex: 1,
        minWidth: 170,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.variance),
        renderCell: ({ row }) => <Amount coinsAmount={row.variance} showDollarSign />
      },
      {
        field: 'studyManager',
        headerName: 'Study Manager',
        flex: 1,
        minWidth: 180,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'principleInvestigatorName',
        headerName: 'Investigator',
        flex: 1,
        minWidth: 180,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'patientId',
        headerName: 'Patient ID',
        flex: 1,
        minWidth: 135,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'subjectId',
        headerName: 'Subject ID',
        flex: 1,
        minWidth: 145,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => (value != null ? `[${value}]` : '')
      },
      {
        field: 'sitePaymentDate',
        headerName: 'Site Payment Date',
        flex: 1,
        minWidth: 160,
        type: 'date',
        valueFormatter: ({ value }) => formatDateForCSV(value),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { sitePaymentDate } }) => (
          <div>{sitePaymentDate ? moment(sitePaymentDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'adjustmentMemo',
        headerName: 'Adjustment Memo',
        flex: 1,
        minWidth: 175,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'patientStatus',
        headerName: 'Current Patient Status',
        flex: 1,
        minWidth: 155,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'actions',
        type: 'actions',
        resizable: false,
        width: 50,
        renderCell: ({ row }) => ''
      }
    ],
    []
  );

  return (
    <FinanceTableMUI
      localStorageTableName="SITE_PAYMENTS"
      getRowId={row => row.id}
      data-testid="site-payments-table"
      rows={tableData}
      columns={columns}
      checkboxSelection
      disableRowSelectionOnClick
      getTogglableColumns={getTogglableColumns}
      footerElementsCount={tableData.length}
      initialState={{
        columns: {
          columnVisibilityModel: SITE_PAYMENTS_COLUMNS_HIDDEN_BY_DEFAULT
        },
        pinnedColumns
      }}
      pinnedColumns={pinnedColumns}
      onPinnedColumnsChange={handlePinnedColumnsChange}
      onRowSelectionModelChange={newRowSelectionModel => {
        setCheckedEvents(newRowSelectionModel);
      }}
      rowSelectionModel={checkedEvents}
      localeText={{ noRowsLabel: firstLoading ? 'Make selections to display results' : 'No results to display' }}
      tableVersion="1.0"
    />
  );
}
