export const SITE_PAYMENTS_COLUMNS_HIDDEN_BY_DEFAULT = {
  studyName: false,
  pcn: false,
  siteName: false,
  siteNumber: false,
  clientName: false,
  type: false,
  epochName: false,
  encounterName: false,
  itemGroupName: false,
  eventDate: false,
  invoice: false,
  depositNumber: false,
  daysOpen: false,
  amountPaid: false,
  datePosted: false,
  variance: false,
  studyManager: false,
  principleInvestigatorName: false,
  patientId: false,
  subjectId: false,
  sitePaymentDate: false,
  adjustmentMemo: false,
  patientStatus: false
};
