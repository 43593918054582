import { isEmpty } from 'lodash/lang';

import { ReviewType } from './reviewConstants';
const icons = {
  Table: 'table_chart',
  NewTable: 'table_chart',
  Logs: 'format_list_numbered',
  LogChecks: 'format_list_numbered',
  Procedure: 'medical_services',
  Reminder: 'alarm_on',
  Comments: 'forum' /* TODO: NEED TO FIX TYPE FOR COMMENTS*/,
  Custom: 'extension',
  Checklist: 'playlist_add_check',
  PatientStatus: 'contact_emergency',
  PatientStipend: 'monetization_on',
  Review: 'rate_review',
  Outreach: 'people_outline',
  ChartReview: 'bar_chart',
  ICF: 'description'
};
const iconsSuit = {
  Table: 'material-outline',
  NewTable: 'material-outline',
  Logs: 'material-outline',
  LogChecks: 'material-outline',
  Procedure: 'material',
  Reminder: 'material-outline',
  Comments: 'material-outline' /* TODO: NEED TO FIX TYPE FOR COMMENTS*/,
  Custom: 'material-outline',
  Checklist: 'material-outline',
  PatientStatus: 'material',
  PatientStipend: 'material-outline',
  Review: 'material',
  Outreach: 'material-outline',
  ChartReview: 'material-outline',
  ICF: 'material-outline'
};

export function getIcon(type) {
  return icons[type.replace(' ', '')];
}

export function getIconSuit(type) {
  return iconsSuit[type.replace(' ', '')];
}

export function logSnapshotReviewStateHasRecords(logSnapshotReviewState) {
  return !isEmpty(logSnapshotReviewState?.logSnapshotState.rows);
}

export function logCheckSnapshotReviewStateHasRecords(logCheckSnapshotReviewState) {
  return !isEmpty(logCheckSnapshotReviewState?.logCheckSnapshotState?.logCheckSnapshot?.logCheckAnswers);
}

export function itemGroupSnapshotReviewStateHasRecordsOrNotPerformed(itemGroupSnapshotReviewState) {
  return (
    !isEmpty(itemGroupSnapshotReviewState?.itemGroupSnapshotState.rows) ||
    itemGroupSnapshotReviewState?.itemGroupSnapshotState?.dataStatus?.notPerformed
  );
}

export function isSmReview(reviewType) {
  return reviewType === ReviewType.SM;
}

export function isPiReview(reviewType) {
  return reviewType === ReviewType.PI;
}

export function isCraReview(reviewType) {
  return reviewType === ReviewType.CRA;
}

export function isSmOrPiReview(reviewType) {
  return isSmReview(reviewType) || isPiReview(reviewType);
}
export function extractNotBlindedItemGroupsFromList(itemGroupList) {
  return itemGroupList.filter(({ blinded }) => !blinded);
}

export function getReviewType(route) {
  if (route.name === 'SM Review') {
    return ReviewType.SM;
  }
  if (route.name === 'Investigator Review') {
    return ReviewType.PI;
  }
  if (route.key === 'CRA Review.Encounter Review') {
    return ReviewType.CRA;
  }
}
