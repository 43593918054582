import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './IconButton.scss';

export default function IconButton(props) {
  const { className, color, suit, ...restProps } = props;
  const suitClassName = {
    'material-icons': suit === 'material',
    [`glyphicon glyphicon-${props.children}`]: suit === 'glyphicon'
  };
  return (
    <button className={cx(`eds-icon-btn eds-icon-btn-${color}`, suitClassName, className)} {...restProps}>
      {suit !== 'glyphicon' ? props.children : ''}
    </button>
  );
}

IconButton.propTypes = {
  title: PropTypes.string,
  style: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.any,
  color: PropTypes.oneOf(['blue', 'red', 'grey', 'light-grey']),
  suit: PropTypes.oneOf(['glyphicon', 'material'])
};

IconButton.defaultProps = {
  suit: 'material',
  color: 'red'
};
