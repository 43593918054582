import React, { useCallback, useState } from 'react';
import { isFunction, isString } from 'lodash/lang';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { BUDGET_EVENT_NAME_LENGTH } from '../../budgetConstant';
import BudgetEventControlButtons from '../BudgetEventControlButtons';
import { PASS_THRU } from '../ExpenseType';
import ExpenseRadioInputs from '../help-component/ExpenseRadioInputs';

const AddOrEditPatientReimbursementBudgetEvent = ({ data, budgetEventType, modalBox, onSave, currentStudyBudget }) => {
  const { groupId } = data || {};
  const [name, setName] = useState(data?.name || '');
  const [patientAmountType, setPatientAmountType] = useState(data?.patientAmountType ?? PASS_THRU);
  const isEditingForbidden = currentStudyBudget && !currentStudyBudget.editPossibility;

  const onChange = setValue => ({ target }) => {
    setValue(target.value);
  };

  const save = useCallback(() => {
    if (isFunction(onSave)) {
      const data = {
        name,
        type: budgetEventType,
        patientAmountType: patientAmountType
      };
      return onSave(data, groupId);
    }
  }, [onSave, name, budgetEventType, patientAmountType, groupId]);

  const onSaveButton = useCallback(() => {
    save();
    modalBox.close();
  }, [modalBox, save]);

  const isValidForm = () => {
    return isString(name) && name.trim() !== '' && name.length <= BUDGET_EVENT_NAME_LENGTH;
  };

  return (
    <>
      <ModalBoxes.Body>
        <div>
          <div className="form-group col-12">
            <label htmlFor="bem-event-name">Event Name</label>
            <input
              id="bem-event-name"
              className="form-control reqfeild"
              name="name"
              value={name}
              disabled={isEditingForbidden}
              onChange={onChange(setName)}
            />
            {name.length > BUDGET_EVENT_NAME_LENGTH && (
              <p className="event-name-length-notification">
                {`Event Name cannot exceed ${BUDGET_EVENT_NAME_LENGTH} characters`}
              </p>
            )}
          </div>
          <div className="form-group col-5">
            <ExpenseRadioInputs
              value={patientAmountType}
              name="patientAmountType"
              onChangeFormControls={onChange(setPatientAmountType)}
              disabled={isEditingForbidden}
            />
          </div>
        </div>
      </ModalBoxes.Body>
      <BudgetEventControlButtons
        isValidForm={isValidForm}
        onClose={modalBox.close}
        onSave={onSaveButton}
        currentStudyBudget={currentStudyBudget}
      />
    </>
  );
};

AddOrEditPatientReimbursementBudgetEvent.title = 'Add Patient Reimbursement budget event';
AddOrEditPatientReimbursementBudgetEvent.className = 'add-patient-reimbursement-modal';
AddOrEditPatientReimbursementBudgetEvent.size = 'w800';

export default AddOrEditPatientReimbursementBudgetEvent;
