import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { without } from 'lodash/array';
import { includes } from 'lodash/collection';
import { isArray, isEmpty, isEqual, isFunction, isString, isUndefined } from 'lodash/lang';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { ControlledMultiselectList } from '../../../../../../../common/inputs/MultiSelectList/ControlledMultiselectList';
import { toCoins } from '../../../../../../../services/financial';
import { BUDGET_EVENT_NAME_LENGTH } from '../../budgetConstant';
import BudgetEventControlButtons from '../BudgetEventControlButtons';
import { DIRECT_COST, PASS_THRU } from '../ExpenseType';
import { UpdatedBudgetEventForm } from '../UpdatedBudgetEventForm';

const maxCount = Number.MAX_SAFE_INTEGER;

const AddOrEditEncounterBudgetEvent = ({
  data,
  budgetEventType,
  encounterTriggers,
  vendors,
  triggerType,
  managementFeePercent,
  onSave,
  modalBox,
  currentStudyBudget
}) => {
  const { groupId, triggerId, triggerName, name, overhead, withholding, managementFee, vendorId, eventRows } =
    data || {};

  const initialVendorId = useMemo(() => vendorId || 'NOT_SELECTED', [vendorId]);

  const emptyRow = useMemo(
    () => ({
      id: null,
      countFrom: 1,
      countTo: maxCount,
      clientAmount: 0,
      siteAmount: 0,
      siteAmountType: PASS_THRU,
      vendorAmount: 0,
      vendorAmountType: null
    }),
    []
  );

  const initialState = useMemo(() => {
    const isEdit = !isUndefined(data);
    return {
      isEdit: isEdit,
      encounterTriggers: encounterTriggers,
      selectedTriggers: isEdit ? [{ id: triggerId, type: triggerType }] : [],
      groupId: groupId,
      name: name || '',
      overhead: !!overhead,
      withholding: !!withholding,
      managementFee: isUndefined(managementFee) ? +managementFeePercent !== 0 : managementFee,
      selectedVendorId: initialVendorId,
      eventRows: isArray(eventRows) ? eventRows : [emptyRow],
      showOverlapsErrorMsg: isArray(eventRows) ? new Array(eventRows.length).fill(false) : [false]
    };
  }, [
    data,
    emptyRow,
    encounterTriggers,
    eventRows,
    groupId,
    initialVendorId,
    managementFee,
    managementFeePercent,
    name,
    overhead,
    triggerId,
    triggerType,
    withholding
  ]);

  const [modalState, setModalState] = useState(initialState);

  useEffect(() => {
    if (modalState.managementFee) {
      setModalState(prevState => {
        const updatedEventRows = prevState.eventRows.map(budgetEvent => ({
          ...budgetEvent,
          siteAmount: +(budgetEvent.clientAmount - (budgetEvent.clientAmount / 100) * managementFeePercent).toFixed(2)
        }));
        return {
          ...prevState,
          eventRows: !isEqual(updatedEventRows, prevState.eventRows) ? updatedEventRows : prevState.eventRows
        };
      });
    }
  }, [modalState.managementFee, managementFeePercent, modalState.eventRows]);

  const isEditPossible = useCallback(() => modalState.isEdit, [modalState.isEdit]);

  const onChangeSidebarOption = useCallback(option => {
    setModalState(prevState => {
      if (includes(prevState.selectedTriggers, option)) {
        return {
          ...prevState,
          selectedTriggers: without(prevState.selectedTriggers, option)
        };
      }
      return {
        ...prevState,
        selectedTriggers: [...prevState.selectedTriggers, option]
      };
    });
  }, []);

  const toggleAllOptions = useCallback(allSelected => {
    setModalState(prevState => ({ ...prevState, selectedTriggers: allSelected ? [] : prevState.encounterTriggers }));
  }, []);

  const revertOrSetUpVendorRadioButtons = useCallback(() => {
    setModalState(prevState => {
      const { eventRows, selectedVendorId } = prevState;
      if (selectedVendorId !== 'NOT_SELECTED' && eventRows.every(e => !e.vendorAmountType)) {
        const updatedEventRows = eventRows.map(e => ({ ...e, vendorAmountType: DIRECT_COST }));
        return { ...prevState, eventRows: updatedEventRows };
      } else if (selectedVendorId === 'NOT_SELECTED' && eventRows.some(e => !!e.vendorAmountType)) {
        const updatedEventRows = eventRows.map(e => ({ ...e, vendorAmountType: null }));
        return { ...prevState, eventRows: updatedEventRows };
      }
      return prevState;
    });
  }, []);

  const onChangeFormControls = useCallback(
    ({ target }) => {
      if (target.type === 'checkbox') {
        if (target.name === 'managementFee') {
          setModalState(prevState => ({
            ...prevState,
            managementFee: !prevState.managementFee,
            eventRows: prevState.managementFee
              ? prevState.eventRows.map(budgetEvent => ({
                  ...budgetEvent,
                  siteAmount: 0
                }))
              : prevState.eventRows
          }));
        } else {
          setModalState(prevState => ({ ...prevState, [target.name]: target.checked }));
        }
      } else {
        setModalState({
          ...modalState,
          [target.name]: target.value
        });
        revertOrSetUpVendorRadioButtons();
      }
    },
    [modalState, revertOrSetUpVendorRadioButtons]
  );

  const checkOverlappedFields = useCallback(idx => {
    setModalState(prevState => {
      const currentRow = prevState.eventRows[idx];
      let isRowValid = true;
      if (currentRow) {
        const isFromEmpty = isUndefined(currentRow.countFrom);
        const isFromPositive = currentRow.countFrom > 0;
        const isFromLessOrEqualsTo = currentRow.countFrom <= currentRow.countTo;
        const rangesDoNotOverlap = prevState.eventRows
          .filter((range, i) => i !== idx)
          .every(range => range.countTo < currentRow.countFrom || range.countFrom > currentRow.countTo);
        isRowValid = !isFromEmpty && isFromPositive && isFromLessOrEqualsTo && rangesDoNotOverlap;
      }
      const updatedShowOverlapsErrorMsg = prevState.showOverlapsErrorMsg.map((item, index) =>
        index === idx ? !isRowValid : item
      );
      return { ...prevState, showOverlapsErrorMsg: updatedShowOverlapsErrorMsg };
    });
  }, []);

  const onChangeEventRow = useCallback(
    idx => evt => {
      const targetBaseName = evt.target.name.split('_')[0];
      setModalState(prevState => {
        const newEventRows = prevState.eventRows.map((eventRow, index) => {
          if (index === idx) {
            return {
              ...eventRow,
              [targetBaseName]:
                targetBaseName === 'countTo' && isUndefined(evt.target.value) ? maxCount : evt.target.value
            };
          }
          return eventRow;
        });
        return { ...prevState, eventRows: newEventRows };
      });
      if (targetBaseName === 'countFrom' || targetBaseName === 'countTo') {
        modalState.showOverlapsErrorMsg.forEach((elem, index) => checkOverlappedFields(index));
      }
    },
    [checkOverlappedFields, modalState.showOverlapsErrorMsg]
  );

  const handleAddEventRow = useCallback(() => {
    const newRows = modalState.eventRows.concat([
      modalState.selectedVendorId === 'NOT_SELECTED'
        ? emptyRow
        : {
            ...emptyRow,
            vendorAmountType: DIRECT_COST
          }
    ]);
    setModalState(prevState => ({
      ...prevState,
      eventRows: newRows,
      showOverlapsErrorMsg: prevState.showOverlapsErrorMsg.concat([false])
    }));
    newRows.forEach((elem, index) => checkOverlappedFields(index));
  }, [checkOverlappedFields, emptyRow, modalState.eventRows, modalState.selectedVendorId]);

  const handleRemoveEventRow = useCallback(
    idx => () => {
      setModalState(prevState => ({
        ...prevState,
        eventRows: prevState.eventRows.filter((row, rowIdx) => idx !== rowIdx),
        showOverlapsErrorMsg: prevState.showOverlapsErrorMsg.filter((item, itemIdx) => idx !== itemIdx)
      }));
      modalState.showOverlapsErrorMsg.forEach((elem, index) => checkOverlappedFields(index));
    },
    [checkOverlappedFields, modalState.showOverlapsErrorMsg]
  );

  const save = useCallback(() => {
    if (isFunction(onSave)) {
      const { selectedTriggers, name, managementFee, selectedVendorId, eventRows } = modalState;
      const data = selectedTriggers.flatMap(trigger =>
        eventRows.map(row => {
          return {
            id: row.id,
            type: trigger.type,
            name,
            overhead: modalState.overhead,
            withholding: modalState.withholding,
            managementFee,
            finVendorId: selectedVendorId === 'NOT_SELECTED' ? null : selectedVendorId,
            countFrom: row.countFrom,
            countTo: row.countTo === maxCount ? null : row.countTo,
            clientAmount: toCoins(row.clientAmount),
            siteAmount: toCoins(row.siteAmount),
            siteAmountType: row.siteAmountType,
            vendorAmount: selectedVendorId !== 'NOT_SELECTED' ? toCoins(row.vendorAmount) : 0,
            vendorAmountType: row.vendorAmountType,
            finTriggerId: trigger.id
          };
        })
      );
      return onSave(data, modalState.groupId);
    }
  }, [modalState, onSave]);

  const onSaveButton = useCallback(() => {
    save();
    modalBox.close();
  }, [modalBox, save]);

  const onSaveAndContinue = useCallback(() => {
    save().then(() => setModalState(initialState));
  }, [initialState, save]);

  const isValidForm = useCallback(() => {
    const { isEdit, selectedTriggers, name, selectedVendorId, showOverlapsErrorMsg, eventRows } = modalState;

    return (
      (selectedTriggers.length > 0 || isEdit) &&
      isString(name) &&
      name.trim() !== '' &&
      showOverlapsErrorMsg.every(isError => !isError) &&
      eventRows.every(
        row =>
          row.clientAmount >= 0 &&
          (!row.siteAmount || row.siteAmount >= 0) &&
          (selectedVendorId === 'NOT_SELECTED' || row.vendorAmount > 0)
      ) &&
      name.length <= BUDGET_EVENT_NAME_LENGTH
    );
  }, [modalState]);

  return (
    <>
      <ModalBoxes.Body>
        <div className="d-flex">
          {!isEditPossible() && isArray(modalState.encounterTriggers) && (
            <div style={{ display: 'flex' }}>
              <div className="add-or-edit-budget-event-sidebar">
                <ControlledMultiselectList
                  options={modalState.encounterTriggers}
                  label="name"
                  height="400px"
                  selectedOptions={modalState.selectedTriggers}
                  onChangeSidebarOption={onChangeSidebarOption}
                  toggleAllOptions={toggleAllOptions}
                />
              </div>
            </div>
          )}
          <div className="bem-form container-fluid d-flex">
            <UpdatedBudgetEventForm
              isEventRanges={true}
              isEdit={isEditPossible}
              triggerName={triggerName}
              name={modalState.name}
              overhead={modalState.overhead}
              withholding={modalState.withholding}
              managementFee={modalState.managementFee}
              managementFeePercent={managementFeePercent}
              finVendors={vendors}
              selectedVendorId={modalState.selectedVendorId}
              budgetEventType={budgetEventType}
              currentStudyBudget={currentStudyBudget}
              eventRows={modalState.eventRows}
              maxCount={maxCount}
              showOverlapsErrorMsg={modalState.showOverlapsErrorMsg}
              onChangeFormControls={onChangeFormControls}
              onChangeEventRow={onChangeEventRow}
              handleAddEventRow={handleAddEventRow}
              handleRemoveEventRow={handleRemoveEventRow}
            />
          </div>
        </div>
      </ModalBoxes.Body>
      <BudgetEventControlButtons
        isValidForm={isValidForm}
        onClose={modalBox.close}
        onSave={onSaveButton}
        onSaveAndContinue={isEmpty(data) ? onSaveAndContinue : null}
        currentStudyBudget={currentStudyBudget}
      />
    </>
  );
};

AddOrEditEncounterBudgetEvent.title = 'Add Encounter budget event';
AddOrEditEncounterBudgetEvent.className = 'add-encounter-modal';
AddOrEditEncounterBudgetEvent.size = 'w800';

export default AddOrEditEncounterBudgetEvent;
