import React, { useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { isArray } from 'lodash/lang';

import { FinBudgetEventApi } from '../../../../../../../api';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { MANAGE_BUDGETS } from '../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../services/auth';
import { toBill } from '../../../../../../../services/financial';
import { onRequestError } from '../../../../../../../services/handlers';
import { ACTIONS_BUTTON } from '../../../../../finance/NewInvoice/InvoiceTable/TableConstants';
import { Amount } from '../../../../../finance/shared/amount-view/Amount/Amount';
import { DefaultBudgetCell } from '../../../../../finance/shared/FinanseTableMUI/DefaultBudgetCell';
import { eventTypeLabel, eventTypes } from '../../budgetConstant';
import { CustomStudyBudgetToolbar } from '../../CustomStudyBudgetToolbar';

import { modalTypes } from './TableConstants';

import './BudgetEventsTable.scss';

export const BudgetEventsTable = ({
  filteredEvents,
  studyId,
  budgetId,
  isEditingForbidden,
  currentStudyBudget,
  loadBudgetEvents,
  managementFeePercent,
  vendors,
  events
}) => {
  const [pinnedColumns, setPinnedColumns] = useState({
    right: [ACTIONS_BUTTON]
  });
  const groupStatusEventsAndBuildData = useCallback(
    budgetEvent => {
      const filteredItems = events
        .filter(event => event.groupId === budgetEvent?.groupId)
        .sort((a, b) => a.countFrom - b.countFrom);
      if (isArray(filteredItems) && filteredItems.length) {
        const {
          name,
          overhead,
          withholding,
          managementFee,
          encounterOverride,
          encounterId,
          encounterName,
          triggerName,
          vendorId,
          patientStatusId,
          groupId,
          secondaryPatientStatusId,
          primaryStatusRatio,
          secondaryStatusRatio
        } = filteredItems[0];
        const eventRows = filteredItems.map(event => {
          return {
            id: event.id,
            countFrom: event.countFrom,
            countTo: event.countTo ? event.countTo : Number.MAX_SAFE_INTEGER,
            clientAmount: toBill(event.clientAmount, false),
            siteAmount: toBill(event.siteAmount, false),
            vendorAmount: toBill(event.vendorAmount, false),
            vendorAmountType: event.vendorAmountType,
            siteAmountType: event.siteAmountType,
            encounterId: event.encounterId,
            encounterName: event.encounterName,
            name: event.name,
            triggerName: event.triggerName,
            vendorId: event.vendorId,
            patientStatusId: event.patientStatusId,
            groupId: event.groupId,
            secondaryPatientStatusId: event.secondaryPatientStatusId,
            primaryStatusRatio: event.primaryStatusRatio,
            secondaryStatusRatio: event.secondaryStatusRatio
          };
        });
        return {
          eventRows,
          name,
          overhead,
          withholding,
          managementFee,
          encounterOverride,
          encounterId,
          encounterName,
          triggerName,
          vendorId,
          patientStatusId,
          groupId,
          secondaryPatientStatusId,
          primaryStatusRatio,
          secondaryStatusRatio
        };
      }
      return {};
    },
    [events]
  );
  const prepareEventData = useCallback(
    budgetRow => {
      if (budgetRow.type === 'FIXED_ITEM') {
        const groupId = budgetRow.groupId;
        const vendorId = budgetRow.vendorId;
        const groupRows = events.filter(event => event.groupId === groupId).sort((a, b) => a.countFrom - b.countFrom);
        if (isArray(groupRows) && groupRows.length) {
          const { name, overhead, withholding, managementFee, encounterOverride } = groupRows[0];
          const eventRows = groupRows.map(groupRow => {
            return {
              id: groupRow.id,
              countFrom: groupRow.countFrom,
              countTo: groupRow.countTo ? groupRow.countTo : Number.MAX_SAFE_INTEGER,
              clientAmount: toBill(groupRow.clientAmount, false),
              siteAmount: toBill(groupRow.siteAmount, false),
              vendorAmount: toBill(groupRow.vendorAmount, false),
              vendorAmountType: groupRow.vendorAmountType,
              siteAmountType: groupRow.siteAmountType
            };
          });
          return { groupId, eventRows, name, overhead, withholding, managementFee, encounterOverride, vendorId };
        }
        return {};
      } else if (
        [
          eventTypes.ENCOUNTER.BudgetRowType,
          eventTypes.UNEXPECTED_ENCOUNTER.BudgetRowType,
          eventTypes.SITUATIONAL_ENCOUNTER.BudgetRowType,

          eventTypes.ENCOUNTER_ITEM_GROUP.BudgetRowType,
          eventTypes.UNEXPECTED_ITEM_GROUP.BudgetRowType,
          eventTypes.SITUATIONAL_ITEM_GROUP.BudgetRowType,

          eventTypes.ITEM_GROUP_GENERAL.BudgetRowType
        ].includes(budgetRow.type)
      ) {
        const { groupId, triggerId, triggerName, name, overhead, withholding, managementFee, vendorId } = budgetRow;

        const eventRows = events
          .filter(event => event.groupId === groupId)
          .sort((a, b) => a.countFrom - b.countFrom)
          .map(groupRow => {
            return {
              id: groupRow.id,
              countFrom: groupRow.countFrom,
              countTo: groupRow.countTo ? groupRow.countTo : Number.MAX_SAFE_INTEGER,
              clientAmount: toBill(groupRow.clientAmount, false),
              siteAmount: toBill(groupRow.siteAmount, false),
              vendorAmount: toBill(groupRow.vendorAmount, false),
              vendorAmountType: groupRow.vendorAmountType,
              siteAmountType: groupRow.siteAmountType
            };
          });

        return {
          groupId,
          triggerId,
          triggerName,
          name,
          overhead,
          withholding,
          managementFee,
          vendorId,
          eventRows
        };
      } else {
        return budgetRow;
      }
    },
    [events]
  );

  function resolveModalTitle(budgetEventType, title) {
    switch (budgetEventType) {
      case 'FIXED_ITEM':
        return 'Edit Expense - Fixed budget event';
      case 'PATIENT_REIMBURSEMENT':
        return 'Edit Patient Reimbursement budget event';
      default:
        return title;
    }
  }

  const showEditBudgetEvent = useCallback(
    (Component, budgetEvent) => {
      if (['STATUS_CHANGE', 'RATIO_STATUS_CHANGE', 'ENCOUNTER_STATUS_CHANGE'].includes(budgetEvent.type)) {
        const modalBox = ModalBoxes.open({
          title: Component.title,
          component: (
            <Component
              triggerType={budgetEvent.type}
              budgetEventType={budgetEvent.type}
              studyId={studyId}
              managementFeePercent={managementFeePercent}
              id={budgetId}
              data={groupStatusEventsAndBuildData(budgetEvent)}
              isEditingForbidden={isEditingForbidden}
              currentStudyBudget={currentStudyBudget}
              onSave={(data, groupId) => {
                if (budgetEvent.type === 'STATUS_CHANGE') {
                  FinBudgetEventApi.updateStatusChangeEvent(budgetId, groupId, data).then(
                    loadBudgetEvents,
                    onRequestError
                  );
                } else if (budgetEvent.type === 'RATIO_STATUS_CHANGE') {
                  FinBudgetEventApi.updateRatioStatusChangeEvent(budgetId, groupId, data).then(
                    loadBudgetEvents,
                    onRequestError
                  );
                } else {
                  FinBudgetEventApi.updateEncounterStatusChanges(budgetId, groupId, data).then(
                    loadBudgetEvents,
                    onRequestError
                  );
                }
                modalBox.close();
              }}
            />
          )
        });
      } else if (budgetEvent.type === 'EXPENSE_VARIABLE') {
        ModalBoxes.open({
          title: 'Edit Expense - Variable budget event',
          component: (
            <Component
              currentStudyBudget={currentStudyBudget}
              saveEvent={data => {
                return FinBudgetEventApi.updateExpenseVariableEvent(budgetId, budgetEvent.groupId, data).then(
                  loadBudgetEvents,
                  onRequestError
                );
              }}
              eventForUpdate={budgetEvent}
            />
          ),
          size: 'w650',
          className: 'expense-variable-modal'
        });
      } else {
        const modalBox = ModalBoxes.open({
          title: resolveModalTitle(budgetEvent.type, Component.title),
          className: Component.className,
          component: (
            <Component
              triggerType={budgetEvent.type}
              budgetEventType={budgetEvent.type}
              id={budgetId}
              studyId={studyId}
              managementFeePercent={managementFeePercent}
              currentStudyBudget={currentStudyBudget}
              data={prepareEventData(budgetEvent)}
              vendors={vendors}
              onSave={(data, groupId) => {
                switch (budgetEvent.type) {
                  case 'FIXED_ITEM':
                    FinBudgetEventApi.updateFixedItemEvent(budgetId, groupId, data).then(
                      loadBudgetEvents,
                      onRequestError
                    );
                    break;
                  case 'ITEM_GROUP_GENERAL':
                    FinBudgetEventApi.updateItemGroupEvent(budgetId, groupId, data).then(
                      loadBudgetEvents,
                      onRequestError
                    );
                    break;
                  case 'PATIENT_REIMBURSEMENT':
                    FinBudgetEventApi.updatePatientReimbursementEvent(budgetId, budgetEvent.id, data).then(
                      loadBudgetEvents,
                      onRequestError
                    );
                    break;
                  case eventTypes.ENCOUNTER.BudgetRowType:
                  case eventTypes.UNEXPECTED_ENCOUNTER.BudgetRowType:
                  case eventTypes.SITUATIONAL_ENCOUNTER.BudgetRowType:
                    FinBudgetEventApi.updateEncounterEvent(budgetId, groupId, data).then(
                      loadBudgetEvents,
                      onRequestError
                    );
                    break;
                  case eventTypes.ENCOUNTER_ITEM_GROUP.BudgetRowType:
                  case eventTypes.UNEXPECTED_ITEM_GROUP.BudgetRowType:
                  case eventTypes.SITUATIONAL_ITEM_GROUP.BudgetRowType:
                    FinBudgetEventApi.updateEncounterItemgroupEvent(budgetId, groupId, data).then(
                      loadBudgetEvents,
                      onRequestError
                    );
                    break;
                  default:
                    FinBudgetEventApi.updatePatientStipendEvent(budgetId, budgetEvent.id, data).then(
                      loadBudgetEvents,
                      onRequestError
                    );
                }
                modalBox.close();
              }}
            />
          )
        });
      }
    },
    [
      budgetId,
      currentStudyBudget,
      groupStatusEventsAndBuildData,
      isEditingForbidden,
      loadBudgetEvents,
      managementFeePercent,
      prepareEventData,
      studyId,
      vendors
    ]
  );

  const removeBudgetEvent = useCallback(
    budgetEvent => {
      ModalBoxes.confirm({
        content: 'Are you sure you want to delete this record?',
        confirmButton: 'Yes',
        cancelButton: 'No'
      }).then(
        () => {
          FinBudgetEventApi.inactivateBudgetEvent(budgetId, budgetEvent.id).then(loadBudgetEvents, onRequestError);
        },
        () => {}
      );
    },
    [budgetId, loadBudgetEvents]
  );

  const columns = useMemo(
    () => [
      {
        headerName: 'Epoch',
        field: 'epochName',
        minWidth: 115,
        flex: 1
      },
      {
        headerName: 'Encounter',
        field: 'encounterName',
        minWidth: 135,
        flex: 1
      },
      {
        headerName: 'Item Group',
        field: 'itemGroupName',
        minWidth: 145,
        flex: 1
      },
      {
        headerName: 'Budget Event Type',
        field: 'type',
        flex: 1,
        minWidth: 190,
        renderCell: DefaultBudgetCell,
        valueGetter: ({ row }) => eventTypeLabel[row.type]
      },
      {
        headerName: 'Event Name',
        field: 'fullEventName',
        minWidth: 145,
        flex: 1
      },
      {
        accessor: 'clientAmount',
        Header: 'Client',
        headerClassName: 'amount-header',
        headerName: 'Client',
        field: 'clientAmount',
        type: 'number',
        flex: 1,
        minWidth: 110,
        valueGetter: ({ row }) =>
          row.type === 'PATIENT_REIMBURSEMENT'
            ? toBill(0)
            : row.type === 'EXPENSE_VARIABLE'
            ? toBill(row.maxAllowanceAmount)
            : toBill(row.clientAmount),
        renderCell: ({ row }) => {
          if (row.type === 'PATIENT_REIMBURSEMENT') {
            return (
              <ZeroAmountWithTooltip tooltipTitle="This study is eligible for submitting patient reimbursements. Amounts will vary." />
            );
          }
          if (row.type === 'EXPENSE_VARIABLE') {
            if (row.maxAllowanceAmount === 0) {
              return <ZeroAmountWithTooltip tooltipTitle="There is no maximum for this expense." />;
            } else {
              return <Amount coinsAmount={row.maxAllowanceAmount} showDollarSign />;
            }
          } else {
            return <Amount coinsAmount={row.clientAmount} defaultZeroValue={''} showDollarSign />;
          }
        }
      },
      {
        field: 'siteAmount',
        headerName: 'Site',
        flex: 1,
        minWidth: 95,
        type: 'number',
        valueGetter: ({ row }) =>
          row.type === 'EXPENSE_VARIABLE' ? toBill(row.maxAllowanceAmount) : toBill(row.siteAmount),
        renderCell: ({ row }) =>
          row.type === 'EXPENSE_VARIABLE' ? (
            row.maxAllowanceAmount === 0 ? (
              <ZeroAmountWithTooltip tooltipTitle="There is no maximum for this expense." />
            ) : (
              <Amount coinsAmount={row.maxAllowanceAmount} showDollarSign />
            )
          ) : (
            <Amount coinsAmount={row.siteAmount} defaultZeroValue={''} showDollarSign />
          )
      },
      {
        accessor: 'patientAmount',
        Header: 'Patient',
        headerClassName: 'amount-header',
        field: 'patientAmount',
        headerName: 'Patient',
        minWidth: 115,
        flex: 1,
        type: 'number',
        valueGetter: ({ row }) => (row.type === 'PATIENT_REIMBURSEMENT' ? toBill(0) : toBill(row.patientAmount)),
        renderCell: ({ row }) => {
          if (row.type === 'PATIENT_REIMBURSEMENT') {
            return (
              <ZeroAmountWithTooltip tooltipTitle="Patients are eligible to receive the full approved reimbursement amount." />
            );
          } else {
            return <Amount coinsAmount={row.patientAmount} defaultZeroValue={''} showDollarSign />;
          }
        }
      },
      {
        field: 'vendorAmount',
        headerName: 'Vendor',
        minWidth: 115,
        flex: 1,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.vendorAmount),
        renderCell: ({ row }) => <Amount coinsAmount={row.vendorAmount} defaultZeroValue={''} showDollarSign />
      },
      {
        accessor: 'elligoAmount',
        Header: 'Net',
        headerClassName: 'amount-header',
        field: 'elligoAmount',
        headerName: 'Net',
        minWidth: 95,
        flex: 1,
        type: 'number',
        valueGetter: ({ row }) => (row.type === 'PATIENT_REIMBURSEMENT' ? toBill(0) : toBill(row.elligoAmount)),
        renderCell: ({ row }) =>
          row.type === 'PATIENT_REIMBURSEMENT' ? (
            <div className="budget-event-table column-value">$0.00</div>
          ) : (
            <Amount coinsAmount={row.elligoAmount} showDollarSign />
          )
      },
      {
        field: 'overhead',
        headerName: 'OH',
        width: 95,
        valueGetter: ({ value }) => (value ? 'Yes' : 'No'),
        renderCell: ({ row }) => {
          return row.overhead ? 'Yes' : 'No';
        }
      },
      {
        field: 'withholding',
        headerName: 'WH',
        width: 95,
        valueGetter: ({ value }) => (value ? 'Yes' : 'No'),
        renderCell: ({ row }) => {
          return row.withholding ? 'Yes' : 'No';
        }
      },
      {
        field: 'managementFee',
        headerName: 'Fee',
        width: 95,
        valueGetter: ({ value }) => (value ? 'Yes' : 'No'),
        renderCell: ({ row }) => {
          return row.managementFee ? 'Yes' : 'No';
        }
      },
      {
        field: 'triggerName',
        headerName: 'Protocol Event',
        flex: 1,
        valueGetter: ({ row }) => (['FIXED_ITEM', 'EXPENSE_VARIABLE'].includes(row.type) ? '' : row.triggerName),
        renderCell: DefaultBudgetCell
      },
      {
        headerName: 'Actions',
        field: 'actions',
        type: 'actions',
        width: 70,
        resizable: false,
        renderCell: ({ row }) => {
          const visibleMode = (
            <span
              style={{ cursor: 'pointer' }}
              className="material-icons pr-2 c-p protocol-setup-btn"
              onClick={() => showEditBudgetEvent(modalTypes[row.type], row)}
            >
              visibility
            </span>
          );
          const editMode = (
            <div>
              <span
                className="glyphicon glyphicon-edit pr-2 c-p protocol-setup-btn"
                onClick={() => showEditBudgetEvent(modalTypes[row.type], row)}
              />
              <span
                className="glyphicon glyphicon-remove c-p protocol-setup-btn"
                onClick={() => removeBudgetEvent(row)}
              />
            </div>
          );
          return isEditingForbidden || !userHasAccessTo(MANAGE_BUDGETS) ? visibleMode : editMode;
        }
      }
    ],
    [isEditingForbidden, removeBudgetEvent, showEditBudgetEvent]
  );

  const handlePinnedColumnsChange = useCallback(updatedPinnedColumns => {
    setPinnedColumns({
      ...updatedPinnedColumns,
      right: [...updatedPinnedColumns.right.filter(column => column !== ACTIONS_BUTTON), ACTIONS_BUTTON]
    });
  }, []);

  const getTogglableColumns = useCallback(
    columns => columns.filter(column => ![ACTIONS_BUTTON].includes(column.field)).map(column => column.field),
    []
  );

  return (
    <DataGridPro
      sx={{
        padding: '0 20px 0 20px',
        border: 'none'
      }}
      data-testid="budget-details-table"
      getRowId={row => row?.id}
      rows={filteredEvents}
      columns={columns}
      rowHeight={38}
      slotProps={{
        columnsPanel: {
          getTogglableColumns
        }
      }}
      initialState={{
        columns: {
          columnVisibilityModel: { triggerName: false }
        }
      }}
      localeText={{ noRowsLabel: 'No Record Found' }}
      pinnedColumns={pinnedColumns}
      onPinnedColumnsChange={handlePinnedColumnsChange}
      disableRowSelectionOnClick
      slots={{ toolbar: CustomStudyBudgetToolbar }}
    />
  );
};

const ZeroAmountWithTooltip = ({ tooltipTitle }) => {
  const CustomizedTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} title={tooltipTitle} placement="right" arrow />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
      fontSize: '90%'
    }
  });
  return (
    <CustomizedTooltip>
      <div className="budget-event-table column-value">
        <span>
          $0.00
          <span className="text-colour">*</span>
        </span>
      </div>
    </CustomizedTooltip>
  );
};
