import React, { useCallback, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Chip, CircularProgress, InputAdornment, TextField, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import cx from 'classnames';
import { isEmpty, isFunction, omit } from 'lodash';
import { uniqWith } from 'lodash/array';
import uuid from 'uuid/v4';

import FinInvoiceApi from '../../../../../../api/finance/FinInvoiceApi';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { ERROR_ICON } from '../../../../../../constants/notificationIcons';
import { onRequestError } from '../../../../../../services/handlers';
import { isValidEmail } from '../../../../setup/AccountsNew/AccountService.js';

import { EmailTemplate } from './EmailTemplate/EmailTemplate';
import Editor from './Editor';

import './SendMailModal.scss';

const MAX_FILES_SIZE = 30000000;
export const SendMailModal = ({
  modalBox,
  invoiceNumber,
  initialSubject,
  initialMailText,
  initialFiles,
  closeCreateInvoiceAfterSave,
  emailTo,
  emailFrom,
  emailCc,
  emailBcc,
  clientName,
  emailModalBoxHeader,
  saveNewInvoice,
  cancelEmailSanding,
  loadInvoice,
  currentInvoiceType,
  billToList,
  invoiceId,
  setInvoiceHistory
}) => {
  const [from, setFrom] = useState(emailFrom);
  const [to, setTo] = useState(emailTo);
  const [cc, setCc] = useState(emailCc);
  const [bcc, setBcc] = useState(emailBcc);

  const [fromInputValue, setFromInputValue] = useState('');
  const [toInputValue, setToInputValue] = useState('');
  const [ccInputValue, setCcInputValue] = useState('');
  const [bccInputValue, setBccInputValue] = useState('');

  const [showCc, setShowCc] = useState(!!emailCc.length);
  const [showBcc, setShowBcc] = useState(!!emailBcc.length);

  const [openToModal, setOpenToModal] = React.useState(false);
  const [openCcModal, setOpenCcModal] = React.useState(false);
  const [openBccModal, setOpenBccModal] = React.useState(false);

  const [subject, setSubject] = useState(initialSubject);
  const [emailText, setEmailText] = useState(null);
  const [invalidFields, setInvalidFields] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(initialFiles);

  const BCC = 'bcc';
  const CC = 'cc';
  const TO = 'to';
  const FROM = 'from';

  const fileInput = useRef(null);

  const clientsWithEmails = billToList
    .filter(client => client.email !== null)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(client => `${client.name}: ${client.email}`);

  const clientsNameList = billToList.map(item => item.name);

  const inputStyles = {
    '& label.Mui-focused': {
      color: '#7d3559'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#7d3559'
    },
    '.MuiInput-root': {
      width: '511px',
      minHeight: '44px',
      color: '#828282',
      marginLeft: '5px'
    },
    '& .MuiChip-label': {
      color: 'black',
      maxWidth: '390px'
    }
  };

  const getInvalidFields = useCallback(() => {
    const fieldsForValidate = [];

    const processEmailField = (emails, fieldName) => {
      emails.forEach(email => {
        if (!isValidEmail(email) || (fieldName === FROM && !/@elligodirect.com$/.test(email))) {
          fieldsForValidate.push({ name: fieldName, value: email });
        }
      });
    };

    processEmailField([from], FROM);
    processEmailField(extractEmailsFromClientInfoList(to), TO);
    processEmailField(extractEmailsFromClientInfoList(cc), CC);
    processEmailField(extractEmailsFromClientInfoList(bcc), BCC);

    return fieldsForValidate;
  }, [bcc, cc, from, to]);

  const customEmailNotSentMessage = useCallback(
    message => (
      <div>
        <h5>Email not Sent</h5>
        {message} Be sure to check the contact email and try resending. If the problem persists, please submit a
        <a href="https://elligodirect.atlassian.net/servicedesk/customer/portal/8"> Support Request</a>
      </div>
    ),
    []
  );

  const errorMessageForNewInvoice = useCallback(
    () => customEmailNotSentMessage('We have saved your invoice but your email was not sent.'),
    [customEmailNotSentMessage]
  );

  const errorMessageForExistingInvoice = useCallback(() => customEmailNotSentMessage('Your email was not sent.'), [
    customEmailNotSentMessage
  ]);

  const biteSize = useMemo(() => uploadedFiles.reduce((acc, value) => acc + value.size, 0), [uploadedFiles]);
  const fullFilesSizeInMB = useMemo(() => {
    return (biteSize / 1000000).toFixed(2);
  }, [biteSize]);
  const isSizeOutOfLimit = useMemo(() => biteSize > MAX_FILES_SIZE, [biteSize]);

  const sendMail = useCallback(
    (emailData, fileId) =>
      FinInvoiceApi.sendInvoice(emailData)
        .then(emailId => {
          return FinInvoiceApi.saveInvoiceLog(invoiceNumber, emailId.data, 'SEND', currentInvoiceType).then(() => {
            NotificationManager.success(`Success! Your invoice has been sent via email`);
            modalBox.close();
            isFunction(closeCreateInvoiceAfterSave) && closeCreateInvoiceAfterSave();
          });
        })
        .then(() => {
          if (invoiceId) {
            return FinInvoiceApi.getInvoiceHistory(invoiceId).then(({ data }) => {
              setInvoiceHistory(data);
            });
          }
        })
        .catch(() => {
          if (isFunction(saveNewInvoice)) {
            modalBox.close();
            isFunction(closeCreateInvoiceAfterSave) && closeCreateInvoiceAfterSave();
            FinInvoiceApi.saveInvoiceLog(invoiceNumber, null, 'SAVE', currentInvoiceType).then(() => {},
            onRequestError);
            loadInvoice(invoiceNumber, fileId, currentInvoiceType);
            toast.error(errorMessageForNewInvoice, { icon: ERROR_ICON });
          } else {
            toast.error(errorMessageForExistingInvoice, { icon: ERROR_ICON });
          }
        }),
    [
      invoiceNumber,
      currentInvoiceType,
      modalBox,
      closeCreateInvoiceAfterSave,
      invoiceId,
      setInvoiceHistory,
      saveNewInvoice,
      loadInvoice,
      errorMessageForNewInvoice,
      errorMessageForExistingInvoice
    ]
  );

  const sendInvoice = useCallback(() => {
    const invalidFields = getInvalidFields();
    if (!isEmpty(invalidFields) || isEmpty(to)) return setInvalidFields(invalidFields);
    const emailData = {
      fromName: `Elligo Health Research Finance Team`,
      from: from,
      to: extractEmailsFromClientInfoList(to),
      cc: extractEmailsFromClientInfoList(cc),
      bcc: extractEmailsFromClientInfoList(bcc),
      subject,
      body: EmailTemplate(emailText),
      content: emailText,
      contentType: `text/html`,
      attachments: uploadedFiles.map(file => omit(file, 'id')),
      invoiceNumber,
      invoiceType: currentInvoiceType
    };
    if (isFunction(saveNewInvoice)) {
      saveNewInvoice().then(({ data: { fileId } }) => {
        sendMail(emailData, fileId);
      });
    } else {
      sendMail(emailData);
    }
  }, [
    getInvalidFields,
    from,
    to,
    cc,
    bcc,
    subject,
    emailText,
    uploadedFiles,
    invoiceNumber,
    currentInvoiceType,
    saveNewInvoice,
    sendMail
  ]);

  const handleFileChange = async ({ target: { files } }) => {
    const uploadedFiles = Array.from(files).map(file => {
      let reader = new FileReader();
      return new Promise(resolve => {
        reader.onload = () =>
          resolve({
            attachment: true,
            fileName: file.name,
            content: reader.result.split(',')[1],
            contentType: file.type,
            size: file.size,
            id: uuid()
          });
        reader.readAsDataURL(file);
      });
    });
    const allFiles = await Promise.all(uploadedFiles);
    setUploadedFiles(prevState => {
      return uniqWith(
        [...prevState, ...allFiles],
        (firstValue, secondValue) =>
          firstValue.content === secondValue.content && firstValue.fileName === secondValue.fileName
      );
    });
    fileInput.current.value = '';
  };

  const removeAttachment = useCallback(id => {
    setUploadedFiles(prevState => prevState.filter(file => file.id !== id));
  }, []);

  function extractEmailsFromClientInfoList(clientInfoList) {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/i;
    return clientInfoList.flatMap(item => (item.match(emailRegex) || []).map(email => email.trim()));
  }

  const resolveDefaultClientsNameAndEmails = useCallback((emails, billToList, clientName) => {
    if (emails.length === 0) return [];

    let savedEmailsToDisplay = [];
    const client = billToList.find(item => item.name === clientName);

    if (emails.length === 1 && client && client.email === emails[0]) {
      savedEmailsToDisplay.push(clientName + ': ' + emails[0]);
      return savedEmailsToDisplay;
    }

    emails.forEach(email => {
      const matchedClient = billToList.filter(client => client.email === email);

      if (matchedClient.length === 1) {
        savedEmailsToDisplay.push(`${matchedClient[0].name}: ${matchedClient[0].email}`);
      } else {
        savedEmailsToDisplay.push(email);
      }
    });
    return [...new Set(savedEmailsToDisplay)];
  }, []);

  function onInputChange(event, value, setInputValue, setOpenModal) {
    if (event.key !== 'Enter') {
      setInputValue(value);
    } else if (event.key === 'Enter' && isValidEmail(value)) {
      setInputValue('');
    }

    setOpenModal(value.length > 0);
  }

  function onFromInputChange(event, value, setInputValue) {
    if (event.key !== 'Enter') {
      setInputValue(value);
    } else if (event.key === 'Enter' && isValidEmail(value) && /@elligodirect.com$/.test(value)) {
      setInputValue('');
    }
  }

  function extractClientNameAndEmail(option) {
    const lastIndex = option?.lastIndexOf(':');
    const clientName = option?.substring(0, lastIndex);
    const email = option?.substring(lastIndex + 1);
    return { clientName, email };
  }

  function onChange(emails, setEmail, setInputValue) {
    const lastValue = emails[emails.length - 1];
    const isEmailValid = isValidEmail(lastValue);
    const isValueEmpty = emails.length === 0;
    const { clientName } = extractClientNameAndEmail(lastValue);
    const isClientNameIncluded = !isValueEmpty && clientsNameList.includes(clientName);

    if (isEmailValid || isValueEmpty || isClientNameIncluded) {
      setEmail(emails);
      setInputValue('');
    }
  }

  function renderClientNameAndEmail(props, option) {
    const { clientName, email } = extractClientNameAndEmail(option);

    return (
      <li {...props}>
        <div>
          <div style={{ fontSize: '16px' }}>{clientName}</div>
          <div style={{ color: '#7d3559' }}>{email}</div>
        </div>
      </li>
    );
  }

  const renderTooltip = (email, index, getTagProps) => (
    <Tooltip title={email} placement="top" key={index}>
      <Chip
        sx={{
          height: '25px',
          marginBottom: '5px'
        }}
        label={email}
        {...getTagProps({ index })}
      />
    </Tooltip>
  );

  const handleFocusChange = useCallback(event => {
    const value = event.target.value;
    if (event.type === 'blur' && isValidEmail(value)) {
      const enterKeyPressEvent = new KeyboardEvent('keydown', {
        key: 'Enter',
        bubbles: true
      });
      event.target.dispatchEvent(enterKeyPressEvent);
    }
  }, []);

  const defaultToValue = resolveDefaultClientsNameAndEmails(to, billToList, clientName);
  const defaultCcValue = resolveDefaultClientsNameAndEmails(cc, billToList, clientName);
  const defaultBccValue = resolveDefaultClientsNameAndEmails(bcc, billToList, clientName);

  return (
    <>
      <ModalBoxes.Header>{emailModalBoxHeader}</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="email-setup-row">
          <span className="email-setup-row-label">From:</span>
          <Autocomplete
            id="tags-standard"
            data-testid="email-from"
            multiple
            freeSolo
            clearIcon={false}
            options={[]}
            value={from ? [from] : []}
            inputValue={fromInputValue}
            onChange={({ target: { value } }) => {
              if ((isValidEmail(value) && /@elligodirect.com$/.test(value)) || !value) {
                setFrom(value);
                setFromInputValue('');
              }
              setInvalidFields(prevState => prevState.filter(el => el.name !== 'from'));
            }}
            onInputChange={(event, value) => onFromInputChange(event, value, setFromInputValue)}
            renderTags={(value, getTagProps) => value.map((email, index) => renderTooltip(email, index, getTagProps))}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                error={invalidFields.some(el => el.name === FROM) || isEmpty(from)}
              />
            )}
            onBlur={event => handleFocusChange(event)}
            sx={inputStyles}
          />
        </div>
        <div className="email-setup-row">
          <span className="email-setup-row-label">To:</span>
          <Autocomplete
            id="tags-standard"
            data-testid="email-to"
            multiple
            autoComplete
            freeSolo
            clearIcon={false}
            options={clientsWithEmails}
            value={defaultToValue}
            inputValue={toInputValue}
            open={openToModal}
            onClose={() => setOpenToModal(false)}
            onChange={(event, newValue) => onChange(newValue, setTo, setToInputValue)}
            onInputChange={(event, value) => onInputChange(event, value, setToInputValue, setOpenToModal)}
            renderOption={(props, option) => renderClientNameAndEmail(props, option)}
            renderTags={(value, getTagProps) => (
              <div>{value.map((email, index) => renderTooltip(email, index, getTagProps))}</div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                error={invalidFields.some(el => el.name === TO) || to.length === 0}
              />
            )}
            onBlur={event => handleFocusChange(event)}
            sx={{
              ...inputStyles,
              '& .MuiAutocomplete-inputRoot': {
                paddingRight: '100px !important'
              }
            }}
          />
          <InputAdornment
            position="end"
            sx={{
              gap: '20px',
              position: 'absolute',
              right: '0',
              '.toggle-to-button': { cursor: 'pointer', margin: '0', '&.active': { color: '#323232' } }
            }}
          >
            <div
              className={cx('toggle-to-button', { active: showCc })}
              onClick={() => {
                if (showCc) {
                  setCc([]);
                  setInvalidFields(prevState => prevState.filter(el => el.name !== CC));
                }
                setShowCc(prevState => !prevState);
              }}
            >
              Cc
            </div>
            <div
              className={cx('toggle-to-button', { active: showBcc })}
              onClick={() => {
                if (showBcc) {
                  setBcc([]);
                  setInvalidFields(prevState => prevState.filter(el => el.name !== BCC));
                }
                setShowBcc(prevState => !prevState);
              }}
            >
              Bcc
            </div>
          </InputAdornment>
        </div>
        {showCc && (
          <div className="email-setup-row">
            <span className="email-setup-row-label">Cc:</span>
            <Autocomplete
              id="tags-standard"
              data-testid="email-cc"
              multiple
              autoComplete
              freeSolo
              clearIcon={false}
              options={clientsWithEmails}
              value={defaultCcValue}
              inputValue={ccInputValue}
              open={openCcModal}
              onClose={() => setOpenCcModal(false)}
              onChange={(event, newValue) => onChange(newValue, setCc, setCcInputValue)}
              onInputChange={(event, value) => onInputChange(event, value, setCcInputValue, setOpenCcModal)}
              renderOption={(props, option) => renderClientNameAndEmail(props, option)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => renderTooltip(option, index, getTagProps))
              }
              renderInput={params => (
                <TextField {...params} variant="standard" error={invalidFields.some(el => el.name === CC)} />
              )}
              onBlur={event => handleFocusChange(event)}
              sx={inputStyles}
            />
          </div>
        )}
        {showBcc && (
          <div className="email-setup-row">
            <span className="email-setup-row-label">Bcc:</span>
            <Autocomplete
              id="tags-standard"
              data-testid="email-bcc"
              multiple
              autoComplete
              freeSolo
              clearIcon={false}
              value={defaultBccValue}
              options={clientsWithEmails}
              inputValue={bccInputValue}
              open={openBccModal}
              onClose={() => setOpenBccModal(false)}
              onChange={(event, newValue) => onChange(newValue, setBcc, setBccInputValue)}
              onInputChange={(event, value) => onInputChange(event, value, setBccInputValue, setOpenBccModal)}
              renderOption={(props, option) => renderClientNameAndEmail(props, option)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => renderTooltip(option, index, getTagProps))
              }
              renderInput={params => (
                <TextField {...params} variant="standard" error={invalidFields.some(el => el.name === BCC)} />
              )}
              onBlur={event => handleFocusChange(event)}
              sx={inputStyles}
            />
          </div>
        )}
        <div className="email-setup-row">
          <span className="email-setup-row-label">Subject:</span>
          <TextField
            sx={inputStyles}
            variant="standard"
            value={subject}
            onChange={({ target: { value } }) => setSubject(value)}
          />
        </div>
        {uploadedFiles.map((file, index) => (
          <div className={cx('attached-file', { 'first-pdf': index === 0 })} key={file.id}>
            <div>
              <AttachFileIcon sx={{ color: '#828282', margin: '0 12px 0 9px' }} /> {file.fileName}
            </div>
            <CloseIcon sx={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => removeAttachment(file.id)} />
          </div>
        ))}
        <Editor onChange={value => setEmailText(value)} initialText={initialMailText} />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <div className="attach-file-wrapper">
          <Button
            sx={{
              color: '#828282',
              textTransform: 'none',
              fontSize: '16px',
              textAlign: 'center',
              height: '48px',
              fontWeight: 400,
              '&:hover': { backgroundColor: 'transparent' }
            }}
            component="label"
            startIcon={<AttachFileIcon style={{ width: '24px', height: '24px', marginTop: '-2px' }} />}
            size="large"
          >
            Attach
            <VisuallyHiddenInput
              ref={fileInput}
              data-testid="file-upload-input"
              type="file"
              multiple
              onInputCapture={handleFileChange}
              accept=".jpg, .jpeg, .png, .pdf, .docx, .xlsx, .csv"
            />
          </Button>
          <CircularProgress
            variant="determinate"
            thickness={7}
            sx={{ color: isSizeOutOfLimit ? '#F34848' : '#27AE60' }}
            value={+((fullFilesSizeInMB * 100) / 30).toFixed()}
          />

          <span className="available-size">
            {isSizeOutOfLimit
              ? 'Exceeds maximum allotment'
              : `${(MAX_FILES_SIZE / 1000000 - fullFilesSizeInMB).toFixed(2)} MB Available`}
          </span>
        </div>

        <ButtonGroup>
          <Button
            priority="medium"
            sx={{ color: '#691E44', textTransform: 'none' }}
            onClick={() => {
              modalBox.close();
              isFunction(cancelEmailSanding) && cancelEmailSanding();
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: '#691E44',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#7d3559'
              }
            }}
            variant="contained"
            onClick={sendInvoice}
            disabled={isSizeOutOfLimit}
          >
            Send
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});
