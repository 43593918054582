import React, { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';

import { FF_PATIENT_REIMBURSEMENT, useFeatureFlag } from '../../../../../../common/hooks/useAppInfo';
import { PatientInfoContext } from '../PatientInfoContext';

import EncounterSearchSection from './EncounterSearchSection/EncounterSearchSection';
import EncountersSectionMenu from './EncountersSectionMenu/EncountersSectionMenu';
import EncountersTab from './EncountersTab/EncountersTab';
import PaymentsSection from './PaymentsSection/PaymentsSection';
import { EncountersSectionProvider } from './EncountersSectionContext';
import useActiveTab, { TAB_ACTIVE, TAB_CANCELED, TAB_PAYMENTS, TAB_PROJECTED } from './useActiveTab';
import useShowHide from './useShowHide';

import './EncountersSection.scss';

export default function EncountersSection() {
  const PTEnabled = useFeatureFlag(FF_PATIENT_REIMBURSEMENT);

  const { ssuPatientId } = useParams();
  const { currentStudy } = useContext(PatientInfoContext);
  const { showEncounterTabs, showPaymentsSection } = useShowHide(PTEnabled, currentStudy?.types);
  const { activeTab, setActiveTab } = useActiveTab(showEncounterTabs, showPaymentsSection);

  const [activeTabRefreshIndex, setActiveTabRefreshIndex] = useState(0);

  const activeTabKey = useMemo(
    function() {
      return `${activeTab}-${activeTabRefreshIndex}`;
    },
    [activeTab, activeTabRefreshIndex]
  );

  if (!ssuPatientId || (!showEncounterTabs && !showPaymentsSection)) {
    return null;
  }

  return (
    <EncountersSectionProvider>
      <section className="encounters-section-wrapper">
        <div className="encounters-section">
          <div className="encounters-section-header">
            <div className="encounters-section-header-title">
              <h2>ENCOUNTERS</h2>
              {showEncounterTabs && (
                <>
                  <EncounterSearchSection />
                  <EncountersSectionMenu reloadTab={reloadTab} />
                </>
              )}
            </div>
            <div className="encounters-section-header-tabs">
              {showEncounterTabs && (
                <div className="encounters-section-header-tabs-group">
                  {[TAB_ACTIVE, TAB_PROJECTED, TAB_CANCELED].map(function(tab) {
                    return (
                      <button
                        key={tab}
                        className={cx({ 'active-tab': activeTab === tab })}
                        onClick={() => setActiveTab(tab)}
                      >
                        {tab}
                      </button>
                    );
                  })}
                </div>
              )}
              {showPaymentsSection && (
                <div className="encounters-section-header-tabs-group">
                  <button
                    className={cx({ 'active-tab': activeTab === TAB_PAYMENTS })}
                    onClick={() => setActiveTab(TAB_PAYMENTS)}
                  >
                    {TAB_PAYMENTS}
                  </button>
                </div>
              )}
            </div>
          </div>
          {activeTab === TAB_PAYMENTS ? (
            <PaymentsSection key={activeTabKey} />
          ) : (
            <EncountersTab key={activeTabKey} activeTab={activeTab} />
          )}
        </div>
      </section>
    </EncountersSectionProvider>
  );

  function reloadTab() {
    setActiveTabRefreshIndex(i => i + 1);
  }
}
