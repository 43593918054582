import React from 'react';
import { useNavigate } from 'react-router-dom';

import Icon from '../../../../../common/general/Icon';
import { scDarkBlue100, scGray70 } from '../../../../../constants/systemColors';
import AccessDeniedTooltip from '../../../../AccessDeniedTooltip/AccessDeniedTooltip';

export const ReviewColumn = ({ isBlinded, reviewButtonDisabled, link }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      {isBlinded ? (
        <AccessDeniedTooltip showContentOnly={false} placement="top">
          <span className="tooltip-cell">
            <Icon style={{ color: scGray70, fontSize: 30 }} suit={'material-outline'}>
              rate_review
            </Icon>
          </span>
        </AccessDeniedTooltip>
      ) : (
        <span className="tooltip-cell">
          {reviewButtonDisabled ? (
            <Icon style={{ color: scGray70, fontSize: 30 }} suit={'material-outline'}>
              rate_review
            </Icon>
          ) : (
            <Icon
              style={{ color: scDarkBlue100, fontSize: 30 }}
              onClick={() => {
                navigate(link);
              }}
            >
              rate_review
            </Icon>
          )}
        </span>
      )}
    </React.Fragment>
  );
};
